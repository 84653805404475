import { PlaybackState } from "@tv4/avod-web-player-common";

import { usePlayerState } from "../providers/CoreProvider";
import { useLayoutSize } from "../providers/LayoutProvider";
import { LayoutSize } from "../util/device";
import { getDurationString, getTimeString } from "../util/time";
import { useTracksMenu } from "./audio-text-tracks/TracksMenu/TracksMenu";
import ChromeCastButton from "./ChromeCastButton";
import {
  Body,
  ButtonGroup,
  ButtonsContainer,
  ControlsContainer,
  ProgressContainer,
} from "./DefaultControls/DefaultControls.styles";
import FullscreenButton from "./FullscreenButton";
import GoToLiveButton from "./GoToLiveButton";
import GoToStartOverButton from "./GoToStartOverButton";
import LiveStatus from "./LiveStatus";
import PlayPauseButton from "./PlayPauseButton";
import ProgressBar from "./progress-bar/ProgressBar";
import SeekBackwardButton from "./SeekBackwardButton";
import SeekForwardButton from "./SeekForwardButton";
import { TimeLabel } from "./TimeLabel";
import VolumeController from "./VolumeController/VolumeController";

export function ChromeCastControls() {
  const {
    canPause,
    canSeek,
    canStartOver,
    currentTime,
    duration,
    isLive,
    isPauseAd,
    playbackState,
    utcCurrentTimeMs,
  } = usePlayerState();
  const size = useLayoutSize();

  const showTimeline = canSeek && !isPauseAd;
  const showSeekbuttons = canSeek && !isPauseAd;

  const showPlayPauseButton =
    canPause || playbackState === PlaybackState.PAUSED;

  const { closeTracksMenu, TracksButton, TracksMenu } = useTracksMenu();
  // when smallest layout we show some buttons in the header instead of here
  const isSmallestLayout = size === LayoutSize.SMALLEST;
  const showRemainingTime = !isLive && !isSmallestLayout;

  const isSeekableLiveStream = isLive && canSeek && !isPauseAd;
  const showStartOverButton = canStartOver && !isSmallestLayout && !isPauseAd;

  return (
    <ControlsContainer $compact={isSmallestLayout}>
      <Body onClick={closeTracksMenu} />
      <ProgressContainer $compact={isSmallestLayout}>
        {showTimeline && (
          <>
            <TimeLabel>
              {utcCurrentTimeMs
                ? getTimeString(utcCurrentTimeMs)
                : getDurationString(currentTime)}
            </TimeLabel>
            <ProgressBar />
            {isLive && <LiveStatus />}
            {showRemainingTime && (
              <TimeLabel>{getDurationString(currentTime - duration)}</TimeLabel>
            )}
          </>
        )}
      </ProgressContainer>

      <ButtonsContainer>
        <ButtonGroup $position="left">
          {showPlayPauseButton && <PlayPauseButton />}
          {showStartOverButton && <GoToStartOverButton />}
          {showSeekbuttons && !isSmallestLayout && (
            <>
              <SeekBackwardButton />
              <SeekForwardButton />
            </>
          )}
        </ButtonGroup>
        <ButtonGroup $position="center">
          {isSmallestLayout ? (
            <>
              {showSeekbuttons && <SeekBackwardButton />}
              {showSeekbuttons && <SeekForwardButton />}
            </>
          ) : (
            TracksButton && <TracksButton />
          )}
        </ButtonGroup>
        <ButtonGroup $position="right">
          {isSeekableLiveStream && <GoToLiveButton />}
          {!isSmallestLayout && <ChromeCastButton />}
          <VolumeController />
          {!isSmallestLayout && <FullscreenButton />}
        </ButtonGroup>
      </ButtonsContainer>
      {TracksMenu && <TracksMenu />}
    </ControlsContainer>
  );
}
