var NpawObject = require('../object')
/**
 * This static class provides device detection methods.
 *
 * @class
 * @static
 * @memberof npaw
 */
var DeviceDetector = NpawObject.extend({
  constructor: function () {
    if (typeof navigator !== 'undefined' && navigator.userAgent) {
      this._isSmartTVDevice = navigator.userAgent.toLowerCase().match(/smarttv/) ||
        navigator.userAgent.toLowerCase().match(/smart-tv/) ||
        navigator.userAgent.toLowerCase().match(/appletv/) ||
        navigator.userAgent.toLowerCase().match(/apple tv/)
      this._isPlaystationDevice = navigator.userAgent.match(/PlayStation/) !== null
      this._isIphoneDevice = navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null
      this._isAndroidDevice = navigator.userAgent.match(/Android/) !== null
      this._isSmartphoneDevice = !this._isSmartTVDevice && !this._isPlaystationDevice && (this._isIphoneDevice || this._isAndroidDevice)
      this._isDesktopDevice = !this._isSmartphoneDevice && !this._isSmartTVDevice && !this._isPlaystationDevice
    }
  },

  isIphone: function () {
    return this._isIphoneDevice
  },

  isAndroid: function () {
    return this._isAndroidDevice
  },

  isSmartphone: function () {
    return this._isSmartphoneDevice
  },

  isDesktop: function () {
    return this._isDesktopDevice
  },

  isSmartTV: function () {
    return this._isSmartTVDevice
  },

  isPlayStation: function () {
    return this._isPlaystationDevice
  }
})

module.exports = DeviceDetector
