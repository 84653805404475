import { DEFAULT_PLAYER_CONFIG } from "@tv4/avod-web-player-common";

import BackwardSvg from "../icons/BackwardIcon.svg";
import { useControls } from "../providers/CoreProvider";
import { PlayerControlButton as Button } from "../styles";

export const SeekBackwardButton = () => {
  const controls = useControls();
  const SEEK_AMOUNT = DEFAULT_PLAYER_CONFIG.seekAmount;

  const seek = () => {
    controls?.seekBackward?.(SEEK_AMOUNT);
  };

  return (
    <Button aria-label={`jump ${SEEK_AMOUNT} seconds back`} onClick={seek}>
      <BackwardSvg />
    </Button>
  );
};

export default SeekBackwardButton;
