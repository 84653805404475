import { PlayerMode, VisualElement } from "@tv4/avod-web-player-common";
import debounce from "lodash.debounce";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  getContainerLayoutSize,
  getLayoutOrientation,
  getWidthLayoutSize,
  LayoutOrientation,
  LayoutSize,
} from "../util/device";

interface PlayerDimensions {
  playerWidth: number;
  playerHeight: number;
}

type TLayoutProviderContext = {
  size: LayoutSize;
  playerDimensions: PlayerDimensions;
  orientation: LayoutOrientation;
  interacting: () => boolean;
  handleInteractionStart: () => void;
  handleInteractionEnd: () => void;
  uiVisible: boolean;
  onShareClick?: () => void;
  setUIVisible: (visible: boolean) => void;
  tracksMenuOpen: boolean;
  setTracksMenuOpen: (open: boolean) => void;
  visuals?: VisualElement[];
  playerMode?: PlayerMode;
  hideMetadataOutsideFullscreen?: boolean;
  hideSubtitlesMenu?: boolean;
  showMobileMuteButton?: boolean;
  playerProgressTintColor?: string;
  enableFullscreen?: boolean;
  noInitialLoadSpinner: boolean;
};

type TLayoutProviderProps = {
  root: HTMLElement;
  enableVisuals?: VisualElement[];
  playerMode?: PlayerMode;
  hideMetadataOutsideFullscreen?: boolean;
  hideSubtitlesMenu?: boolean;
  onShareClick?: () => void;
  showMobileMuteButton?: boolean;
  playerProgressTintColor?: string;
  children: ReactNode;
  enableFullscreen?: boolean;
  noInitialLoadSpinner?: boolean;
};

const RESIZE_RECALCULATION_DELAY = 500;

const noop = (): void => undefined;

export const LayoutContext = createContext<TLayoutProviderContext>({
  size: LayoutSize.SMALLEST,
  playerDimensions: { playerWidth: 0, playerHeight: 0 },
  orientation: LayoutOrientation.LANDSCAPE,
  interacting: () => false,
  handleInteractionStart: noop,
  handleInteractionEnd: noop,
  uiVisible: false,
  setUIVisible: noop,
  tracksMenuOpen: false,
  setTracksMenuOpen: noop,
  visuals: [],
  enableFullscreen: true,
  noInitialLoadSpinner: false,
});

const HIDE_UI_DELAY = 3 * 1000;
let uiTimeout: number = -1;
function clearUITimeout() {
  if (uiTimeout !== -1) {
    clearTimeout(uiTimeout);
    uiTimeout = -1;
  }
}
function setUITimeout(callback: () => void) {
  clearUITimeout();
  uiTimeout = window.setTimeout(callback, HIDE_UI_DELAY);
}

export function LayoutProvider({
  root,
  children,
  enableVisuals,
  playerMode,
  hideMetadataOutsideFullscreen,
  hideSubtitlesMenu,
  showMobileMuteButton,
  playerProgressTintColor,
  enableFullscreen,
  onShareClick,
  noInitialLoadSpinner,
}: TLayoutProviderProps) {
  const [size, setSize] = useState(getContainerLayoutSize(root));
  const [playerDimensions, setPlayerDimensions] = useState<PlayerDimensions>({
    playerWidth: 0,
    playerHeight: 0,
  });
  const [orientation, setOrientation] = useState(
    getLayoutOrientation(window.innerWidth, window.innerHeight)
  );

  const [tracksMenuOpen, setTracksMenuOpen] = useState(false);
  const [visuals, setVisuals] = useState<VisualElement[]>();

  useEffect(() => {
    setVisuals(enableVisuals ?? []);
  }, [enableVisuals]);

  useEffect(() => {
    if ("ResizeObserver" in window) {
      const resizeObserver = new ResizeObserver(
        debounce(([rootEntry]) => {
          if (rootEntry) {
            setOrientation(
              getLayoutOrientation(window.innerWidth, window.innerHeight)
            );
            setSize(getWidthLayoutSize(rootEntry.contentRect.width));
            setPlayerDimensions({
              playerWidth: rootEntry.contentRect.width,
              playerHeight: rootEntry.contentRect.height,
            });
          }
        }, RESIZE_RECALCULATION_DELAY)
      );
      resizeObserver.observe(root);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [root]);

  const [uiVisible, setUIVisible] = useState(false);

  const interactions = useRef(0);

  const handleInteractionStart = () => {
    setUIVisible(true);
    clearUITimeout();
    interactions.current++;
  };

  const handleInteractionEnd = useCallback(() => {
    interactions.current--;
    if (interactions.current === 0) {
      setUITimeout(() => setUIVisible(false));
    }
  }, [setUIVisible]);

  const interacting = useCallback((): boolean => interactions.current > 0, []);

  // clear timeout on unmount
  useEffect(() => clearUITimeout, []);

  // clear timeout if ui is not visible
  useEffect(() => {
    if (!uiVisible) {
      clearUITimeout();
    }
  }, [uiVisible]);

  return (
    <LayoutContext.Provider
      value={{
        uiVisible,
        onShareClick,
        setUIVisible,
        interacting,
        handleInteractionStart,
        handleInteractionEnd,
        size,
        playerDimensions,
        orientation,
        tracksMenuOpen,
        setTracksMenuOpen,
        visuals,
        playerMode,
        hideMetadataOutsideFullscreen,
        hideSubtitlesMenu,
        showMobileMuteButton,
        playerProgressTintColor,
        enableFullscreen,
        noInitialLoadSpinner:
          typeof noInitialLoadSpinner === "boolean"
            ? noInitialLoadSpinner
            : false,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export const useLayoutSize = (): LayoutSize => {
  const { size } = useContext(LayoutContext);
  return size;
};

export const usePlayerDimensions = (): PlayerDimensions => {
  const { playerDimensions } = useContext(LayoutContext);
  return playerDimensions;
};

export const useLayoutOrientation = (): LayoutOrientation => {
  const { orientation } = useContext(LayoutContext);
  return orientation;
};

export const useTracksMenuOpen = () => {
  const { tracksMenuOpen, setTracksMenuOpen } = useContext(LayoutContext);

  return { tracksMenuOpen, setTracksMenuOpen };
};

export const useUIVisibility = () => {
  const { uiVisible, setUIVisible } = useContext(LayoutContext);

  return { uiVisible, setUIVisible };
};

export const useVisuals = () => {
  const { visuals } = useContext(LayoutContext);
  return visuals;
};

export const useLayoutSettings = () => {
  const {
    hideMetadataOutsideFullscreen,
    playerMode,
    showMobileMuteButton,
    playerProgressTintColor,
    hideSubtitlesMenu,
    onShareClick,
    enableFullscreen,
    noInitialLoadSpinner,
  } = useContext(LayoutContext);
  return {
    hideMetadataOutsideFullscreen,
    playerMode,
    showMobileMuteButton,
    playerProgressTintColor,
    hideSubtitlesMenu,
    onShareClick,
    enableFullscreen,
    noInitialLoadSpinner,
  };
};
