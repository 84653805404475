/* global ActiveXObject */
var NpawObject = require('../object')

var DataExtractor = NpawObject.extend({
  constructor: function (plugin) {
    this.plugin = plugin
  },

  getAllData: function () {
    var returnValue = this.getNonRandomData()
    returnValue.timestamp = this.getTimestamp()
    return returnValue
  },

  getNonRandomData: function () {
    var returnValue = {}
    returnValue.userAgent = this.getUserAgent()
    returnValue.threads = this.getVirtualCores()
    returnValue.language = this.getLanguage()
    returnValue.langList = this.getAvailableLanguages()
    returnValue.resolution = this.getResolution()
    returnValue.colorDepth = this.getColorDepth()
    returnValue.deviceMemory = this.getMemory()
    returnValue.touchscreen = this.getTouchscreen()
    returnValue.localStorage = this.getLocalStorage()
    returnValue.sessionStorage = this.getSessionStorage()
    returnValue.cookiesAvailable = this.getCookiesAvailable()
    returnValue.flashAvailable = this.getHasFlash()
    returnValue.timeZone = this.getTimeZone()
    returnValue.plugins = this.getPluginList()
    return returnValue
  },

  // Getters
  getUserAgent: function () {
    return this._getNavigatorValue('userAgent')
  },

  getVirtualCores: function () {
    return this._getNavigatorValue('hardwareConcurrency')
  },

  getLanguage: function () {
    return this._getNavigatorValue('language')
  },

  getAvailableLanguages: function () {
    return this._getNavigatorValue('languages')
  },

  getResolution: function () {
    var ret = null
    try {
      if (this._getNavigatorValue('screen')) {
        ret = navigator.screen.width.toString() + navigator.screen.height.toString()
      }
    } catch (err) {
      // ret = null
    }
    return ret
  },

  getColorDepth: function () {
    var ret = null
    try {
      if (this._getNavigatorValue('screen')) {
        ret = navigator.screen.colorDepth
      }
    } catch (err) {
      // ret = null
    }
    return ret
  },

  getMemory: function () {
    return this._getNavigatorValue('deviceMemory')
  },

  getTouchscreen: function () {
    return this._getNavigatorValue('maxTouchPoints') || false
  },

  getLocalStorage: function () {
    var ret = false
    try {
      ret = typeof localStorage !== 'undefined'
    } catch (err) {
      // ret = false
    }
    return ret
  },

  getSessionStorage: function () {
    var ret = false
    try {
      ret = typeof sessionStorage !== 'undefined'
    } catch (err) {
      // ret = false
    }
    return ret
  },

  getCookiesAvailable: function () {
    return this._getNavigatorValue('cookieEnabled') || false
  },

  getHasFlash: function () {
    var ret = false
    try {
      var plugins = this._getNavigatorValue('plugins')
      ret = (plugins && typeof plugins['Shockwave Flash'] === 'object') || (typeof window !== 'undefined' && window.ActiveXObject && (new ActiveXObject('ShockwaveFlash.ShockwaveFlash')) !== false)
    } catch (err) {
      // ret = false
    }
    return ret
  },

  getPluginList: function () {
    var ret = null
    try {
      var plugins = this._getNavigatorValue('plugins')
      if (plugins && plugins.length !== 0) {
        var pluginlist = ''
        for (var counter = 0; counter < navigator.plugins.length; counter++) {
          pluginlist += navigator.plugins[counter].description + ' ' + navigator.plugins[counter].filename + ' ' + navigator.plugins[counter].name + ' '
        }
        ret = pluginlist
      }
    } catch (err) {
      // return null
    }
    return ret
  },

  getTimeZone: function () {
    var ret = null
    try {
      var date = new Date()
      ret = date.getTimezoneOffset().toString()
    } catch (err) {
      // return null
    }
    return ret
  },

  getTimestamp: function () {
    return new Date().getTime()
  },

  _navigatorCheck: function () {
    return typeof navigator !== 'undefined'
  },

  _getNavigatorValue: function (key) {
    var ret = null
    try {
      if (this._navigatorCheck()) {
        ret = navigator[key]
      }
    } catch (err) {
      // ret = null
    }
    return ret
  }

})

module.exports = DataExtractor
