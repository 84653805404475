import { Adapter } from "./Adapter";
import {
  AdapterStateManager,
  CONTENT_ADAPTER_INITIAL_STATE,
  ContentAdapterState,
} from "./AdapterStateManager";

// The extended adapter has type any, create our own
// interface for the documented methods.
export interface IContentAdapter extends Adapter {
  // Required
  getIsLive(): boolean;

  // Optional
  getRendition(): string; // `${width}x${height}@${bitrate}Mbps`
  getPlayrate(): number;
  getThroughput(): unknown;
  getFramesPerSecond(): number;
  getDroppedFrames(): number;
  getLatency(): number;
  getVideoCodec(): string;
  getAudioCodec(): string;

  // Override these
  registerListeners(): void;
  unregisterListeners(): void;
  checkExistsPlayer(): boolean;

  // Available after NPAW merges the custom class with the
  // builtin base adapter.
  // Should not be overridden
  fireSeekBegin(): void;
  fireSeekEnd(): void;
  checkExistsObjectOnPage(element: HTMLElement): boolean;
}

export class ContentAdapter {
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // NOTE: Once NPAW plugin has processed the class, all
  // custom properties will the stripped from it.
  // Including anything set from the constructor.
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  // Once NPAW processes the class, player will be
  // a reference to the first parameter passed to
  // registerAdapterFromClass(--> player <--, adapter)
  //
  // Assign it here for TypeScript purposes. This property
  // will be stripped by NPAW plugin before being merged with
  // their internal base adapter functionality.
  private player = new AdapterStateManager<ContentAdapterState>(
    CONTENT_ADAPTER_INITIAL_STATE
  );

  public getIsLive() {
    return this.player.getState().isLive;
  }

  public getPlayerName() {
    return this.player.getState().playerName;
  }

  public getPlayerVersion() {
    return this.player.getState().playerVersion;
  }

  public getResource() {
    return (
      this.player.getState().manifestUrl ||
      this.player.getConfig()?.media?.accessUrl ||
      this.player.getConfig()?.media?.manifestUrl ||
      ""
    );
  }

  public getDuration() {
    return this.getIsLive() ? 0 : this.player.getState().duration;
  }

  public getPlayhead() {
    return this.player.getState().playhead;
  }

  public getTitle() {
    return (
      this.player.getConfig()?.metadata?.tracking.youbora?.title || "Unknown"
    );
  }

  public getRendition() {
    const { videoWidth, videoHeight, bitrate } = this.player.getState();
    return `${videoWidth}x${videoHeight}@${Number((bitrate / 1000 / 1000).toFixed(2))}Mbps`;
  }

  // Bits per second
  public getBitrate() {
    return this.player.getState().bitrate;
  }

  public getPlayrate() {
    return this.player.getState().playrate;
  }
  public getThroughput() {
    return 0;
  }
  public getFramesPerSecond() {
    return this.player.getState().fps;
  }
  public getDroppedFrames() {
    return this.player.getState().droppedFrames;
  }
  public getLatency() {
    return this.player.getState().latency;
  }
  public getVideoCodec() {
    return this.player.getState().videoCodec;
  }
  public getAudioCodec() {
    return this.player.getState().audioCodec;
  }

  public registerListeners() {
    // noop, listeners are handled in NpawTracker.ts
  }

  public unregisterListeners() {
    // noop, listeners are handled in NpawTracker.ts
  }

  public checkExistsPlayer() {
    return true;
  }
}
