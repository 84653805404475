const NpawObject = require('../object');
const DeprecatedOptions = require('./deprecatedOptions');
const { default: Log } = require('../../common/log');
const TransportFormat = require('../constants/transportFormat');
const StreamingProtocol = require('../constants/streamingProtocols');
const RequestMethod = require('../constants/requestMethod');
const { AnalyticsTag } = require('../../common/Constants');

var Options = NpawObject.extend(
  /** @lends npaw.Options.prototype */
  {
    /**
     * This Class store npaw configuration settings.
     * Any value specified in this class, if set, will override the info the plugin is able to get
     * on its own.
     *
     * @constructs Options
     * @param {Object|Options} [options] A literal containing values.
     * @extends npaw.NpawObject
     * @memberof npaw
     */
    constructor: function (options) {
      /** @prop {boolean} [enabled=true] If false, the plugin won't send NQS requests. */
      this.enabled = true;

      /**
       * @prop {string} [authToken]
       * Optional auth token to validate all the requests
       */
      this.authToken = undefined;

      /**
       * @prop {string} [authType]
       * Optional auth type. Used if authToken is set.
       * 'Bearer' by default.
       */
      this.authType = 'Bearer';

      /**
       *  @prop {boolean} [preventZombieViews=true]
       * If true, the plugin will check if the last event
       * was sent more than 10 mins ago
       * so it will not send more events to the same view
       */
      this.preventZombieViews = true;

      /**
       * @prop {boolean} [offline=false]
       * If true the plugin will store the events and send them later when there's connection
       */
      this.offline = false;

      /** @prop {string} [referer] Site url.
       * By default, window.location.href
       */
      this.referer = undefined;

      /** @prop {string} [referral] Previous site url.
       * By default, document.referrer
       */
      this.referral = undefined;

      /**
       * @prop {boolean} [disableCookies]
       * To disable cookies storage fallback after local/sessionstorage
       * True by default
       */
      this.disableCookies = true;

      /**
       * @prop {boolean} [forceCookies]
       * To force the use of cookies storage instead of local/sessionstorage
       * False by default
       */
      this.forceCookies = false;

      /**
       * @prop {boolean} [disableStorage]
       * To disable all possible storages usage (cookies, localStorage, sessionStorage)
       * CAUTION: enabling this option session tracking may stop to work properly
       * False by default
       */
      this.disableStorage = false;

      // USER

      /**
       * @prop {string} [user.email]
       * User email.
       */
      this['user.email'] = undefined;

      /**
       * @prop {string} [user.type]
       * User type.
       */
      this['user.type'] = undefined;

      /**
       * @prop {string} [user.name]
       * User ID value inside your system.
       */
      this['user.name'] = undefined;

      /**
       * @prop {string} [userId]
       * User ID value inside your system.
       */
      this['userId'] = undefined;

      /**
       * @prop {string} [profileId]
       * Profile ID value inside your system.
       */
      this['profileId'] = undefined;

      /**
       *  @prop {boolean} [user.obfuscateIp=false]
       * If true, the view will have the IP obfuscated
       */
      this['user.obfuscateIp'] = false;

      /**
       * @prop {string} [user.anonymousId]
       * Anonymous identifier of the user provided by the customer.
       */
      this['user.anonymousId'] = undefined;

      /**
       * @prop {string} [user.privacyProtocol]
       * Privacy protocol to be used, nothing by default.
       * Possible values are "optin" and "optout"
       */
      this['user.privacyProtocol'] = undefined;

      // PARSERS

      /**
       * @prop {boolean} [parse.manifest=false]
       * If true the plugin will look for location value in manifest header to retrieve the actual resource,
       * will parse HLS files to use the first .ts file found as resource and
       * will look for location and segment values inside DASH manifest to retrieve the actual resource
       * It might slow performance down.
       */
      this['parse.manifest'] = false;

      /**
       * @prop {object} [parse.manifest.auth={}]
       * If parse.manifest enabled, it adds extra headers to the request of the content.
       * Use this if for example, the player needs to include authentication headers to request the content,
       * so the plugin needs it to access to the manifest files too.
       */
      this['parse.manifest.auth'] = {};

      /**
       * @prop {array<string>} [parse.CdnNameHeader]
       * If defined, resource parse will try to fetch the CDN code from the custom header defined
       * by this property. ie: '[x-cdn-forward]'
       */
      this['parse.cdnNameHeader'] = ['x-cdn-forward'];

      /**
       * @prop {string} [parse.CdnNodeHeader]
       * If defined, resource parse will try to fetch the CDN node name from the custom header defined
       * by this property. ie: 'x-node'
       */
      this['parse.cdnNodeHeader'] = '';

      /**
       * @prop {boolean} [parse.CdnNode=false]
       * If true the plugin will query the CDN to retrieve the node name.
       * It might slow performance down.
       */
      this['parse.cdnNode'] = false;

      /**
       * @prop {array<string>} [parse.CdnNode.list=false]
       * If true the plugin will query the CDN to retrieve the node name.
       * It might slow performance down.
       */
      this['parse.cdnNode.list'] = [
        'Akamai',
        'Amazon',
        'Cloudfront',
        'Level3',
        'Fastly',
        'Highwinds',
        'Telefonica',
        'Edgecast',
        'NosOtt'
      ];

      /**
       * @prop {function} [parse.fdsResponseHost=null]
       * Parses fastdata response to modify the host where the requests will be sent
       */
      this['parse.fdsResponseHost'] = undefined;

      /**
       * @prop {function} [parse.cdnSwitchHeader=false]
       * Parses a video chunk or manifest every x seconds to read the x-cdn header and report it
       */
      this['parse.cdnSwitchHeader'] = false;

      /**
       * @prop {function} [parse.cdnTTL=60]
       * if parse.cdnSwitchHeader enabled, the time between new requests
       */
      this['parse.cdnTTL'] = 60;

      // NETWORK

      /** @prop {string} [network.ip] IP of the viewer/user. ie= '100.100.100.100'. */
      this['network.ip'] = undefined;

      /** @prop {string} [network.isp] Name of the internet service provider of the viewer/user. */
      this['network.isp'] = undefined;

      /**
       * @prop {string} [network.connectionType]
       * Type of connection used
       */
      this['network.connectionType'] = undefined;

      // DEVICE

      /**
       * @prop {string} [device.id]
       * Unique identifyer of the device. If set it will consider the value as the device id.
       * By default, the plugin tries to generate a unique id based on exposed information on the browser.
       * It won't be sent if 'device.isAnonymous option' is set to true.
       */
      this['device.id'] = undefined;

      /**
       * @prop {string} [device.code]
       * Youbora's device code. If specified it will rewrite info gotten from user agent.
       * See a list of codes in {@link http://mapi.youbora.com:8081/devices}
       */
      this['device.code'] = undefined;

      /**
       * @prop {string} [device.model]
       * Device model name
       */
      this['device.model'] = undefined;

      /**
       * @prop {string} [device.brand]
       * Device vendor name
       */
      this['device.brand'] = undefined;

      /**
       * @prop {string} [device.type]
       * Device type (pc, smartphone, stb, tv, etc.)
       */
      this['device.type'] = undefined;

      /**
       * @prop {string} [device.name]
       * Device name. It must exist in NPAW database.
       */
      this['device.name'] = undefined;

      /**
       * @prop {string} [device.osName]
       * OS name.
       */
      this['device.osName'] = undefined;

      /**
       * @prop {string} [device.osVersion]
       * OS version.
       */
      this['device.osVersion'] = undefined;

      /**
       * @prop {string} [device.browserName]
       * Browser name.
       */
      this['device.browserName'] = undefined;

      /**
       * @prop {string} [device.browserVersion]
       * Browser version.
       */
      this['device.browserVersion'] = undefined;

      /**
       * @prop {string} [device.browserType]
       * Browser type.
       */
      this['device.browserType'] = undefined;

      /**
       * @prop {string} [device.browserEngine]
       * Browser engine.
       */
      this['device.browserEngine'] = undefined;

      /**
       * @prop {string} [device.EDID]
       * Connected screen EDID.
       * The expected format is a hex value
       */
      this['device.EDID'] = undefined;

      /**
       * @prop {bool} [device.isAnonymous]
       * If true, it blocks 'deviceUUID' parameter in requests.
       */
      this['device.isAnonymous'] = false;

      // CONTENT

      /** @prop {string} [content.transactionCode] Custom unique code to identify the view. */
      this['content.transactionCode'] = undefined;

      /** @prop {string} [content.resource] URL/path of the current media resource. */
      this['content.resource'] = undefined;

      /** @prop {boolean} [content.isLive] True if the content is live false if VOD. */
      this['content.isLive'] = undefined;

      /** @prop {boolean} [content.isLive.noSeek] True if the player seeks automatically when resumed or ending buffer. Only for live content. False by default */
      this['content.isLive.noSeek'] = false;

      /** @prop {boolean} [content.isLive.noMonitor] True if the player returns non consistent values for the playhead on live, so playhead monitor wont work to detect buffers and seeks. False by default. */
      this['content.isLive.noMonitor'] = false;

      /** @prop {string} [content.title] Title of the media. */
      this['content.title'] = undefined;

      /** @prop {string} [content.program] Secondary title of the media */
      this['content.program'] = undefined;

      /** @prop {number} [content.duration] Duration of the media. */
      this['content.duration'] = undefined;

      /** @prop {number} [content.fps] Frames per second of the content in the current moment. */
      this['content.fps'] = undefined;

      /** @prop {number} [content.segmentDuration] Video segment length in milliseconds. */
      this['content.segmentDuration'] = undefined;

      /** @prop {number} [content.bitrate] Bitrate of the content in bits per second. */
      this['content.bitrate'] = undefined;

      /** @prop {number} [content.totalBytes] Total downloaded bytes of the content. */
      this['content.totalBytes'] = undefined;

      /** @prop {bool} [content.sendTotalBytes] Additionaly report totalbytes or not, default false. */
      this['content.sendTotalBytes'] = false;

      /** @prop {number} [content.throughput] Throughput of the client bandwith in bits per second. */
      this['content.throughput'] = undefined;

      /** @prop {string} [content.rendition] Name of the current rendition of the content. */
      this['content.rendition'] = undefined;

      /**
       * @prop {string} [content.cdn]
       * Codename of the CDN where the content is streaming from.
       * See a list of codes in {@link http://mapi.youbora.com:8081/cdns}
       * */
      this['content.cdn'] = undefined;

      /** @prop {string} [content.cdnNode] CDN node id */
      this['content.cdnNode'] = undefined;

      /**
       * @prop {number} [content.cdnType] CDN node content access type
       * It defines if the content request hits the cache or not
       * TCP_HIT / HIT: 1
       * TCP_MISS / MISS: 2
       * TCP_MEM_HIT: 3
       * TCP_IMS_HIT: 4
       */
      this['content.cdnType'] = undefined;

      /**
       * @prop {object} [content.metadata]
       * Item containing mixed extra information about the content like: director, parental rating,
       * device info or the audio channels.This object may store any serializable key:value info.
       */
      this['content.metadata'] = {};

      /**
       * @prop {object} [content.metrics]
       * Item containing metrics in json format. Reported every ping if the values change
       */
      this['content.metrics'] = {};

      /** @prop {string} [content.streamingProtocol] Name of the streaming media protocol.
       * Can be:
       *   - HDS (Adobe HDS)
       *   - HLS (Apple HLS)
       *   - MSS (Microsoft Smooth Streaming)
       *   - DASH (MPEG-DASH)
       *   - RTMP (Adobe RTMP)
       *   - RTP (RTP)
       *   - RTSP (RTSP)
       *   - MULTICAST (Multicast)
       *   - DVB (DVB)
       *   - DVBC (DVB-C)
       *   - DVBT (DVB-T)
       *   - DVBT2 (DVB-T2)
       */
      this['content.streamingProtocol'] = undefined;

      /** @prop {string} [content.transportFormat] Name of the transport format.
       * Can be:
       *   - TS (MPEG-2 TS)
       *   - MP4 (Fragmented MP4)
       */
      this['content.transportFormat'] = undefined;

      /** @prop {number} [content.package] Package of the media. */
      this['content.package'] = undefined;

      /** @prop {number} [content.saga] Saga of the media. */
      this['content.saga'] = undefined;

      /** @prop {number} [content.tvShow] TV Show of the media. */
      this['content.tvShow'] = undefined;

      /** @prop {number} [content.season] Season of the media. */
      this['content.season'] = undefined;

      /** @prop {number} [content.episodeTitle] Episode title of the media. */
      this['content.episodeTitle'] = undefined;

      /** @prop {number} [content.channel] Channel name of the media. */
      this['content.channel'] = undefined;

      /** @prop {number} [content.id] ID of the media. */
      this['content.id'] = undefined;

      /** @prop {number} [content.imdbId] IMDB id of the media. */
      this['content.imdbId'] = undefined;

      /** @prop {number} [content.gracenoteId] Gracenote id of the media. */
      this['content.gracenoteId'] = undefined;

      /** @prop {number} [content.type] Type of the media. */
      this['content.type'] = undefined;

      /** @prop {number} [content.genre] Genre of the media. */
      this['content.genre'] = undefined;

      /** @prop {number} [content.language] Language of the media. */
      this['content.language'] = undefined;

      /** @prop {boolean} [content.autodetect.language] Auto detect change languages value. */
      this['content.autodetect.language'] = true;

      /** @prop {number} [content.subtitles] Subtitles of the media. */
      this['content.subtitles'] = undefined;

      /** @prop {boolean} [content.autodetect.subtitles] Auto detect change subtitles value. */
      this['content.autodetect.subtitles'] = true;

      /** @prop {number} [content.contractedResolution] Contracted Resolution of the media. */
      this['content.contractedResolution'] = undefined;

      /** @prop {number} [content.cost] Cost of the media. */
      this['content.cost'] = undefined;

      /** @prop {number} [content.price] Price of the media. */
      this['content.price'] = undefined;

      /** @prop {number} [content.playbackType] Type of the media. Can be Vod, Live, catch-up or offline */
      this['content.playbackType'] = undefined;

      /** @prop {number} [content.drm] DRM of the media. */
      this['content.drm'] = undefined;

      // Encoding

      /** @prop {number} [content.encoding.videoCodec] Video codec of the media. */
      this['content.encoding.videoCodec'] = undefined;

      /** @prop {number} [content.encoding.audioCodec] Audio codec of the media. */
      this['content.encoding.audioCodec'] = undefined;

      /** @prop {number} [content.encoding.codecSettings] Codec settings of the media. */
      this['content.encoding.codecSettings'] = undefined;

      /** @prop {number} [content.encoding.codecProfile] Codec profile of the media. */
      this['content.encoding.codecProfile'] = undefined;

      /** @prop {number} [content.encoding.containerFormat] Container format of the media. */
      this['content.encoding.containerFormat'] = undefined;

      // ADS

      /**
       * @prop {object} [ad.metadata]
       * Item containing mixed extra information about ads like: request url.
       * This object may store any serializable key:value info.
       */
      this['ad.metadata'] = {};

      /**
       * @prop {string} [ad.campaign]
       * String containing the name of the campaign
       */
      this['ad.campaign'] = undefined;

      /**
       * @prop {string} [ad.creativeId]
       * String containing the id of the creative
       */
      this['ad.creativeId'] = undefined;

      /**
       * @prop {string} [ad.provider]
       * String containing the provider of the ad
       */
      this['ad.provider'] = undefined;

      /**
       * @prop {string} [ad.resource]
       * String containing the ad resource
       */
      this['ad.resource'] = undefined;

      /**
       * @prop {string} [ad.title]
       * String containing the title of the campaign
       */
      this['ad.title'] = undefined;

      /**
       * @prop {duration} [ad.duration]
       * Duration of the ad in seconds
       */
      this['ad.duration'] = undefined;

      /**
       * @prop {object} [ad.expectedPattern]
       * Json with the position of the breaks expected.
       * Arrays are the number of breaks, and the numbers in them, the number of ads for each break
       *
       * Example:
       * {pre: [1],
       * mid: [1,2],
       * post: [1]}
       * Would be a view with 1 preroll break with 1 ad, 2 midroll breaks, one with 1 ad and
       * the other with 2, and one postroll break with 1 ad.
       */
      this['ad.expectedPattern'] = undefined;

      /**
       * @prop {string} [ad.givenAds]
       * Number of ads given by the adserver for this break
       */
      this['ad.givenAds'] = undefined;

      /**
       * @prop {number[]} [ad.breaksTime]
       * Array of numbers for the time position of adbreaks
       */
      this['ad.breaksTime'] = undefined;

      /**
       * @prop {string} [ad.expectedBreaks]
       * Number of breaks expected for the view
       */
      this['ad.expectedBreaks'] = undefined;

      /**
       * @prop {number} [ad.givenBreaks]
       * Number of breaks given by the adserver for the view
       */
      this['ad.givenBreaks'] = undefined;

      /**
       * @prop {boolean} [ad.ignore]
       * False by default.
       * If true, npaw blocks ad events and calculates jointime ignoring ad time.
       */
      this['ad.ignore'] = false;

      /**
       * @prop {boolean} [ad.blockerDetected]
       * Null (notified as false) by default.
       * Sets if an adblocker has been detected
       */
      this['ad.blockerDetected'] = undefined;

      /**
       * @prop {number} [ad.postRollTimeout]
       * Timeout to wait for a postroll ad
       * Default: 1000ms
       */
      this['ad.postRollTimeout'] = 1000;

      // APP

      /**
       * @prop {string} [app.name]
       * String containing the name of the app
       */
      this['app.name'] = undefined;

      /**
       * @prop {string} [app.releaseVerson]
       * String containing the app version
       */
      this['app.releaseVersion'] = undefined;

      /**
       * @prop {boolean} [app.https=true]
       * Define the security of NQS calls.
       * If true it will use 'https://',
       * if false it will use 'http://',
       * if null/undefined it will use '//'.
       */
      this['app.https'] = true;

      // BACKGROUND

      /**
       *  @prop {boolean} [background.enabled=false]
       * If true, plugin will send background/foreground events
       * Different device behaviour is settable in background.settings
       */
      this['background.enabled'] = true;

      /**
       *  @prop {string} [background.settings]
       * Action to do when the browser goes to background.
       * Options are 'stop', 'pause', and '' for no action.
       * stop will be used to stop the view and track post-foreground events in a new view
       * pause will be used when after foreground event, an action like pressing play button is expected to resume the content
       * '' will be used if the content can be played in background
       * If not defined, specific device options will be used
       * background.setings.android / background.settings.iOS / background.settings.desktop / background.settings.tv
       * Default specific device values are stop for android and iphone, nothing for desktop.
       */
      this['background.settings'] = undefined;

      /**
       *  @prop {string} [background.settings.android='stop']
       * If background.settings is not defined, action to do when the browser goes to background if
       * the device is android type.
       * Options are 'stop', 'pause', and '' or not defined for no action.
       * bg by default
       */
      this['background.settings.android'] = 'stop';

      /**
       *  @prop {string} [background.settings.iOS='stop']
       * If background.settings is not defined, action to do when the browser goes to background if
       * the device is iphone type.
       * Options are 'stop', 'pause', and '' or not defined for no action.
       * bg by default
       */
      this['background.settings.iOS'] = 'stop';

      /**
       *  @prop {string} [background.settings.desktop=null]
       * If background.settings is not defined, action to do when the browser goes to background if
       * the device is desktop type.
       * Options are 'stop', 'pause', and '' or not defined for no action.
       * Null by default
       */
      this['background.settings.desktop'] = undefined;

      /**
       *  @prop {string} [background.settings.tv='stop']
       * If background.settings is not defined, action to do when the browser goes to background if
       * the device is smartTV type.
       * Options are 'stop', 'pause', and '' or not defined for no action.
       * bg by default
       */
      this['background.settings.tv'] = 'stop';

      /**
       *  @prop {string} [background.settings.playstation='stop']
       * If background.settings is not defined, action to do when the browser goes to background if
       * the device is playstation type.
       * Options are 'stop', 'pause', and '' or not defined for no action.
       * Null by default
       */
      this['background.settings.playstation'] = 'stop';

      // SMARTSWITCH

      /**
       *  @prop {string} [smartswitch.configCode]
       * Config code for smartswitch
       * null by default
       */
      this['smartswitch.configCode'] = undefined;

      /**
       *  @prop {string} [smartswitch.groupCode]
       * Group code for smartswitch
       * null by default
       */
      this['smartswitch.groupCode'] = undefined;

      /**
       *  @prop {string} [smartswitch.contractCode]
       * Contract code for smartswitch
       * null by default
       */
      this['smartswitch.contractCode'] = undefined;

      // EXTRAPARAMS // CUSTOM DIMENSIONS

      /** @prop {object} [content.customDimensions] Custom dimensions object. */
      this['content.customDimensions'] = {};

      /** @prop {string} [content.customDimension.1] Custom parameter 1. */
      this['content.customDimension.1'] = undefined;

      /** @prop {string} [content.customDimension.2] Custom parameter 2. */
      this['content.customDimension.2'] = undefined;

      /** @prop {string} [content.customDimension.3] Custom parameter 3. */
      this['content.customDimension.3'] = undefined;

      /** @prop {string} [content.customDimension.4] Custom parameter 4. */
      this['content.customDimension.4'] = undefined;

      /** @prop {string} [content.customDimension.5] Custom parameter 5. */
      this['content.customDimension.5'] = undefined;

      /** @prop {string} [content.customDimension.6] Custom parameter 6. */
      this['content.customDimension.6'] = undefined;

      /** @prop {string} [content.customDimension.7] Custom parameter 7. */
      this['content.customDimension.7'] = undefined;

      /** @prop {string} [content.customDimension.8] Custom parameter 8. */
      this['content.customDimension.8'] = undefined;

      /** @prop {string} [content.customDimension.9] Custom parameter 9. */
      this['content.customDimension.9'] = undefined;

      /** @prop {string} [content.customDimension.10] Custom parameter 10. */
      this['content.customDimension.10'] = undefined;

      /** @prop {string} [content.customDimension.11] Custom parameter 11. */
      this['content.customDimension.11'] = undefined;

      /** @prop {string} [content.customDimension.12] Custom parameter 12. */
      this['content.customDimension.12'] = undefined;

      /** @prop {string} [content.customDimension.13] Custom parameter 13. */
      this['content.customDimension.13'] = undefined;

      /** @prop {string} [content.customDimension.14] Custom parameter 14. */
      this['content.customDimension.14'] = undefined;

      /** @prop {string} [content.customDimension.15] Custom parameter 15. */
      this['content.customDimension.15'] = undefined;

      /** @prop {string} [content.customDimension.16] Custom parameter 16. */
      this['content.customDimension.16'] = undefined;

      /** @prop {string} [content.customDimension.17] Custom parameter 17. */
      this['content.customDimension.17'] = undefined;

      /** @prop {string} [content.customDimension.18] Custom parameter 18. */
      this['content.customDimension.18'] = undefined;

      /** @prop {string} [content.customDimension.19] Custom parameter 19. */
      this['content.customDimension.19'] = undefined;

      /** @prop {string} [content.customDimension.20] Custom parameter 20. */
      this['content.customDimension.20'] = undefined;

      /** @prop {string} [ad.customDimension.1] Ad custom parameter 1. */
      this['ad.customDimension.1'] = undefined;

      /** @prop {string} [ad.customDimension.2] Ad custom parameter 2. */
      this['ad.customDimension.2'] = undefined;

      /** @prop {string} [ad.customDimension.3] Ad custom parameter 3. */
      this['ad.customDimension.3'] = undefined;

      /** @prop {string} [ad.customDimension.4] Ad custom parameter 4. */
      this['ad.customDimension.4'] = undefined;

      /** @prop {string} [ad.customDimension.5] Ad custom parameter 5. */
      this['ad.customDimension.5'] = undefined;

      /** @prop {string} [ad.customDimension.6] Ad custom parameter 6. */
      this['ad.customDimension.6'] = undefined;

      /** @prop {string} [ad.customDimension.7] Ad custom parameter 7. */
      this['ad.customDimension.7'] = undefined;

      /** @prop {string} [ad.customDimension.8] Ad custom parameter 8. */
      this['ad.customDimension.8'] = undefined;

      /** @prop {string} [ad.customDimension.9] Ad custom parameter 9. */
      this['ad.customDimension.9'] = undefined;

      /** @prop {string} [ad.customDimension.10] Ad custom parameter 10. */
      this['ad.customDimension.10'] = undefined;

      /** @prop {bool} [forceInit] Forces init to be sent instead of start, use it when mediaduration,
       * title, source or is live is reported with a wrong value by the player until jointime happens
       */
      this.forceInit = false;

      /** @prop {bool} [simpleNewSession] Enable the newSession behaviour to replace the fireStartSessio/begin methods. */
      this.simpleNewSession = false;

      /**
       * @prop {object} [session.metrics]
       * Item containing metrics in json format. Reported every beat if the values change
       */
      this['session.metrics'] = {};

      /**
       * @prop {bool} [session.context]
       * Boolean to choose to report context or not. False by default
       */
      this['session.context'] = false;

      /**
       * @prop {array<string>} [errors.fatal=[]]
       * If it has elements on it, all the errors matching this code will fire the stop event to end the view
       */
      this['errors.fatal'] = [];

      /**
       * @prop {array<string>} [errors.nonFatal=[]]
       * If it has elements on it, all the errors matching this code won't fire a stop event to end the view
       */
      this['errors.nonFatal'] = [];

      /**
       * @prop {array<string>} [errors.ignore=[]]
       * If it has elements on it, all the errors matching this code wont be reported
       */
      this['errors.ignore'] = [];

      /**
       * @prop {boolean} [pause.ignoreSmallEvents]
       * If flag is true, the plugin will ignore send pauseDuration value in pauses with less than 50 milliseconds
       */
      this['pause.ignoreSmallEvents'] = true;

      /**
       * @prop {boolean} [check.playerExists]
       * If flag is true, the plugin and adapter have the responsibility to check if player exists in the document, and if not exists, will send stop event
       */
      this['check.playerExists'] = true;

      /**
       * Auto Start AppAnalytics module
       * @prop {boolean} [check.appAnalytics.autoBegin]
       */
      this['check.appAnalytics.autoBegin'] = false;

      /**
       * Enable AppAnalytics module
       * @prop {boolean} [check.appAnalytics.autoBegin]
       */
      this['check.appAnalytics.enabled'] = true;

      /**
       * Send PlayerEvents Logs to QATools module
       * @prop {boolean} [debug.playerEvents.show]
       */
      this['debug.playerLogs.enabled'] = false;

      /**
       * Send Plugin Logs to QATools module
       * @prop {boolean} [debug.pluginLogs.show]
       */
      this['debug.pluginLogs.enabled'] = false;

      /**
       * Enable VideoAnalytics module
       * @type {boolean}
       */
      this['check.videoAnalytics.enabled'] = true;

      /**
       * Enable get configuration from LMA periodically
       * @type {boolean}
       */
      this['lma.refresh.configuration.enabled'] = false;

      /**
       * Time to get configuration from LMA service
       * @type {int}
       */
      this['lma.refresh.configuration.seconds'] = 300;

      /**
       * @prop {string} linkedViewId
       * String to send on start events to link views with previous session events
       */
      this.linkedViewId = undefined;

      /**
       * @prop {bool} [waitForMetadata]
       * Boolean to delay the start event. Use with `pendingMetadata`
       */
      this.waitForMetadata = false;

      /**
       * @prop {array<string>} [pendingMetadata]
       * List of values that should be ready to send in start event. Use with `waitForMetadata` set to True.
       */
      this.pendingMetadata = [];

      /**
       * @prop {string} method
       * Method used to send request to backend side
       * Options are 'get' or 'post'
       * 'get' by default
       */
      this.method = RequestMethod.GET;

      /**
       * @prop {bool} [playhead.monitor]
       * Method to enabled/disable support for playhead monitor method
       */
      this['playhead.monitor'] = true;

      /**
       * @prop {bool} [network.monitor]
       * Method to enabled/disable support for readyState monitor method
       */
      this['readyState.monitor'] = true;

      /**
       * @prop {boolean} [adapters-json.overwrite]
       * If flag is false, only use json defined by customer, and don't get it from options source. (false by default).
       */
      this['adapters.overwrite'] = false;

      /**
       * @prop {object} adapters-json
       * Define adapters-json to update if it's possible (adapters-json templates to overwrite)
       */
      this['adapters.templates'] = {};

      /**
       * @prop {object}
       * Defined adapter custom properties (to condition adapter logic)
       */
      this['adapters.properties'] = {};

      /**
       * @prop {bool} [enableExtraMetricCollection]
       * If true it will enable the collection of extra metrics like accurate location, resource usage and network information
       * This might ask the user permission to access location
       */
      this.enableExtraMetricCollection = false;

      /**
       * @prop {number} [renditionQueryInterval]
       * Defines the time interval in milliseconds for the rendition query to the adapter for players that don't
       * support listeners for quality changes
       */
      this.renditionQueryInterval = 250;

      /**
       * @prop {bool} [enableLegacyBufferBehaviour]
       * Defines if legacy buffer behavior should be used (ignore audio buffers)
       */
      this['enableLegacyBufferBehaviour'] = false;

      /**
       * @prop {bool} [ignoreSeekInTimeUpdate]
       * Defines if seeking in timeupdate listerner should be ignored if seeking to the same position (false by default).
       */
      this.ignoreSeekInTimeUpdate = false;

      /**
       * @prop {bool} [checkVideoBufferInPlayheadMonitor]
       * Defines if video buffer should be checked in playhead monitor before triggering a buffer begin (false by default).
       */
      this.checkVideoBufferInPlayheadMonitor = false;

      this.setOptions(options);
    },

    /**
     * Recursively sets the properties present in the params object.
     * ie: this.username = params.username.
     *
     * @param {Object} options A literal or another Data containing values.
     * @param {Object} [base=this] Start point for recursion.
     * @param isUpdate
     */
    setOptions: function (options, base, isUpdate = false) {
      let isInBase = false;
      let deprecatedOptions;
      if (base === undefined) {
        base = this;
        isInBase = true;
        deprecatedOptions = new DeprecatedOptions();
      }
      if (typeof options !== 'undefined') {
        for (var key in options) {
          let deprecated = false;
          let newKey;
          if (isInBase) {
            if (!this.hasOwnProperty(key)) {
              // eslint-disable-line no-prototype-builtins
              if (deprecatedOptions.exists(key)) {
                newKey = deprecatedOptions.getNewName(key);
                deprecated = true;
              } else if (typeof options[key] !== 'function') {
                Log.warn(AnalyticsTag, 'The option "' + key + '" does not exist, so it cannot be set');
              }
            }
          }
          if (
            isUpdate ||
            (typeof base[key] === 'object' &&
              base[key] !== undefined &&
              !Array.isArray(base[key]) &&
              key === 'parse.cdnNode.list')
          ) {
            this.setOptions(options[key], base[key]);
          } else {
            if (deprecated) {
              base[newKey] = options[key];
            } else {
              base[key] = options[key];
            }
          }
        }
      }
    },

    /**
     * Sets all the values given in an array as extraparams. Limit is 20
     * @param {array<string>} paramsArray array of extraparam strings
     */
    setExtraParams: function (paramsArray) {
      const maxLength = 20;
      if (typeof paramsArray !== 'object' || !paramsArray.length) return;
      if (paramsArray.length >= maxLength) paramsArray = paramsArray.slice(0, maxLength);
      while (paramsArray.length < maxLength) {
        paramsArray.push(undefined);
      }
      paramsArray.forEach((param, index) => {
        this['content.customDimension.' + (index + 1).toString()] = param;
      });
    },

    /**
     * @alias npaw.options.prototype.setExtraParams.
     */
    setCustomDimensions: function () {
      Options.prototype.setExtraParams.apply(this, arguments);
    },

    /**
     * Sets all the values given in an array as extraparams. Limit is 10
     * @param {array<string>} paramsArray array of extraparam strings
     */
    setAdExtraParams: function (paramsArray) {
      const maxLength = 10;
      if (typeof paramsArray !== 'object' || !paramsArray.length) return;
      if (paramsArray.length >= maxLength) paramsArray = paramsArray.slice(0, maxLength);
      while (paramsArray.length < maxLength) {
        paramsArray.push(undefined);
      }
      paramsArray.forEach((param, index) => {
        this['ad.customDimension.' + (index + 1).toString()] = param;
      });
    },

    /**
     * @alias npaw.options.prototype.setAdExtraParams.
     */
    setAdCustomDimensions: function () {
      Options.prototype.setAdExtraParams.apply(this, arguments);
    },

    setEnableLegacyBufferBehaviour: function () {
      Options.prototype.setEnableLegacyBufferBehaviour.apply(this, arguments);
    }
  },
  {
    // Constants
    StreamingProtocol: StreamingProtocol,
    TransportFormat: TransportFormat,
    RequestMethod: RequestMethod
  }
);

module.exports = Options;
