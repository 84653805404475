import { VisualElement } from "@tv4/avod-web-player-common";
import {
  isChrome,
  isMobile,
  isSupportedOS as isDesktopOS,
} from "@tv4/avod-web-player-device-capabilities";
import styled, { css } from "styled-components";

import ChevronLeftSvg from "../icons/ChevronLeft.svg";
import CloseButtonSvg from "../icons/CloseIcon.svg";
import { useControls, usePlayerState } from "../providers/CoreProvider";
import {
  useLayoutOrientation,
  useLayoutSettings,
  useLayoutSize,
  useVisuals,
} from "../providers/LayoutProvider";
import { PlayerControlButton as Button } from "../styles";
import { LayoutOrientation, LayoutSize } from "../util/device";
import AirplayButton from "./AirplayButton";
import ChromeCastButton from "./ChromeCastButton";
import CurrentProgramMetaData from "./CurrentProgramMetaData";
import FullscreenButton from "./FullscreenButton";
import IconButton from "./IconButton";
import { VotingButton } from "./VotingButton";

export const Container = styled.div<{ useMinHeight?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;

  ${({ useMinHeight }) =>
    useMinHeight &&
    isDesktopOS() &&
    css`
      min-height: 5em;
    `};

  button {
    flex-shrink: 0;
  }
`;

const HeaderButtons = styled.div`
  display: flex;
  gap: 1em;
  justify-content: flex-end;
  align-items: flex-start;
  flex-grow: 1;
`;

type THeaderProps = {
  onBackClick?: () => void;
  onCloseClick?: () => void;
};

export function Header({ onBackClick, onCloseClick }: THeaderProps) {
  const { isPauseAd, isFullscreen, isAd } = usePlayerState();
  const controls = useControls();
  const visuals = useVisuals();
  const orientation = useLayoutOrientation();
  const { enableFullscreen } = useLayoutSettings();

  const showVotingButton = visuals?.includes(VisualElement.VOTING_BUTTON);

  const handleBackClick = () => {
    if (isFullscreen) controls?.toggleFullscreen?.();
    onBackClick?.();
  };

  const size = useLayoutSize();
  // when smallest layout we show some buttons in the header instead of bottom controls
  const isSmallestOrMobile = isMobile() || size === LayoutSize.SMALLEST;
  const showFullScreenButton = isSmallestOrMobile;
  const showCastButton = isSmallestOrMobile && isChrome();
  const showBackButton = onBackClick || (!onBackClick && isFullscreen);
  const { hideMetadataOutsideFullscreen } = useLayoutSettings();
  const shouldHideMetadataIfNotFullscreen =
    !isFullscreen && hideMetadataOutsideFullscreen;

  return (
    <Container useMinHeight={isFullscreen}>
      {showBackButton && (
        <IconButton
          label="navigate back"
          gridArea="back"
          onClick={handleBackClick}
        >
          <ChevronLeftSvg />
        </IconButton>
      )}
      <CurrentProgramMetaData
        /** reduced maxWidth to make space for client overlay in Shorts UI */
        style={{ maxWidth: isSmallestOrMobile ? "65%" : "100%" }}
        hidden={
          ([LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size) &&
            isFullscreen &&
            orientation === LayoutOrientation.PORTRAIT) ||
          shouldHideMetadataIfNotFullscreen ||
          isPauseAd ||
          isAd
        }
      />
      {!isPauseAd && (
        <HeaderButtons>
          {showVotingButton && <VotingButton />}
          {showCastButton && <ChromeCastButton />}
          {isMobile() && <AirplayButton />}
          {enableFullscreen && showFullScreenButton && <FullscreenButton />}
          {onCloseClick && (
            <Button onClick={onCloseClick}>
              <CloseButtonSvg />
            </Button>
          )}
        </HeaderButtons>
      )}
    </Container>
  );
}
