// {@see CdnParser}

module.exports = {
  cdnName: 'HIGHNEGR',
  parsers: [{
    element: 'host+type',
    headerName: 'X-HW',
    regex: /.+,[0-9]+\.(.+)\.(.+)/
  }],
  parseType: function (type) {
    switch (type) {
      case 'c':
      case 'x':
        return 1
      default:
        return 2
    }
  }
}
