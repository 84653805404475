/**
 * List of transport formats
 *   - TS (MPEG-2 TS)
 *   - MP4 (Fragmented MP4)
 *   - CMF
 */
var TransportFormat = {
  MP4: 'MP4',
  MPEG2: 'TS',
  CMF: 'CMF'
}
module.exports = TransportFormat
