var NpawObject = require('../object')
/**
 * This static class provides screen scrolling and resizing detection methods.
 *
 * @class
 * @static
 * @memberof npaw
 */
var ResizeScrollDetector = NpawObject.extend({
  constructor: function (plugin, video) {
    this.plugin = plugin
    this.video = video
    this.listenerReference = this._changeListener.bind(this)
    this.loadReference = this._loadListener.bind(this)
    this.isEnabled = false

    this.pageHeight = 0
    this.maxHeight = 0
    this.startDetection()
  },

  startDetection: function () {
    if (!this.isEnabled && typeof window !== 'undefined' && typeof window.addEventListener === 'function') {
      window.addEventListener('scroll', this.listenerReference)
      window.addEventListener('resize', this.listenerReference)
      window.addEventListener('load', this.loadReference)
      this.isEnabled = true
    }
  },

  stopDetection: function () {
    if (this.isEnabled && typeof window !== 'undefined' && typeof window.removeEventListener === 'function') {
      window.removeEventListener('scroll', this.listenerReference)
      window.removeEventListener('resize', this.listenerReference)
      window.removeEventListener('load', this.loadReference)
      this.isEnabled = false
    }
  },

  getScrollDepth: function () {
    var ret = null
    if (this.maxHeight && this.pageHeight) {
      ret = Math.min(Math.trunc((this.maxHeight / this.pageHeight) * 100), 100)
    }
    return ret
  },

  _loadListener: function () {
    if (typeof document !== 'undefined') {
      var docEl = document.documentElement
      if (docEl) {
        this.pageHeight = Math.round(docEl.scrollHeight, docEl.clientHeight, docEl.offsetHeight)
        this.maxHeight = window.innerHeight
        this._updateCompletionRate()
      }
    }
  },

  _changeListener: function () {
    if (this.pageHeight) {
      var prevHeight = this.maxHeight
      this.maxHeight = Math.max(this.maxHeight, window.pageYOffset + window.innerHeight)
      if (prevHeight !== this.maxHeight) {
        this._updateCompletionRate()
      }
    }
    var adsAdapter = this.video.getAdsAdapter()
    if (adsAdapter && adsAdapter.flags.isStarted) {
      if (!adsAdapter.getIsVisible()) {
        adsAdapter.stopChronoView()
      } else {
        adsAdapter.startChronoView()
      }
    }
  },

  _updateCompletionRate: function () {
    setTimeout(function () {
      this.plugin.storage.setSession('pageScrollDepth', this.getScrollDepth())
    }.bind(this), 3000)
  }
})

module.exports = ResizeScrollDetector
