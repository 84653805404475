import { AWPError, ErrorLevel } from "@tv4/avod-web-player-common";

let beforeUnloadTriggered = false;
window.addEventListener("beforeunload", () => {
  beforeUnloadTriggered = true;
});

export enum ErrorSeverity {
  ERROR = "error",
  FATAL = "fatal",
}

export type NpawError = {
  errorCode: string;
  msg: string;
  errorMetadata: {
    originalError?: string;
    message: string;
    details?: string;
  };
  errorLevel: ErrorSeverity;
};

export const enumerateError = (val: Error | MediaError): any =>
  Object.getOwnPropertyNames(val).reduce(
    (acc, propName) => ({
      ...acc,
      [propName]: val[propName],
    }),
    {}
  );

type TReplacerPipe = <key, value>([k, v]: [key, value]) => [key, unknown];

const replaceErrors: TReplacerPipe = ([k, v]) => [
  k,
  v instanceof Error || v instanceof MediaError ? enumerateError(v) : v,
];

const getReplaceCyclicalReferencesPipe = (): TReplacerPipe => {
  const refs = new WeakSet();

  return ([k, v]) => {
    if (v !== null && typeof v === "object") {
      if (refs.has(v)) return [k, "cyclical"];

      refs.add(v);
    }

    return [k, v];
  };
};

const safeStringify = (data: unknown): string => {
  const replaceCyclicalReferences = getReplaceCyclicalReferencesPipe();

  const replacerPipe = (key: string, value: unknown): unknown =>
    replaceErrors(replaceCyclicalReferences([key, value]))[1];

  try {
    return JSON.stringify(data, replacerPipe);
  } catch (e) {
    return JSON.stringify(
      {
        message: "Could not stringify error",
        error: e || "Caught undefined error",
      },
      replacerPipe
    );
  }
};

export const ignore = (error: AWPError): boolean =>
  !error || [ErrorLevel.USER, ErrorLevel.CLIENT].includes(error.errorLevel);

export const convertAWPErrorToNpaw = (error: AWPError): NpawError => {
  const errorLevel =
    beforeUnloadTriggered || !error.fatal
      ? ErrorSeverity.ERROR
      : ErrorSeverity.FATAL;

  // Assure stringify safety by running the data through
  // replacers, then parsing back to regular javascript
  // to avoid double stringify-ing when data is handed over
  // to trackers.
  const sanitizedRaw =
    error.raw === undefined ? error.raw : JSON.parse(safeStringify(error.raw));
  const sanitizedDetails =
    error.details === undefined
      ? error.details
      : JSON.parse(safeStringify(error.details));

  const offline = !navigator.onLine;

  function getMsg(): string {
    if (beforeUnloadTriggered) {
      return `BeforeUnload[${error.category}]`;
    }

    if (offline) {
      return `Offline[${error.category}]`;
    }

    return error.category;
  }

  return {
    errorCode: error.code || "", // = name in npaw
    msg: getMsg(),
    errorMetadata: {
      originalError: sanitizedRaw,
      message: String(error.message),
      // additional details if available
      details: sanitizedDetails,
    },
    errorLevel,
  };
};
