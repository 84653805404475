import { AppAnalyticsTag, SessionListeners } from '../common/Constants';
import Log from '../common/log';
import Constants from '../analytics/constants';
export default class AppAnalytics {
    constructor(session) {
        this.listeners = [
            { event: SessionListeners.START_BEFORE, handler: this.beforeStartListener.bind(this) },
            { event: SessionListeners.START_AFTER, handler: this.afterStartListener.bind(this) },
            { event: SessionListeners.STOP_BEFORE, handler: this.beforeStopListener.bind(this) },
            { event: SessionListeners.STOP_AFTER, handler: this.afterStopListener.bind(this) },
            { event: SessionListeners.EVENT_BEFORE, handler: this.beforeSessionEventListener.bind(this) },
            { event: SessionListeners.EVENT_AFTER, handler: this.afterSessionEventListener.bind(this) }
        ];
        this.appAnalyticsStarted = false;
        this.appAnalyticsStopped = false;
        this.session = session;
        this.setListeners();
    }
    destroy() {
        this.session.destroy();
    }
    setOptions(options) {
        this.session.setOptions(options);
    }
    getOptions() {
        return this.session.getOptions();
    }
    newSession(options, dimensions = {}) {
        if (this.session.isUsingLegacy())
            return;
        this._logFireNewSessionListener(options !== null && options !== void 0 ? options : {}, dimensions);
        // If is simple new session, is not necessary to close the opened session (because has the same behaviour than begin method)
        if (!this.session.isSimpleNewSession()) {
            if (this.appAnalyticsStarted || this.appAnalyticsStopped) {
                this.endSession();
            }
        }
        if (options) {
            this.setOptions(options);
        }
        this.begin(dimensions);
    }
    begin(dimensions = {}) {
        if (this.session.isUsingLegacy()) {
            return;
        }
        this._logBeginEvent(dimensions);
        if (this.appAnalyticsStopped) {
            this.appAnalyticsStopped = false;
        }
        if (!this.appAnalyticsStarted) {
            this.appAnalyticsStarted = true;
            if (!this.session.expiredSession(dimensions, false)) {
                this.session.start(undefined, dimensions, undefined, undefined, true);
            }
        }
    }
    endSession(params = {}) {
        if (this.session.isUsingLegacy())
            return;
        if (this.appAnalyticsStarted) {
            this.appAnalyticsStopped = true;
            this.appAnalyticsStarted = false;
            this.session.stop(params, undefined, undefined, true);
        }
    }
    /**
     * @deprecated The new method is begin(dimensions)
     */
    fireSessionStart(dimensions = {}) {
        this.begin(dimensions);
    }
    /**
     * @deprecated The new method is endSession(params)
     */
    fireSessionStop(params = {}) {
        this.endSession(params);
    }
    fireEvent(eventName = '', dimensions = {}, values = {}, topLevelDimensions = {}) {
        const additionalDimensions = {
            eventSource: 'Application Analytics',
            eventType: 'CustomEvent'
        };
        const mergedDimensions = Object.assign(Object.assign({}, dimensions), additionalDimensions);
        this.session.sendEvent(eventName, mergedDimensions, values, topLevelDimensions);
    }
    fireNavigation(dimensions = {}) {
        if (this.session.isUsingLegacy())
            return;
        if (this.session.isActive()) {
            this.appAnalyticsStarted = true;
            this.session.fireNav(dimensions);
        }
    }
    /**
     * @deprecated The new method is fireNavigation(dimensions)
     */
    fireNav(dimensions = {}) {
        this.fireNavigation(dimensions);
    }
    isActive() {
        return this.session.isActive();
    }
    isStarted() {
        return this.appAnalyticsStarted;
    }
    getSessionRoot() {
        if (this.isActive()) {
            return this.session.getSessionRoot();
        }
        else {
            return null;
        }
    }
    getSessionHost() {
        if (this.isActive()) {
            return this.session.getSessionHost();
        }
        else {
            return null;
        }
    }
    _logBeginEvent(dimensions) {
        const params = {
            logs: {
                data: dimensions
            },
            logAction: 'beginSession',
            logType: 'pluginMethod'
        };
        this.session._sendPluginLogs(Constants.WillSendLog.WILL_SEND_LOG_INFINITY_BEGIN, Constants.Service.APP_ANALYTICS_PLUGIN_LOGS, params);
    }
    _logFireNewSessionListener(options, eventParams) {
        const params = {
            logs: {
                data: options,
                params: eventParams
            },
            logAction: 'newSession',
            logType: 'pluginMethod'
        };
        this.session._sendPluginLogs(Constants.WillSendLog.WILL_SEND_LOG_INFINITY_NAV, Constants.Service.APP_ANALYTICS_PLUGIN_LOGS, params);
    }
    // Listeners
    setListeners() {
        for (const { event, handler } of this.listeners) {
            this.session.addListener(event, handler);
        }
    }
    beforeStartListener(options, dimensions) {
        Log.debug(AppAnalyticsTag, SessionListeners.START_BEFORE, options, dimensions);
    }
    afterStartListener(options, dimensions) {
        Log.debug(AppAnalyticsTag, SessionListeners.START_AFTER, options, dimensions);
    }
    beforeStopListener(params) {
        Log.debug(AppAnalyticsTag, SessionListeners.STOP_BEFORE, params);
    }
    afterStopListener(params) {
        Log.debug(AppAnalyticsTag, SessionListeners.STOP_AFTER, params);
    }
    beforeSessionEventListener(eventName, dimensions, values, topLevelDimensions) {
        Log.debug(AppAnalyticsTag, SessionListeners.EVENT_BEFORE, eventName, dimensions, values, topLevelDimensions);
    }
    afterSessionEventListener(eventName, dimensions, values, topLevelDimensions) {
        Log.debug(AppAnalyticsTag, SessionListeners.EVENT_AFTER, eventName, dimensions, values, topLevelDimensions);
    }
}
