import { Metadata } from "@tv4/avod-web-player-common";

declare const VERSION: string;

export function getVersion() {
  return typeof VERSION !== "undefined" ? VERSION : "dev";
}

export function isBumpersAllowed(metadata: Metadata): boolean {
  return !metadata.asset.isClip;
}
