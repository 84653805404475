import {
  AdBreakPayload,
  AdClickPayload,
  AdPayload,
  CdnChangedPayload,
  CoreEvents,
  CoreEventsMap,
  DroppedFramesPayload,
  EngineSelectedPayload,
  ErrorPayload,
  FullscreenPayload,
  MediaLoadingPayload,
  OptionalTrackingPayload,
  PlaybackErrorPayload,
  PlaybackRateChangePayload,
  TimePayload,
  TrackPayload,
  VolumePayload,
} from "@tv4/avod-web-player-common";
import {
  ITrackerSetupOptions,
  Tracker,
  TrackingManager,
} from "@tv4/avod-web-player-tracking";

export class TrackingManagerProxy {
  private trackingManager: TrackingManager;

  constructor(trackers: Tracker[], trackerOptions: ITrackerSetupOptions) {
    this.trackingManager = new TrackingManager(trackers);
    this.trackingManager.setup(trackerOptions);
  }

  public getTrackingManager(): TrackingManager {
    return this.trackingManager;
  }

  public on(eventType: CoreEvents, data: CoreEventsMap[CoreEvents]) {
    switch (eventType) {
      case CoreEvents.ENGINE:
        this.trackingManager.onEngine(data as EngineSelectedPayload);
        break;
      case CoreEvents.LOADING_PLAYBACK:
        this.trackingManager.onLoading();
        break;
      case CoreEvents.CONTENT_MEDIA_LOADING:
        this.trackingManager.onContentMediaLoading(data as MediaLoadingPayload);
        break;
      case CoreEvents.LOADED_PLAYBACK:
        this.trackingManager.onLoaded(data as TimePayload);
        break;
      case CoreEvents.START:
        this.trackingManager.onStart(data as TimePayload);
        break;
      // TODO introduce CoreEvents.PLAY to indicate a request to
      //      play has been made by the user
      case CoreEvents.PAUSED:
        this.trackingManager.onPause(data as TimePayload);
        break;
      case CoreEvents.RESUME:
        this.trackingManager.onResume(data as TimePayload);
        break;
      case CoreEvents.RESET:
        this.trackingManager.onReset();
        break;
      case CoreEvents.SEEKING:
        this.trackingManager.onSeek(data as TimePayload);
        break;
      case CoreEvents.SEEKED:
        this.trackingManager.onSeeked(data as TimePayload);
        break;
      case CoreEvents.BUFFERING:
        this.trackingManager.onBuffering(data as TimePayload);
        break;
      case CoreEvents.BUFFERED:
        this.trackingManager.onBuffered(data as TimePayload);
        break;
      case CoreEvents.TIME_UPDATED:
        this.trackingManager.onTimeUpdate(data as TimePayload);
        break;
      case CoreEvents.ERROR:
      case CoreEvents.LOAD_PLAYBACK_ERROR:
        this.trackingManager.onError(data as PlaybackErrorPayload);
        break;
      case CoreEvents.ENDED:
        this.trackingManager.onEnded();
        break;
      case CoreEvents.TRACK_CHANGED:
        this.trackingManager.onTrackChanged(data as TrackPayload);
        break;
      case CoreEvents.DROPPED_FRAMES:
        this.trackingManager.onDroppedFrames(data as DroppedFramesPayload);
        break;
      case CoreEvents.BREAK_START:
        this.trackingManager.onAdBreakStart(data as AdBreakPayload);
        break;
      case CoreEvents.BREAK_END:
        this.trackingManager.onAdBreakEnd(data as OptionalTrackingPayload);
        break;
      case CoreEvents.AD_LOADING:
        this.trackingManager.onAdLoading(data as AdPayload);
        break;
      case CoreEvents.AD_MEDIA_LOADING:
        this.trackingManager.onAdMediaLoading(data as MediaLoadingPayload);
        break;
      case CoreEvents.AD_LOADED:
        this.trackingManager.onAdLoaded(data as AdPayload);
        break;
      case CoreEvents.AD_START:
        this.trackingManager.onAdPlaying(data as AdPayload);
        break;
      case CoreEvents.AD_PAUSED:
        this.trackingManager.onAdPaused(data as TimePayload);
        break;
      case CoreEvents.AD_RESUME:
        this.trackingManager.onAdResume(data as TimePayload);
        break;
      case CoreEvents.AD_TIME_UPDATED:
        this.trackingManager.onAdTimeUpdate(data as TimePayload);
        break;
      case CoreEvents.AD_BUFFERING:
        this.trackingManager.onAdBuffering(data as TimePayload);
        break;
      case CoreEvents.AD_BUFFERED:
        this.trackingManager.onAdBuffered(data as TimePayload);
        break;
      case CoreEvents.AD_ENDED:
        this.trackingManager.onAdEnded();
        break;
      case CoreEvents.AD_ERROR:
        this.trackingManager.onAdError(data as ErrorPayload);
        break;
      case CoreEvents.AD_CLICK:
        this.trackingManager.onAdClick(data as AdClickPayload);
        break;
      case CoreEvents.CDN_CHANGED:
        this.trackingManager.onCdnChanged(data as CdnChangedPayload);
        break;
      case CoreEvents.FULLSCREEN_CHANGED:
        this.trackingManager.onFullscreenChanged(data as FullscreenPayload);
        break;
      case CoreEvents.VOLUME_CHANGED:
        this.trackingManager.onVolumeChanged(data as VolumePayload);
        break;
      case CoreEvents.PLAYBACK_RATE_CHANGE:
        this.trackingManager.onPlaybackRateChanged(
          data as PlaybackRateChangePayload
        );
        break;
      default:
        break;
    }
  }

  public destroy() {
    this.trackingManager.destroy();
  }
}
