var YBRequest = require('../../../request')
var Parser = require('./parser')

var LocationheaderParser = Parser.extend(
  /** @lends npaw.LocationheaderParser.prototype */
  {
    /**
     * Parses given header to check.
     */
    parse: function (url, lastManifest) {
      if (!lastManifest) {
        this._realResource = url
        var request = new YBRequest(url, null, null, {
          cache: true,
          requestHeaders: this._headers
        })

        request.on(YBRequest.Event.SUCCESS, function (resp) {
          this._parseWithManifest(resp)
        }.bind(this))

        request.on(YBRequest.Event.ERROR, function (resp) {
          this.done()
        }.bind(this))

        request.send()
      } else {
        this._parseWithManifest(lastManifest)
      }
    },

    _parseWithManifest: function (resp) {
      this._lastManifest = resp
      var response = resp.getResponseHeaders()
      var found = false
      if (resp && resp.xhr && resp.xhr.responseURL && resp.xhr.responseURL !== this._realResource) {
        this._realResource = resp.xhr.responseURL
        this.parse(this._realResource)
        found = true
      } else {
        response.split('\n').forEach(function (line) {
          if (line.startsWith('Location:')) {
            this._realResource = line.slice(10)
            this.parse(this._realResource)
            found = true
          }
        }.bind(this))
      }
      if (!found) {
        this.done()
      }
    }
  }
)

module.exports = LocationheaderParser
