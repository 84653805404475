/**
 * @tv4/avod-web-player package will build with webpack and expose
 * the current player version with process.env.npm_package_version
 * using webpack.DefinePlugin.
 * see packages/player/webpack.config.ts for more information.
 * version value exposed in webpack build is used in production.
 *
 * when the dev server is running, the source is compiled by
 * parcel and the avod-web-player webpack build is never used so
 * the process.env.npm_package_version value is not available to
 * the code. fallback value is only used during development.
 */

/**
 * this version represents the version of the web player and
 * should only be used by code that is exclusive to the web
 * player.
 * for example, the tracking package is published and used
 * separately in other places, so the tracking package should
 * never access this value directly. if the tracking package
 * needs this value then the web player code that uses the
 * tracking package needs to access this value and pass it to
 * the tracking package code where needed.
 */

export default process.env.npm_package_version || "dev";
