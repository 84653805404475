import Log from '../common/log';
import { ProductAnalyticsTag } from '../common/Constants';
window.npawtmConfig = window.npawtmConfig || {};
/**
 * NpawProductAnalyticsConfig class handles automatical event binding
 */
export default class ProductAnalyticsConfig {
    /**
     * Constructor
     */
    constructor(fireEvent) {
        this.fireEvent = fireEvent;
        // Load npawtmConfig object
        if (typeof window === 'undefined' || typeof window.npawtmConfig !== 'object') {
            // Load directly from AdminAPI?: this.loadTagManager()
            this.config = null;
        }
        else {
            this.config = window.npawtmConfig;
        }
    }
    /**
     * Identifies current page and get events to monitor when available.
     * @param {URL | null} URL to be identified
     * @returns {string} Identified page name / null
     */
    identify(url) {
        let events;
        let page;
        page = null;
        events = [];
        if (!this.config) {
            Log.warn(ProductAnalyticsTag, 'no config descriptor available');
        }
        else {
            page = this.getPage(url);
            if (page === null) {
                // No page identified: set default page name
                Log.warn(ProductAnalyticsTag, 'no page found');
            }
            else {
                // Page identified: look for bound events
                events = this.getEvents(page);
            }
        }
        // Bind events
        if (typeof window === 'undefined') {
            Log.warn(ProductAnalyticsTag, 'Product Analytics Config: cannot bind events since DOM is not available');
        }
        else if (events.length > 0) {
            window.addEventListener('load', () => {
                this.bindElements(events);
            });
        }
        return page;
    }
    /**
     * Looks for a page matching the supplied URL (either directly or by means of a regular expression).
     * @param {URL} url URL to look for.
     * @returns {string|*} Name of the page matching the URL or null if no matches have been found.
     * @private
     */
    getPage(url) {
        let urlString;
        let regexInfo;
        let pageInfo;
        let regex;
        let i;
        let page = null;
        if (typeof url === 'object' &&
            url !== null &&
            this.config &&
            this.config.urls &&
            this.config.regexes &&
            this.config.domains &&
            this.config.domains.includes(url.hostname.toLowerCase())) {
            // Direct URL lookup
            pageInfo = this.config.urls[url.pathname];
            if (typeof pageInfo !== 'undefined' && typeof pageInfo.page !== 'undefined') {
                page = pageInfo.page;
            }
            // If URL lookup fails, start regex lookup
            urlString = url.pathname + url.search;
            for (i = 0; i < this.config.regexes.length && page === null; i++) {
                regexInfo = this.config.regexes[i];
                regex = new RegExp(regexInfo.regex);
                if (urlString.match(regex)) {
                    page = regexInfo.page;
                }
            }
        }
        return page;
    }
    /**
     * Gets events bound to the current page.
     * @param {string} page Page name to look for.
     * @private
     */
    getEvents(page) {
        let events = this.config && this.config.events ? this.config.events[page] : null;
        if (typeof events === 'undefined' || events === null) {
            events = [];
        }
        return events;
    }
    /**
     * Binding of element events pointed out by npawtmConfig.
     * @param {Array} events List of events to bind
     * @private
     */
    bindElements(events) {
        let eventInfo;
        let eventType;
        let element;
        let j;
        let i;
        for (i = 0; i < events.length; i++) {
            eventInfo = events[i];
            element = document.querySelector(eventInfo.selector);
            if (element === null) {
                Log.warn(ProductAnalyticsTag, 'element ' + eventInfo.selector + ' not found');
            }
            else if (eventInfo.events.length === 0) {
                Log.warn(ProductAnalyticsTag, 'element ' + eventInfo.selector + ' has no events to bind');
            }
            else {
                for (j = 0; j < eventInfo.events.length; j++) {
                    eventType = eventInfo.events[j];
                    element.npawEventInfo = eventInfo;
                    element.npawEventType = eventType;
                    element.addEventListener(eventType, (eventData) => {
                        this.elementEvent(eventData.currentTarget.npawEventInfo, eventData.currentTarget.npawEventType);
                    });
                }
            }
        }
    }
    /**
     * Tracks an event after a monitored element event has been triggered.
     * @param {Object} eventInfo
     * @param {ProductAnalyticsEventType} eventType type of the triggered event (click...).
     * @param {Object} eventData additional event data supplied by the event listener.
     * @private
     */
    elementEvent(eventInfo, eventType) {
        const eventName = '[' + eventType + '] ' + eventInfo.name;
        if (this.fireEvent) {
            this.fireEvent(eventName, eventType, {
                uiElement: eventInfo.name,
                eventName: eventName,
                eventType: eventType
            });
        }
        else {
            Log.warn(ProductAnalyticsTag, 'Cannot trigger event since no handler has been provided');
        }
    }
}
