import {
  AdBreakTrackingEvent,
  AdBreakType,
  AdInsertionType,
  AdTrackingEvent,
  AdVideoVariant,
  dateStringToSeconds,
  IAd,
  IAdBreak,
  IAdCreative,
  IAdMediaFile,
} from "@tv4/avod-web-player-common";
import { isMobile } from "@tv4/avod-web-player-device-capabilities";

const findCreative = (creatives: IAdCreative[]): IAdCreative | undefined => {
  return creatives.find(
    (creative) => creative.type === "linear" && creative.mediafiles.length > 0
  );
};

function getVideoScreenSize(): { width: number; height: number } {
  /**
   * specifically safari private window will use same
   * values as window.innerWidth and window.innerHeight
   * for window.screen.width and window.screen height,
   * presumably in an effort to prevent tracking/
   * fingerprinting.
   */
  if (
    window.screen.height === window.innerHeight &&
    window.screen.width === window.innerWidth
  ) {
    // use 0 values to indicate there are no reliable values
    return {
      width: 0,
      height: 0,
    };
  }
  // dpr is used to calculate actual resolution that can render video
  let dpr = window.devicePixelRatio || 1;
  // ignore dpr on mobile - the difference is less noticeable on a smaller screen
  if (isMobile()) {
    dpr = 1;
  }
  return {
    width: window.screen.width * dpr,
    height: window.screen.height * dpr,
  };
}

// logic here will assume that all video is using landscape aspect
function filterAdMediaFilesResolutions(
  mediaFiles: IAdMediaFile[]
): IAdMediaFile[] {
  const { width: screenWidth, height: screenHeight } = getVideoScreenSize();
  if (screenHeight !== 0 && screenWidth !== 0) {
    const mobile = isMobile();
    const highResolutionFiles: IAdMediaFile[] = []; // resolutions that will not fit current screen resolution
    const allowedFiles: IAdMediaFile[] = []; // allowed resolutions that fit in current screen resolution
    mediaFiles.forEach((mediaFile: IAdMediaFile) => {
      const { width = 0, height = 0 } = mediaFile;
      let fit = width < screenWidth && height < screenHeight;
      // mobile display can be rotated, so video resolution may fit in rotated screen resolution
      if (mobile && !fit) {
        fit = width < screenHeight && height < screenWidth;
      }
      if (fit) {
        allowedFiles.push(mediaFile);
      } else {
        highResolutionFiles.push(mediaFile);
      }
    });
    /**
     * allowed list will only contain resolutions lower than screen resolution.
     * add the next higher bitrate, which will be exact or higher than screen resolution,
     * so that the highest bitrate will not be a resolution that scales up to fit the
     * screen in fullscreen. this highest allowed resolution will still only play if
     * allowed by estimated bandwidth handled by other logic.
     */
    if (highResolutionFiles.length) {
      const highestAllowedResolution = highResolutionFiles.reduce(
        (
          highestAllowedResolutionFile: IAdMediaFile,
          mediaFile: IAdMediaFile
        ) => {
          const highestAllowedSize =
            highestAllowedResolutionFile.width! *
            highestAllowedResolutionFile.height!;
          const candidateSize = mediaFile.width! * mediaFile.height!;

          if (candidateSize < highestAllowedSize) {
            return mediaFile;
          } else {
            return highestAllowedResolutionFile;
          }
        },
        highResolutionFiles[0]
      );

      allowedFiles.push(
        ...highResolutionFiles.reduce(
          (
            highestAllowedResolutionFiles: IAdMediaFile[],
            mediaFile: IAdMediaFile
          ) => {
            if (
              mediaFile.width === highestAllowedResolution.width &&
              mediaFile.height === highestAllowedResolution.height
            ) {
              highestAllowedResolutionFiles.push(mediaFile);
            }
            return highestAllowedResolutionFiles;
          },
          []
        )
      );
    }
    if (allowedFiles.length) {
      return allowedFiles;
    }
  }
  return mediaFiles;
}

const filterMediaFiles = (mediaFiles: IAdMediaFile[]): IAdMediaFile[] => {
  return (
    filterAdMediaFilesResolutions(
      mediaFiles.filter(
        (mediaFile) =>
          mediaFile.mimeType === "video/mp4" && mediaFile.fileUrl.length > 0
      )
    )
      // filtered list top down
      .sort((a, b) =>
        a.bitrate && b.bitrate && a.height && b.height
          ? b.bitrate - a.bitrate || b.height - a.height
          : 0
      )
  );
};

// TODO: Does this function actually do anything?
const mapMediaFileObject = (mediaFile: any): IAdMediaFile => {
  const mediaFileObject: IAdMediaFile = {
    id: mediaFile.id,
    mimeType: mediaFile.mimeType,
    fileUrl: mediaFile.fileURL,
    bitrate: mediaFile.bitrate,
    height: mediaFile.height,
    width: mediaFile.width,
  };
  return mediaFileObject;
};

const mapCreativeObject = (creative: any): IAdCreative => {
  const creativeObject: IAdCreative = {
    id: creative.id,
    adId: creative.adId,
    type: creative.type,
    duration: creative.duration,
    mediafiles: filterMediaFiles(
      creative.mediaFiles?.map((mediaFile) => mapMediaFileObject(mediaFile)) ??
        []
    ),
    trackingEvents: {
      [AdTrackingEvent.CLICK_THROUGH]: [
        ...(creative.videoClickTrackingURLTemplates ?? []),
      ],
      ...creative.trackingEvents,
    },
    clickThroughUrlTemplate: creative.videoClickThroughURLTemplate,
  };
  return creativeObject;
};

const findCreativeType = (ad: any): string | undefined => {
  return ad.extensions.find((extension) =>
    extension.children.find((child) =>
      child.children.find(
        (target) =>
          target.name === "CreativeParameter" &&
          target.attributes?.name?.toLowerCase() === "adtype"
      )
    )
  )?.children[0].children[0].value;
};

const detectVariantType = (ad: any): AdVideoVariant => {
  const adType = findCreativeType(ad)?.toLowerCase();
  switch (adType) {
    case "bumper":
      return AdVideoVariant.VIGNETTE;
    case "sponsor":
      return AdVideoVariant.SPONSOR;
    case "trailer":
      return AdVideoVariant.TRAILER;
    default:
      return AdVideoVariant.NORMAL;
  }
};

const mapAdsObject = (ad: any): IAd | undefined => {
  const adServerExtension = ad.extensions.find(
    (extension) => extension.attributes.type === "AdServer"
  );

  const universalAdId = ad?.creatives[0]?.universalAdIds[0]?.value || undefined;

  const variant =
    adServerExtension?.children.find((ext) => ext.name === "AdInfo")?.attributes
      ?.variant || detectVariantType(ad);

  const creative = findCreative(ad.creatives.map(mapCreativeObject));

  if (!creative) {
    return;
  }

  const adObject: IAd = {
    id: ad.id,
    universalAdId,
    system: ad.system?.value,
    sequence: ad.sequence,
    title: ad.title,
    variant,
    creative,
    errorUrlTemplates: ad.errorURLTemplates,
    impressionUrlTemplates: ad.impressionURLTemplates.map(
      (impressionUrlTemplate) => impressionUrlTemplate.url
    ),
  };

  return adObject;
};

const cleanAdBreak = (ads: IAd[] = []) => {
  /**
   * filter ad that is empty first
   *
   * this rule was originally added in d5014013c2e8cdd7c28eea03df054b7f4a4ccb23,
   * unclear if an ad can still have empty list of mediafiles,
   * so potentially this could be removed, but if an ad cannot
   * be played there is no need to keep it.
   */
  ads = ads.filter((ad) => (ad.creative.mediafiles?.length || 0) > 0);

  const variants = ads.map((ad) => ad.variant);
  const normalAds = variants.includes(AdVideoVariant.NORMAL);
  const trailerAds = variants.includes(AdVideoVariant.TRAILER);
  const sponsorAds = variants.includes(AdVideoVariant.SPONSOR);

  if (!normalAds && !trailerAds && !sponsorAds) {
    // no any ads found, emptying ads array
    return [];
  } else if (!normalAds && (trailerAds || sponsorAds)) {
    // no normal ads found, only other types exist, removing bumpers
    return ads.filter((ad) => ad.variant !== AdVideoVariant.VIGNETTE);
  }
  // full ad break with at least normal ads, returning as is
  return ads;
};

export const mapAdBreakObject = (adBreak: any, ads: IAd[]): IAdBreak => {
  ads = ads
    ?.map((ad) => mapAdsObject(ad))
    .filter((ad): ad is IAd => ad !== null);

  ads = cleanAdBreak(ads);

  return {
    insertionType: AdInsertionType.ClientSide,
    breakType:
      adBreak.timeOffset === "start"
        ? AdBreakType.Preroll
        : AdBreakType.Midroll,
    timeOffset:
      adBreak.timeOffset === "start"
        ? 0
        : dateStringToSeconds(adBreak.timeOffset),
    ads,
    trackingEvents: {
      breakStart:
        adBreak.trackingEvents
          ?.filter((abte) => abte.event === AdBreakTrackingEvent.BREAK_START)
          ?.map((abte) => abte.uri) || [],
      breakEnd:
        adBreak.trackingEvents
          ?.filter((abte) => abte.event === AdBreakTrackingEvent.BREAK_END)
          ?.map((abte) => abte.uri) || [],
    },
    empty: ads.length === 0,
  };
};
