import { Asset } from "@tv4/avod-web-player-common";
import { createContext, ReactNode, useContext } from "react";

type TMetadataProviderContext = {
  asset?: Asset;
  poster?: string;
};

type TMetadataProviderProps = {
  asset?: Asset;
  poster?: string;
  children: ReactNode;
};

const MetadataContext = createContext<TMetadataProviderContext>({
  asset: undefined,
});

export function MetadataProvider({
  asset,
  poster,
  children,
}: TMetadataProviderProps) {
  return (
    <MetadataContext.Provider
      value={{
        asset,
        poster,
      }}
    >
      {children}
    </MetadataContext.Provider>
  );
}

export const useMetadata = (): TMetadataProviderContext => {
  return useContext(MetadataContext);
};
