// {@see CdnParser}

module.exports = {
  cdnName: 'LEVEL3',
  parsers: [{
    element: 'host+type',
    headerName: 'X-WR-Diag',
    regex: /Host:(.+)\sType:(.+)/
  }],
  requestHeaders: {
    'X-WR-Diag': 'host'
  },
  parseType: function (type) {
    if (type) {
      if (
        type.indexOf('TCP_HIT') === 0 ||
        type.indexOf('TCP_MEM_HIT') === 0 ||
        type.indexOf('TCP_IMS_HIT') === 0
      ) {
        return 1
      } else if (type.indexOf('TCP_MISS') === 0) {
        return 2
      }
    }

    return 0
  }
}
