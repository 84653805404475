/**
 * tracker implementations may be used
 * in other solutions on other platforms.
 * this file will setup each tracker with
 * values needed for using trackers with
 * web-player.
 */

import {
  isFotbollskanalen,
  isInIframe,
  isKoket,
  isMtv,
  isMtvUutiset,
  isTv4Play,
  isTv4Se,
  LinkPulseTrackingMetadata,
  NielsenTrackingMetadata,
  Service,
  storage,
  uuid,
} from "@tv4/avod-web-player-common";
import { VideoTrackingMetadata } from "@tv4/avod-web-player-common/src";
import { version } from "@tv4/avod-web-player-core";
import {
  getOSName,
  isChromecast,
  isIpadOS,
  isMobile,
} from "@tv4/avod-web-player-device-capabilities";
import {
  FinnpanelTracking,
  GTMCoreTracking,
  IGTMCoreTrackerInstanceOptions,
  IKilkayaTrackerInstanceOptions,
  INpawTrackerInstanceOptions,
  KilkayaTracking,
  NielsenTracking,
  WireVideoTracking,
} from "@tv4/avod-web-player-tracking";

import { TPlayerOptions } from "../WebPlayer";

export function createGtmCoreTracking(
  options: IGTMCoreTrackerInstanceOptions
): GTMCoreTracking {
  return new GTMCoreTracking(options);
}

function shouldInitKilkaya(
  trackingMetadata?: LinkPulseTrackingMetadata
): boolean {
  return !!(
    (isTv4Play() || isTv4Se() || isFotbollskanalen()) &&
    trackingMetadata &&
    "kilkaya" in window
  );
}

export function createKilkayaTrackingIfNeeded(
  options: Partial<IKilkayaTrackerInstanceOptions>
): KilkayaTracking | undefined {
  if (shouldInitKilkaya(options.trackingMetadata)) {
    return new KilkayaTracking({
      channel: isMobile() ? "mobile" : "desktop",
      // custom values
      ...options,
      // redefine required value to fulfill typescript requirements
      trackingMetadata: options.trackingMetadata!,
    });
  }
}

function getNielsenAppId(): string {
  const AppIdWeb = {
    TV4_PLAY: "P72755BF3-0E83-4549-B6A7-5D2688403B1A",
    TV4_SE: "PEC155E29-C26C-467A-9578-2DACB0682DDD",
    KOKET: "P5F8026F4-8681-4D15-B0D0-8E017F367AFA",
    FOTBOLLSKANALEN: "P0C745EA4-F271-492F-A507-C277B8B4B7C7",
  };

  const AppIdChromecast = {
    TV4_PLAY: "P941D3D50-957F-415F-9C86-D889C885FC33",
    TV4_SE: "PF85D5105-C54A-4DA3-AF1F-77394EE34E20",
    KOKET: "P9D157DB1-67E1-488F-88C5-F82FE87AFB57",
    FOTBOLLSKANALEN: "PCE098E0D-061B-4A9E-B814-11EA01668CC3",
  };

  const appIdMap = isChromecast() ? AppIdChromecast : AppIdWeb;

  let appId = appIdMap.TV4_PLAY;
  if (isTv4Se()) {
    appId = appIdMap.TV4_SE;
  } else if (isKoket()) {
    appId = appIdMap.KOKET;
  } else if (isFotbollskanalen()) {
    appId = appIdMap.FOTBOLLSKANALEN;
  }

  return appId;
}

export function createNielsenTracking(options: {
  trackingMetadata: NielsenTrackingMetadata;
  userInitiatedPlayback: boolean;
}): NielsenTracking {
  return new NielsenTracking({
    playerName: "tv4html5player",
    appId: getNielsenAppId(),
    playerVersion: version,
    ...options,
  });
}

const DEVICE_ID_KEY = "PTDID"; // persistent tracking device id

function getDeviceId(): string {
  /**
   * web browser has no concept of a device id, so a pseudo unique value is created.
   * deviceId needs to persist as long as possible, like a permanent device id would,
   * so value is stored locally on device.
   */
  let deviceId: string = storage.getData<string>(DEVICE_ID_KEY) || "";

  // create new value if not previously set
  if (!deviceId) {
    deviceId = uuid();
    // update storage
    storage.setData(DEVICE_ID_KEY, deviceId);
  }

  return deviceId;
}

export function createFinnpanelTracking(service: Service): FinnpanelTracking {
  return new FinnpanelTracking({
    service,
    deviceId: getDeviceId(),
  });
}

function getWireVideoTrackingPlatform(): string {
  if (isChromecast()) return "bigscreen";
  if (isIpadOS()) return "tablet";
  if (isMobile()) return "mobile";
  return "computer";
}
export function createWireVideoTracking(options: {
  config: VideoTrackingMetadata;
  getAccessToken: () => string | undefined;
}): WireVideoTracking {
  return new WireVideoTracking({
    playbackApiVideoTrackingData: options.config,
    client: "web",
    playerName: "avod-web-player",
    playerVersion: version,
    osName: getOSName(),
    trackingPlatform: getWireVideoTrackingPlatform(),
    deviceId: getDeviceId(),
    getAccessToken: options.getAccessToken,
  });
}

export function getNpawPlayerPlatform(): string {
  // TODO using "Platform" to define Chromecast is not great
  //  npaw has a `device` field that should be used instead
  if (isChromecast()) return "Chromecast";
  return "HTML5";
}

// TODO this should be passed to the player by the client
function getNpawPlayerService(): string {
  if (isInIframe()) return "iFramed";
  if (isTv4Play()) return "TV4 Play";
  if (isTv4Se()) return "TV4.se";
  if (isFotbollskanalen()) return "Fotbollskanalen";
  if (isKoket()) return "Köket";
  if (isMtvUutiset()) return "mtvuutiset";
  if (isMtv()) return "MTV";
  return "Unknown";
}

export const createNpawInitOptions = ({
  playerName,
  userId,
  assetId,
  appVersion,
  appName,
  environment,
}: {
  playerName: string;
  userId: string;
  assetId: string;
  appVersion: string;
  appName: string;
  environment: TPlayerOptions["environment"];
}): INpawTrackerInstanceOptions => {
  // set debug to `process.env.NODE_ENV === "development"` to enable NPAW debugging
  const debug = false;

  return {
    userId,
    debug,
    appVersion,
    appName,
    // TODO service should be passed to the player by the client
    service: getNpawPlayerService(),
    // TODO platform should be passed to the web player by the platform owner
    platform: getNpawPlayerPlatform(),
    playerName,
    playerVersion: version,
    assetId,
    account: environment === "production" ? "mtvoy" : "mtvoydev",
  };
};
