import { clamp, PlayerMode } from "@tv4/avod-web-player-common";
import { isMobile } from "@tv4/avod-web-player-device-capabilities";
import { useCallback, useRef } from "react";

import useDragging from "../../hooks/useDragging";
import VolumeIconSvg from "../../icons/VolumeIcon.svg";
import VolumeIconMutedSvg from "../../icons/VolumeIconMuted.svg";
import { useControls, usePlayerState } from "../../providers/CoreProvider";
import {
  useLayoutSettings,
  useLayoutSize,
} from "../../providers/LayoutProvider";
import { LayoutSize } from "../../util/device";
import {
  VolumeButton,
  VolumeControllerWrapper,
  VolumeSliderActualProgress,
  VolumeSliderProgressBar,
  VolumeSliderThumb,
  VolumeSliderThumbWrapper,
  VolumeSliderWrapper,
} from "./VolumeController.styles";

type TVolumeSliderProps = React.ComponentPropsWithoutRef<"div"> & {
  $progressBarSpacing: boolean;
  value: number;
  onChanged: (value: number) => void;
};

const VolumeSlider = ({ value, onChanged, ...props }: TVolumeSliderProps) => {
  const { muted } = usePlayerState();
  const progressBarRef = useRef<HTMLDivElement>(null);

  const onDragChange = useCallback(
    (event: PointerEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (event.currentTarget instanceof Element) {
        event.currentTarget.setPointerCapture(event.pointerId);
      }

      if (!progressBarRef.current) return;
      const position = event.clientY;
      const rect = progressBarRef.current.getBoundingClientRect();
      const volume = (rect.bottom - position) / (rect.bottom - rect.top);
      onChanged(clamp(volume));
    },
    [onChanged]
  );

  const { dragging, startDragging } = useDragging({
    onDragChange,
  });

  let volumeSliderProps = props;
  if (dragging) {
    volumeSliderProps = {
      ...volumeSliderProps,
      className: "player-volume-dragging",
    };
  }

  const volumeBarHeight = String(muted ? 0 : 100 * value) + "%";

  return (
    <VolumeSliderWrapper {...volumeSliderProps} onPointerDown={startDragging}>
      <VolumeSliderProgressBar>
        <VolumeSliderActualProgress style={{ height: volumeBarHeight }} />
      </VolumeSliderProgressBar>
      <VolumeSliderThumbWrapper ref={progressBarRef}>
        <VolumeSliderThumb style={{ bottom: volumeBarHeight }} />
      </VolumeSliderThumbWrapper>
    </VolumeSliderWrapper>
  );
};

export const VolumeController = () => {
  const controls = useControls();
  const { volume, muted } = usePlayerState();
  const { playerMode } = useLayoutSettings();

  const setVolume = useCallback(
    (value: number): void => {
      controls?.setVolume?.(value);
      if (value > 0) {
        controls?.unmute?.();
      }
    },
    [controls]
  );

  const muteHandler = useCallback(
    (_e: React.PointerEvent<HTMLElement>) => {
      controls?.toggleMute?.();
    },
    [controls]
  );

  const size = useLayoutSize();
  const progressBarSpacing: boolean = playerMode !== PlayerMode.PREVIEW;

  return (
    <VolumeControllerWrapper
      $progressBarSpacing={progressBarSpacing}
      onPointerUp={(e) => {
        // Stop all pointer up events on the volume slider from propagating
        e.stopPropagation();
        e.preventDefault();
        e.currentTarget.setPointerCapture(e.pointerId);
      }}
    >
      <VolumeButton
        className="player-volume-button"
        aria-label="volume-control"
        onPointerUp={muteHandler}
      >
        {muted ? <VolumeIconMutedSvg /> : <VolumeIconSvg />}
      </VolumeButton>
      {!isMobile() && size !== LayoutSize.SMALLEST && (
        <VolumeSlider
          $progressBarSpacing={progressBarSpacing}
          value={volume}
          onChanged={setVolume}
        />
      )}
    </VolumeControllerWrapper>
  );
};

export default VolumeController;
