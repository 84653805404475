import {
  FunctionComponent,
  SVGAttributes,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";

import { useMetadata } from "../providers/MetadataProvider";
import {
  ageIcons,
  descriptorIcons,
  ProductPlacementSvg,
} from "./ContentRatingIcons";

const ContentRatingsWrapper = styled.div`
  height: 32px;
  margin-top: 10px;
`;

const ContentRatingItem = styled.span`
  margin-right: 6px;
  height: 32px;
`;

export const ContentRatings = () => {
  const { asset } = useMetadata();
  const [descriptorSVGs, setDescriptorSVGs] = useState<
    FunctionComponent<SVGAttributes<SVGElement>>[]
  >([]);
  const contentRating = asset?.contentRating;

  const ageRating = asset?.contentRating?.ageRating;
  const AgeIcon = ageRating
    ? ageIcons.find((icon) => icon.age === asset?.contentRating?.ageRating)
        ?.component
    : null;

  const fetchDescriptorSVGs = useCallback(async () => {
    if (!asset?.contentRating?.descriptors) return;
    const icons = asset.contentRating.descriptors.flatMap(
      (descriptor) =>
        descriptorIcons.find((icon) => icon.name === descriptor)?.component ??
        []
    );
    setDescriptorSVGs(icons);
  }, [asset]);

  useEffect(() => {
    fetchDescriptorSVGs();
  }, [asset, fetchDescriptorSVGs]);

  if (
    contentRating &&
    (contentRating?.ageRating ||
      contentRating?.productPlacement ||
      (contentRating?.descriptors && contentRating?.descriptors.length > 0))
  ) {
    return (
      <ContentRatingsWrapper>
        <ContentRatingItem>{AgeIcon ? <AgeIcon /> : null}</ContentRatingItem>
        {descriptorSVGs.map((DescriptorSVG, index) => (
          <ContentRatingItem key={index}>
            <DescriptorSVG />
          </ContentRatingItem>
        ))}
        {contentRating?.productPlacement && (
          <ContentRatingItem>
            <ProductPlacementSvg />
          </ContentRatingItem>
        )}
      </ContentRatingsWrapper>
    );
  }
  return null;
};

export default ContentRatings;
