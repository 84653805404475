import { ReactNode } from "react";
import styled from "styled-components";

import { useLayoutSize } from "../providers/LayoutProvider";
import { PlayerControlButton } from "../styles";
import { LayoutSize } from "../util/device";

const Button = styled(PlayerControlButton)`
  span {
    white-space: nowrap;
  }
  // space between icon and label when both are showing
  svg + span {
    margin-left: 10px;
  }
`;

export type TPlayerControlLabelButton = {
  label?: string;
  autoHideLabel?: boolean;
  Icon?: ReactNode;
} & React.HTMLAttributes<HTMLButtonElement>;

export const PlayerControlLabelButton = ({
  label,
  autoHideLabel,
  Icon,
  ...props
}: TPlayerControlLabelButton) => {
  const layoutSize = useLayoutSize();

  // default auto hide to true
  if (
    autoHideLabel !== false &&
    [LayoutSize.SMALL, LayoutSize.SMALLEST].includes(layoutSize)
  ) {
    label = "";
  }

  return (
    <Button {...props}>
      {Icon}
      {label && <span>{label}</span>}
    </Button>
  );
};
