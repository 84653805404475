import "./core.scss";

import { loadWebSender, type WebSender } from "@tv4/avod-web-player-chromecast";
import {
  AWPError,
  Capabilities,
  CoreEvents,
  CoreEventsMap,
  ERROR_CATEGORY,
  ErrorLevel,
  EventEmitter,
  getMRMProfileDevice,
  getMRMSiteSectionDevice,
  getPersistentID,
  getRemoteConfigValue,
  isbot,
  Media,
  MediaType,
  Metadata,
  PlaybackMode,
  PlaybackState,
  PLAYER_ERROR,
  PlayerMode,
  ResetPayload,
  Service,
  StateChangedPayload,
  TControls,
  uuid,
} from "@tv4/avod-web-player-common";
import {
  canPlayVideo,
  isChrome,
  isMobile,
} from "@tv4/avod-web-player-device-capabilities";
import {
  FreeWheelAdServer,
  type StreamInfoService,
} from "@tv4/avod-web-player-http";

import { PauseAdEngine } from "./ads_engine/PauseAdEngine";
import { HtmlVideoEngine } from "./media_engine/HtmlVideoEngine";
import { getMediaEngine } from "./media_engine/utils/getMediaEngine";
import { PauseHandler } from "./PauseHandler";
import { BasePlayback, PlaybackOptions } from "./playback/BasePlayback";
import { CSAIPlayback } from "./playback/CSAIPlayback";
import { SSAIPlayback } from "./playback/SSAIPlayback";
import { StateManager } from "./StateManager";
import { createVideoElement } from "./utils/html";

export type TCoreOptions = {
  muted?: boolean;
  refreshToken?: string;
  autoplay?: boolean;
  gdprConsent?: string;
  fullscreenElementId: string;
  iosNativeFullscreen?: boolean;
  service: Service;
  playerMode: PlayerMode;
  adUuid?: string;
  castId?: string;
  userId: string;
  enableCast: boolean;
  enableAirplay: boolean;
  enableFullscreen: boolean;
  loopVideo: boolean;
};

export type TCoreLoadOptions = {
  media: Media;
  availablePlaybackModes: PlaybackMode[];
  capabilities: Capabilities;
  metadata?: Metadata;
  startTime?: number;
  adTags?: string;
  noAds?: boolean;
  autoplay?: boolean;
  streamInfoService?: StreamInfoService;
};

type CoreEventsPayloadType<T extends CoreEvents> = CoreEventsMap[T];

export class Core extends EventEmitter<CoreEventsMap> {
  private root: HTMLElement;
  private backgroundCanvas: HTMLCanvasElement;

  private options!: TCoreOptions;
  private adServer: FreeWheelAdServer;

  private stateManager: StateManager;
  private castSender?: WebSender;
  private castSenderPromise?: Promise<WebSender | undefined>;

  private pauseAdEngine: PauseAdEngine | null = null;
  private playbackController:
    | BasePlayback
    | CSAIPlayback
    | SSAIPlayback
    | null = null;
  private currentMediaEngine?: HtmlVideoEngine;
  private unmutedByPlayer = false;
  private userVolumeWhenPlayerUnmuted?: number;
  private hasUsedInitialAdImmunity = false;
  private _sessionId?: string;
  private pauseHandler: PauseHandler;
  private noAds = false;
  private destroyed = false;

  constructor(containerElement: HTMLElement, options: TCoreOptions) {
    super();
    if (!containerElement.parentElement) {
      throw new AWPError({
        context: "core",
        message: "the provided element is not attached to the DOM",
        category: ERROR_CATEGORY.DEFAULT,
        code: PLAYER_ERROR.GENERIC,
        errorLevel: ErrorLevel.PLAYER,
      });
    }

    this.setOptions({
      autoplay: false,
      ...options,
      muted: options.muted ?? false,
    });

    if (options.castId && options.playerMode === PlayerMode.DEFAULT) {
      this.setupCastSender();
    }

    this.root = document.createElement("div");
    this.root.classList.add("avod-web-player-media");
    this.backgroundCanvas = document.createElement("canvas");
    this.backgroundCanvas.classList.add("avod-web-player-canvas-background");

    containerElement.appendChild(this.backgroundCanvas);
    containerElement.appendChild(this.root);

    this.stateManager = new StateManager(options.playerMode);

    this.pauseHandler = new PauseHandler({
      on: this.on.bind(this),
      off: this.off.bind(this),
      emit: this.emit.bind(this),
    });

    this.adServer = new FreeWheelAdServer({
      service: options.service,
      userId: options.adUuid || options.userId,
      persistentID: getPersistentID(),
      profileDevice: getMRMProfileDevice(options.service),
      sectionDevice: getMRMSiteSectionDevice(),
      gdprConsent: options.gdprConsent,
    });

    this.onAll((type, data) => {
      this.stateManager.handleAllEvents(type, data);
    });

    this.stateManager.on(
      CoreEvents.STATE_CHANGED,
      (event: StateChangedPayload) => {
        const { playbackMode, streamInfo } = event.state;
        this.emit(CoreEvents.STATE_CHANGED, event);
        if (playbackMode) {
          this.playbackController?.setPlaybackMode(playbackMode);
        }
        if (streamInfo) {
          this.playbackController?.setStreamInfo(streamInfo);
        }
      }
    );
  }

  /**
   * Setup the cast sender if it hasn't been set up already, and return it if successful
   */
  private async setupCastSender(): Promise<WebSender | undefined> {
    if (!this.options.enableCast) {
      return undefined;
    }

    this.castSenderPromise ??= new Promise((resolve) => {
      loadWebSender(
        this.noAds,
        this.options.castId,
        this.options.gdprConsent
      ).then((castSender) => {
        if (castSender && !this.destroyed) {
          this.castSender = castSender;
          castSender.onAll((eventType, data) => {
            this.emit(eventType, data);
          });
          this.emit(CoreEvents.CHROMECAST_SENDER_LOADED, undefined);
          resolve(castSender);
        } else {
          resolve(undefined);
        }
      });
    });
    return this.castSenderPromise;
  }

  public async isCasting(playerMode?: PlayerMode) {
    if (!("chrome" in window)) return false;

    if (playerMode !== PlayerMode.DEFAULT) return false;

    const castSender = await this.setupCastSender();

    if (this.destroyed || !castSender) {
      return false;
    }

    return castSender.isCasting();
  }

  public setOptions(options: Partial<TCoreOptions>) {
    this.options = {
      ...this.options,
      ...options,
    };
    this.emit(CoreEvents.CORE_OPTIONS_CHANGED, undefined);

    const adUuid = options.adUuid ?? options.userId;

    if (this.adServer && adUuid) {
      this.adServer.setUserId(adUuid);
    }
  }

  public emitCoreEvent<T extends CoreEvents>(
    eventType: T,
    payload: CoreEventsPayloadType<T>
  ) {
    this.emit(eventType, payload);
  }

  public async load({
    media,
    availablePlaybackModes: playbackModes,
    capabilities,
    metadata,
    startTime,
    adTags,
    noAds,
    autoplay,
    streamInfoService,
  }: TCoreLoadOptions): Promise<void> {
    if (this.playbackController) {
      throw new Error(
        "Unexpected core.load() call. First call core.reset() if the intention is to play again on same instance."
      );
    }

    if (this.destroyed) {
      throw new Error("Unexpected core.load() call. Core instance destroyed.");
    }

    if (this.isLoading() || !(await this.isCapableToPlayVideo(media))) return;

    if (this.destroyed) return;

    const videoElement = createVideoElement({
      muted: !!this.options.muted,
      parent: this.root,
      loop: this.options.loopVideo,
      playerMode: this.options.playerMode,
      classes: ["main"],
    });

    this.noAds =
      metadata?.asset.hideAds ||
      noAds ||
      this.options.playerMode === PlayerMode.PREVIEW;

    this.playbackController =
      (await this.createPlaybackController(
        media,
        playbackModes,
        capabilities,
        videoElement,
        metadata,
        adTags,
        streamInfoService
      )) ?? null;

    if (this.destroyed || !this.playbackController) return;

    if (
      isChrome() &&
      !isMobile() &&
      media.type === MediaType.HLS &&
      media.isLive &&
      media.isStitched
    ) {
      document.addEventListener(
        "visibilitychange",
        this.onVisibilityChange.bind(this)
      );
    }
    const useInitialAdImmunity =
      !this.hasUsedInitialAdImmunity &&
      this.stateManager.getState().playbackMode !== PlaybackMode.DEFAULT;

    if (!this.noAds && capabilities.pause_ads) {
      this.initializePauseAdEngine();
    }

    this.playbackController.onAll((evt, data) => this.emit(evt, data));
    return this.playbackController
      .load({
        startTime,
        autoplay: autoplay ?? this.options.autoplay ?? false,
        useInitialAdImmunity,
      })
      .then(() => {
        if (this.destroyed) return;

        this.hasUsedInitialAdImmunity = true;
      });
  }

  private isLoading(): boolean {
    if (this.root.innerHTML) return true;
    return this.stateManager.getState().playbackState === PlaybackState.LOADING;
  }

  private initializePauseAdEngine() {
    if (!this.playbackController) return;

    this.pauseAdEngine = new PauseAdEngine({
      root: this.root,
      adServerInstance: this.adServer,
    });
    this.pauseAdEngine.onAll((evt, data) => this.emit(evt, data));
    this.playbackController.onAll(
      this.pauseAdEngine.onEvent.bind(this.pauseAdEngine)
    );
  }

  public get sessionId(): string {
    return this._sessionId || this.generateTrackingSessionId();
  }

  public generateTrackingSessionId(): string {
    const sessionId = uuid();
    this._sessionId = sessionId;
    this.emit(CoreEvents.SESSION_CREATED, { sessionId });
    return sessionId;
  }

  public async createPlaybackController(
    media: Media,
    availablePlaybackModes: PlaybackMode[],
    capabilities: Capabilities,
    videoElement: HTMLVideoElement,
    metadata?: Metadata,
    adTags?: string,
    streamInfoService?: StreamInfoService
  ) {
    const { fullscreenElementId, iosNativeFullscreen, service } = this.options;
    const MediaEngine = await getMediaEngine(media);

    if (this.destroyed) return;

    this.currentMediaEngine = new MediaEngine({
      videoElement,
      enableAirplay: this.options.enableAirplay,
    });

    this.emit(CoreEvents.ENGINE, { name: this.currentMediaEngine.engineName });

    const baseOptions: PlaybackOptions = {
      service,
      mediaEngine: this.currentMediaEngine,
      media,
      capabilities,
      availablePlaybackModes: availablePlaybackModes,
      fullscreenElementId,
      iosNativeFullscreen,
      enableFullscreen: this.options.enableFullscreen,
      playerMode: this.options.playerMode,
    };

    if (metadata) {
      if (adTags) {
        metadata.adBreak.trackingData.tags += `,${adTags}`;
      }
      if (this.noAds) {
        return new BasePlayback({ ...baseOptions, metadata });
      }
      await this.adServer
        .load({
          adServerOptions: metadata.adBreak.trackingData,
          isLive: metadata.asset?.isLive,
          wireSessionId: this.sessionId,
        })
        .catch((err: AWPError) => {
          if (this.destroyed) return;

          this.emit(CoreEvents.LOAD_PLAYBACK_ERROR, {
            error: err,
          });
          throw err;
        });

      if (this.destroyed) return;

      const playbackMode = this.getState().playbackMode;
      const useStreamInfoSSAIEngine =
        playbackMode === PlaybackMode.ORIGIN ||
        playbackMode === PlaybackMode.ORIGIN_FALLBACK;

      if (useStreamInfoSSAIEngine || media.isStitched) {
        return new SSAIPlayback({
          ...baseOptions,
          metadata,
          gdprConsent: this.options.gdprConsent,
          adUuid: this.options.adUuid ?? getPersistentID(this.options.userId),
          engine: useStreamInfoSSAIEngine ? "linear" : "ssai",
          sessionId: this.sessionId,
          streamInfoService,
        });
      } else {
        return new CSAIPlayback({
          ...baseOptions,
          metadata,
          root: this.root,
          adServerInstance: this.adServer,
          muted: this.options.muted ?? false,
        });
      }
    } else {
      this.adServer.load({
        wireSessionId: this.sessionId,
      });

      return new BasePlayback(baseOptions);
    }
  }

  private async allowLoadVideo(): Promise<boolean> {
    return (
      canPlayVideo() ||
      // allow web crawler to load video so it will show in search results
      (await isbot())
    );
  }

  private async isCapableToPlayVideo(media: Media): Promise<boolean> {
    let error: Partial<AWPError> | undefined;
    if (!media) {
      error = {
        message: "load failed, media is falsy",
        code: PLAYER_ERROR.MISSING_DATA,
      };
    }
    if (!(await this.allowLoadVideo())) {
      if (this.destroyed) return false;

      error = {
        message: "load failed, device not supported",
        code: PLAYER_ERROR.UNSUPPORTED_DEVICE,
      };
    }

    if (error) {
      this.emit(CoreEvents.LOAD_PLAYBACK_ERROR, {
        error: new AWPError({
          context: "core",
          category: ERROR_CATEGORY.DEFAULT,
          errorLevel: ErrorLevel.PLAYER,
          // satisfy type definition by including message. error.message will override below.
          message: "unknown error",
          ...error,
        }),
      });
      return false;
    } else {
      return true;
    }
  }

  public getControls(): TControls {
    if (!this.playbackController) {
      return null;
    }

    return this.playbackController.getControls();
  }

  public getOptions(): TCoreOptions {
    return this.options;
  }

  public getChromeCastManager(): WebSender | undefined {
    return this.castSender;
  }

  public getState() {
    return this.stateManager.getState();
  }

  public getDebugInfo() {
    return {
      mediaEngine: this.currentMediaEngine,
      playbackController: this.playbackController,
      adImmunityDuration: getRemoteConfigValue("AD_IMMUNITY_DURATION"),
    };
  }

  public notifyCoreEvent(
    eventType: CoreEvents,
    data: CoreEventsMap[CoreEvents]
  ) {
    if (!this.playbackController) return;
    this.playbackController?.handleCoreEvents(eventType, data);
  }

  private onVisibilityChange() {
    // Chrome and Safari pause the video when switching tabs
    // which (especially on Chrome) breaks live SSAI ad event flow.
    // This addresses the issue by unmuting and setting a very low volume when this occurs
    // and the ads keep playing.
    const isVisible = document.visibilityState === "visible";
    const controls = this.getControls?.();

    if (!controls) return;

    if (isVisible) {
      if (this.unmutedByPlayer) {
        if (this.userVolumeWhenPlayerUnmuted && controls.setVolume)
          controls.setVolume(this.userVolumeWhenPlayerUnmuted);
        controls.mute?.();
      }
      this.unmutedByPlayer = false;
      return;
    }
    if (!this.currentMediaEngine?.getMuted()) return;

    controls.unmute?.();
    this.userVolumeWhenPlayerUnmuted = this.currentMediaEngine?.getVolume();
    controls.setVolume?.(0.000000001);
    this.unmutedByPlayer = true;
  }

  public setVideoFrameToBackground() {
    if (!this.backgroundCanvas) return;
    this.playbackController?.drawVideoFrameOnCanvas(this.backgroundCanvas);
  }

  private resetBackgroundCanvas() {
    const context = this.backgroundCanvas.getContext("2d");
    if (!context) return;

    context.clearRect(
      0,
      0,
      this.backgroundCanvas.width,
      this.backgroundCanvas.height
    );
  }

  public reset(resetOptions: ResetPayload = { modeSwitch: false }) {
    document.removeEventListener("visibilitychange", this.onVisibilityChange);
    this.playbackController?.destroy();
    this.playbackController = null;

    this.pauseAdEngine?.destroy();
    this.pauseAdEngine = null;

    this.root.innerHTML = "";
    if (!resetOptions.modeSwitch) {
      this.resetBackgroundCanvas();
    }

    this.emit(CoreEvents.RESET, resetOptions);
  }

  public override destroy() {
    this.reset();

    this.adServer.destroy();
    this.pauseHandler.destroy();
    this.stateManager.destroy();
    this.castSender?.destroy();

    this.destroyed = true;
  }
}
