import { ThumbnailCue } from "@tv4/avod-web-player-common";
import { useEffect, useRef, useState } from "react";

import { getDurationString, getTimeString } from "../../util/time";
import {
  ScrubbingOverlayContainer,
  ScrubbingTime,
  ThumbnailImage,
} from "./styles";

type TimeThumbnailOverlayProps = {
  left: number;
  time: number;
  thumbnailCues: ThumbnailCue[];
  utcStartTimeMs?: number;
};

export function TimeThumbnailOverlay({
  left,
  time,
  thumbnailCues,
  utcStartTimeMs,
}: TimeThumbnailOverlayProps) {
  const [loadedImages, setLoadedImages] = useState<{
    [key: string]: boolean;
  }>({});
  const thumbnail = thumbnailCues?.find(
    (t) => time >= t.startTime && time <= t.endTime
  );

  const imageRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const imageUrl = thumbnail?.imageUrl;
  const frame = thumbnail?.frame || { x: 0, y: 0, width: 0, height: 0 };
  const { width, height } = frame;

  // preload image, ignore if already loaded, assume it's cached.
  useEffect(() => {
    if (!imageUrl || loadedImages[imageUrl]) {
      return;
    }

    const image = new Image();
    image.onload = () => {
      setLoadedImages((prev) => ({
        ...prev,
        [imageUrl]: true,
      }));
    };
    image.src = imageUrl;
    return () => {
      image.src = "";
    };
  }, [imageUrl, loadedImages]);

  return (
    <ScrubbingOverlayContainer ref={containerRef}>
      {frame && imageUrl && (
        <ThumbnailImage
          ref={imageRef}
          style={{
            width: `${width}px`,
            height: `${height}px`,
            left: `clamp(0%, ${left}% - ${width / 2}px, 100% - ${width}px)`,
            backgroundImage: loadedImages[imageUrl] ? `url(${imageUrl})` : "",
            backgroundPosition: `${-frame.x}px ${-frame.y}px`,
          }}
        />
      )}
      <ScrubbingTime style={{ left: `${left}%` }}>
        {utcStartTimeMs
          ? getTimeString(utcStartTimeMs + time * 1000)
          : getDurationString(time)}
      </ScrubbingTime>
    </ScrubbingOverlayContainer>
  );
}
