import { DEFAULT_PLAYER_CONFIG } from "@tv4/avod-web-player-common";

import ForwardSvg from "../icons/ForwardIcon.svg";
import { useControls } from "../providers/CoreProvider";
import { PlayerControlButton as Button } from "../styles";

const SEEK_AMOUNT = DEFAULT_PLAYER_CONFIG.seekAmount;

export const SeekForwardButton = () => {
  const controls = useControls();

  const seek = () => {
    controls?.seekForward?.(SEEK_AMOUNT);
  };

  return (
    <Button aria-label={`jump ${SEEK_AMOUNT} seconds forward`} onClick={seek}>
      <ForwardSvg />
    </Button>
  );
};

export default SeekForwardButton;
