import {
  AdBreakType,
  AdInsertionType,
  AdMarker,
  BufferSeekingPayload,
  Capabilities,
  CoreEvents,
  CoreEventsMap,
  EventEmitter,
  getRemoteConfigValue,
  IAdBreak,
  TimePayload,
} from "@tv4/avod-web-player-common";
import type { StreamInfoService } from "@tv4/avod-web-player-http";

import { MediaID3Payload } from "../media_engine/utils/mediaConstants";
import {
  TSSAIAdEngineParams,
  TSSAISeekHandler,
} from "../playback/SSAIPlayback";
import { hasAdImmunity, setAdImmunity } from "../utils/adImmunity";

export class StreamInfoLinearAdEngine extends EventEmitter<CoreEventsMap> {
  public readonly insertionType = AdInsertionType.Linear;
  // TODO: Refactor seeking event payload so it gets the pre-seek time, not just seek-time
  // This will also be more exact, using the real current time, while this is updated every ~250ms
  private currentTime = 0;
  private currentAdMarker?: AdMarker;
  private redirectPosition?: number;
  private seekHandler: TSSAISeekHandler;
  private adImmunityDuration = getRemoteConfigValue("AD_IMMUNITY_DURATION");
  private capabilities: Capabilities;
  private assetId: string;
  private streamInfoService?: StreamInfoService;

  constructor(options: TSSAIAdEngineParams) {
    super();
    this.seekHandler = options.seekHandler;
    this.streamInfoService = options.streamInfoService;
    this.capabilities = options.capabilities;
    this.assetId = options.metadata.asset.id;
  }

  public getVodAdMarkers(): AdMarker[] {
    return this.streamInfoService?.adMarkers ?? [];
  }

  public isInAdBreak() {
    return !!this.currentAdMarker;
  }

  public handleTimeUpdate({ currentTime }: TimePayload): boolean {
    if (this.hasAdImmunity()) {
      return false;
    }

    this.currentTime = currentTime;

    const adMarker = this.streamInfoService?.getAdMarkerAt(currentTime);
    if (!this.currentAdMarker && adMarker?.required && !adMarker.watched) {
      this.currentAdMarker = adMarker;
      const adBreak: IAdBreak = {
        breakType: AdBreakType.Midroll,
        insertionType: AdInsertionType.Linear,
        ads: [],
        timeOffset: adMarker.start,
      };
      this.emit(CoreEvents.BREAK_START, { adBreak, tracking: false });
    }

    if (this.currentAdMarker && !adMarker) {
      setAdImmunity(this.assetId);
      this.currentAdMarker = undefined;
      this.emit(CoreEvents.BREAK_END, { tracking: false });
      if (this.redirectPosition !== undefined) {
        this.seekHandler(this.redirectPosition);
        this.redirectPosition = undefined;
      }
    }

    return false;
  }

  public handleSeeking(payload: BufferSeekingPayload) {
    if (this.redirectPosition) {
      return true;
    }
    if (this.hasAdImmunity()) {
      return false;
    }

    let seekToAdMarker: AdMarker | undefined;

    const seekPositionAdMarker = this.streamInfoService?.getAdMarkerAt(
      payload.currentTime
    );

    // If trying to seek into an ad break, seek to the start instead
    if (seekPositionAdMarker) {
      seekToAdMarker = seekPositionAdMarker;
    } else {
      // If seeking forwards in time, skipping ad breaks, get the closest preceding ad break
      const precedingAdMarker = this.streamInfoService
        ?.getAdMarkerStartingBetween(this.currentTime, payload.currentTime)
        .pop();

      if (precedingAdMarker?.required && !precedingAdMarker.watched) {
        seekToAdMarker = precedingAdMarker;
        this.redirectPosition = payload.currentTime;
      }
    }

    if (
      seekToAdMarker?.required &&
      !seekToAdMarker.watched &&
      // When seekTo is requestedPosition it means we have already seeked, which triggers the
      // same event listener, and we need to ignore the second time to prevent an infinite loop
      seekToAdMarker.start !== payload.currentTime
    ) {
      this.seekHandler(seekToAdMarker.start);

      // The eventflow is seeking -> seeked -> break_start since this seeked is ignored we need
      // to fake a successful seeked event.
      this.emit(CoreEvents.SEEKED, {
        ...payload,
        currentTime: seekToAdMarker.start,
      });
      return true;
    }

    return this.isInAdBreak();
  }

  public handleSeeked(_payload: BufferSeekingPayload) {
    return false;
  }

  public markAdBreaksBeforeStartTimeAsWatched(_startTime: number): void {
    // This only applies to VOD, so nothing to do here
  }

  public hasAdImmunity(): boolean {
    return (
      this.capabilities.skip_ads ||
      hasAdImmunity(this.assetId, this.adImmunityDuration)
    );
  }

  public getContentPositionForPlayheadPosition(playheadPosition: number) {
    return playheadPosition;
  }

  public getPlayheadPositionForContentPosition(contentPosition: number) {
    return contentPosition;
  }

  public handlePlaying(_payload: TimePayload) {
    return false;
  }

  public handlePaused(_payload: TimePayload) {
    return false;
  }

  public handleBuffering(_payload: TimePayload) {
    // This method doesn't apply to static SSAI
    return false;
  }

  public handleBuffered(_payload: TimePayload) {
    // This method doesn't apply to static SSAI
    return false;
  }

  public handleEnded(_payload: TimePayload) {
    return false;
  }

  public handleID3(_payload: MediaID3Payload) {
    // no-op
  }
}
