import {
  AWPAudioTrack,
  AWPTextTrack,
  AWPTextTrackKind,
  languages,
  Locale,
  localPreferences,
  NO_TEXT_TRACK,
  Track,
} from "@tv4/avod-web-player-common";
import { isSupportedOS as isDesktopOS } from "@tv4/avod-web-player-device-capabilities";
import { FunctionComponent, useCallback, useEffect, useMemo } from "react";

import Check from "../../../icons/check.svg";
import { useControls, usePlayerState } from "../../../providers/CoreProvider";
import {
  useLayoutOrientation,
  useLayoutSettings,
  useLayoutSize,
  useTracksMenuOpen,
} from "../../../providers/LayoutProvider";
import { useTranslations } from "../../../providers/TranslationsProvider";
import { LayoutOrientation, LayoutSize } from "../../../util/device";
import TextTrackControls from "../TextTrackControls";
import { TracksButton } from "./TracksButton";
import {
  CloseIcon,
  Divider,
  NoTextTracksAvailable,
  TrackItem,
  TrackListContainer,
  TracksContainer,
  TracksMenuContainer,
  TracksWrapper,
  TrackTitle,
} from "./TracksMenu.styles";
import { usePreferredTextSize } from "./usePreferredTextSize";

const getLanguageTranslation = (
  translations: ReturnType<typeof useTranslations>,
  language: string
): string =>
  translations(`language__${language}` as keyof (typeof languages)[Locale]);

const getTrackTranslation = (
  translations: ReturnType<typeof useTranslations>,
  track: Track
) => {
  let translation: string;
  if (track.language) {
    translation = getLanguageTranslation(translations, track.language);
  } else if ((track as AWPTextTrack).kind === AWPTextTrackKind.NOTHING) {
    translation = translations("language__subtitles_off");
  } else {
    translation = track.label;
  }
  return translation || "";
};

type TracksListProps = {
  tracks: Track[];
  title: string;
  activeTrack: Track | null | undefined;
  onClick: (track: Track) => void;
  $stackVertically: boolean;
};

const TracksList = ({
  tracks,
  title,
  activeTrack,
  onClick,
  $stackVertically,
}: TracksListProps) => {
  const translations = useTranslations();
  return (
    <TrackListContainer>
      <TrackTitle $stackVertically={$stackVertically}>{title}</TrackTitle>
      {tracks.map((track, i) => {
        const isActive = activeTrack?.id === track.id;
        return (
          <TrackItem
            onClick={() => onClick(track)}
            className={isActive ? "active" : ""}
            key={track.id + i}
          >
            <div style={{ marginRight: "10px", width: "16px" }}>
              {isActive && <Check width="16" />}
            </div>
            {getTrackTranslation(translations, track)}
          </TrackItem>
        );
      })}
    </TrackListContainer>
  );
};

const HIDE_TRACKS_MENU_DELAY = 3 * 1000;
let tracksMenuOpenTimeout: number;

function Tracks({
  handleInteraction,
  closeTracksMenu,
  displayCover,
}: {
  handleInteraction: () => void;
  closeTracksMenu: () => void;
  displayCover: boolean;
}) {
  const {
    activeTextTrack,
    activeAudioTrack,
    audioTracks,
    textTracks,
    isLive,
    isFullscreen,
  } = usePlayerState();
  const controls = useControls();
  const layoutSize = useLayoutSize();
  const layoutOrientation = useLayoutOrientation();

  const translations = useTranslations();

  const handleTextTrackClick = useCallback(
    (textTrack: Track) => {
      controls?.setTextTrack?.(textTrack as AWPTextTrack);
      localPreferences.setPreferredText(textTrack as AWPTextTrack, isLive);
      handleInteraction();
    },
    [controls, handleInteraction, isLive]
  );

  const handleAudioTrackClick = useCallback(
    (audioTrack: Track) => {
      controls?.setAudioTrack?.(audioTrack as AWPAudioTrack);
      localPreferences.setPreferredAudio(audioTrack.language);
      handleInteraction();
    },
    [controls, handleInteraction]
  );

  const showTextTracks = textTracks.length > 1;
  const showAudioTracks = audioTracks.length > 1;

  const stackOptionsVertically =
    layoutSize === LayoutSize.SMALLEST ||
    (isFullscreen && layoutOrientation === LayoutOrientation.PORTRAIT) ||
    (!isFullscreen &&
      layoutOrientation === LayoutOrientation.PORTRAIT &&
      !isDesktopOS());

  return (
    <TracksContainer
      $displayCover={displayCover}
      onPointerMove={handleInteraction}
    >
      <TracksMenuContainer $displayCover={displayCover}>
        <TracksWrapper
          $displayCover={displayCover}
          $stackVertically={stackOptionsVertically}
        >
          {!showTextTracks && !showAudioTracks ? (
            <NoTextTracksAvailable>
              {translations("language__no_subtitles")}
            </NoTextTracksAvailable>
          ) : (
            <>
              {showTextTracks && (
                <TracksList
                  tracks={textTracks}
                  activeTrack={activeTextTrack}
                  title={translations("language__subtitles")}
                  onClick={handleTextTrackClick}
                  $stackVertically={stackOptionsVertically}
                />
              )}
              {showAudioTracks && (
                <>
                  {showTextTracks && (
                    <Divider $stackVertically={stackOptionsVertically} />
                  )}
                  <TracksList
                    tracks={audioTracks}
                    activeTrack={activeAudioTrack}
                    title={translations("language__audio")}
                    onClick={handleAudioTrackClick}
                    $stackVertically={stackOptionsVertically}
                  />
                </>
              )}
              {showTextTracks && (
                <>
                  <Divider $stackVertically={stackOptionsVertically} />
                  <TextTrackControls
                    $stackVertically={stackOptionsVertically}
                  />
                </>
              )}
            </>
          )}
        </TracksWrapper>
      </TracksMenuContainer>
      {displayCover && <CloseIcon onClick={closeTracksMenu} />}
    </TracksContainer>
  );
}

type TTracksHandler = {
  TracksButton?: FunctionComponent<Partial<Parameters<typeof TracksButton>[0]>>;
  TracksMenu?: FunctionComponent<Partial<Parameters<typeof Tracks>[0]>>;
  closeTracksMenu: () => void;
  tracksMenuOpen: boolean;
};

export const useTracksMenu = (): TTracksHandler => {
  const { isAd, isPauseAd } = usePlayerState();

  const showTracks =
    !useLayoutSettings().hideSubtitlesMenu && !isPauseAd && !isAd;

  const { activeTextTrack, activeAudioTrack, audioTracks, textTracks } =
    usePlayerState();
  const layoutSize = useLayoutSize();
  const { tracksMenuOpen, setTracksMenuOpen } = useTracksMenuOpen();

  const hasTracksToChooseFrom = audioTracks.length > 1 || textTracks.length > 1;

  const translations = useTranslations();

  usePreferredTextSize();

  const activeTextTrackLabel = activeTextTrack?.label || "";
  const activeTextTrackLanguage = activeTextTrack?.language || "";

  const activeAudioTrackLabel = activeAudioTrack?.label || "";
  const activeAudioTrackLanguage = activeAudioTrack?.language || "";

  const textTracksCount = textTracks?.length || 0;
  const audioTracksCount = audioTracks?.length || 0;

  const isSmallLayout = [LayoutSize.SMALL, LayoutSize.SMALLEST].includes(
    layoutSize
  );

  const audioAndTextLabel = useMemo(() => {
    if (!hasTracksToChooseFrom) return translations("language__no_subtitles");

    const getTextTrackLabel = (): string => {
      if (activeTextTrackLanguage) {
        return getLanguageTranslation(translations, activeTextTrackLanguage);
      }
      if (textTracksCount > 1) {
        // if there are more than 1 subtitle, but no activeTextTrack then display as no subtitles
        return getTrackTranslation(translations, NO_TEXT_TRACK);
      }
      return activeTextTrackLabel || "";
    };

    const getAudioTrackLabel = () => {
      return activeAudioTrackLanguage &&
        activeAudioTrackLanguage !== "0" &&
        activeAudioTrackLanguage !== "und"
        ? getLanguageTranslation(translations, activeAudioTrackLanguage)
        : activeAudioTrackLabel;
    };

    const textTrackLabel = getTextTrackLabel();
    const audioTrackLabel = getAudioTrackLabel();
    const audioTranslation = translations("language__audio");

    if (!audioTrackLabel || audioTracksCount < 2) return textTrackLabel;
    if (!textTrackLabel || textTracksCount < 2)
      return `${audioTranslation}: ${audioTrackLabel}`;

    const separator = textTrackLabel !== "" ? ", " : "";

    return `${textTrackLabel}${separator}${audioTranslation}: ${audioTrackLabel}`;
  }, [
    activeTextTrackLabel,
    activeTextTrackLanguage,
    activeAudioTrackLabel,
    activeAudioTrackLanguage,
    audioTracksCount,
    hasTracksToChooseFrom,
    textTracksCount,
    translations,
  ]);

  const displayCover = isSmallLayout || !isDesktopOS();

  const handleInteraction = useCallback(() => {
    clearTimeout(tracksMenuOpenTimeout);
    if (tracksMenuOpen && !displayCover) {
      tracksMenuOpenTimeout = window.setTimeout(
        () => setTracksMenuOpen(false),
        HIDE_TRACKS_MENU_DELAY
      );
    }
    return () => {
      clearTimeout(tracksMenuOpenTimeout);
    };
  }, [setTracksMenuOpen, tracksMenuOpen, displayCover]);

  // trigger handleInteraction when tracksMenuOpen changes so timeout is started when first opened
  useEffect(handleInteraction, [handleInteraction]);

  // set as closed when unmounted
  useEffect(() => () => setTracksMenuOpen(false), [setTracksMenuOpen]);

  const handleTracksButtonClick = useCallback(() => {
    setTracksMenuOpen(!tracksMenuOpen);
  }, [setTracksMenuOpen, tracksMenuOpen]);

  const closeTracksMenu = useCallback(() => {
    if (tracksMenuOpen) {
      setTracksMenuOpen(false);
    }
  }, [setTracksMenuOpen, tracksMenuOpen]);

  return {
    tracksMenuOpen,
    closeTracksMenu,
    TracksButton: useMemo(
      () => (props?: Partial<Parameters<typeof TracksButton>[0]>) =>
        showTracks ? (
          <TracksButton
            onClick={handleTracksButtonClick}
            hasTracksToChooseFrom={hasTracksToChooseFrom}
            label={audioAndTextLabel}
            {...props}
          />
        ) : null,
      [
        handleTracksButtonClick,
        hasTracksToChooseFrom,
        audioAndTextLabel,
        showTracks,
      ]
    ),
    TracksMenu: useMemo(
      () => (props?: Partial<Parameters<typeof Tracks>[0]>) =>
        showTracks && tracksMenuOpen ? (
          <Tracks
            handleInteraction={handleInteraction}
            closeTracksMenu={closeTracksMenu}
            displayCover={displayCover}
            {...props}
          />
        ) : null,
      [
        handleInteraction,
        closeTracksMenu,
        showTracks,
        tracksMenuOpen,
        displayCover,
      ]
    ),
  };
};
