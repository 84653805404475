import styled, { css } from "styled-components";

import { DefaultButton, DefaultSvg } from "../../styles";
import { colors } from "../../theme/colors";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.6);
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Message = styled.div`
  font-size: 2em;
  margin-bottom: 1em;
`;

const ResponseButton = styled.button<{ confirm?: boolean }>`
  ${DefaultButton}
  ${DefaultSvg}

  padding: 1.1em 2em;

  ${(options) =>
    options.confirm
      ? css`
          background: ${colors.primary};
        `
      : css`
          background: linear-gradient(
            180deg,
            rgba(173, 173, 173, 0.4) 0%,
            rgba(165, 165, 165, 0.4) 79.69%,
            rgba(196, 196, 196, 0.4) 100%
          );
        `}

  border-radius: 8px;
  width: 200px;
  margin-bottom: 1em;
`;

export { ButtonsContainer, Container, Message, ResponseButton };
