import { API_ERROR, AWPError, PLAYER_ERROR } from "@tv4/avod-web-player-common";
import { useState } from "react";

import { useLayoutSize } from "../../providers/LayoutProvider";
import { useTranslations } from "../../providers/TranslationsProvider";
import { LayoutSize } from "../../util/device";
import RefreshPageButton from "../RefreshPageButton";
import {
  ActiveStream,
  DevCode,
  ErrorButton,
  Message,
  StreamingDevice,
  StreamingLocation,
  StreamingTitle,
  Title,
  Wrapper,
} from "./ErrorMessage.styles";

interface ActiveStreamData {
  city: string;
  country: string;
  device: string;
  os: string;
  platform: string;
  title: string;
  type: string;
}
interface StreamingDetailsProps {
  streamsArray: ActiveStreamData[];
  isSmallLayout: boolean;
}
const StreamingDetails = ({
  streamsArray,
  isSmallLayout,
}: StreamingDetailsProps) => {
  const translations = useTranslations();
  return (
    <>
      {streamsArray.map((activeStream, index) => (
        <ActiveStream key={index}>
          <StreamingDevice
            variant={isSmallLayout ? "body3Strong" : "body1Strong"}
          >
            {activeStream.device} -
          </StreamingDevice>
          <StreamingTitle variant={isSmallLayout ? "body4" : "body3"}>
            {activeStream.title}
          </StreamingTitle>
          <StreamingLocation variant={isSmallLayout ? "body4" : "body3"}>
            ({activeStream.city})
          </StreamingLocation>
        </ActiveStream>
      ))}
      <Message variant={isSmallLayout ? "body4" : "body3"}>
        {isSmallLayout
          ? translations(
              "error_message__stream_limit__button_details_log_out_instructions_short"
            )
          : translations(
              "error_message__stream_limit__button_details_log_out_instructions"
            )}
      </Message>
    </>
  );
};

const getUniqueDeviceOsCityCombinations = (
  activeStreams: ActiveStreamData[]
) => {
  const uniqueDeviceOsCityCombinations: ActiveStreamData[] = [];
  const uniqueCombinations = new Set();

  activeStreams.forEach((activeStream) => {
    const combination =
      activeStream.device + "-" + activeStream.os + "-" + activeStream.city;
    if (!uniqueCombinations.has(combination)) {
      uniqueCombinations.add(combination);
      uniqueDeviceOsCityCombinations.push(activeStream);
    }
  });

  return uniqueDeviceOsCityCombinations;
};

export const ErrorMessage = ({
  error,
  onExitClick,
}: {
  error?: AWPError;
  onExitClick?: () => void;
}) => {
  const [showActiveStreamDetails, setShowActiveStreamDetails] = useState(false);
  const translations = useTranslations();
  const layoutSize = useLayoutSize();
  const isSmallLayout = [LayoutSize.SMALL, LayoutSize.SMALLEST].includes(
    layoutSize
  );
  const activeStreams: [ActiveStreamData] =
    (error?.raw as any)?.details?.active_streams || [];
  const uniqueDeviceOsCityCombinations: ActiveStreamData[] =
    getUniqueDeviceOsCityCombinations(activeStreams);

  const getHeaderText = () => {
    switch (error?.code) {
      case PLAYER_ERROR.AD_BLOCKER:
        return translations("error_message__ad_blocker__header");
      case API_ERROR.STREAM_LIMIT_SINGLE_STREAM_VIOLATION:
        if (showActiveStreamDetails)
          translations(
            "error_message__stream_limit_details__header_playing_now"
          );
        return translations(
          "error_message__stream_limit_single_stream__header",
          {
            eventTitle:
              activeStreams[0]?.title ??
              translations(
                "error_message__stream_limit_single_stream__header_this_stream"
              ),
          }
        );
      case API_ERROR.STREAM_LIMIT_QUOTA_EXCEEDED:
        if (showActiveStreamDetails)
          return translations(
            "error_message__stream_limit_details__header_playing_now"
          );
        return translations("error_message__stream_limit_stream_quota__header");
      default:
        return translations("error_message__problem_general");
    }
  };

  const getMessageText = () => {
    switch (error?.code) {
      case PLAYER_ERROR.AD_BLOCKER:
        return translations("error_message__ad_blocker__message");
      case API_ERROR.STREAM_LIMIT_SINGLE_STREAM_VIOLATION:
        return isSmallLayout
          ? translations(
              "error_message__stream_limit_single_stream__message_shortened"
            )
          : translations("error_message__stream_limit_single_stream__message");
      case API_ERROR.STREAM_LIMIT_QUOTA_EXCEEDED:
        return isSmallLayout
          ? translations(
              "error_message__stream_limit_stream_quota__message_shortened"
            )
          : translations("error_message__stream_limit_stream_quota__message");
      default:
        return error?.message;
    }
  };
  return (
    <Wrapper>
      <Title variant={isSmallLayout ? "title3" : "title2"}>
        {getHeaderText()}
      </Title>
      {!!error?.message && (
        <>
          {showActiveStreamDetails ? (
            <StreamingDetails
              streamsArray={uniqueDeviceOsCityCombinations}
              isSmallLayout={isSmallLayout}
            />
          ) : (
            <Message variant={isSmallLayout ? "body3Strong" : "body1Strong"}>
              {getMessageText()}
            </Message>
          )}
          {error.code == PLAYER_ERROR.AD_BLOCKER && (
            <RefreshPageButton
              refreshPageHandler={() => location.reload()}
            ></RefreshPageButton>
          )}
          {(error.code == API_ERROR.STREAM_LIMIT_SINGLE_STREAM_VIOLATION ||
            error.code == API_ERROR.STREAM_LIMIT_QUOTA_EXCEEDED) && (
            <>
              <ErrorButton
                onClick={
                  showActiveStreamDetails
                    ? () => setShowActiveStreamDetails(false)
                    : onExitClick
                }
                layoutSize={layoutSize}
                close
              >
                {showActiveStreamDetails
                  ? translations("error_message__stream_limit__button_back")
                  : translations("error_message__stream_limit__button_close")}
              </ErrorButton>
              {!showActiveStreamDetails && (
                <ErrorButton
                  onClick={() => setShowActiveStreamDetails(true)}
                  layoutSize={layoutSize}
                >
                  {translations(
                    "error_message__stream_limit__button_details_about_streaming"
                  )}
                </ErrorButton>
              )}
            </>
          )}
          <DevCode>{btoa(error.message)}</DevCode>
        </>
      )}
    </Wrapper>
  );
};

export default ErrorMessage;
