/* eslint no-extend-native: "off" */

/**
 * When executed, this function applies polyfills to the following functionalities:
 * Function.prototype.bind and
 * Array.prototype.forEach.
 *
 * @memberof npaw
 */
var applyPolyfills = function () {
  // Bind, changed for compatibility
  Function.prototype.bind = Function.prototype.bind || function (context) {
    var slice = Array.prototype.slice
    var func = this
    var args = slice.call(arguments, 1)
    function bound () {
      var invokedAsConstructor = func.prototype && (this instanceof func)
      return func.apply(
        !invokedAsConstructor && context || this, args.concat(slice.call(arguments)) // eslint-disable-line no-mixed-operators
      )
    }
    bound.prototype = func.prototype
    return bound
  }

  // Foreach
  Array.prototype.forEach = Array.prototype.forEach || function (callback, thisArg) {
    if (typeof (callback) !== 'function') {
      throw new TypeError(callback + ' is not a function!')
    }
    var len = this.length
    for (var i = 0; i < len; i++) {
      callback.call(thisArg, this[i], i, this)
    }
  }

  // Trunc
  Math.trunc = Math.trunc || function (x) {
    if (isNaN(x)) {
      return NaN
    }
    if (x > 0) {
      return Math.floor(x)
    }
    return Math.ceil(x)
  }
}

module.exports = applyPolyfills
