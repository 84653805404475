import { css } from "styled-components";

export const MAX_WIDTH_BREAKPOINT = 1276;

export const EXTRA_LARGE_DESKTOP = 1800;
export const LARGE_DESKTOP = 1280;
export const DESKTOP = 1023;
export const TABLET = 767;
export const PHONE_LANDSCAPE_BREAKPOINT = 480;

export function mediaCondition(condition: string) {
  return (...args: Parameters<typeof css>) => css`
    @media ${condition} {
      ${css(...args)}
    }
  `;
}

export function mediaMinWidth(breakpoint: number) {
  return mediaCondition(`(min-width: ${breakpoint}px)`);
}

function mediaMaxWidth(breakpoint: number) {
  return mediaCondition(`(max-width: ${breakpoint - 1}px)`);
}

function mediaBetweenWidths(min: number, max: number) {
  return mediaCondition(`(min-width: ${min}px) and (max-width: ${max - 1}px)`);
}

export const media = {
  tablet: mediaMinWidth(TABLET),
  desktop: mediaMinWidth(DESKTOP),
  largeDesktop: mediaMinWidth(LARGE_DESKTOP),
  extraLargeDesktop: mediaMinWidth(EXTRA_LARGE_DESKTOP),
  belowDesktop: mediaMaxWidth(DESKTOP),
  belowTablet: mediaMaxWidth(TABLET),
  belowPhoneLandscape: mediaMaxWidth(PHONE_LANDSCAPE_BREAKPOINT),
  phoneLandscape: mediaMinWidth(PHONE_LANDSCAPE_BREAKPOINT),
  betweenWidths: mediaBetweenWidths,
  maxWidth: mediaMinWidth(MAX_WIDTH_BREAKPOINT),
  fullscreenLarge: (...args: Parameters<typeof css>) => css`
    @media (min-width: ${MAX_WIDTH_BREAKPOINT}px) {
      .fullscreen & {
        ${css(...args)}
      }
    }
  `,
};
