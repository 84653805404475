import { PlaybackState, VisualElement } from "@tv4/avod-web-player-common";
import {
  isChrome,
  isSupportedOS as isDesktopOS,
} from "@tv4/avod-web-player-device-capabilities";
import { useCallback, useState } from "react";

import { useControls, usePlayerState } from "../../providers/CoreProvider";
import { useCustomButton } from "../../providers/CustomButtonContext";
import {
  useLayoutSettings,
  useLayoutSize,
  useVisuals,
} from "../../providers/LayoutProvider";
import { LayoutSize } from "../../util/device";
import { getDurationString, getTimeString } from "../../util/time";
import AirplayButton from "../AirplayButton";
import { useTracksMenu } from "../audio-text-tracks/TracksMenu/TracksMenu";
import { ChannelsButton } from "../ChannelsButton";
import ChromeCastButton from "../ChromeCastButton";
import CurrentProgramMetaData from "../CurrentProgramMetaData";
import FullscreenButton from "../FullscreenButton";
import GoToLiveButton from "../GoToLiveButton";
import GoToStartOverButton from "../GoToStartOverButton";
import LiveStatus from "../LiveStatus";
import { PlaybackRateButton } from "../PlaybackRateButton";
import { PlaybackRateSelector } from "../PlaybackRateSelector";
import PlayPauseButton from "../PlayPauseButton";
import ProgressBar from "../progress-bar/ProgressBar";
import SeekBackwardButton from "../SeekBackwardButton";
import SeekForwardButton from "../SeekForwardButton";
import { ShareButton } from "../ShareButton";
import { TimeLabel } from "../TimeLabel";
import VolumeController from "../VolumeController/VolumeController";
import {
  Body,
  Bottom,
  ButtonGroup,
  ButtonsContainer,
  ControlsContainer,
  Middle,
  ProgressContainer,
  Top,
} from "./DefaultControls.styles";

export function DefaultControls() {
  const {
    canGoToLive,
    canPause,
    canSeek,
    canStartOver,
    currentTime,
    duration,
    isCasting,
    isLive,
    isAd,
    isPauseAd,
    isFullscreen,
    playbackState,
    utcCurrentTimeMs,
  } = usePlayerState();
  const [showPlaybackRateSelector, setShowPlaybackRateSelector] =
    useState(false);
  const size = useLayoutSize();
  const controls = useControls();
  const { enableFullscreen, onShareClick } = useLayoutSettings();

  const playing = playbackState === PlaybackState.PLAYING;

  const { customButton } = useCustomButton();

  // when smallest layout we show some buttons in the header instead of here
  const isSmallestLayout = size === LayoutSize.SMALLEST;
  const includeFullScreenButton = !isSmallestLayout;
  const includeChromeCastButton = isChrome() && !isSmallestLayout;

  const showTimeline = canSeek && !isPauseAd;
  const showSeekbuttons = canSeek && !isPauseAd;

  const showPlayPauseButton =
    canPause || playbackState === PlaybackState.PAUSED;

  const showGoToLiveButton = canGoToLive && !isPauseAd;
  const showStartOverButton = canStartOver && !isSmallestLayout && !isPauseAd;

  const visuals = useVisuals();
  const showChannelsButton = visuals?.includes(VisualElement.CHANNELS_BUTTON);
  const { TracksButton, TracksMenu, tracksMenuOpen, closeTracksMenu } =
    useTracksMenu();

  const showMetaData =
    isFullscreen && [LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size);
  const showRemainingTime = !isLive && !isSmallestLayout;

  // whether to allow clicking on video to toggle playback
  const isClickableElement =
    !isAd && !isPauseAd && !isCasting && isDesktopOS() && canPause;

  const showPlaybackRateButton =
    controls?.setPlaybackRate && !isAd && !isPauseAd && !isLive;

  const clickHandler = useCallback(() => {
    // close tracks menu if open
    if (tracksMenuOpen) {
      closeTracksMenu();
    }
    // toggle playback
    else if (isClickableElement) {
      if (playing) {
        controls?.pause?.({ programmatic: false });
      } else {
        controls?.play?.();
      }
    }
  }, [isClickableElement, controls, playing, closeTracksMenu, tracksMenuOpen]);

  const playbackRateClickHandler = useCallback(() => {
    setShowPlaybackRateSelector((prev) => !prev);
  }, []);

  return (
    <ControlsContainer $compact={isSmallestLayout}>
      <Body
        $isFullscreen={isFullscreen}
        $isClickableElement={isClickableElement}
        onClick={clickHandler}
      >
        {showMetaData && (
          <>
            <Top>
              <CurrentProgramMetaData hidden={false} />
            </Top>
            <Middle />
            <Bottom />
          </>
        )}
        {showPlaybackRateButton && showPlaybackRateSelector && (
          <PlaybackRateSelector
            onSelected={() => setShowPlaybackRateSelector(false)}
          />
        )}
      </Body>
      <ProgressContainer $compact={isSmallestLayout}>
        {showTimeline && (
          <>
            <TimeLabel>
              {utcCurrentTimeMs
                ? getTimeString(utcCurrentTimeMs)
                : getDurationString(currentTime)}
            </TimeLabel>
            <ProgressBar />
            {isLive && <LiveStatus />}
            {showRemainingTime && (
              <TimeLabel>{getDurationString(currentTime - duration)}</TimeLabel>
            )}
          </>
        )}
      </ProgressContainer>

      <ButtonsContainer>
        <ButtonGroup $position="left">
          {showPlayPauseButton && <PlayPauseButton />}
          {showStartOverButton && <GoToStartOverButton />}
          {showSeekbuttons && !isSmallestLayout && (
            <>
              <SeekBackwardButton />
              <SeekForwardButton />
            </>
          )}
        </ButtonGroup>
        <ButtonGroup $position="center">
          {isSmallestLayout ? (
            <>
              {showSeekbuttons && <SeekBackwardButton />}
              {showChannelsButton && <ChannelsButton />}
              {customButton}
              {showSeekbuttons && <SeekForwardButton />}
            </>
          ) : (
            <>
              {TracksButton && <TracksButton />}
              {showChannelsButton && <ChannelsButton />}
              {customButton}
            </>
          )}
        </ButtonGroup>
        <ButtonGroup $position="right">
          {showPlaybackRateButton && (
            <PlaybackRateButton onClick={playbackRateClickHandler} />
          )}
          {showGoToLiveButton && <GoToLiveButton />}
          {onShareClick && <ShareButton onClick={onShareClick} />}
          <AirplayButton />
          {includeChromeCastButton && <ChromeCastButton />}
          <VolumeController />
          {enableFullscreen && includeFullScreenButton && <FullscreenButton />}
        </ButtonGroup>
      </ButtonsContainer>
      {TracksMenu && <TracksMenu />}
    </ControlsContainer>
  );
}
