import {
  emptyFetch,
  getTopLocationHost,
  loggedIn,
  Metadata,
  PlaybackErrorPayload,
  TimePayload,
  VideoTrackingMetadata,
} from "@tv4/avod-web-player-common";
import { OperatingSystem } from "@tv4/avod-web-player-device-capabilities";

import { ITrackerSetupOptions, Tracker } from "./tracker";

/**
 * Documentation and swagger available at
 * https://tv4-video-tracking.a2d.tv/docs/
 * https://tv4.video-tracking.a2d.tv/docs/
 * https://github.com/TV4/nordic-video-tracking/blob/main/docs/blueprint.md
 */

enum WireEventType {
  SESSION_START = "sessionStart",
  SESSION_END = "sessionEnd",
  PROGRESS = "progress",
}

interface IPostData {
  eventType: WireEventType;
  position: number;
}

export interface IWireVideoTrackerInstanceOptions {
  playbackApiVideoTrackingData: VideoTrackingMetadata;
  deviceId: string;
  debug?: boolean;
  trackingPlatform: string;
  client: string;
  playerName: string;
  playerVersion: string;
  osName: OperatingSystem | string;
  getAccessToken: () => string | undefined;
}

export class WireVideoTracking extends Tracker {
  private sessionId?: string;

  private options: IWireVideoTrackerInstanceOptions;

  private metadata?: Metadata;

  private baseData?: Record<string, unknown>;

  private heartbeat?: number;
  private sessionEndFired: boolean = false;

  private sequenceNumber: number = 0;
  private startDateTime: number = 0;
  private position: number = 0;
  private duration: number = 0;

  private HEARTBEAT_INTERVAL: number;

  constructor(options: IWireVideoTrackerInstanceOptions) {
    super();

    this.HEARTBEAT_INTERVAL =
      options.playbackApiVideoTrackingData.configuration.progressInterval *
      1000;

    this.options = options;

    if (this.options.debug) {
      console.debug("WireVideoTracking initialized");
    }
    window.addEventListener("beforeunload", () => {
      this.onEnded();
    });
  }

  public override setup(options: ITrackerSetupOptions): void {
    super.setup(options);
    this.metadata = options.metadata;
    this.sessionId = options.sessionId;
  }

  public onStart(): void {
    this.duration = this.metadata?.asset.duration ?? 0;
    this.sessionStart(this.position);
  }

  public onPlay({ currentTime }: TimePayload): void {
    this.progress(currentTime);
  }

  public onPause({ currentTime }: TimePayload): void {
    this.progress(currentTime);
  }

  public onEnded(): void {
    this.postData({
      eventType: WireEventType.SESSION_END,
      position: this.position,
    });
    clearInterval(this.heartbeat);
    this.sessionEndFired = true;
  }

  public onError({ currentTime }: PlaybackErrorPayload): void {
    if (currentTime) {
      this.progress(currentTime);
    }
  }

  public onBuffering({ currentTime }: TimePayload): void {
    this.progress(currentTime);
  }

  public onTimeUpdate({ currentTime, duration }: TimePayload): void {
    this.duration = duration;
    this.position = currentTime;
  }

  private setBaseData(): void {
    this.baseData = {
      offline: false,
      host: getTopLocationHost(),
      os: this.options.osName,
      platform: this.options.trackingPlatform,
      player: `${this.options.playerName}-${this.options.playerVersion}`,
      sessionId: this.sessionId,
      deviceId: this.options.deviceId,
      ...this.options.playbackApiVideoTrackingData.data,
    };
  }

  private loggedIn(): boolean {
    return loggedIn(this.options.getAccessToken() || "");
  }

  private sessionStart(currentTime: number): void {
    this.sequenceNumber = 0;
    this.startDateTime = Date.now();

    this.setBaseData();

    this.postData({
      eventType: WireEventType.SESSION_START,
      position: currentTime,
    });

    this.heartbeat = window.setInterval(() => {
      this.progress(this.position, true);
    }, this.HEARTBEAT_INTERVAL);
  }

  private progress(currentTime: number, isHeartbeat: boolean = false): void {
    if (isHeartbeat) {
      currentTime = this.position;
    }
    this.postData({
      eventType: WireEventType.PROGRESS,
      position: currentTime,
    });
  }

  private getEndpoint(): string {
    // accessToken may change during playback, so technically logged in status may change during playback
    return this.loggedIn()
      ? this.options.playbackApiVideoTrackingData.configuration.endpoint
      : this.options.playbackApiVideoTrackingData.configuration
          .anonymousEndpoint || "";
  }

  private getHeaders(): HeadersInit {
    const headers: HeadersInit = {
      "Content-Type": "application/json;charset=UTF-8",
      Client: this.options.client,
    };

    const token = this.options.getAccessToken();

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    return headers;
  }

  private postData({ eventType, position }: IPostData): void {
    const ENDPOINT = this.getEndpoint();

    if (
      !this.baseData ||
      !this.baseData.sessionId ||
      !this.baseData.videoId ||
      !ENDPOINT
    ) {
      return;
    }

    const durationInMs = Math.round(this.duration * 1000);
    const positionInMs = Math.round(position * 1000);

    const data = {
      ...this.baseData,
      eventType,
      sequenceNumber: this.sequenceNumber,
      position: positionInMs,
      duration: durationInMs,
    };

    this.sequenceNumber += 1;

    if (this.metadata?.asset.isLive) {
      data.position = Date.now() - this.startDateTime;
    }

    if (this.options.debug) {
      console.debug(`WireVideoTracking - ${eventType}`, data);
    }

    emptyFetch(ENDPOINT, {
      method: "POST",
      headers: this.getHeaders(),
      body: JSON.stringify(data),
      keepalive: true,
    });
  }

  public destroy(): void {
    if (!this.sessionEndFired) {
      this.onEnded();
    }
  }
}
