import { AdBreakType } from "@tv4/avod-web-player-common";

import { NielsenAdBreakType, NielsenEvent } from "./Nielsen";

export function getAdType(
  adBreakType: AdBreakType | unknown
): NielsenAdBreakType | null {
  switch (adBreakType) {
    case AdBreakType.Preroll:
      return NielsenAdBreakType.PREROLL;
    case AdBreakType.Midroll:
      return NielsenAdBreakType.MIDROLL;
  }
  return NielsenAdBreakType.AD;
}

export function isStopOrEnd(event: NielsenEvent | undefined): boolean {
  return [NielsenEvent.STOP, NielsenEvent.END].includes(event as NielsenEvent);
}

export function nielsenBool(value: boolean): "yes" | "no" {
  return value ? "yes" : "no";
}
