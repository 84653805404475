import { AdInsertionType, IAd } from "@tv4/avod-web-player-common";

import { TAdapterOptions } from "./Adapter";

export type BaseAdapterState = {
  playerName: string;
  playerVersion: string;
  appName: string;
  appVersion: string;
  playhead: number;
  duration: number;
  bitrate: number;
};

export type ContentAdapterState = BaseAdapterState & {
  droppedFrames: number;
  videoWidth: number;
  videoHeight: number;
  playrate: number;
  fps: number;
  latency: number;
  videoCodec: string;
  audioCodec: string;
  isLive: boolean;
  manifestUrl: string;
};

export const CONTENT_ADAPTER_INITIAL_STATE: ContentAdapterState = {
  playhead: 0,
  duration: 0,
  droppedFrames: 0,
  videoWidth: 0,
  videoHeight: 0,
  bitrate: 0,
  playrate: 1,
  fps: 0,
  latency: 0,
  videoCodec: "",
  audioCodec: "",
  isLive: false,
  manifestUrl: "",
  playerName: "",
  playerVersion: "",
  appName: "",
  appVersion: "",
};

export type AdsAdapterState = BaseAdapterState & {
  ongoingAdDetails?: IAd;
  adInsertionType?: AdInsertionType;
  adBreakPosition?: "pre" | "mid";
  numberOfAds?: number;
  source?: string;
  fullscreen?: boolean;
  muted: boolean;
};

export const ADS_ADAPTER_INITIAL_STATE: AdsAdapterState = {
  playhead: 0,
  duration: 0,
  bitrate: 0,
  muted: false,
  playerName: "",
  playerVersion: "",
  appName: "",
  appVersion: "",
  ongoingAdDetails: undefined,
  adInsertionType: undefined,
  adBreakPosition: undefined,
  numberOfAds: undefined,
  source: undefined,
};

export class AdapterStateManager<T> {
  private state: T;

  constructor(initialState: T) {
    this.state = initialState;
  }

  private config: TAdapterOptions | undefined;

  public getState() {
    return this.state;
  }

  public updateState(state: Partial<T>) {
    this.state = {
      ...this.state,
      ...state,
    };
  }

  public getConfig() {
    return this.config;
  }

  public setConfig(config: TAdapterOptions) {
    this.config = config;
  }
}
