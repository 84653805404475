import {
  AdBreakTrackingEvent,
  AdInsertionType,
  AdTrackingEvent,
  AWPError,
  BufferSeekingPayload,
  CoreEvents,
  CoreEventsMap,
  ERROR_CATEGORY,
  ErrorLevel,
  EventEmitter,
  IAd,
  IAdBreak,
  IAdMediaFile,
  Media,
  MediaLoadingPayload,
  Metadata,
  NonEmptyArray,
  PauseOptions,
  PLAYER_ERROR,
  resolveMediaType,
  Service,
  TAdTrackingEvent,
  TimePayload,
} from "@tv4/avod-web-player-common";
import { FreeWheelAdServer } from "@tv4/avod-web-player-http";

import { AdVideoEngine } from "../media_engine/AdVideoEngine";
import {
  MediaErrorPayload,
  MediaEvents,
  MediaEventsMap,
  MediaPlaybackEndedPayload,
  MediaVolumePayload,
} from "../media_engine/utils/mediaConstants";
import { hasAdImmunity, setAdImmunity } from "../utils/adImmunity";
import { createElement, createVideoElement } from "../utils/html";
import {
  AdProgressFailurePayload,
  AdProgressFailureReason,
  AdProgressMonitor,
  AdProgressMonitorEvents,
} from "./util/AdProgressMonitor";

type AdjacentAdBreak = {
  adBreak: IAdBreak;
  breakIndex: number;
};

enum GRACE_PERIOD_STATUS {
  INACTIVE,
  ACTIVE,
  GRACE_PERIOD,
}

type AdBreaksState = {
  watchedAdBreaksTimeOffsets: number[];
  ignoredAdBreaks: number[];
  gracePeriodStatus: GRACE_PERIOD_STATUS;
};

export class CSAIAdEngine extends EventEmitter<CoreEventsMap> {
  public readonly insertionType = AdInsertionType.ClientSide;
  private trackedAdBreaks: {
    [timeOffset: number]: {
      [key: string]: true;
    };
  } = {};
  private trackedAds: {
    [adId: string]: {
      [key: string]: true;
    };
  } = {};

  private adBreaksState: AdBreaksState = {
    watchedAdBreaksTimeOffsets: [],
    ignoredAdBreaks: [],
    gracePeriodStatus: GRACE_PERIOD_STATUS.INACTIVE,
  };

  public currentAdBreak: IAdBreak | null = null;
  private immutableCurrentAdBreak: IAdBreak | null = null;
  private currentAd: IAd | null = null;

  private container: HTMLDivElement;

  protected mediaEngine: AdVideoEngine;

  private adServerInstance: FreeWheelAdServer;

  private _adBreaks?: IAdBreak[];
  public get adBreaks(): IAdBreak[] {
    return this._adBreaks?.slice() || [];
  }

  private assetId: string;
  private autoplay = true;
  private isPaused = false;
  private muted: boolean;

  private adBuffering: boolean;
  private adProgressMonitor: AdProgressMonitor;
  private repeatableEvents = [
    AdTrackingEvent.COLLAPSE,
    AdTrackingEvent.EXPAND,
    AdTrackingEvent.MUTE,
    AdTrackingEvent.UNMUTE,
    AdTrackingEvent.PAUSE,
    AdTrackingEvent.RESUME,
  ];

  private service: Service;

  private contentTime: number = -1;

  private graceTimeout: number = -1;

  private adImmunityDuration: number;

  private destroyed = false;

  constructor(options: {
    service: Service;
    parent: HTMLElement;
    muted?: boolean;
    adImmunityDuration?: number;
    adServerInstance: FreeWheelAdServer;
    metadata: Metadata;
  }) {
    super();

    this.container = createElement({
      type: "div",
      parent: options.parent,
      classes: ["avod-web-player-csai"],
    });
    this.container.style.display = "none";

    this.mediaEngine = new AdVideoEngine({
      videoElement: createVideoElement({
        muted: options.muted ?? false,
        loop: false,
        parent: this.container,
        classes: ["padding-if-no-src"],
      }),
      enableAirplay: false,
      useNativeErrorListener: true,
    });
    this.mediaEngine.setIsSeekable(process.env.NODE_ENV === "development");

    this.assetId = options.metadata.asset.id;
    this.muted = options.muted ?? false;

    this.service = options.service;

    this.adServerInstance = options.adServerInstance;

    this.adImmunityDuration = options.adImmunityDuration || 0;

    this.addListener(MediaEvents.LOADING, this.onLoading);
    this.addListener(MediaEvents.LOADED, this.onLoaded);
    this.addListener(MediaEvents.BUFFERING, this.onAdBuffering);
    this.addListener(MediaEvents.BUFFERED, this.onAdBuffered);
    this.addListener(MediaEvents.PLAYING, this.onPlaying);
    this.addListener(MediaEvents.PAUSED, this.onPaused);
    this.addListener(MediaEvents.TIME_UPDATE, this.onTimeUpdate);
    this.addListener(MediaEvents.VOLUME_CHANGED, this.onVolumeChanged);
    this.addListener(MediaEvents.END_OF_STREAM, this.onEnded);
    this.addListener(MediaEvents.ERROR, this.onMediaError);
    this.addListener(MediaEvents.AUTOPLAY_BLOCKED, this.onAutoplayBlocked);
    this.addListener(MediaEvents.SUCCESSFUL_PLAY, this.onSuccessfulPlay);

    this.adBuffering = false;
    this.adProgressMonitor = new AdProgressMonitor();

    this.adProgressMonitor.on(
      AdProgressMonitorEvents.FAILED,
      this.onMonitorError.bind(this)
    );
  }

  private addListener<K extends keyof MediaEventsMap>(
    type: K,
    handler: (event: MediaEventsMap[K]) => void
  ) {
    this.mediaEngine.on(type, handler.bind(this));
  }

  private cancelGraceTimeout() {
    if (this.graceTimeout !== -1) {
      clearTimeout(this.graceTimeout);
      this.graceTimeout = -1;
    }
  }

  private setGraceTimeout(callback: () => void) {
    this.cancelGraceTimeout();
    this.graceTimeout = window.setTimeout(() => {
      this.graceTimeout = -1;
      return callback();
    }, this.adImmunityDuration * 1000);
  }

  private setupAdBreaksGracePeriod(resetGraceTime: boolean) {
    this.adBreaksState.ignoredAdBreaks = [];
    this.setAdBreakState({
      gracePeriodStatus: GRACE_PERIOD_STATUS.ACTIVE,
    });

    this.setGraceTimeout(() => {
      const priorAdBreak = this.findPriorAdBreak(this.contentTime);
      if (priorAdBreak) {
        this.addIgnoredAdBreak(priorAdBreak.adBreak.timeOffset);
      }
      this.setAdBreakState({
        gracePeriodStatus: GRACE_PERIOD_STATUS.GRACE_PERIOD,
      });
    });

    if (resetGraceTime) {
      setAdImmunity(this.assetId);
    }
  }

  private setPriorAdBreaksAsWatched(startTime: number) {
    if (!this.adBreaks?.length || !startTime) return;

    this.setAdBreakState({
      watchedAdBreaksTimeOffsets: Array.from(
        new Set([
          0,
          ...this.adBreaks
            .filter((adBreak) => adBreak.timeOffset < startTime)
            .map((adBreak) => adBreak.timeOffset),
        ])
      ),
    });
  }

  private addWatchedAdBreak(timeOffset: number) {
    this.adBreaksState.watchedAdBreaksTimeOffsets.push(timeOffset);
  }

  private addIgnoredAdBreak(timeOffset: number) {
    this.adBreaksState.ignoredAdBreaks.push(timeOffset);
  }

  private setAdBreakState(newState: Partial<AdBreaksState>) {
    this.adBreaksState = {
      ...this.adBreaksState,
      ...newState,
    };
  }

  private replaceIgnoredAdBreak(currentTime: number) {
    this.adBreaksState.ignoredAdBreaks.pop();

    const priorAdBreak = this.findPriorAdBreak(currentTime);
    if (priorAdBreak) {
      if (
        this.adBreaksState.watchedAdBreaksTimeOffsets.includes(
          priorAdBreak.adBreak.timeOffset
        )
      ) {
        return;
      }

      this.addIgnoredAdBreak(priorAdBreak.adBreak.timeOffset);
    }
  }

  private disableGracePeriodIfOutsideOfSection(currentTime: number) {
    const priorAdBreak = this.findPriorAdBreak(currentTime);
    if (
      priorAdBreak &&
      !this.adBreaksState.ignoredAdBreaks.includes(
        priorAdBreak.adBreak.timeOffset
      )
    ) {
      this.adBreaksState.gracePeriodStatus = GRACE_PERIOD_STATUS.INACTIVE;
    }
  }

  public async load({ contentStartTime, autoplay }): Promise<boolean> {
    const [adBreaks, adBreakError] = await this.adServerInstance.getLinearAds();

    if (this.destroyed) return false;

    if (adBreakError) {
      throw adBreakError;
    }

    this._adBreaks = adBreaks || [];

    this.setPriorAdBreaksAsWatched(contentStartTime);

    const immune = hasAdImmunity(this.assetId, this.adImmunityDuration);

    if (
      // contentStartTime is falsy -
      // if contentStartTime is 0 then content is expected to play from beginning, and if there is a preroll at 0 then it will play first.
      // if contentStartTime is undefined and vod is playing then vod will play from 0 and above applies.
      // if contentStartTime is undefined and live content is playing then live content will start playing at live position. in this scenario a preroll at position 0 should not play, but live content likely have no preroll configured at 0 position.
      !contentStartTime &&
      // adBreak might be empty, but will still be loaded so empty ad break can be tracked.
      this.adBreaks[0]?.timeOffset === 0
    ) {
      if (immune) {
        console.debug("ad grace period still valid - skipping prerolls");
        if (!this.adBreaks[0].empty) {
          this.setupAdBreaksGracePeriod(false);
        }
      } else {
        this.loadAdBreak(0, autoplay);
      }
    }

    // return true if an ad is currently playing. if an adbreak is empty then no ad will play and false is returned.
    return this.currentAdBreak !== null;
  }

  public setEstimatedBitrate(value: number): void {
    this.mediaEngine.estimatedBitrate = value;
  }

  private loadAdBreak(index: number, autoplay = true) {
    this.currentAdBreak = this.adBreaks?.[index] || null;

    if (this.currentAdBreak) {
      this.autoplay = autoplay;
      this.immutableCurrentAdBreak = JSON.parse(
        JSON.stringify(this.currentAdBreak)
      );
      this.container.style.display = "block";
      if (!this.currentAdBreak.empty) {
        /**
         * ⚠️ this event is expected to be emitted before an ad video starts to play
         *    because CSAIAdPlayback has logic that should run before ad video playback
         *    starts when this event happens
         */
        this.emit(CoreEvents.BREAK_START, {
          adBreak: this.immutableCurrentAdBreak!,
        });
      }
      this.trackAdBreakEvent(
        this.currentAdBreak,
        AdBreakTrackingEvent.BREAK_START
      );
      this.playNextVideo();
    } else {
      this.container.style.display = "none";
    }
  }

  protected findPriorAdBreak(currentTime: number): AdjacentAdBreak | undefined {
    if (!this.adBreaks?.length) return;

    let breakIndex = this.adBreaks.length - 1;

    for (breakIndex; breakIndex >= 0; breakIndex--) {
      if (currentTime >= this.adBreaks[breakIndex].timeOffset) {
        return {
          adBreak: this.adBreaks[breakIndex],
          breakIndex: breakIndex,
        };
      }
    }
  }

  private isImmuneFromAdBreak(): boolean {
    return this.adBreaksState.gracePeriodStatus === GRACE_PERIOD_STATUS.ACTIVE;
  }

  protected triggerAdBreakIfUnwatched(currentTime: number) {
    const priorAdBreak = this.findPriorAdBreak(currentTime);
    if (!priorAdBreak) return;

    const { adBreak, breakIndex } = priorAdBreak;
    const { watchedAdBreaksTimeOffsets, ignoredAdBreaks } = this.adBreaksState;

    if (
      currentTime > adBreak.timeOffset &&
      !watchedAdBreaksTimeOffsets.includes(adBreak.timeOffset) &&
      !ignoredAdBreaks.includes(adBreak.timeOffset)
    ) {
      this.loadAdBreak(breakIndex);
    }
  }

  private endCurrentAdBreak() {
    this.trackAdBreakEvent(
      this.currentAdBreak!,
      AdBreakTrackingEvent.BREAK_END
    );

    const isEmptyAdbreak = this.currentAdBreak!.empty;

    this.addWatchedAdBreak(this.currentAdBreak!.timeOffset);

    this.currentAdBreak = null;

    if (!isEmptyAdbreak) {
      this.setupAdBreaksGracePeriod(true);

      this.emit(CoreEvents.BREAK_END, {});
    }

    this.container.style.display = "none";
  }

  private async playNextVideo(): Promise<void> {
    this.currentAd = this.currentAdBreak?.ads.shift() || null;

    // mediafiles should technically never be empty here because ads with no mediafiles are filtered out in adBreakMapper
    if (this.currentAd?.creative.mediafiles.length) {
      this.emit(CoreEvents.AD_LOADING, { ad: this.currentAd });

      const fileUrl = (
        await this.getClosestAdQualityToEstimatedMaxBitrate(
          this.currentAd.creative.mediafiles as NonEmptyArray<IAdMediaFile>
        )
      )?.fileUrl;

      if (this.destroyed || !fileUrl) return;

      this.loadAd(fileUrl);
    }
    // no more ad to play, end current ad break
    else if (this.currentAdBreak) {
      this.endCurrentAdBreak();
    }
  }

  private async getClosestAdQualityToEstimatedMaxBitrate(
    mediaFiles: NonEmptyArray<IAdMediaFile>
  ): Promise<IAdMediaFile | null> {
    let estimatedBitrate = await this.mediaEngine.getEstimatedBitrate(
      mediaFiles[0].fileUrl
    );

    if (this.destroyed) return null;

    if (estimatedBitrate === undefined || Number.isNaN(estimatedBitrate)) {
      estimatedBitrate = 1500; // default fallback bitrate if there is no estimation
    }

    // list is expected to be sorted with higher bitrates first, so the first match will be used.
    return (
      mediaFiles.find(
        ({ bitrate }) => bitrate && bitrate <= estimatedBitrate
      ) ||
      // minimum 1000 bitrate if available
      mediaFiles.find(({ bitrate }) => bitrate && bitrate <= 1000) ||
      // use last item with lowest quality if nothing else is found
      (mediaFiles.at(-1) as IAdMediaFile)
    );
  }

  private loadAd(url: string) {
    const adMedia: Media = {
      manifestUrl: url,
      type: resolveMediaType(url),
      isLive: false,
      isStartOver: false,
      isStitched: false,
      loadPreference: "speed",
    };

    this.mediaEngine.load(
      adMedia,
      0,
      // only estimate bitrate if there are more ads to play after this
      (this.currentAdBreak?.ads.length || 0) > 0
    );
  }

  public play() {
    this.mediaEngine.play();
  }

  public pause(options: PauseOptions) {
    this.mediaEngine.pause(options);
  }

  public mute() {
    this.mediaEngine.mute();
  }

  public unmute() {
    this.mediaEngine.unmute();
  }

  public toggleMute() {
    this.mediaEngine.toggleMute();
  }

  public setVolume(volume: number) {
    this.mediaEngine.setVolume(volume);
  }

  // main content video progress updates
  public onContentTimeUpdate(contentTime: number) {
    this.contentTime = contentTime;
    if (!this.isImmuneFromAdBreak()) {
      this.triggerAdBreakIfUnwatched(contentTime);
    }
  }

  public onContentSeekForwardComplete(currentTime: number) {
    return this.onContentSeekComplete(currentTime);
  }

  public onContentSeekBackwardComplete(currentTime: number) {
    return this.onContentSeekComplete(currentTime, true);
  }

  private onContentSeekComplete(
    currentTime: number,
    seekedBackwards: boolean = false
  ) {
    this.contentTime = currentTime;
    if (!this.isImmuneFromAdBreak()) {
      if (
        this.adBreaksState.gracePeriodStatus ===
        GRACE_PERIOD_STATUS.GRACE_PERIOD
      ) {
        if (seekedBackwards) {
          this.replaceIgnoredAdBreak(currentTime);
        } else {
          this.disableGracePeriodIfOutsideOfSection(currentTime);
        }
      }

      this.triggerAdBreakIfUnwatched(currentTime);
    }
  }

  protected onLoading(payload: MediaLoadingPayload) {
    this.adProgressMonitor.bufferingStarted();

    this.emit(CoreEvents.AD_MEDIA_LOADING, payload);
  }

  protected onLoaded() {
    this.adProgressMonitor.bufferingEnded();

    if (this.currentAd) {
      this.emit(CoreEvents.AD_LOADED, { ad: this.currentAd });
      this.trackEvent(AdTrackingEvent.START);
      this.trackEvent(AdTrackingEvent.IMPRESSION);
    }
    if (this.autoplay) {
      this.mediaEngine.play();
    }
  }

  protected onAdBuffering(payload: BufferSeekingPayload): void {
    this.adBuffering = true;
    this.adProgressMonitor.bufferingStarted();
    this.emit(CoreEvents.AD_BUFFERING, payload);
  }

  protected onAdBuffered(payload: BufferSeekingPayload): void {
    this.adBuffering = false;
    this.adProgressMonitor.bufferingEnded();
    this.emit(CoreEvents.AD_BUFFERED, payload);
  }

  protected onPlaying(payload: TimePayload) {
    // once a break is playing autoplay is enabled
    this.autoplay = true;

    if (this.currentAd) {
      if (!this.isPaused) {
        this.trackEvent(AdTrackingEvent.START);
        this.trackEvent(AdTrackingEvent.IMPRESSION);
        this.emit(CoreEvents.AD_START, { ad: this.currentAd });
        this.adProgressMonitor.adStarted(this.currentAd);
      } else {
        if (this.adBuffering) {
          this.adBuffering = false;
          this.adProgressMonitor.bufferingEnded();
        }
        this.trackEvent(AdTrackingEvent.RESUME);
        this.emit(CoreEvents.AD_RESUME, {
          ...payload,
          ad: this.currentAd,
        });
      }
    }
  }

  protected onPaused(payload: TimePayload) {
    if (this.currentAd) {
      this.isPaused = true;
      this.emit(CoreEvents.AD_PAUSED, payload);
      this.trackEvent(AdTrackingEvent.PAUSE);
    }
  }

  protected onTimeUpdate(payload: TimePayload) {
    if ((payload.duration || 0) === 0) return;
    this.emit(CoreEvents.AD_TIME_UPDATED, payload);
    this.monitorProgress(payload);
  }

  protected onVolumeChanged({ volume, muted }: MediaVolumePayload) {
    this.emit(CoreEvents.VOLUME_CHANGED, { volume, muted });
    const changed = this.updateMuted(muted);
    if (changed) {
      this.trackEvent(muted ? AdTrackingEvent.MUTE : AdTrackingEvent.UNMUTE);
    }
  }

  private updateMuted(muted: boolean): boolean {
    const changed = this.muted !== muted;
    this.muted = muted;
    return changed;
  }

  protected onEnded({ error }: MediaPlaybackEndedPayload) {
    this.adBuffering = false;
    this.isPaused = false;
    if (this.currentAd) {
      this.trackEvent(AdTrackingEvent.COMPLETE);
    }
    this.adProgressMonitor.adEnded();
    this.emit(CoreEvents.AD_ENDED, undefined);
    if (error) {
      console.debug("Error in ad playback: ", error);
    }

    void this.playNextVideo();
  }

  private emitError(error: AWPError) {
    this.emit(CoreEvents.AD_ERROR, { error });
  }

  protected onMediaError(payload: MediaErrorPayload) {
    if (this.currentAd) {
      this.trackEvent(AdTrackingEvent.ERROR);
    }

    this.emitError(payload.error);

    void this.playNextVideo();
  }

  protected onAutoplayBlocked() {
    this.emit(CoreEvents.AUTOPLAY_BLOCKED, undefined);
  }

  protected onSuccessfulPlay() {
    this.emit(CoreEvents.SUCCESSFUL_PLAY, undefined);
  }

  private monitorProgress({ currentTime, duration }: TimePayload): void {
    if (!this.currentAd) return;
    const remainingTime = duration - currentTime;
    const percentWatched = Math.round(100 - (100 * remainingTime) / duration);
    const TrackingCuePoints: { [key: number]: AdTrackingEvent } = {
      25: AdTrackingEvent.FIRST_QUARTILE,
      50: AdTrackingEvent.MIDPOINT,
      75: AdTrackingEvent.THIRD_QUARTILE,
    };
    Object.keys(TrackingCuePoints).map((value) => {
      const event: AdTrackingEvent = TrackingCuePoints[value];
      if (percentWatched > parseInt(value, 10) && this.currentAd) {
        this.trackEvent(event);
      }
    });
  }

  private trackAdBreakEvent(
    adBreak: IAdBreak,
    trackingEvent: AdBreakTrackingEvent
  ): void {
    if (
      !adBreak ||
      !adBreak.trackingEvents ||
      !trackingEvent ||
      this.trackedAdBreaks[adBreak.timeOffset]?.[trackingEvent] === true
    )
      return;
    if (!this.trackedAdBreaks[adBreak.timeOffset])
      this.trackedAdBreaks[adBreak.timeOffset] = {};
    const trackingUrls = adBreak.trackingEvents[trackingEvent] || [];
    trackingUrls.forEach(async (url: string) => {
      console.debug(`debug ad break tracking event -> ${trackingEvent}`, url);
      new Image().src = url;
    });
    this.trackedAdBreaks[adBreak.timeOffset][trackingEvent] = true;
  }

  private trackEvent(trackingEvent: AdTrackingEvent): void {
    const ad = this.currentAd;
    if (
      !ad ||
      !trackingEvent ||
      this.trackedAds[ad.id]?.[trackingEvent] === true
    )
      return;

    let trackingUrls: NonNullable<
      | (typeof ad.creative.trackingEvents)[AdTrackingEvent]
      | typeof ad.impressionUrlTemplates
      | typeof ad.errorUrlTemplates
    > = [];
    switch (trackingEvent) {
      case AdTrackingEvent.IMPRESSION:
        trackingUrls = ad.impressionUrlTemplates || [];
        break;
      case AdTrackingEvent.ERROR:
        trackingUrls = ad.errorUrlTemplates || [];
        break;
      default:
        trackingUrls = ad.creative.trackingEvents[trackingEvent] || [];
        break;
    }
    trackingUrls.forEach(async (url: (typeof trackingUrls)[number]) => {
      console.debug(`debug ad tracking event -> ${trackingEvent}`, url);
      new Image().src = (url as TAdTrackingEvent).url || (url as string);
    });
    if (!this.repeatableEvents.includes(trackingEvent)) {
      this.trackedAds[ad.id] = {
        ...this.trackedAds[ad.id],
        [trackingEvent]: true,
      };
    }
  }

  private onMonitorError({ videoAd, reason }: AdProgressFailurePayload) {
    let errorCode = videoAd ? PLAYER_ERROR.GENERIC : PLAYER_ERROR.AD_TIMEOUT;
    let errorDetails = "[CSAIAdEngine] ad error triggered";

    switch (reason) {
      case AdProgressFailureReason.TIMEOUT:
        errorCode = PLAYER_ERROR.AD_STALLED;
        errorDetails = `[CSAIAdEngine] ad playback stalled`;
        break;
      case AdProgressFailureReason.BUFFERING_EVENTS:
        errorCode = PLAYER_ERROR.AD_PERFORMANCE;
        errorDetails = `[CSAIAdEngine] ad playback buffering`;
        break;
    }

    if (this.currentAd) {
      this.trackEvent(AdTrackingEvent.ERROR);
    }

    this.emitError(
      new AWPError({
        context: "core",
        code: errorCode,
        category: ERROR_CATEGORY.ADS,
        errorLevel: ErrorLevel.UNKNOWN,
        message: errorDetails,
      })
    );

    void this.playNextVideo();
  }

  public handleCoreEvent(eventType: CoreEvents, data: unknown) {
    if (eventType === CoreEvents.FULLSCREEN_CHANGED) {
      if (this.currentAd) {
        const event =
          data &&
          typeof data === "object" &&
          "fullscreen" in data &&
          data.fullscreen
            ? AdTrackingEvent.EXPAND
            : AdTrackingEvent.COLLAPSE;
        this.trackEvent(event);
      }
    }
    return;
  }

  public override destroy(): void {
    this.cancelGraceTimeout();
    this.mediaEngine.destroy();
    super.destroy();
    this.destroyed = true;
  }
}
