import styled, { css } from "styled-components";

import { DefaultButton, DefaultSvg } from "../styles";

const Button = styled.button<{ $gridArea?: string }>`
  ${DefaultButton}
  ${DefaultSvg}

  ${({ $gridArea }) =>
    $gridArea &&
    css`
      grid-area: ${$gridArea};
    `}
  display: flex;
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
  align-items: flex-start;
  justify-content: center;
`;

type TIconButtonProps = {
  onClick?: () => void;
  label?: string;
  hidden?: boolean;
  gridArea?: string;
  children?: React.ReactNode;
};

const IconButton = ({
  onClick,
  label,
  hidden,
  gridArea,
  children,
}: TIconButtonProps) => {
  return (
    <Button
      aria-label={label}
      $gridArea={gridArea}
      onClick={onClick}
      hidden={hidden}
    >
      {children}
    </Button>
  );
};

export default IconButton;
