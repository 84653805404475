/**
 * storing and handling time stamp for ad immunity.
 * storage key and value are obfuscated so it will
 * not look like a value related to ad immunity grace
 * period for anyone looking at the values in their
 * browser.
 * session storage when inspecting in browser will
 * look like
 * "player.uait": "4b9cl3c7q"
 */

// unrelated key name used for obfuscation
// export mainly needed for unit tests
export const AD_IMMUNITY_KEY = "uait"; // user ad immunity timestamp
const DELIMITER = "¤";

export function getRemainingAdImmunity(assetId: string, max: number): number {
  const immunityStart = getAdImmunityStartTime(assetId);
  if (!immunityStart) {
    return 0;
  }
  const immunityExpires = immunityStart + max * 1000;
  return Math.max(0, immunityExpires - Date.now()) / 1000;
}

export function hasAdImmunity(assetId: string, duration: number): boolean {
  const immunityStart = getAdImmunityStartTime(assetId);
  const now = Date.now();
  return immunityStart <= now && immunityStart + duration * 1000 >= now;
}

const OBFUSCATE_BASE = 31; // arbitrary base value
const OBFUSCATE_FACTOR = 3; // arbitrary multiplication factor

// will return the time stamp when ad immunity was last set or 0
export function getAdImmunityStartTime(assetId: string): number {
  const [obfuscatedAssetId, obfuscatedValue] =
    sessionStorage.getItem(AD_IMMUNITY_KEY)?.split(DELIMITER) || [];

  let hasAssetId = false;
  try {
    hasAssetId =
      typeof obfuscatedAssetId === "string" &&
      atob(obfuscatedAssetId) === assetId;
  } catch (_error) {
    // atob can fail if user has manipulated their storage
    hasAssetId = false;
  }

  if (!hasAssetId) return 0;

  const immunityStart =
    Number(
      parseInt(obfuscatedValue, OBFUSCATE_BASE)
        .toString()
        .split("")
        .reverse()
        .join("")
    ) / OBFUSCATE_FACTOR;
  return immunityStart || 0;
}

// will set ad immunity to current time
// stored value is obfuscated to not look like a timestamp
export function setAdImmunity(assetId: string): void {
  console.log(
    "[Ad immunity]",
    new Date().toLocaleTimeString(),
    "✅ Starting ad immunity period for asset",
    assetId
  );
  const value = Number(
    (Date.now() * OBFUSCATE_FACTOR)
      // reverse the number order so the value will not look sequential
      .toString()
      .split("")
      .reverse()
      .join("")
  ).toString(OBFUSCATE_BASE);
  sessionStorage.setItem(
    AD_IMMUNITY_KEY,
    `${btoa(assetId)}${DELIMITER}${value}`
  );
}
