"use strict";

exports.__esModule = true;
exports.isValidCallbackImplementation = isValidCallbackImplementation;
exports.raiseCallback = raiseCallback;
var _YoLog = require("./YoLog");
function _createForOfIteratorHelperLoose(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; } /*
 * COPYRIGHT 2022-2024 YOSPACE TECHNOLOGIES LTD. ALL RIGHTS RESERVED.
 * The contents of this file are proprietary and confidential.
 * Unauthorised copying of this file, via any medium is strictly prohibited.
 */
//
// raiseCallback helper - safely calls a callback and handles any exception that may be encountered.
//
// Arguments:
//   owner: the owner of the function.  Without it, calls to 'this' will fail.  It is expected to be a valid object
//   but can be null if it is an anonymous function
//
//   func: the function name (without parentheses and arguments).
//
//   args: any argument that the function requires.  Can be null, undefined or left out completely
//
// Returns:
//   the result of the function call
//
// Example usages:
//   raiseCallback(observer, observer.callbackFunction, arg1, arg2, arg3);
//   raiseCallback(observer, observer.callbackFunction);
//   let result = raiseCallback(observer, observer.callbackFunction, arg1, arg2);
//
function raiseCallback(owner, func) {
  // Bind the function to its owner as it no longer has a context and calls to 'this' would fail.
  if (owner) {
    func = func.bind(owner);
  }
  var ret = null;
  try {
    for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      args[_key - 2] = arguments[_key];
    }
    ret = func.apply(void 0, args);
  } catch (e) {
    var f = func.toString();
    var reg = /function ([^\(]*)/;
    var name = reg.exec(f);
    // The regex is not expected to fail but let's not take chances
    if (name && name.length > 0) {
      _YoLog.YoLog.e("Exception thrown from callback function " + name[1] + ": " + e);
    } else {
      _YoLog.YoLog.e("Exception thrown from callback function.");
    }
  }
  return ret;
}

// Checks whether the passed object conforms sufficiently to fulfil the functionality of an interface of the specified
// type
function isValidCallbackImplementation(implementation, type) {
  if (!implementation) {
    _YoLog.YoLog.e(type.name + " has not been implemented");
    return false;
  }
  var success = true;
  var defaultImpl = new type();
  var propertyNames = Object.getOwnPropertyNames(type.prototype);

  // implementation could be a class instance that doesn't provide an implementation of all required functions, or it
  // could be a duck-typed object
  for (var _iterator = _createForOfIteratorHelperLoose(propertyNames), _step; !(_step = _iterator()).done;) {
    var p = _step.value;
    // Ignore the constructor - a duck-typed object won't have one
    if (p === "constructor") {
      continue;
    }
    if (!implementation[p] || implementation[p] === defaultImpl[p]) {
      _YoLog.YoLog.e(type.name + " does not implement " + p);
      success = false;
    }
  }
  return success;
}