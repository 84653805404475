var Emitter = require('../emitter');
var Chrono = require('../chrono');
var Options = require('../plugin/options');
const { default: Log } = require('../../common/log');
var Constants = require('../constants');
var AnalyticsUtil = require('../util');
const { default: Util } = require('../../core/utils/Util');
var Adapter = require('adapter');
var RequestBuilder = require('../plugin/requestbuilder');
var HybridNetwork = require('../monitors/hybridnetwork');
var ResizeScrollDetector = require('../detectors/resizeScrollDetector');
const { default: ExpirationManager } = require('../../common/ExpirationManager');
const { default: DiagnosticTool } = require('../../diagnostic/DiagnosticTool');
const { default: Core } = require('../../core/Core');
const { default: VideoAnalyticsRequest } = require('../comm/VideoAnalyticsRequest');
const { default: CoreConstants } = require('../../core/utils/CoreConstants');
const { ExpireDefault, Method, AnalyticsTag, CoreEvents } = require('../../common/Constants');

var NpawVideo = Emitter.extend({
  /**
   *
   * @param videoKey
   * @param plugin
   * @param options
   * @param adapter
   */
  constructor: function (videoKey, plugin, options, adapter) {
    /** Video Key */
    this._key = videoKey;

    /** Instance plugin */
    this.plugin = plugin;

    /** This flags indicates that /init has been called. */
    this.isInitiated = false;

    /** This flags indicates that /adManifest has been called. */
    this.isAdsManifestSent = false;

    /** Postroll counter to fix plugins reporting stop before postrolls */
    this.playedPostrolls = 0;

    /** This flags indicates if an ad break is started */
    this.isBreakStarted = false;

    /** Chrono for init times. */
    this.initChrono = new Chrono();

    /** Stored {@link Options} of the video. */
    this.options = new Options(options);

    /** Ads counters */
    this._adNumber = 0;
    this._adNumberInBreak = 0;

    /** Creating Expiration Manager */
    this.expirationManager = new ExpirationManager(ExpireDefault.DEFAULT_VIEW_TIMEOUT_MS, ExpireDefault.DEFAULT_MAX_DURATION_VIEW_MS);

    /** Registering the Core Expire Event */
    Core.getInstance().subscribeListener(this._coreEventListener.bind(this));

    /** Request Builder for Video context */
    this.requestBuilder = new RequestBuilder();

    /** Hybrid Network */
    this.hybridNetwork = new HybridNetwork();

    /** Resize Scroll Detector */
    this.resizeScrollDetector = new ResizeScrollDetector(plugin, this);

    this._adapter = null;
    this._adsAdapter = null;

    if (adapter) {
      this.setAdapter(adapter, plugin);
    }

    /** Define plugin logs Queue */
    this._pluginLogsQueue = [];

    this.lastEventTime = null;

    this.lastKnownLocation = undefined;
    this.isFetchingLocation = false;

    /** PING events */
    this.sendingPings = false;
    this._startPings();
  },

  /**
   *
   * @param {string} options
   */
  mergeOptions: function (options) {
    if (options) {
      this.options.setOptions(options, this.options);
    }
  },

  /**
   *
   * @param {string} options
   */
  setVideoOptions: function (options) {
    if (options) {
      this.options.setOptions(options);
    }
  },

  /**
   * Updates custom metrics
   *
   * @param {object} metrics Metrics to be updated
   */
  updateCustomMetrics: function (metrics) {
    if (metrics) {
      this.options.setOptions(metrics, undefined, true);
    }
  },

  /**
   * Get Video Key
   * @returns {*}
   */
  getVideoKey: function () {
    return this._key;
  },

  /**
   * This method will increment the view index (timestamp values). The library handles this
   * automatically, but some error flow might need calling this manually.
   * @return {string} new viewcode
   */
  nextView: function () {
    this._viewIndex = new Date().getTime();
    this._viewCode = Core.getInstance().getFastDataSessionToken();
    Core.getInstance().registerCommonVariable(CoreConstants.Products.VIDEO_ANALYTICS, CoreConstants.AnalyticsVariables.VIEW_CODE, this._viewIndex);
    return this.getViewCode();
  },

  /**
   * Returns current viewcode
   * @return {string} viewcode
   */
  getViewCode: function () {
    const coreSessionToken = Core.getInstance().getFastDataSessionToken();
    if (coreSessionToken.length !== 0 && (!this._viewCode || this._viewCode !== coreSessionToken)) {
      this._viewCode = coreSessionToken;
    }
    return this._viewCode + (this._viewIndex ? `_${this._viewIndex}` : '');
  },

  /**
   *
   * @param {object} adapter
   * @param {object} plugin
   */
  setAdapter: function (adapter, plugin) {
    this.plugin = plugin;
    this._adapter = adapter;
    this._adapter.setIsAds(false);
    this._adapter._setAdapter = true;

    // Register listeners
    this.contentAdapterListeners = {}
    this.contentAdapterListeners[Adapter.Event.START] = this._startListener.bind(this);
    this.contentAdapterListeners[Adapter.Event.JOIN] = this._joinListener.bind(this);
    this.contentAdapterListeners[Adapter.Event.DATA_OBJECT] = this._advancedDataListener.bind(this);
    this.contentAdapterListeners[Adapter.Event.PAUSE] = this._pauseListener.bind(this);
    this.contentAdapterListeners[Adapter.Event.RESUME] = this._resumeListener.bind(this);
    this.contentAdapterListeners[Adapter.Event.SEEK_BEGIN] = this._seekBufferBeginListener.bind(this);
    this.contentAdapterListeners[Adapter.Event.SEEK_END] = this._seekEndListener.bind(this);
    this.contentAdapterListeners[Adapter.Event.BUFFER_BEGIN] = this._seekBufferBeginListener.bind(this);
    this.contentAdapterListeners[Adapter.Event.BUFFER_END] = this._bufferEndListener.bind(this);
    this.contentAdapterListeners[Adapter.Event.ERROR] = this._errorListener.bind(this);
    this.contentAdapterListeners[Adapter.Event.STOP] = this._stopListener.bind(this);
    this.contentAdapterListeners[Adapter.Event.VIDEO_EVENT] = this._videoEventListener.bind(this);

    for (var key in this.contentAdapterListeners) {
      this._adapter.on(key, this.contentAdapterListeners[key]);
    }

    if (this.options.enableExtraMetricCollection) {
      this.shouldStartRenditionTimer();
    }
  },

  /**
   * Returns current adapter or null.
   *
   * @returns {Adapter}
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdapter: function () {
    return this._adapter;
  },

  /**
   *
   */
  removeAdapter: function () {
    try {
      if (this._adapter) {
        this._adapter.dispose();
        if (this.contentAdapterListeners) {
          for (var key in this.contentAdapterListeners) {
            this._adapter.off(key, this.contentAdapterListeners[key]);
          }
          delete this.contentAdapterListeners;
        }
        this._adapter = null;
      }
    } catch (err) {
      Log.error(AnalyticsTag, 'Is not possible to remove adapter for video: ' + this._key);
    }
  },

  /**
   * Returns current adapter or null.
   *
   * @returns {Adapter}
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdsAdapter: function () {
    return this._adsAdapter;
  },

  fetchLocation: function () {
    this.isFetchingLocation = true;
    if (navigator && navigator.geolocation) {
      const options = {
        enableHighAccuracy: true,
        timeout: 10000
      };
      this.lastKnownLocation = undefined;
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.lastKnownLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          this.isFetchingLocation = false;
        },
        (error) => {
          this.isFetchingLocation = false;
        },
        options
      );
    } else {
      this.lastKnownLocation = undefined;
      this.isFetchingLocation = false;
    }
  },

  shouldStartRenditionTimer: function () {
    if (!this._adapter.getAdapterClass('detectQualityChange') && !this.renditionInterval) {
      const intervalTime = this.options.renditionQueryInterval < 100 ? 100 : this.options.renditionQueryInterval;
      this.renditionInterval = setInterval(() => {
        const rendition = this._adapter.getRendition();
        if (rendition) {
          this.triggerUpdateRendition(rendition);
        }
      }, intervalTime || 250);
    }
  },

  triggerUpdateRendition: function (rendition) {
    if (!this.lastObservedRendition) {
      this.lastObservedRendition = rendition;
      // this._sendPing();
    } else if (this.lastObservedRendition != rendition) {
      this.lastObservedRendition = rendition;
      // this._sendPing();
    }
  },

  /**
   * @param {Object} [params] Object of key:value params.
   * @param {string} [triggeredEvent]
   */
  fireInit: function (params, triggeredEvent) {
    if (!this.isInitiated) {
      if (!this.getAdapter() || (this.getAdapter() && !this.getAdapter().flags.isStarted)) {
        if (!this._viewIndex) {
          this.plugin.getNextViewIndex(this);
        }
        this._startPings();
        this.initChrono.start();
        this.isInitiated = true;
        params = params || {};
        if (triggeredEvent) {
          params.triggeredEvents = [triggeredEvent];
        }
        this._send(Constants.WillSendEvent.WILL_SEND_INIT, Constants.Service.INIT, params);
        this._adSavedError();
        this._adSavedManifest();
        Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + Constants.Service.INIT + ', title/res: ' + (params.title || params.mediaResource) + ((params.triggeredEvents) ? ', eventsTriggered: ' + params.triggeredEvents : ''));
      }
    }
  },

  /**
   * Sends /error. Should be used when the error is related to out-of-player errors: like async
   * resource load or player loading errors.
   *
   * @param {String|Object} [code] Error Code, if an object is sent, it will be treated as params.
   * @param {String} [msg] Error Message
   * @param {Object} [metadata] Object defining error metadata
   * @param {String} [level] Level of the error. (Deprecated parameter)
   * @param {string} [triggeredEvent]
   * @param {boolean} [fatalError] Indicate if is categorized as fatalError
   *
   * @memberof npaw.Plugin.prototype
   */
  fireError: function (code, msg, metadata, level, triggeredEvent, fatalError) {
    this.fireInit();
    var params = AnalyticsUtil.buildErrorParams(code, msg, metadata);
    params.entities = this.requestBuilder.getChangedEntities([this, this.plugin], this.options);
    if (params.code) {
      delete params.code;
    }
    if (triggeredEvent) {
      params.triggeredEvents = [triggeredEvent];
    }
    try {
      fatalError = fatalError || false;
      if (fatalError && this._adapter) {
        if (this._adapter.flags && this._adapter.flags.isJoined) {
          params = params || {};
          params.errorType = 'fatal';
        }
      }
    } catch (e) {}
    this._send(Constants.WillSendEvent.WILL_SEND_ERROR, Constants.Service.ERROR, params);
    this._adSavedError();
    this._adSavedManifest();
    Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + Constants.Service.ERROR + ', errorType: ' + params.errorType + ', errorCode: ' + params.errorCode + ((params.triggeredEvents) ? ', eventsTriggered: ' + params.triggeredEvents : ''));

    if (params.errorType === 'fatal') {
      this.fireStop();
    }
  },

  /**
   * Calls fireErrors and then stops pings.
   *
   * @param {String|Object} [code] Error Code, if an object is sent, it will be treated as params.
   * @param {String} [msg] Error Message
   * @param {Object} [metadata] Object defining error metadata
   * @param {String} [level] Level of the error. Currently, supports 'error' and 'fatal'
   * @param {string} [triggeredEvent]
   *
   * @memberof npaw.Plugin.prototype
   */
  fireFatalError: function (code, msg, metadata, level, triggeredEvent) {
    this.fireError(code, msg, metadata, level, triggeredEvent, true);
  },

  /**
   * Fires /stop. Should be used to terminate sessions once the player is gone or if
   * plugin.fireError() is called.
   *
   * @param {Object} [params] Object of key:value params.
   * @param {string} [triggeredEvent]
   *
   * @memberof npaw.Plugin.prototype
   */
  fireStop: function (params, triggeredEvent) {
    if (this.isInitiated || this.isStarted) {
      if (this._adapter) {
        this._adapter.flags.isStopped = true;
        if (this._adapter.monitor) this._adapter.monitor.stop();
      }
      if (this._adsAdapter && this.isBreakStarted) {
        this._adsAdapter.fireStop();
        this._adsAdapter.fireAdBreakStop();
      }
      // TODO Check if we can remove it and manage in the reset()
      if (this.expirationManager) {
        this.expirationManager.reset();
      }
      params = params || {};
      params.entities = this.requestBuilder.getChangedEntities([this, this.plugin], this.options);
      if (triggeredEvent) {
        params.triggeredEvents = [triggeredEvent];
      }
      DiagnosticTool.getInstance().setTriedSendingNQSStats(true);
      this._send(Constants.WillSendEvent.WILL_SEND_STOP, Constants.Service.STOP, params, undefined, undefined, () => { DiagnosticTool.getInstance().addNQSRequest(Constants.Service.STOP); });
      var chronos = this._adapter ? this._adapter.chronos : null;
      if (chronos) {
        chronos.total.stop();
        chronos.join.reset();
        chronos.pause.reset();
        chronos.buffer.reset();
        chronos.seek.reset();
      }
      this.lastKnownLocation = undefined;
      this.observedRenditions = [];
      if (this.renditionInterval) {
        clearInterval(this.renditionInterval);
        this.renditionInterval = undefined;
      }
      Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + Constants.Service.STOP + ' at ' + params.playhead + 's' + ((params.triggeredEvents) ? ', eventsTriggered: ' + params.triggeredEvents : ''));
      this._reset();
    }
  },

  /**
   * Fires /offlineEvents. If offline is disabled, will try to send all the views stored.
   *
   * @param {Object} [params] Object of key:value params.
   *
   * @memberof npaw.Plugin.prototype
   */
  fireOfflineEvents: function (params) {
    if (this.options && !this.options.offline) {
      if (!this.isInitiated &&
        (!this._adapter || !this._adapter.flags.isStarted) &&
        (!this._adsAdapter || !this._adsAdapter.flags.isStarted)) {
        this._offlineParams = params;
        if (Core.getInstance().getFastDataService().getAccountCode() && Core.getInstance().getFastDataSessionToken()) {
          this._generateAndSendOffline();
        } else {
          this.offlineReference = this._generateAndSendOffline.bind(this);
          // this.fastDataTransform.on(Transform.Event.DONE, this.offlineReference)
        }
      } else {
        Log.error(AnalyticsTag, 'Adapters have to be stopped');
      }
    } else {
      Log.error(AnalyticsTag, 'To send offline events, offline option must be disabled');
    }
  },

  _generateAndSendOffline: function () {
    if (this.options.disableStorage) return null;
    const params = this._offlineParams;
    while (true) {
      var bodyAndId = this.requestBuilder.buildBody(Constants.Service.OFFLINE_EVENTS, [this, this.plugin]).viewJson;
      if (bodyAndId[0] === null) break;
      var newViewCode = this.plugin.getNextViewIndex(this);
      var body = bodyAndId[0].replace(/CODE_PLACEHOLDER/g, newViewCode.toString())
        .replace(/,"sessionId":"SESSION_PLACEHOLDER"/g, '') // this.viewTransform.getSession()
        .replace(/,"sessionRoot":"ROOT_PLACEHOLDER"/g, ''); // this.viewTransform.getSession()
      // modify to support offline+appanalytics
      this._send(Constants.WillSendEvent.WILL_SEND_OFFLINE_EVENTS, Constants.Service.OFFLINE_EVENTS,
        params, body, 'POST', function (a, callbackParams) {
          this.plugin.offlineStorage.removeView(callbackParams.offlineId);
        }.bind(this), { offlineId: bodyAndId[1] });
    }
    this.plugin.offlineStorage.sent();
    this._offlineParams = null;
  },

  /**
   * Reset all variables and stop all timers
   * @private
   */
  _reset: function () {
    try {
      this._stopPings();
      this.resetExpirationManager();
      if (this._adapter) { // The fix
        this._adapter.flags.reset();
      }
      this._viewIndex = undefined;
      this.isInitiated = false;
      this.isStarted = false;
      this.startDelayed = false;
      this.isAdsManifestSent = false;
      this.initChrono.reset();
      this._totalPrerollsTime = 0;
      this.requestBuilder.lastSent.breakNumber = 0;
      this.requestBuilder.lastSent.adNumber = 0;
      this._savedAdManifest = null;
      this._savedAdError = null;
      this.playedPostrolls = 0;
      this.isBreakStarted = false;
      this._adNumber = 0;
      this._adNumberInBreak = 0;
    } catch (err) {}
  },

  resetExpirationManager: function () {
    if (this.expirationManager) {
      this.expirationManager.reset();
    }
  },

  _coreEventListener: function (eventType) {
    if (eventType === CoreEvents.SESSION_EXPIRE) {
      Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') Closing View by Session Expire Event');
      this.fireStop();
    }
  },

  _expiredSession: function() {
    if (this.expirationManager && this.expirationManager.isExpired()) {
      Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') Closing View by Expire Event');
      this.fireStop();
      return true
    }
    return false;
  },

  /**
   * Creates and enqueues related request using {@link Communication#sendRequest}.
   * It will fire will-send-events.
   *
   * @param {string} willSendEvent Name of the will-send event. Use {@link Plugin.Event} enum.
   * @param {string} service Name of the service. Use {@link Constants.Service} enum.
   * @param {Object} params Params of the request
   * @param {Object} body Body of the request, if it is a POST request
   * @param {string} method Request method. GET by default
   * @param {function} callback Callback method for successful request
   * @param {Object} callbackParams Json with params for callback call
   * @private
   */
  _send: function (willSendEvent, service, params, body, method, callback, callbackParams) {

    if ((service !== Constants.Service.STOP) && this._expiredSession()) {
      return
    }

    const reqMethod = Util.methodFromString(method);
    const now = new Date().getTime();
    if (this.options.preventZombieViews && this.lastEventTime && (now > (this.lastEventTime + (600 * 1000)))) { // 600 * 1000ms = 10 minutes
      // if last event was sent more than 10 minutes ago, it will use new view code
      this.plugin.getNextViewIndex(this);
    }
    this.lastEventTime = (service === Constants.Service.STOP) ? null : now;

    const request = new VideoAnalyticsRequest(service, params, this.getVideoKey(), reqMethod, undefined, callback, undefined, (service !== Constants.Service.STOP) ? this.expirationManager : undefined);

    this.plugin.resourceTransform.parse(request);

    params = this.requestBuilder.buildParams(params, service, [this, this.plugin], this.options);

    if (this.getIsLive() === true) {
      params.mediaDuration = this.options['content.duration'];
      params.playhead = undefined;
    }

    request.setParams(params);
    if (body) request.setBody(body);
    request.setMethod(reqMethod);

    if (this.plugin.isMethodPostEnabled()) {
      request.setMethod(Method.POST);
    }

    const data = {
      params: request.getParams(),
      plugin: this,
      adapter: this.getAdapter(),
      adsAdapter: this.getAdsAdapter()
    };

    this.emit(willSendEvent, data);

    if (
      this.plugin.analyticsRequestHandler &&
      (params !== null || typeof method !== 'undefined') &&
      this.options.enabled
    ) {
      this.plugin.lastServiceSent = service;
      this.plugin.analyticsRequestHandler.sendRequest(request);
    }
  },

  /**
   *
   * @param params
   */
  firePlayerLog: function (params) {
    try {
      if (this.plugin.isPlayerLogsEnabled()) {
        if (this._adapter && !this._adapter.isStarted()) {
          params.timemark = new Date().getTime();
          this._pluginLogsQueue.push(params);
        } else {
          while (this._pluginLogsQueue.length > 0) {
            var queuedLogParams = this._pluginLogsQueue.shift();
            this._sendPlayerLog(queuedLogParams);
          }
          // And finally, send current log
          this._sendPlayerLog(params);
        }
      }
    } catch (e) {}
  },

  /**
   *
   * @param params
   * @private
   */
  _sendPlayerLog: function (params) {
    try {
      if (params && params.logType && params.logAction) {
        Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + 'PlayerLog ' + params.logType + ': Action ' + params.logAction);
      }
      if (this.plugin._comm && params) {
        params = this.requestBuilder.buildParams(params, Constants.Service.VIDEO_PLUGIN_LOGS, this, this.options);
        if (params !== null) {
          this._send(null, Constants.Service.VIDEO_PLUGIN_LOGS, params);
        }
      }
    } catch (e) {}
  }
})

AnalyticsUtil.assign(NpawVideo.prototype, require('./video_ads'));
AnalyticsUtil.assign(NpawVideo.prototype, require('./video_content'));
AnalyticsUtil.assign(NpawVideo.prototype, require('./video_getters'));
AnalyticsUtil.assign(NpawVideo.prototype, require('./video_ping'));

module.exports = NpawVideo;
