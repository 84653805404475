import { PlayerMode, storage, StorageKey } from "@tv4/avod-web-player-common";

import { HtmlVideoEngine } from "../media_engine/HtmlVideoEngine";

/**
 * Method to sync playback settings between multiple open player instances
 * in the same browser tab. It should not sync between browser tabs.
 * @param mode
 * @param mediaEngine
 */

export const syncSessionPlaybackSettings = ({
  mode,
  mediaEngine,
}: {
  mode: PlayerMode;
  mediaEngine: HtmlVideoEngine;
}) => {
  const storedMutedSetting = storage.getSessionData<boolean>(
    mode === PlayerMode.PREVIEW ? StorageKey.MUTED_PREVIEW : StorageKey.MUTED
  );
  const storedVolumeLevel = storage.getSessionData<number>(
    mode === PlayerMode.PREVIEW ? StorageKey.VOLUME_PREVIEW : StorageKey.VOLUME
  );

  if (typeof storedMutedSetting === "boolean") {
    if (storedMutedSetting) {
      mediaEngine.mute();
    } else {
      mediaEngine.unmute();
    }
  }

  if (typeof storedVolumeLevel === "number") {
    mediaEngine.setVolume(storedVolumeLevel);
  }
};
