"use strict";

exports.__esModule = true;
exports.createHeaders = createHeaders;
exports.fetchWithTimeout = fetchWithTimeout;
exports.getAbsolute = getAbsolute;
exports.getUrl = getUrl;
var _StringUtils = require("./StringUtils");
var _YoLog = require("./YoLog");
var _Session = require("../Public/Session");
/*
 * COPYRIGHT 2020-2023 YOSPACE TECHNOLOGIES LTD. ALL RIGHTS RESERVED.
 * The contents of this file are proprietary and confidential.
 * Unauthorised copying of this file, via any medium is strictly prohibited.
 */

function getAbsolute(relativeUrl, absoluteBaseUrl) {
  var bUrl = new URL(absoluteBaseUrl);
  var aUrl = new URL(bUrl, relativeUrl);
  return aUrl.toString();
}
function getUrl(url) {
  if ((0, _StringUtils.isNullOrEmpty)(url)) {
    return null;
  }
  var u = null;
  try {
    u = new URL(url);
  } catch (e) {
    return null;
  }
  if (!u) {
    return null;
  }
  try {
    decodeURI(u.pathname);
  } catch (e) {
    return null;
  }
  return u;
}
function createHeaders(userAgent) {
  var headers = new Headers();
  headers.append("User-Agent", userAgent);
  return headers;
}

// This function should handle any error that arises and handle it gracefully without throwing
function fetchWithTimeout(url, opts, timeout) {
  var handle = null;
  var timer = new Promise(function (resolve) {
    handle = setTimeout(resolve, timeout, {
      timeout: true,
      error: "timeout",
      statusText: "Connection timeout",
      status: _Session.CONNECTION_TIMEOUT
    });
  });
  return Promise.race([timer, fetch(url, opts)]).then(function (response) {
    if (response.timeout) {
      _YoLog.YoLog.d(_YoLog.DebugFlags.DEBUG_HTTP_REQUESTS, "Request for " + url + " timed out");
    } else if (!response.ok && response.status) {
      // Don't log CORS responses - we have no information on them and they result in support tickets
      _YoLog.YoLog.d(_YoLog.DebugFlags.DEBUG_HTTP_REQUESTS, "Request for " + url + " failed. Status: " + response.status + ". Message: " + response.statusText);
    }
    return response;
  })["catch"](function (e) {
    _YoLog.YoLog.d(_YoLog.DebugFlags.DEBUG_HTTP_REQUESTS, "Request for " + url + " failed. Exception: " + e);
    var status = _Session.CONNECTION_ERROR;
    if (e.code === 'ERR_INVALID_URL' || e.cause && e.cause.code === 'ERR_INVALID_URL') {
      status = _Session.MALFORMED_URL;
    }
    return {
      error: e,
      statusText: e.message,
      status: status
    };
  })["finally"](function () {
    clearTimeout(handle);
  });
}