import PlaybackRateIcon from "../icons/playback-rate.svg";
import { PlayerControlButton as Button } from "../styles";

export const PlaybackRateButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button aria-label="Change Playback Rate" onClick={onClick}>
      <PlaybackRateIcon />
    </Button>
  );
};
