// {@see CdnParser}

module.exports = {
  cdnName: 'AMAZON',
  parsers: [{
    element: 'host',
    headerName: 'x-amz-cf-pop',
    regex: /(.+)/
  }, {
    element: 'type',
    headerName: 'x-cache',
    regex: /(.+)\sfrom.+/
  }],
  parseType: function (type) {
    if (type.toLowerCase().indexOf('hit') !== -1) {
      return 1
    } else if (type.toLowerCase().indexOf('miss') !== -1) {
      return 2
    }
    return 0
  }
}
