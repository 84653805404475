import { isSupportedOS as isDesktopOS } from "@tv4/avod-web-player-device-capabilities";
import styled, { css } from "styled-components";

import TracksSvg from "../../../icons/tracks.svg";
import { useTracksMenuOpen } from "../../../providers/LayoutProvider";
import { PlayerControlLabelButton } from "../../PlayerControlLabelButton";

type TracksButtonProps = {
  onClick: () => void;
  hasTracksToChooseFrom?: boolean;
  label?: string;
};

const Button = styled(PlayerControlLabelButton)<{
  $isOpen?: boolean;
  $usePadding?: boolean;
  $hasTracksToChooseFrom?: boolean;
}>`
  padding: ${({ $usePadding }) => ($usePadding ? "0.5em" : "0em")};
  ${({ $isOpen, $usePadding }) =>
    $isOpen &&
    $usePadding &&
    css`
      background: rgba(255, 255, 255, 0.2);
      border-radius: 8px;
    `}

  opacity: ${({ $hasTracksToChooseFrom }) =>
    $hasTracksToChooseFrom ? 1 : 0.7};
`;

export const TracksButton = ({
  hasTracksToChooseFrom,
  ...props
}: TracksButtonProps) => {
  const { tracksMenuOpen } = useTracksMenuOpen();

  return (
    <Button
      $isOpen={tracksMenuOpen}
      $usePadding={isDesktopOS()}
      $hasTracksToChooseFrom={hasTracksToChooseFrom}
      Icon={<TracksSvg />}
      {...props}
    />
  );
};
