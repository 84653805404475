// {@see CdnParser}

module.exports = {
  cdnName: 'FASTLY',
  parsers: [{
    element: 'host',
    headerName: 'X-Served-By',
    regex: /([^,\s]+)$/
  }, {
    element: 'type',
    headerName: 'X-Cache',
    regex: /([^,\s]+)$/
  }],
  parseType: function (type) {
    switch (type) {
      case 'HIT':
        return 1
      case 'MISS':
        return 2
      default:
        return 0
    }
  }
}
