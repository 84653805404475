import { useEffect, useState } from "react";

import CloseIconSvg from "../../icons/CloseIcon.svg";
import { usePlayerState } from "../../providers/CoreProvider";
import { useLayoutSize } from "../../providers/LayoutProvider";
import { useTranslations } from "../../providers/TranslationsProvider";
import { LayoutSize } from "../../util/device";
import {
  AnimationContainer,
  CloseButton,
  Container,
  Message,
  MinimizedMessage,
} from "./AdShowingIndicator.styles";

const AdShowingIndicator = ({
  visible,
  maximized,
}: {
  visible: boolean;
  maximized?: boolean;
}) => {
  const [shownOnce, setShownOnce] = useState(false);
  const { isPauseAd } = usePlayerState();
  const translations = useTranslations();
  const size = useLayoutSize();
  const isSmallestLayout = size === LayoutSize.SMALLEST;

  useEffect(() => {
    if (shownOnce) return;
    if (maximized || !isPauseAd) return;
    setShownOnce(true);
  }, [maximized, isPauseAd, shownOnce]);

  useEffect(() => {
    if (!isPauseAd) setShownOnce(false);
  }, [isPauseAd]);

  return (
    <Container
      visible={visible}
      open={maximized && !shownOnce}
      isSmallestLayout={isSmallestLayout}
    >
      <AnimationContainer>
        <div></div>
        {!isSmallestLayout && (
          <Message>{translations("ad_indicator__ad")}</Message>
        )}
        {/* Pause Engine controls interactions with this button */}
        <CloseButton id="close-pause-ad-button" aria-label="close pause ad">
          {(shownOnce || !maximized || isSmallestLayout) && (
            <MinimizedMessage>
              {translations("ad_indicator__ad")}
            </MinimizedMessage>
          )}
          <CloseIconSvg />
        </CloseButton>
      </AnimationContainer>
    </Container>
  );
};

export { AdShowingIndicator };
