import { PlaybackState } from "@tv4/avod-web-player-common";
import { useCallback } from "react";

import PauseSvg from "../icons/pause.svg";
import PlaySvg from "../icons/play.svg";
import { useControls, usePlayerState } from "../providers/CoreProvider";
import { PlayerControlButton as Button } from "../styles";

export const PlayPauseButton = () => {
  const { playbackState } = usePlayerState();
  const controls = useControls();

  const togglePlayPause = useCallback(() => {
    if ([PlaybackState.PAUSED, PlaybackState.ENDED].includes(playbackState)) {
      controls?.play?.();
    } else {
      controls?.pause?.({ programmatic: false });
    }
  }, [playbackState, controls]);

  return (
    <Button aria-label="start playback" onClick={togglePlayPause}>
      {playbackState === PlaybackState.PAUSED ? <PlaySvg /> : <PauseSvg />}
    </Button>
  );
};

export default PlayPauseButton;
