"use strict";

exports.__esModule = true;
exports.equalsIgnoreCase = equalsIgnoreCase;
exports.formatHtml = formatHtml;
exports.isNullOrEmpty = isNullOrEmpty;
exports.isString = isString;
exports.toIndentedString = toIndentedString;
exports.toUnindentedString = toUnindentedString;
/*
 * COPYRIGHT 2020-2022 YOSPACE TECHNOLOGIES LTD. ALL RIGHTS RESERVED.
 * The contents of this file are proprietary and confidential.
 * Unauthorised copying of this file, via any medium is strictly prohibited.
 */

function equalsIgnoreCase(s1, s2) {
  if (s1 === null) {
    return s2 === null;
  }
  if (s1 === undefined) {
    return s2 === undefined;
  }
  if (isString(s1) && isString(s2)) {
    return s1.toUpperCase() === s2.toUpperCase();
  } else {
    return false;
  }
}
function isString(s) {
  return typeof s === 'string' || s instanceof String;
}
function formatHtml(s) {
  if (!s) {
    return "";
  }
  try {
    var lvl = 0;
    var res = "";
    var cur = null;
    var last = 0;
    for (var i = 0; i < s.length; ++i) {
      cur = s[i];
      if (cur === '<') {
        var next = s[i + 1];
        if (next === '/') {
          lvl--;
        }
        res = pad(res, last, lvl);
        if (next !== '/' && next !== '!') {
          lvl++;
        }
      }
      res += cur;
      last = cur;
    }
    if (lvl === 0) {
      return res;
    }
  } catch (e) {
    // Malformed string
  }
  return s;
}
function toIndentedString(o) {
  if (!o) {
    return "null";
  }
  return "    " + o.toString().replace("\n", "\n    ");
}
function toUnindentedString(o) {
  if (!o) {
    return "null";
  } else {
    return "" + o.toString().replace("\n    ", "\n");
  }
}
function isNullOrEmpty(s) {
  return !s || s === "";
}
function pad(str, last, lvl) {
  var res = str;
  if (last === 0 || last === '>') {
    res += "\n";
    for (var i = 0; i < lvl * 4; ++i) {
      res += " ";
    }
  }
  return res;
}