import styled from "styled-components";

import { useTranslations } from "../providers/TranslationsProvider";
import { DefaultButton, DefaultSvg } from "../styles";
import { colors } from "../theme/colors";

const Button = styled.button`
  ${DefaultButton}
  ${DefaultSvg}

  color: ${colors.white};
  padding: 0.5em 1em;
  line-height: 1.5em;
  background: ${colors.primary};
  border-radius: 8px;
  width: 300px;
  margin-bottom: 1em;
`;

type TRefreshPageButtonProps = {
  refreshPageHandler?: () => void;
};

const RefreshPageButton = ({ refreshPageHandler }: TRefreshPageButtonProps) => {
  const translations = useTranslations();
  return (
    <Button aria-label="refresh page" onClick={refreshPageHandler}>
      {translations("refresh_page")}
    </Button>
  );
};

export default RefreshPageButton;
