import type { WebSender } from "@tv4/avod-web-player-chromecast";
import {
  AdMarker,
  DEFAULT_PLAYER_STATE,
  IAd,
  IAdBreak,
  PlayerState,
  TControls,
} from "@tv4/avod-web-player-common";
import type { DebugInfo, TCoreOptions } from "@tv4/avod-web-player-core";
import { createContext, ReactNode, useContext } from "react";

type TCoreProviderContext = {
  playerState: PlayerState;
  adMarkers: AdMarker[];
  currentAdBreak?: IAdBreak;
  currentAd?: IAd;
  controls: TControls;
  options?: TCoreOptions;
  chromeCastManager?: WebSender;
  getCurrentState?: () => PlayerState;
  getDebugInfo?: () => DebugInfo;
};

type TCoreProviderProps = {
  playerState: PlayerState;
  controls: TControls;
  adMarkers: AdMarker[];
  currentAdBreak?: IAdBreak;
  currentAd?: IAd;
  options?: TCoreOptions;
  chromeCastManager?: WebSender;
  getCurrentState?: () => PlayerState;
  getDebugInfo?: () => DebugInfo;
  children: ReactNode;
};

const CoreContext = createContext<TCoreProviderContext>({
  playerState: DEFAULT_PLAYER_STATE,
  controls: null,
  adMarkers: [],
  currentAdBreak: undefined,
  currentAd: undefined,
  options: undefined,
  chromeCastManager: undefined,
});

export function CoreProvider({
  playerState,
  controls,
  adMarkers,
  currentAdBreak,
  currentAd,
  options,
  chromeCastManager,
  getCurrentState,
  getDebugInfo,
  children,
}: TCoreProviderProps) {
  return (
    <CoreContext.Provider
      value={{
        playerState,
        controls,
        adMarkers,
        currentAdBreak,
        currentAd,
        options,
        chromeCastManager,
        getCurrentState,
        getDebugInfo,
      }}
    >
      {children}
    </CoreContext.Provider>
  );
}

export const usePlayerState = (): PlayerState => {
  const { playerState } = useContext(CoreContext);
  return playerState;
};

export const useControls = (): TControls => {
  const { controls } = useContext(CoreContext);
  return controls;
};

export const useAdMarkers = (): AdMarker[] => {
  const { adMarkers } = useContext(CoreContext);

  return adMarkers;
};

export const useCurrentAdBreak = () => {
  const { currentAdBreak, currentAd } = useContext(CoreContext);
  return { currentAdBreak, currentAd };
};

export const useChromecastManager = (): WebSender | undefined => {
  const { chromeCastManager } = useContext(CoreContext);
  return chromeCastManager;
};

export const useOptions = (): TCoreOptions | undefined => {
  const { options } = useContext(CoreContext);
  return options;
};

export const useDebugInfo = () => {
  const { getDebugInfo } = useContext(CoreContext);

  return getDebugInfo && getDebugInfo();
};
