import EnterFullscreenSvg from "../icons/enterFullscreen.svg";
import ExitFullscreenSvg from "../icons/exitFullscreen.svg";
import { useControls, usePlayerState } from "../providers/CoreProvider";
import { PlayerControlButton as Button } from "../styles";

export const FullscreenButton = () => {
  const controls = useControls();
  const { isFullscreen } = usePlayerState();

  const toggleFullscreen = (e: React.MouseEvent) => {
    e.stopPropagation();
    controls?.toggleFullscreen?.();
  };

  return (
    <Button aria-label="Enter fullscreen" onClick={toggleFullscreen}>
      {isFullscreen ? <ExitFullscreenSvg /> : <EnterFullscreenSvg />}
    </Button>
  );
};

export default FullscreenButton;
