export enum LayoutSize {
  SMALLEST = "SMALLEST",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export enum LayoutOrientation {
  PORTRAIT = "PORTRAIT",
  LANDSCAPE = "LANDSCAPE",
}

export const LayoutBreakpoints = {
  [LayoutSize.SMALL]: 512,
  [LayoutSize.MEDIUM]: 768,
  [LayoutSize.LARGE]: 1024,
};

export function getWidthLayoutSize(width: number): LayoutSize {
  if (width >= LayoutBreakpoints[LayoutSize.LARGE]) {
    return LayoutSize.LARGE;
  } else if (width >= LayoutBreakpoints[LayoutSize.MEDIUM]) {
    return LayoutSize.MEDIUM;
  } else if (width >= LayoutBreakpoints[LayoutSize.SMALL]) {
    return LayoutSize.SMALL;
  }
  return LayoutSize.SMALLEST;
}

export function getContainerLayoutSize(container: HTMLElement): LayoutSize {
  const clientWidth = container.clientWidth;
  return getWidthLayoutSize(clientWidth);
}

export function getLayoutOrientation(width: number, height: number) {
  return width > height
    ? LayoutOrientation.LANDSCAPE
    : LayoutOrientation.PORTRAIT;
}
