import { ThumbnailCue } from "@tv4/avod-web-player-common";
import { WebVTTParser } from "webvtt-parser";

const parser = new WebVTTParser();

export function parseThumbnailVTT(
  vtt: string,
  baseUrl: string
): ThumbnailCue[] {
  const { cues } = parser.parse(vtt);
  return cues.map((cue) => {
    if (!cue.text.includes("#xywh=")) {
      console.warn("[ThumbnailVttParser] Invalid cue:", cue.text);
    }
    const [path, frame] = cue.text.split("#xywh=");
    const [x, y, width, height] = frame.split(",").map(Number);
    if (
      !Number.isFinite(x) ||
      !Number.isFinite(y) ||
      !Number.isFinite(width) ||
      !Number.isFinite(height)
    ) {
      console.warn("[ThumbnailVttParser] Invalid frame:", frame);
    }

    return {
      imageUrl: new URL(path, baseUrl).toString(),
      startTime: cue.startTime,
      endTime: cue.endTime,
      frame: { x, y, width, height },
    };
  });
}

export function getThumbnails(vttUrl: string): Promise<ThumbnailCue[]> {
  return (
    fetch(vttUrl)
      .then((response) => response.text())
      .then((vtt) => parseThumbnailVTT(vtt, vttUrl))
      // The most likely reason for failure is that the VTT file has not been generated
      // which is by design so that the playback-api doesn't have to verify that the VTT file exists.
      .catch(() => [])
  );
}
