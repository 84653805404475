import { AdMarker, StreamInfoPayload } from "@tv4/avod-web-player-common";

const RETRY_INTERVAL = 1000;
const UPDATE_INTERVAL = 5000;

export type StreamInfoServiceResponse = {
  adBreaks: { adStart: string; adEnd: string }[];
  durationMs: number;
  ended: boolean;
  liveEdge: string;
  startTime: string;
};

export type StreamInfoListenerPayload = {
  streamInfo: StreamInfoPayload;
  adMarkers: Required<AdMarker>[];
  adMarkersChanged: boolean;
  endedChanged: boolean;
};

export class StreamInfoService {
  public readonly url: URL;
  private adsRequired: boolean;
  private data: StreamInfoPayload | undefined;
  private adMarkerMap = new Map<number, Required<AdMarker>>();
  private updateListeners: ((payload: StreamInfoListenerPayload) => void)[] =
    [];
  private destroyed = false;
  constructor(options: { url: URL; adsRequired: boolean }) {
    this.url = options.url;
    this.adsRequired = !!options.adsRequired;
  }

  /**
   * Set up the polling subscription to fetch data
   * @param updateListener optional callback
   */
  public async setup(
    updateListener?: (payload: StreamInfoListenerPayload) => void
  ): Promise<void> {
    if (updateListener) {
      this.updateListeners.push(updateListener);
    }
    const doUpdate = async () => {
      try {
        await this.update();
        if (this.destroyed || this.data?.ended) return;
        window.setTimeout(doUpdate, UPDATE_INTERVAL);
      } catch (error) {
        console.error(
          `Error updating stream info. Retrying in ${RETRY_INTERVAL}s`,
          error
        );
        await new Promise((resolve) => setTimeout(resolve, RETRY_INTERVAL));
        if (this.destroyed || this.data?.ended) return;
        await doUpdate();
      }
    };
    await doUpdate();
  }

  public async update(): Promise<void> {
    if (this.destroyed || this.data?.ended) {
      return;
    }

    const previousEnded = !!this.data?.ended;
    const response = await fetch(this.url);
    const responseData: StreamInfoServiceResponse = await response.json();

    if (this.destroyed) {
      return;
    }

    const streamStart = Date.parse(responseData.startTime) / 1000;

    // ignoring responseData.liveEdge. It is the same as startTime + durationMs
    this.data = {
      startTime: streamStart,
      duration: responseData.durationMs / 1000,
      ended: responseData.ended,
    };

    const adMarkerMap = new Map<number, Required<AdMarker>>();

    for (const adBreak of responseData.adBreaks || []) {
      const startEpoch = Date.parse(adBreak.adStart) / 1000;
      const endEpoch = Date.parse(adBreak.adEnd) / 1000;
      const watched = !!this.adMarkerMap.get(startEpoch)?.watched;
      const required = this.adsRequired;
      // The first ad break may contain ads before the program start, which needs to be excluded
      // Additionally the test streams have duplicate ad breaks, which need to be excluded
      if (startEpoch >= streamStart && !adMarkerMap.has(startEpoch)) {
        adMarkerMap.set(startEpoch, {
          start: startEpoch - streamStart,
          end: endEpoch - streamStart,
          duration: endEpoch - startEpoch,
          watched,
          required,
        });
      }
    }

    const adMarkersChanged =
      adMarkerMap.size !== this.adMarkerMap.size ||
      adMarkerMap.keys().next().value !==
        this.adMarkerMap.keys().next().value ||
      adMarkerMap.values().next().value?.start !==
        this.adMarkerMap.values().next().value?.start;

    this.adMarkerMap = adMarkerMap;

    this.notifyListeners({
      streamInfo: this.data,
      adMarkers: this.adMarkers,
      adMarkersChanged,
      endedChanged: previousEnded !== this.data.ended,
    });
  }

  private notifyListeners(payload: StreamInfoListenerPayload) {
    for (const listener of this.updateListeners) {
      listener(payload);
    }
  }

  public destroy() {
    this.destroyed = true;
    this.updateListeners = [];
    this.adMarkerMap.clear();
  }

  public get duration(): number | undefined {
    return this.data?.duration;
  }

  public get startTime(): number | undefined {
    return this.data?.startTime;
  }

  public get ended(): boolean | undefined {
    return this.data?.ended;
  }

  public get adMarkers(): Required<AdMarker>[] {
    return [...this.adMarkerMap.values()];
  }

  /** get ad marker at a given position or undefined if there is none */
  public getAdMarkerAt(position: number): Required<AdMarker> | undefined {
    return this.adMarkers.find(
      (marker) => marker.start <= position && position < marker.end
    );
  }

  /** get all ad markers starting between two positions */
  public getAdMarkerStartingBetween(
    start: number,
    end: number
  ): Required<AdMarker>[] {
    return this.adMarkers.filter(
      (marker) => start <= marker.start && marker.start < end
    );
  }

  /** Marks the ad marker closest matching the end time as watched */
  public markWatchedAdBreak(endTime: number): void {
    const closestMarker = this.adMarkers.reduce((prev, curr) => {
      return Math.abs(curr.end - endTime) < Math.abs(prev?.end - endTime)
        ? curr
        : prev;
    });
    if (!this.data || !closestMarker) {
      console.warn("Tried to set ad marker, but stream info is not ready");
      return;
    }
    closestMarker.watched = true;
    this.notifyListeners({
      streamInfo: this.data,
      adMarkers: this.adMarkers,
      adMarkersChanged: true,
      endedChanged: false,
    });
  }
}
