import {
  getTranslationsForLocale,
  Locale,
  localeFromService,
  Service,
} from "@tv4/avod-web-player-common";
import React, { createContext, useContext } from "react";

const LocaleContext = createContext("sv" as Locale);

export function TranslationsProvider({
  service,
  children,
}: {
  service: Service;
  children: React.ReactNode;
}) {
  const locale: Locale = localeFromService(service);

  return (
    <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>
  );
}

export const useTranslations = () => {
  const locale = useContext(LocaleContext);
  return getTranslationsForLocale(locale);
};
