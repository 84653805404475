import {
  getRemoteConfigValue,
  ThumbnailCue,
  ThumbnailVariants,
} from "@tv4/avod-web-player-common";
import { getThumbnails } from "@tv4/avod-web-player-thumbnail-vtt-parser";
import React, { createContext, useContext, useEffect, useState } from "react";

import { LayoutSize } from "../util/device";
import { useLayoutSize } from "./LayoutProvider";

const ThumbnailsContext = createContext<{
  thumbnails: ThumbnailVariants;
  useImageProxy: boolean;
}>({
  thumbnails: {},
  useImageProxy: false,
});

export function ThumbnailsProvider({
  thumbnails = {},
  useImageProxy,
  children,
}: {
  thumbnails?: ThumbnailVariants;
  useImageProxy: boolean;
} & React.PropsWithChildren) {
  return (
    <ThumbnailsContext.Provider value={{ thumbnails, useImageProxy }}>
      {children}
    </ThumbnailsContext.Provider>
  );
}

export const useThumbnailCues = () => {
  const imageProxyUrl = getRemoteConfigValue("IMAGE_PROXY_URL");
  const { thumbnails, useImageProxy } = useContext(ThumbnailsContext);
  const size = useLayoutSize();

  const [thumbnailSize, setThumbnailSize] = useState<keyof ThumbnailVariants>();
  const [cues, setCues] = useState<ThumbnailCue[]>([]);

  useEffect(() => {
    switch (size) {
      case LayoutSize.LARGE:
      case LayoutSize.MEDIUM:
        setThumbnailSize("desktop");
        break;
      default:
        setThumbnailSize("mobile");
        break;
    }
  }, [size, thumbnails]);

  useEffect(() => {
    if (!thumbnailSize) return;

    const thumbnailsUrl = thumbnails[thumbnailSize]?.url;
    if (!thumbnailsUrl) {
      setCues([]);
      return;
    }

    let cancelled = false;
    getThumbnails(thumbnailsUrl)
      .then((thumbnailCues) => {
        if (!cancelled)
          setCues(
            thumbnailCues.map((cue) => {
              let imageUrl = cue.imageUrl;
              if (useImageProxy) {
                const proxyUrl = new URL(imageProxyUrl);
                proxyUrl.searchParams.append("source", imageUrl);
                imageUrl = proxyUrl.toString();
              }
              return {
                ...cue,
                imageUrl,
              };
            })
          );
      })
      .catch(() => {
        if (!cancelled) setCues([]);
      });
    return () => {
      cancelled = true;
    };
  }, [thumbnails, useImageProxy, imageProxyUrl, thumbnailSize]);

  return cues;
};
