// https://engineeringportal.nielsen.com/docs/DCR_Sweden_Video_Browser_SDK#Add_Static_Queue_Snippet
// copied directly from the nielsen js script, when updated simply replace the below code with the new code
/* eslint-disable @eslint-community/eslint-comments/no-unlimited-disable -- disabling all linting for external code */
/* eslint-disable -- external code, not subject to project linting rules */
function loadSdk() {
  (function (t, n) {
    t[n] = t[n] || {
      nlsQ: function (e, o, c, r, s, i) {
        return (
          (s = t.document),
          (r = s.createElement("script")),
          (r.async = 1),
          (r.src =
            ("http:" === t.location.protocol ? "http:" : "https:") +
            "//cdn-gl.imrworldwide.com/conf/" +
            e +
            ".js#name=" +
            o +
            "&ns=" +
            n),
          (i = s.getElementsByTagName("script")[0]),
          i.parentNode.insertBefore(r, i),
          (t[n][o] = t[n][o] || {
            g: c || {},
            ggPM: function (e, c, r, s, i) {
              (t[n][o].q = t[n][o].q || []).push([e, c, r, s, i]);
            },
          }),
          t[n][o]
        );
      },
    };
  })(window, "NOLBUNDLE");
}
/* eslint-enable */

loadSdk();

export enum NielsenEvent {
  LOAD_METADATA = "loadMetadata",
  SET_PLAYHEAD_POSITION = "setPlayheadPosition",
  STOP = "stop",
  END = "end",
}

export type NielsenContentMetadata = {
  type: string;
  userid?: string;
  assetid: string;
  length: string;
  clientpassparam?: string;
  program: string;
  title: string;
  playlisttitle?: string;
  ispremiumcontent: string;
  isautoplay: string;
  plugv: string;
  playerv: string;
};

export type NielsenAdMetadata = {
  type: string;
  assetid: string;
  ad_campaign?: string;
  length: string;
  isprogrammatic?: string;
  isthirdpartyad?: string;
  adplatformorigin?: string;
  adidx: string;
};

export type NielsenInstance = {
  ggPM: (
    event: NielsenEvent,
    parameter: number | NielsenContentMetadata | NielsenAdMetadata
  ) => void;
};

type NOLBUNDLE = {
  nlsQ: (
    appId: string,
    instanceName: string,
    options?: {
      nol_sdkDebug?: string;
      hem_unknown?: string;
    }
  ) => NielsenInstance;
};

// @ts-expect-error it's just how it is
export const nlns: NOLBUNDLE = window.NOLBUNDLE;

export enum NielsenAdBreakType {
  PREROLL = "preroll",
  MIDROLL = "midroll",
  AD = "ad",
}
