import styled, { css } from "styled-components";

import CloseIconSvg from "../../../icons/CloseCircle.svg";
import { type TMainPadding, withMainPadding } from "../../../styles";

const darkenBackground = css`
  background: rgba(0, 0, 0, 0.5);
`;

// Main container for all the track menu content - will always cover entire player to allow positioning content inside and also used to dim all of the ui behind
export const TracksContainer = styled.div<{
  $displayCover?: boolean;
}>`
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  position: absolute;
  user-select: none;
  pointer-events: ${({ $displayCover }) =>
    $displayCover ? "inherit" : "none"};
  ${({ $displayCover }) => $displayCover && darkenBackground}
`;

const CLOSE_BUTTON_SIZE = 2;
const CLOSE_BUTTON_PADDING = 0.5;

// container for handling scrolling when needed
export const TracksMenuContainer = withMainPadding(styled.div<{
  $displayCover?: boolean;
  $mainPadding: TMainPadding;
}>`
  position: absolute;
  display: flex;
  overflow: auto;
  padding: 0;
  margin: auto;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;

  pointer-events: ${({ $displayCover }) =>
    $displayCover ? "inherit" : "none"};

  ${({ $displayCover, $mainPadding }) =>
    $displayCover
      ? css`
          bottom: ${$mainPadding.bottom +
          CLOSE_BUTTON_PADDING * 2 +
          CLOSE_BUTTON_SIZE}em;
        `
      : css``}
`);

export const TrackListContainer = styled.div`
  min-width: 115px;
  line-height: 24px;
`;

export const TrackItem = styled.div`
  opacity: 0.5;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.625em;
  &.active {
    opacity: 1;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TrackTitle = styled.div<{
  $stackVertically: boolean;
}>`
  ${($stackVertically) =>
    $stackVertically &&
    css`
      text-align: center;
    `}
  font-weight: 500;
  opacity: 1;
  margin-bottom: 0.625em;
`;

// container to center and position the contents
export const TracksWrapper = styled.div<{
  $stackVertically: boolean;
  $displayCover: boolean;
}>`
  ${({ $displayCover }) => !$displayCover && darkenBackground}

  padding: 1.5em;
  display: flex;
  margin: auto;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
  gap: 1.5em;

  flex-direction: ${({ $stackVertically }) =>
    $stackVertically ? "column" : "row"};
`;

export const Divider = styled.div<{
  $stackVertically: boolean;
}>`
  ${({ $stackVertically }) => ($stackVertically ? "height" : "width")}: 1px;
  background: #fff;
  opacity: 0.25;
`;

export const NoTextTracksAvailable = styled.div`
  color: rgba(255, 255, 255, 0.7);
`;

export const CloseIcon = withMainPadding(styled(CloseIconSvg)<{
  $mainPadding: TMainPadding;
}>`
  position: absolute;
  right: 0;
  bottom: ${({ $mainPadding }) => $mainPadding.bottom + CLOSE_BUTTON_PADDING}em;
  left: 0;
  margin: auto;
  cursor: pointer;
  width: ${CLOSE_BUTTON_SIZE}em;
  height: ${CLOSE_BUTTON_SIZE}em;
`);

export const TextSizeOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  place-items: center;
  place-content: center;
  gap: 10px;
`;

export const TextTrackSize = styled.div<{
  $size: number;
  $lineHeight: number;
  $active: boolean;
}>`
  font-size: ${({ $size }) => $size}px;
  line-height: ${({ $lineHeight }) => $lineHeight}px;

  font-weight: 500;
  padding: 4px 5px 4px 5px;
  ${({ $active }) =>
    $active &&
    css`
      background-color: #fff;
      color: #000;
      border-radius: 6px;
    `}

  cursor: pointer;
`;
