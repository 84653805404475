const { default: DiagnosticTool } = require('../../diagnostic/DiagnosticTool');
var Constants = require('../constants');
const Event = require('../constants/event');
const { default: Log } = require('../../common/log');
var Util = require('../util');
var Adapter = require('adapter');
const { AnalyticsTag } = require('../../common/Constants');
const { default: CoreConstants } = require('../../core/utils/CoreConstants');
const { default: Core } = require('../../core/Core');

var NpawVideoContentMixin = {

  _startListener: function (e) {
    if (this.plugin.options.enableExtraMetricCollection && !this.lastKnownLocation && !this.isFetchingLocation) {
      this.fetchLocation();
    }
    if (!this.isInitiated) {
      if (!this._viewIndex) {
        this.plugin.getNextViewIndex(this);
      }
      this._startPings();
    } else if (this.initChrono.startTime !== 0) {
      this._adapter.chronos.join.startTime = this.initChrono.startTime;
    }
    var params = e.data.params || {};
    DiagnosticTool.getInstance().addNQSEvent(Adapter.Event.START, params);
    var allParamsReady = this.getResource() && typeof this.getIsLive() === 'boolean' &&
      (this.getIsLive() || (typeof this.getDuration() === 'number' && this.getDuration() > 0)) && this.getTitle()
    var isDecisionFinished = Core.getInstance().getCommonVariable(
      CoreConstants.Products.BALANCER,
      CoreConstants.BalancerVariables.DECISION_FINISHED
    );
    if (isDecisionFinished === undefined) {
      isDecisionFinished = true;
    }
    allParamsReady = this.options.forceInit ? false : (allParamsReady && this._isExtraMetadataReady() && isDecisionFinished &&  this._isRequiredMetadataReady());
    if (allParamsReady && !this.isInitiated) { // start
      DiagnosticTool.getInstance().setTriedSendingNQSStats(true);
      this._send(Constants.WillSendEvent.WILL_SEND_START, Constants.Service.START, params, undefined, undefined, () => { DiagnosticTool.getInstance().addNQSRequest(Constants.Service.START); });
      this._adSavedError();
      this._adSavedManifest();
      Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + Constants.Service.START + ', title/res: ' + (params.title || params.mediaResource) + ((params.triggeredEvents) ? ', eventsTriggered: ' + params.triggeredEvents : ''));
      this.isStarted = true;
      // chrono if had no adapter when inited
    } else if (!this.isInitiated) { // init
      this.isInitiated = true;
      this._adapter.chronos.join.start();
      DiagnosticTool.getInstance().setTriedSendingNQSStats(true);
      this._send(Constants.WillSendEvent.WILL_SEND_INIT, Constants.Service.INIT, params, undefined, undefined, () => { DiagnosticTool.getInstance().addNQSRequest(Constants.Service.INIT); });
      this._adSavedError();
      this._adSavedManifest();
      Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + Constants.Service.INIT + ', title/res: ' + (params.title || params.mediaResource) + ((params.triggeredEvents) ? ', eventsTriggered: ' + params.triggeredEvents : ''));
    }
    if (this.options.enableExtraMetricCollection) {
      this.shouldStartRenditionTimer();
    }
  },

  _retryStart: function (e) {
    var isDecisionFinished = Core.getInstance().getCommonVariable(
      CoreConstants.Products.BALANCER,
      CoreConstants.BalancerVariables.DECISION_FINISHED
    );
    if (isDecisionFinished === undefined) {
      isDecisionFinished = true;
    }
    if (this._isExtraMetadataReady() && isDecisionFinished && this._isRequiredMetadataReady()) {
      this._send(Constants.WillSendEvent.WILL_SEND_START, Constants.Service.START, { triggeredEvents: ['retryStart'] });
      this.startDelayed = false;
    }
  },

  _joinListener: function (e) {
    var params = {};
    Util.assign(params, e.data.params || {});
    if (!this._adsAdapter || !this._adsAdapter.flags.isStarted) {
      DiagnosticTool.getInstance().addNQSEvent(Adapter.Event.JOIN, params);
      if (this._adapter) {
        this._adapter.chronos.join.startTime = Math.min(
          this._adapter.chronos.join.startTime + (this._totalPrerollsTime || 0),
          new Date().getTime()
        );
        this._totalPrerollsTime = 0;
      }
      if (this.isInitiated && !this.isStarted) {
        // start if just inited
        var isDecisionFinished = Core.getInstance().getCommonVariable(
          CoreConstants.Products.BALANCER,
          CoreConstants.BalancerVariables.DECISION_FINISHED
        );
        if (isDecisionFinished === undefined) {
          isDecisionFinished = true;
        }
        if (this._isExtraMetadataReady() && isDecisionFinished && this._isRequiredMetadataReady()) {
          DiagnosticTool.getInstance().setTriedSendingNQSStats(true);
          this._send(Constants.WillSendEvent.WILL_SEND_START, Constants.Service.START, params, undefined, undefined, () => { DiagnosticTool.getInstance().addNQSRequest(Constants.Service.START); });
        } else {
          this.startDelayed = true;
        }
        this._adSavedError();
        this._adSavedManifest();
        Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + Constants.Service.START + ', title/res: ' + (params.title || params.mediaResource) + ((params.triggeredEvents) ? ', eventsTriggered: ' + params.triggeredEvents : ''));
        this.isStarted = true;
      }
      params = e.data.params || {};
      DiagnosticTool.getInstance().addNQSEvent(Event.JOIN_TIME, params);
      if (this._adsAdapter && this.isBreakStarted) {
        this._adsAdapter.fireAdBreakStop();
      }
      DiagnosticTool.getInstance().setTriedSendingNQSStats(true);
      this._send(Constants.WillSendEvent.WILL_SEND_JOIN, Constants.Service.JOIN, params, undefined, undefined, () => { DiagnosticTool.getInstance().addNQSRequest(Constants.Service.JOIN); });
      Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + Constants.Service.JOIN + ', duration: ' + params.joinDuration + 'ms' + ((params.triggeredEvents) ? ', eventsTriggered: ' + params.triggeredEvents : ''));
    } else { // If it is currently showing ads, join is invalidated
      if (this._adapter.monitor) this._adapter.monitor.stop();
      this._adapter.flags.isJoined = false;
      this._adapter.chronos.join.stopTime = 0;
    }
  },

  _advancedDataListener: function (e) {
    var params = e.data.params || {};
    this._send(Constants.WillSendEvent.WILL_SEND_DATA_OBJECT, Constants.Service.DATA_OBJECT, params);
    Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + Constants.Service.DATA_OBJECT +
    ((params.triggeredEvents) ? ', eventsTriggered: ' + params.triggeredEvents : ''));
  },

  _pauseListener: function (e) {
    if (this._adapter) {
      if (this._adapter.flags.isBuffering ||
        this._adapter.flags.isSeeking ||
        (this._adsAdapter && this._adsAdapter.flags.isStarted)) {
        this._adapter.chronos.pause.reset();
      }
    }

    var params = e.data.params || {};
    DiagnosticTool.getInstance().addNQSEvent(Adapter.Event.PAUSE, params);
    this._send(Constants.WillSendEvent.WILL_SEND_PAUSE, Constants.Service.PAUSE, params);
    Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + Constants.Service.PAUSE + ' at ' + params.playhead + 's' + ((params.triggeredEvents) ? ', eventsTriggered: ' + params.triggeredEvents : ''));
  },

  _resumeListener: function (e) {
    if (this._adsAdapter && this.isBreakStarted && !this._adsAdapter.flags.isStarted) {
      this._adsAdapter.fireAdBreakStop();
    }
    var params = e.data.params || {};
    DiagnosticTool.getInstance().addNQSEvent(Adapter.Event.RESUME, params);
    this._send(Constants.WillSendEvent.WILL_SEND_RESUME, Constants.Service.RESUME, params);
    Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + Constants.Service.RESUME + ', duration: ' + params.pauseDuration + 'ms' + ((params.triggeredEvents) ? ', eventsTriggered: ' + params.triggeredEvents : ''));
    this._adapter.chronos.pause.reset();
  },

  _seekBufferBeginListener: function (e) {
    if (this._adapter && this._adapter.flags.isPaused) {
      // Instead of reset the pause chronos, pause the chronos, to report pause time on scenario when user is pause the content, and later, do a seek, for example
      this._adapter.chronos.pause.pause();
    }
    if (e.type && e.type.includes('buffer')) {
      DiagnosticTool.getInstance().addNQSEvent(Adapter.Event.BUFFER_BEGIN, {});
      Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + e.type + ', eventsTriggered: ' + this._adapter.fireEventsStruct.buffer);
    } else {
      DiagnosticTool.getInstance().addNQSEvent(Adapter.Event.SEEK_BEGIN, {});
      Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + e.type + ', eventsTriggered: ' + this._adapter.fireEventsStruct.seek);
    }
  },

  _seekEndListener: function (e) {
    var params = e.data.params || {};
    DiagnosticTool.getInstance().addNQSEvent(Adapter.Event.SEEK_END, params);
    this._send(Constants.WillSendEvent.WILL_SEND_SEEK, Constants.Service.SEEK, params);
    Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + Constants.Service.SEEK +
      ' to ' + params.playhead +
      ' in ' + params.seekDuration + 'ms' +
      ((params.triggeredEvents) ? ', eventsTriggered: ' + params.triggeredEvents : '')
    );
  },

  _bufferEndListener: function (e) {
    var params = e.data.params || {};
    params.entities = this.requestBuilder.getChangedEntities([this, this.plugin], this.options);
    DiagnosticTool.getInstance().addNQSEvent(Adapter.Event.BUFFER_END, params);
    this._send(Constants.WillSendEvent.WILL_SEND_BUFFER, Constants.Service.BUFFER, params);
    Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + Constants.Service.BUFFER +
      ' to ' + params.playhead +
      ' in ' + params.bufferDuration + 'ms' +
      ((params.triggeredEvents) ? ', eventsTriggered: ' + params.triggeredEvents : '')
    );
  },

  _errorListener: function (e) {
    if (!this._blockError(e.data.params)) {
      DiagnosticTool.getInstance().addNQSEvent(Adapter.Event.ERROR, e.data.params);
      this.fireError(e.data.params || {});
      this._adSavedError();
      this._adSavedManifest();
    }
  },

  _blockError: function (errorParams) {
    var now = Date.now();
    var sameError = this._lastErrorParams
      ? this._lastErrorParams.errorCode === errorParams.errorCode && this._lastErrorParams.msg === errorParams.msg
      : false;
    if (sameError && this._lastErrorTime + 5000 > now) {
      this._lastErrorTime = now;
      return true;
    }
    this._lastErrorTime = now;
    this._lastErrorParams = errorParams;
    return false;
  },

  _stopListener: function (e) {
    DiagnosticTool.getInstance().addNQSEvent(Adapter.Event.STOP, e.data.params);
    this.fireStop(e.data.params || {});
  },

  _isStopReady: function (e) {
    let ret = false;
    // this solution is only for the case of:
    if (!this.requestBuilder.lastSent.live && // VOD, live have no postrolls
      this._adsAdapter && // having ads adapter connected
      this._adapter && // playhead close to the end of the content (or 0 because is already restarted)
      (!this._adapter.getPlayhead() || this._adapter.getPlayhead() >= this.requestBuilder.lastSent.mediaDuration - 1)) {
      let expectedPostrolls = 0;
      const pat = this.options['ad.expectedPattern'];
      // We can get the expectedPostrolls from the expected pattern if it has postrolls defined
      if (pat && pat.post && pat.post[0]) {
        expectedPostrolls = pat.post[0];
        // If not, while playing postrolls after adbreakstart we can get the givenAds
      } else if (this.requestBuilder.lastSent.breaksTime) {
        if (this.requestBuilder.lastSent.position === Constants.AdPosition.Postroll) {
          expectedPostrolls = this.requestBuilder.lastSent.givenAds;
        }
        // Or before playing postrolls, at least, we can check using breaksTime (from adManifest event) if we expect at least 1 postroll
        if (!expectedPostrolls && this.requestBuilder.lastSent.breaksTime) {
          const breaks = this.requestBuilder.lastSent.breaksTime;
          if (breaks.length > 0 && this.requestBuilder.lastSent.mediaDuration) { // If there is no duration probably is a live content, so no postrolls
            const lastTimePosition = Math.round(breaks[breaks.length - 1]);
            if (lastTimePosition + 1 >= this.requestBuilder.lastSent.mediaDuration) {
              expectedPostrolls = 1;
            }
          }
        }
        // If none of the previous solutions found anything, we assume we have no postrolls
      } else {
        ret = true;
      }
      // Finally, if the number of played postrolls is the same (or more) than the expected, we can close the view
      if (expectedPostrolls <= this.playedPostrolls) {
        ret = true;
      }
    } else {
      ret = true;
    }
    return ret;
  },

  _videoEventListener: function (e) {
    DiagnosticTool.getInstance().addNQSEvent(Adapter.Event.VIDEO_EVENT, e.data.params);
    this._send(Constants.WillSendEvent.WILL_SEND_VIDEO_EVENT, Constants.Service.VIDEO_EVENT, e.data.params);
  },

  _isExtraMetadataReady: function (e) {
    // If the option is disabled, always is ready
    if (!this.options.waitForMetadata || this.options.pendingMetadata.length < 1) {
      return true;
    }
    // If for existing parameters, one of them is false (no value for it), return false
    try {
      const getters = this.requestBuilder.getGetters();
      return this.options.pendingMetadata.map(function (element) {
        if (getters.hasOwnProperty(element)) { // eslint-disable-line no-prototype-builtins
          if (this[getters[element]]) {
            return !!this[getters[element]]();
          } else if (this.plugin[getters[element]]) {
            return !!this.plugin[getters[element]]();
          }
        }
      }.bind(this)).indexOf(false) < 0
    } catch (e) {
      Log.warn('[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') Error processing isExtraMetadataReady method.');
    }
    return true;
  },

  _isRequiredMetadataReady: function () {
    var now = new Date().getTime();
    var diff = now - this._adapter.chronos.join.startTime;
    if (diff < Constants.Metadata.REQUIRED_METADATA_TIMEOUT) {
      try {
        const getters = this.requestBuilder.getGetters();
        const metadataReady = this.requestBuilder.getRequiredMetadataParams().every(
          function (element) {
            if (getters.hasOwnProperty(element)) { // eslint-disable-line no-prototype-builtins
              if (typeof this[getters[element]] === 'function') {
                const result = this[getters[element]]();
                if (result !== undefined && result !== null && !Number.isNaN(result)) {
                  return true;
                }
              } else if (this.plugin && typeof this.plugin[getters[element]] === 'function') {
                const pluginResult = this.plugin[getters[element]]();
                if (pluginResult !== undefined && pluginResult !== null && !Number.isNaN(pluginResult)) {
                  return true;
                }
              }
            }
            return false;
          }.bind(this));

        return metadataReady;
      } catch (e) {
        Log.warn('[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') Error processing _isRequiredMetadataReady method.');
      }
      return false;
    }
    return true;
  }
}

module.exports = NpawVideoContentMixin;
