// {@see CdnParser}

module.exports = {
  cdnName: 'NOSOTT',
  parsers: [{
    element: 'host',
    headerName: 'X-NOS-Server',
    regex: /(.+)/
  }, {
    element: 'type',
    headerName: 'X-Cache',
    regex: /(.*)/
  }],
  parseType: function (type) {
    switch (type) {
      case 'Hit':
        return 1
      case 'Miss':
        return 2
      default:
        return 0
    }
  }
}
