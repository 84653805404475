export const getDurationString = (totalSeconds: number): string => {
  // Returns a string with an amount of hours, minutes and seconds
  // Based on http://jsfiddle.net/StevenIseki/apg8yx1s/
  // This can be rewritten with Intl.DurationFormat when browser support is better, but it would be about as complex anyway
  const absSeconds = Math.abs(totalSeconds);
  const hours = Math.floor(absSeconds / 3600);
  const minutes = Math.floor((absSeconds - hours * 3600) / 60);
  const seconds = Math.floor(absSeconds - hours * 3600 - minutes * 60);

  const timeArray = [minutes, seconds];
  if (hours > 0) {
    timeArray.unshift(hours);
  }

  const prefix = totalSeconds < 0 ? "-" : "";

  const timeString = timeArray
    .map((num) => String(isNaN(num) ? 0 : num).padStart(2, "0"))
    .join(":");

  return `${prefix}${timeString}`;
};

export function getTimeString(epochDate: number): string {
  return new Intl.DateTimeFormat(undefined, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  }).format(new Date(epochDate));
}
