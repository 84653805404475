import Channels from "../icons/channels.svg";
import { useTranslations } from "../providers/TranslationsProvider";
import { PlayerControlLabelButton } from "./PlayerControlLabelButton";

const ChannelsButton = () => {
  const translations = useTranslations();

  const sendToggleEvent = (e: React.MouseEvent) => {
    e.stopPropagation();
    document.dispatchEvent(new CustomEvent("toggleChannelsOverlay"));
  };

  return (
    <PlayerControlLabelButton
      aria-label={"toggle channels overlay"}
      onClick={sendToggleEvent}
      Icon={<Channels />}
      label={translations("channels")}
    />
  );
};

export { ChannelsButton };
