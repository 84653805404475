import "@tv4/avod-web-player-core/dist/index.css";
import "./player.scss";

import {
  AWPError,
  Capabilities,
  ChromecastContentUpdatedPayload,
  ChromecastSessionEndedPayload,
  CoreEvents,
  CoreEventsMap,
  DrmLicenseErrorPayload,
  ensureAWPError,
  ERROR_CATEGORY,
  ErrorLevel,
  EventEmitter,
  getImageProxyUrl,
  getRemoteConfigValue,
  getSelectedDrm,
  getUserId,
  initRemoteConfig,
  isMtvOrUutisetService,
  Media,
  Metadata,
  PauseOptions,
  PlaybackErrorPayload,
  PlaybackMode,
  PlaybackModeChangePayload,
  PlaybackModeSetPayload,
  PlaybackState,
  PLAYER_ERROR,
  PlayerMode,
  PlayResponse,
  resolveMediaType,
  Service,
  setRemoteConfigValue,
  storage,
  UserTier,
  uuid,
} from "@tv4/avod-web-player-common";
import {
  Core,
  getRemainingAdImmunity,
  setAdImmunity,
  TCoreLoadOptions,
} from "@tv4/avod-web-player-core";
import { canPlayVideo } from "@tv4/avod-web-player-device-capabilities";
import { PlaybackAPI, StreamInfoService } from "@tv4/avod-web-player-http";
import {
  ContentMetadata,
  CustomButton,
  ReactNode,
  SkinController,
} from "@tv4/avod-web-player-skin";
import {
  disableCdnBalancer,
  enableCdnBalancer,
  ITrackerSetupOptions,
  NpawTracker,
  Tracker,
} from "@tv4/avod-web-player-tracking";

import { TrackingManagerProxy } from "./TrackingManagerProxy";
import { AssetLoadOptions, PlayerLoadOptions } from "./types";
import { mapPlaybackAssetToMetadata, mapPlayResponse } from "./util/services";
import {
  createFinnpanelTracking,
  createGtmCoreTracking,
  createKilkayaTrackingIfNeeded,
  createNielsenTracking,
  createNpawInitOptions,
  createWireVideoTracking,
} from "./util/webPlayerTracking";

type TAspectRatio = "9x16" | "16x9";

type TPlayerOptions = {
  fullscreenElementId?: string;
  autoplay?: boolean;
  gdprConsent?: string;
  refreshToken?: string;
  iosNativeFullscreen?: boolean;
  poster?: string;
  castId?: string;
  loopVideo?: boolean;
  onBackClick?: () => void;
  onCloseClick?: () => void;
  onExitClick?: () => void;
  onVideoClick?: () => void;
  onUserSelectedPlaybackRate?: (rate: number) => void;
  onShareClick?: () => void;
  service: Service;
  /**
   * getAccessToken - return an access token if user is signed in, or undefined
   * if no session exists
   */
  getAccessToken: () => string | undefined;
  appName:
    | "Nordic HTML5"
    | "Fotbollskanalen HTML5"
    | "Köket HTML5"
    | "Köket Kurser HTML5"
    | "TV4 Nyheterna HTML5"
    | "MTV Uutiset HTML5"
    | "Demo HTML5";
  environment: "production" | "development";
  appVersion: string;
  playbackApiURL?: string;
  playbackApiIncludeCredentials?: boolean;
  playerMode?: PlayerMode;
  muted?: boolean;
  hideMetadataOutsideFullscreen?: boolean;
  adUuid?: string;
  hideSubtitlesMenu?: boolean;
  showMobileMuteButton?: boolean;
  playerProgressTintColor?: string;
  noUi?: boolean;
  noInitialLoadSpinner?: boolean;
  aspectRatio?: TAspectRatio;

  enableCast: boolean;
  enableAirplay: boolean;
  enableFullscreen: boolean;
  enableShortcuts?: boolean;

  getContentMetadata?: (assetId: string) => Promise<ContentMetadata>;
  customButton?: ReactNode;
};

const defaultOptions: Partial<TPlayerOptions> = {
  playerMode: PlayerMode.DEFAULT,
  getAccessToken: () => undefined,
  enableCast: true,
  enableAirplay: true,
  enableFullscreen: true,
  environment: "production",
  enableShortcuts: true,
};

const PLAYER_NAME = "web-player";

type NumberBetween0And1 = number;

const DESTROYED_ERROR =
  "WebPlayer instance destroyed. Calls to this method after destroying the player indicates bugs in the player integration.";

let numPlayerInstances = 0;

class WebPlayer extends EventEmitter<CoreEventsMap> {
  private destroyed = false;
  private core: Core;
  private skinController?: SkinController;
  private playbackApi: PlaybackAPI;
  private trackingManagerProxy?: TrackingManagerProxy;
  private npawSessionGroupId?: string;
  private allowDrmLicenseRefetch = true;
  private userId: string;

  private options!: TPlayerOptions; // ! assigned in method called from constructor

  private media?: Media;
  private metadata?: Metadata;
  private capabilities: Capabilities = {
    seek: true,
    pause: true,
    pause_ads: true,
    skip_ads: false,
    stream_switch: false,
  };
  private startOverMedia?: Media;
  private originMedia?: Media;
  private currentAssetId?: string;
  private currentSource?: string;
  private adTags?: string;
  private noAds?: boolean;
  private userInitiatedPlayback = false;
  private service: Service;
  private streamInfoService?: StreamInfoService;
  private userTier?: UserTier;

  private container: HTMLElement;
  private mutableState = {
    cancelled: false,
  };

  constructor(root: HTMLElement, opts: TPlayerOptions) {
    super();

    numPlayerInstances++;

    if (numPlayerInstances === 1) {
      enableCdnBalancer();
    } else {
      disableCdnBalancer();
    }

    initRemoteConfig(opts.service);

    this.service = opts.service || getRemoteConfigValue("SERVICE");

    // Check localstorage cast receiver id override, for testing purposes
    // ex: localStorage.setItem("player.CCRAID", '"ASDF1234"')
    // Note that it must be set to a JSON.stringified string
    const CAST_ID_KEY = "CCRAID"; // Chromecast Custom Receiver App Id
    const castId = storage.getData<string>(CAST_ID_KEY) || opts?.castId;

    this.userId = getUserId(opts.getAccessToken()) || "";

    this.updateOptions({
      ...defaultOptions,
      ...opts,
      castId,
    });

    this.playbackApi = new PlaybackAPI({
      ...this.options,
      url:
        this.options.playbackApiURL || getRemoteConfigValue("PLAYBACK_API_URL"),
      includeCredentials: this.options.playbackApiIncludeCredentials,
    });

    this.container = this.createContainerElement({
      aspectRatio: this.options.aspectRatio,
    });
    root.appendChild(this.container);

    if (this.options.environment !== "production") {
      console.log("\n\n!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      console.log("!!!!!!!!! DEV MODE !!!!!!!!!");
      console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!\n\n\n");
      const devNote = document.createElement("div");
      devNote.innerText = "DEV MODE";
      devNote.classList.add("dev_mode_notification");
      this.container.appendChild(devNote);
    }

    this.core = new Core(this.container, {
      ...this.options,
      loopVideo: this.options.loopVideo ?? false,
      fullscreenElementId:
        this.options.fullscreenElementId || this.container.id,
      playerMode: this.options.playerMode || PlayerMode.DEFAULT,
      userId: this.userId,
    });

    this.core.onAll((eventType, data) => {
      this.emit(eventType, data);

      this.trackingManagerProxy?.on(eventType, data);

      this.core.notifyCoreEvent(eventType, data);

      switch (eventType) {
        case CoreEvents.PLAYBACK_MODE_CHANGE:
          return this.handlePlaybackModeChange(
            data as PlaybackModeChangePayload
          );
        case CoreEvents.USER_ACTIVE_CONFIRM:
          this.core.getControls()?.play?.();
          return this.skinController?.update({ showInactivePrompt: false });
        case CoreEvents.USER_ACTIVE_DECLINE:
          return this.skinController?.update({ showInactivePrompt: false });
        case CoreEvents.CHROMECAST_SESSION_STARTED:
          return this.reset();
        case CoreEvents.CHROMECAST_CONTENT_UPDATED:
          return this.handleChromecastContentUpdate(
            data as ChromecastContentUpdatedPayload
          );
        case CoreEvents.CHROMECAST_SESSION_ENDED:
          return this.resumePlaybackFromChromecast(
            data as ChromecastSessionEndedPayload
          );
        case CoreEvents.DRM_LICENSE_ERROR:
          return this.handleDrmLicenseError(data as DrmLicenseErrorPayload);
        case CoreEvents.AUTOPLAY_BLOCKED:
          if (this.options.playerMode !== PlayerMode.PREVIEW) return;

          this.core.getControls()?.mute?.();
          requestAnimationFrame(() => {
            this.core.getControls()?.play?.();
          });
          break;
        case CoreEvents.ERROR:
          if (this.destroyed) return;

          if (data && "error" in data && data.error?.fatal) {
            // give the user a grace period if the asset
            // fatally errors
            if (this.currentAssetId) {
              setAdImmunity(this.currentAssetId);
            }

            this.reset();
          }
          break;
        default:
          break;
      }
    });

    if (!this.options.noUi) {
      this.skinController = new SkinController({
        core: this.core,
        root: this.container,
        onPlayWhenEnded: () => this.restartAsset(true),
        enableShortcuts: this.options.enableShortcuts ?? true,
      });

      // SkinController will not accept all options in constructor
      this.updateSkinController();
    }
  }

  private resumePlaybackFromChromecast(data: ChromecastSessionEndedPayload) {
    const { contentId, currentTime } = data;
    if (!contentId) {
      return;
    }
    this.load({
      assetId: contentId,
      startTime: currentTime,
      userInitiatedPlayback: false,
    });
  }

  private setupTracking(
    media: Media,
    metadata: Metadata,
    playbackMode: PlaybackMode,
    trackers: Tracker[]
  ) {
    if (!media || !metadata) {
      return;
    }

    this.discardTrackingManagerProxy();

    if (metadata.tracking.GA) {
      const gtmCoreTracking = createGtmCoreTracking({
        trackingMetadata: metadata.tracking.GA,
      });
      trackers.push(gtmCoreTracking);
    }

    const kilkayaTracking = createKilkayaTrackingIfNeeded({
      trackingMetadata: metadata.tracking.linkPulse,
    });

    if (kilkayaTracking) {
      trackers.push(kilkayaTracking);
    }
    if (metadata.tracking.nielsen) {
      trackers.push(
        createNielsenTracking({
          trackingMetadata: metadata.tracking.nielsen,
          userInitiatedPlayback: this.userInitiatedPlayback,
        })
      );
    }
    if (metadata.tracking.videoTracking) {
      trackers.push(
        createWireVideoTracking({
          config: metadata.tracking.videoTracking,
          getAccessToken: this.options.getAccessToken,
        })
      );
    }
    if (isMtvOrUutisetService(this.service)) {
      trackers.push(createFinnpanelTracking(this.service));
    }
    this.trackingManagerProxy = new TrackingManagerProxy(
      [
        // new DebugTracker(),
        ...trackers,
      ],
      this.getTrackerOptions(media, metadata, playbackMode)
    );
  }

  private getTrackerOptions(
    media?: Media,
    metadata?: Metadata,
    playbackMode?: PlaybackMode
  ): ITrackerSetupOptions {
    return {
      sessionId: this.core.sessionId,
      media,
      metadata,
      playbackMode,
      playerMode: this.options.playerMode,
      npawSessionGroupId: this.npawSessionGroupId,
      userId: this.userId,
      userTier: this.userTier,
    };
  }
  // This method is used to report PlaybackApi errors to npaw that occur before the TrackingManagerProxy has been created
  private handleStartupMetadataAndMediaError(
    npawTracker: NpawTracker,
    error: AWPError
  ) {
    console.log("Failed to get metadata and media", error);
    npawTracker.setup({
      npawSessionGroupId: null, // define here to avoid typescript error, overridden with value from getTrackerOptions.
      ...this.getTrackerOptions(),
      assetId: this.currentAssetId,
      userId: this.userId,
    });

    const errorPayload: PlaybackErrorPayload = {
      currentTime: 0,
      duration: 0,
      error,
      isInAdBreak: false,
    };
    npawTracker.onError(errorPayload);
    this.core.emitCoreEvent(CoreEvents.ERROR, errorPayload);
  }

  private updateOptions(options: Partial<TPlayerOptions>) {
    this.options = {
      ...this.options,
      ...options,
    };

    setRemoteConfigValue("ACCESS_TOKEN", this.options.getAccessToken() || "");
  }

  public async setOptions(options: Partial<TPlayerOptions>) {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    this.updateOptions(options);

    this.core.setOptions({
      ...this.options,
      userId: this.userId,
      fullscreenElementId:
        this.options.fullscreenElementId || this.container.id,
    });

    this.updateSkinController();
  }

  private updateSkinController(): void {
    this.skinController?.update({
      ...this.options,
      asset: this.metadata?.asset,
      poster: getImageProxyUrl(
        this.options.poster || this.metadata?.asset?.image || undefined
      ),
      service: this.service,
      thumbnails: this.media?.thumbnails,
    });
  }

  private createContainerElement({
    aspectRatio,
  }: {
    aspectRatio?: TAspectRatio;
  }) {
    const container = document.createElement("div");
    container.id = "container";
    container.classList.add("avod-web-player-container");

    container.style.setProperty(
      "--aspect-ratio",
      aspectRatio === "9x16" ? "177.77777778%" : "56.25%"
    );

    return container;
  }
  public getChromeCastManager() {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    return this.core.getChromeCastManager();
  }

  public restartAsset(userInitiatedPlayback: boolean) {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    // TODO throwing here does not do much. This should be emitted as
    //  a fatal error.
    if (!this.currentAssetId) {
      if (this.currentSource) {
        return this.loadSrc(this.currentSource);
      }
      throw new AWPError({
        context: "player",
        message: "Attempted restart without asset id.",
        category: ERROR_CATEGORY.DEFAULT,
        code: PLAYER_ERROR.WRONG_INPUT,
        errorLevel: ErrorLevel.CLIENT,
      });
    }

    this.setOptions({ autoplay: true });
    this.load({
      assetId: this.currentAssetId,
      enableVisuals: this.skinController?.getEnabledVisuals(),
      userInitiatedPlayback,
    });
  }

  private async handleChromecastContentUpdate(data: PlayResponse["metadata"]) {
    this.currentAssetId = data.id;
    this.metadata = mapPlaybackAssetToMetadata({
      metadata: data,
      id: data.id,
      trackingData: {
        videoplaza: {
          contentId: data.id,
          tags: "",
          shares: "",
          contentForm: "",
        },
      },
    });

    this.updateSkinController();
  }

  public async load(options: PlayerLoadOptions) {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    this.reset();

    this.adTags = options.adTags;
    this.noAds = options.noAds;
    this.userInitiatedPlayback = options.userInitiatedPlayback;

    if (
      "assetId" in options &&
      (await this.core.isCasting(this.options.playerMode))
    ) {
      if (this.destroyed) return;

      this.handleCastSessionPlayback(
        options.assetId,
        options.useStartOver ? 0 : options.startTime
      );

      return;
    }

    // generate a new session id each time load is called, used for the wire tracking session
    this.core.generateTrackingSessionId();

    if ("src" in options) {
      this.currentAssetId = undefined;
      this.currentSource = options.src;
      this.loadSrc(options.src, options.startTime);
    } else {
      this.currentSource = undefined;
      this.currentAssetId =
        "assetId" in options ? options.assetId : options.asset.id;

      let npawTracker: NpawTracker | undefined;

      if (
        "assetId" in options ||
        // do not init if pre-fetched asset has no trackingdata
        ("asset" in options && options.asset.trackingData.youbora)
      ) {
        npawTracker = new NpawTracker(
          createNpawInitOptions({
            playerName: PLAYER_NAME,
            userId: this.userId,
            appVersion: this.options.appVersion,
            appName: this.options.appName,
            assetId: this.currentAssetId,
            environment: this.options.environment,
          })
        );
      }

      try {
        const asset =
          "asset" in options
            ? options.asset
            : await this.playbackApi.playRequest(options.assetId);

        if (this.destroyed) return;

        if (!asset.trackingData.youbora) {
          disableCdnBalancer();
        }

        await this.loadAsset({
          ...options,
          asset,
          npawTracker,
        });

        if (this.destroyed) return;
      } catch (e) {
        if (this.destroyed) return;

        // TODO e is not guaranteed to be AWP error
        if (npawTracker) {
          this.handleStartupMetadataAndMediaError(npawTracker, e as AWPError);
        }
      }
    }

    if (options.enableVisuals) {
      this.skinController?.update({ enableVisuals: options.enableVisuals });
    }
  }

  private async loadAsset(
    options: AssetLoadOptions & { npawTracker?: NpawTracker }
  ) {
    this.allowDrmLicenseRefetch = true; // Reset this flag when loading a new asset
    this.npawSessionGroupId = uuid(); // This should only change when consumer loads a new asset

    const { metadata, media, capabilities, originMedia, startOverMedia } =
      mapPlayResponse({
        playbackApi: this.playbackApi,
        asset: options.asset,
      });

    this.media = media;
    this.metadata = metadata;
    this.capabilities = capabilities;
    this.startOverMedia = startOverMedia;
    this.originMedia = originMedia;
    this.userTier = options.asset.userTier;
    const { streaminfoUrl } = options.asset.playbackItem;

    if (!this.metadata || !metadata) return;

    if (capabilities.stream_switch && streaminfoUrl) {
      this.streamInfoService = new StreamInfoService({
        url: new URL(streaminfoUrl),
        adsRequired: !this.capabilities.skip_ads,
      });
      this.streamInfoService.setup(
        ({ streamInfo, adMarkers, adMarkersChanged }) => {
          this.core.emitCoreEvent(CoreEvents.STREAM_INFO_UPDATED, streamInfo);
          if (adMarkersChanged) {
            this.core.emitCoreEvent(CoreEvents.AD_MARKERS_UPDATED, {
              adMarkers,
            });
          }
        }
      );
    }

    this.updateSkinController();

    // TODO: Use a setting to achieve "current behavior" (necessary changes/best approach
    // need to be investigated)
    let initialPlaybackMode = PlaybackMode.DEFAULT;
    if (this.streamInfoService) {
      if (startOverMedia && options.useStartOver) {
        initialPlaybackMode = PlaybackMode.START_OVER;
      } else if (
        this.core.getState().playbackMode === PlaybackMode.ORIGIN ||
        // fallback to origin if startOverMedia is not available
        options.useStartOver
      ) {
        if (options.useStartOver) {
          // start from the beginning if startOver was requested
          options.startTime = 0;
        }
        initialPlaybackMode = PlaybackMode.ORIGIN;
      } else if (media?.isLive && media?.isStitched) {
        initialPlaybackMode = PlaybackMode.LIVE_DAI;
      }
    }
    const playbackModeSetPayload: PlaybackModeSetPayload = {
      playbackMode: initialPlaybackMode,
    };
    this.core.emitCoreEvent(
      CoreEvents.PLAYBACK_MODE_SET,
      playbackModeSetPayload
    );
    const initialMedia =
      {
        [PlaybackMode.START_OVER]: startOverMedia,
        [PlaybackMode.ORIGIN]: originMedia,
      }[initialPlaybackMode] || media;

    this.setupTracking(
      initialMedia,
      metadata,
      initialPlaybackMode,
      options.npawTracker ? [options.npawTracker] : []
    );

    await this.loadCore({
      media: initialMedia,
      startTime: options.startTime,
    });

    if (this.options.environment !== "production") {
      const remainingAdImmunity = getRemainingAdImmunity(
        this.metadata?.asset.id,
        getRemoteConfigValue("AD_IMMUNITY_DURATION")
      );
      if (remainingAdImmunity) {
        console.log(
          "[Ad immunity]",
          new Date().toLocaleTimeString(),
          `✅ Reloaded Web Player with ${remainingAdImmunity} seconds remaining ad immunity for asset`,
          this.metadata?.asset.id
        );
      }
    }
  }

  private async handleCastSessionPlayback(assetId: string, startTime?: number) {
    const castManager = this.core.getChromeCastManager();
    const sessionState = castManager?.getCurrentChromecastSessionState();
    if (!sessionState) return;

    const sessionAssetId = sessionState.content?.contentId;

    if (
      !sessionState.session ||
      (sessionAssetId && sessionAssetId === assetId)
    ) {
      if (!sessionState.session) {
        console.warn(
          "Chromecast is connected, but there is no session, did chromecast crash?",
          { sessionState }
        );
      }
      return;
    }

    this.core.emitCoreEvent(CoreEvents.CHROMECAST_CONNECTION_STATUS, {
      isConnected: true,
    });

    this.currentAssetId = assetId;
    this.changeChromecastProgram(assetId, startTime);
  }

  private async loadCore({
    media,
    startTime,
    autoplay,
  }: {
    media: Media;
    startTime?: number;
    autoplay?: boolean;
  }): Promise<void> {
    /**
     * core.load will initialize pause ads which relies on skin being rendered,
     * so if core.load is called before skin has rendered, execution will crash.
     * loadAsset will make requests to load content before calling core.load,
     * so skin will have enough time to render.
     * loadSrc will directly call core.load with url before skin has had time to
     * render.
     * ideally core should not assume that skin has rendered arbitrary dom
     * content, but for now always wait for skin to complete render before
     * core.load is called.
     */
    const options: TCoreLoadOptions = {
      media,
      startTime,
      autoplay,
      availablePlaybackModes: this.getAvailablePlaybackModes(),
      capabilities: this.capabilities,
      metadata: this.metadata,
      adTags: this.adTags,
      noAds: this.noAds,
      streamInfoService: this.streamInfoService,
    };
    try {
      await this.skinController?.awaitRender();

      if (this.destroyed) return;

      await this.core.load(options);
    } catch (originalError) {
      if (this.destroyed) return;

      if (
        originalError instanceof AWPError &&
        this.originMedia &&
        options.media !== this.originMedia
      ) {
        options.media = this.originMedia;
        // TODO: emit the error as non-fatal here, so that we can see it in npaw,
        // but it must not count for the AFS

        // Reset core before emitting PLAYBACK_MODE_SET, since it would
        // otherwise be reset to default.
        this.core.reset();

        this.core.emitCoreEvent(CoreEvents.PLAYBACK_MODE_SET, {
          playbackMode: PlaybackMode.ORIGIN_FALLBACK,
        });

        return this.loadCore(options);
      }

      if (this.destroyed) return;

      const error = ensureAWPError(originalError);
      this.emit(CoreEvents.LOAD_PLAYBACK_ERROR, { error });
    }
  }

  private loadSrc(src: string, startTime?: number) {
    this.reset();
    this.loadCore({
      media: {
        manifestUrl: src,
        type: resolveMediaType(src),
        isLive: false,
        isStartOver: false,
        isStitched: false,
        loadPreference: "speed",
      },
      startTime,
    });
  }

  private async changeChromecastProgram(assetId: string, startTime?: number) {
    const { activeAudioTrack } = this.core.getState();
    const { getAccessToken, refreshToken } = this.options;

    const remainingAdImmunity = getRemainingAdImmunity(
      assetId,
      getRemoteConfigValue("AD_IMMUNITY_DURATION")
    );

    await this.core.getChromeCastManager()?.cast({
      assetId,
      title: "",
      currentTime: startTime,
      // TODO Rework, make cast only use refresh token
      accessToken: getAccessToken(),
      refreshToken,
      remainingAdImmunity,
      preferredAudioLanguage: activeAudioTrack?.language,
    });

    if (this.destroyed) return;

    this.core.emitCoreEvent(CoreEvents.PLAYBACK_MODE_SET, {
      playbackMode: PlaybackMode.DEFAULT,
    });
  }

  public play() {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    const controls = this.core.getControls();
    controls?.play?.();
  }

  public pause(options: PauseOptions) {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    const controls = this.core.getControls();
    controls?.pause?.(options);
  }

  public seek(targetInSeconds: number) {
    this.core.getControls()?.seekTo?.(targetInSeconds, false);
  }

  public getPositionInSeconds(): number {
    return this.core.getState().currentTime;
  }

  public mute() {
    this.core?.getControls()?.mute?.();
  }

  public unmute() {
    this.core?.getControls()?.unmute?.();
  }

  public setVolume(vol: NumberBetween0And1) {
    if (vol > 1 || vol < 0 || Number.isNaN(vol)) {
      throw new Error(`Bad number provided to volume controls: ${vol}`);
    }
    this.core?.getControls()?.setVolume?.(vol);
  }

  public setPlaybackRate(rate: number) {
    this.core.getControls()?.setPlaybackRate(rate);
  }

  public toggleFullscreen() {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    const controls = this.core.getControls();
    controls?.toggleFullscreen?.();
  }

  public enterFullscreen() {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    const controls = this.core.getControls();
    controls?.enterFullscreen?.();
  }

  public exitFullscreen() {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    const controls = this.core.getControls();
    controls?.exitFullscreen?.();
  }

  public showSkin() {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    this.skinController?.update({
      forceShowSkin: true,
    });
  }

  public hideSkin() {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    this.skinController?.update({
      forceShowSkin: false,
    });
  }

  public isFullscreen() {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    return this.core.getState().isFullscreen;
  }

  public isStartOver() {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    return this.core.getState().playbackMode === PlaybackMode.START_OVER;
  }

  public isPlaying() {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    return this.core.getState().playbackState === PlaybackState.PLAYING;
  }

  public static async isBrowserSupported(
    checkDrmSupport = false
  ): Promise<boolean> {
    const supported = canPlayVideo();
    if (!supported) return false;

    if (checkDrmSupport) {
      return !!(await getSelectedDrm());
    }

    return true;
  }

  public inactiveUsageDetected() {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    this.skinController?.update({
      showInactivePrompt: true,
    });
    this.core.getControls()?.pause?.({ programmatic: true });
  }

  private getAvailablePlaybackModes(): PlaybackMode[] {
    if (this.streamInfoService && this.media && this.originMedia) {
      const playbackModes: PlaybackMode[] = [
        PlaybackMode.ORIGIN,
        PlaybackMode.LIVE_DAI,
      ];
      if (this.startOverMedia) playbackModes.push(PlaybackMode.START_OVER);
      return playbackModes;
    }
    return [PlaybackMode.DEFAULT];
  }

  private handlePlaybackModeChange(payload: PlaybackModeChangePayload) {
    if (!this.media || !this.metadata || !this.currentAssetId) return;

    let { playbackMode, originStartTime } = payload;
    let chosenMedia = this.media;

    // If yospace startover can be disabled on the back-end, then try to fallback on origin with startTime 0
    if (
      playbackMode === PlaybackMode.START_OVER &&
      (!this.startOverMedia || !this.metadata.asset.startOver)
    ) {
      playbackMode = PlaybackMode.ORIGIN;
      originStartTime = 0;
    }

    if (playbackMode === PlaybackMode.START_OVER && this.startOverMedia) {
      chosenMedia = this.startOverMedia;
    } else if (playbackMode === PlaybackMode.ORIGIN && this.originMedia) {
      chosenMedia = this.originMedia;
      this.streamInfoService?.update();
    }

    // If startover is requested when there's no startover media and we fallback to live, we change playback mode to reflect this.
    // We might actually want the fallback to be Origin in this case?
    if (
      playbackMode === PlaybackMode.START_OVER &&
      chosenMedia === this.media
    ) {
      playbackMode = PlaybackMode.LIVE_DAI;
    }

    this.core.setVideoFrameToBackground();
    this.core.reset({ modeSwitch: true });

    this.setupTracking(chosenMedia, this.metadata, playbackMode, [
      new NpawTracker(
        createNpawInitOptions({
          playerName: PLAYER_NAME,
          userId: this.userId,
          appVersion: this.options.appVersion,
          appName: this.options.appName,
          assetId: this.currentAssetId,
          environment: this.options.environment,
        })
      ),
    ]);

    this.core.emitCoreEvent(CoreEvents.PLAYBACK_MODE_SET, {
      playbackMode,
    });

    this.loadCore({
      media: chosenMedia,
      startTime: originStartTime,
      autoplay: payload.autoplay,
    });
  }

  private async handleDrmLicenseError(drmLicenseError: DrmLicenseErrorPayload) {
    // This function should only be called when there is a DRM license error for currently loaded media, so if media doesn't contain a license, we should do nothing
    if (!this.currentAssetId || !this.media?.license) return;

    if (!this.allowDrmLicenseRefetch)
      return this.core.emitCoreEvent(CoreEvents.ERROR, drmLicenseError);

    // Reset immediately to kill the ongoing session
    this.core.reset();

    this.allowDrmLicenseRefetch = false; // Only allow license refetch once per top-level load or when the license has expired

    // TODO this timeout needs to be saved and cleared on destroy/reset
    window.setTimeout(
      () => {
        this.allowDrmLicenseRefetch = true;
      },
      10 * 60 * 1000
    ); // Allow license refetch again after 10 minutes, when it's expected that the last license fetched has expired

    let media: Media | undefined;

    try {
      const metadataAndMedia = mapPlayResponse({
        playbackApi: this.playbackApi,
        asset: await this.playbackApi.playRequest(this.currentAssetId),
      });

      if (this.destroyed) return;

      if (metadataAndMedia) {
        media = metadataAndMedia.media;
      }
    } catch (playbackApiError) {
      if (this.destroyed) return;

      const errorPayload: PlaybackErrorPayload = {
        ...drmLicenseError,
        error: playbackApiError as AWPError,
      };
      return this.core.emitCoreEvent(CoreEvents.ERROR, errorPayload);
    }

    if (this.destroyed) return;

    // If fetching the license was successful, update the license for all media (same license is shared by all)
    if (media?.license) {
      [this.media, this.originMedia, this.startOverMedia].forEach(
        (existingMedia) => {
          if (existingMedia?.license) {
            existingMedia.license = media?.license;
          }
        }
      );
    }
    let chosenMedia = this.media;
    const wasPlaying = this.isPlaying();
    const playbackMode = this.core.getState().playbackMode;

    if (playbackMode === PlaybackMode.START_OVER && this.startOverMedia) {
      chosenMedia = this.startOverMedia;
    } else if (
      (playbackMode === PlaybackMode.ORIGIN ||
        playbackMode === PlaybackMode.ORIGIN_FALLBACK) &&
      this.originMedia
    ) {
      chosenMedia = this.originMedia;
    }

    this.loadCore({
      media: chosenMedia,
      startTime: drmLicenseError.currentTime,
      autoplay: wasPlaying,
    });
  }

  private discardTrackingManagerProxy(): void {
    this.trackingManagerProxy?.destroy();
    this.trackingManagerProxy = undefined;
  }
  public reset(): void {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    // Cancel ongoing playback api requests since it holds a reference
    // to this mutable object.
    this.mutableState.cancelled = true;

    // create a new mutableState object to reset the state
    // for the next playback session.
    this.mutableState = {
      cancelled: false,
    };

    this.core.reset();
    this.media = undefined;
    this.metadata = undefined;
    this.startOverMedia = undefined;
    this.updateSkinController();
    this.discardTrackingManagerProxy();
    this.streamInfoService?.destroy();
  }

  public override destroy(): void {
    if (this.destroyed) {
      throw new Error(DESTROYED_ERROR);
    }

    numPlayerInstances--;

    if (numPlayerInstances === 1) {
      enableCdnBalancer();
    }

    this.playbackApi.destroy();

    this.destroyed = true;
    this.mutableState.cancelled = true;

    super.destroy();
    this.skinController?.destroy();
    this.discardTrackingManagerProxy();
    this.core.destroy();
    this.streamInfoService?.destroy();
    this.container.remove();
  }
}

export { CustomButton, PlaybackAPI, WebPlayer };
export type { TPlayerOptions };
