import type { WebSender } from "@tv4/avod-web-player-chromecast";
import { AdMarker, StateChangedPayload } from "@tv4/avod-web-player-common";
import {
  AWPError,
  CoreEvents,
  DEFAULT_PLAYER_STATE,
  IAd,
  IAdBreak,
  PlayerState,
  TControls,
} from "@tv4/avod-web-player-common";
import type { Core, TCoreOptions } from "@tv4/avod-web-player-core";
import { useEffect, useState } from "react";

const useCore = (core: Core) => {
  const [playerState, setPlayerState] =
    useState<PlayerState>(DEFAULT_PLAYER_STATE);

  const [adMarkers, setAdMarkers] = useState<AdMarker[]>([]);
  const [currentAdBreak, setCurrentAdBreak] = useState<IAdBreak>();
  const [currentAd, setCurrentAd] = useState<IAd>();
  const [controls, setControls] = useState<TControls>(null);
  const [error, setError] = useState<AWPError | null>(null);
  const [options, setOptions] = useState<TCoreOptions>(core.getOptions());
  const [chromeCastManager, setChromeCastManager] = useState<
    WebSender | undefined
  >(core.getChromeCastManager());

  useEffect(() => {
    core.on(CoreEvents.STATE_CHANGED, ({ state }: StateChangedPayload) => {
      setPlayerState(state);
    });
    core.on(CoreEvents.RESET, (payload) => {
      if (!payload.modeSwitch) {
        setAdMarkers([]);
      }
      setCurrentAdBreak(undefined);
      setCurrentAd(undefined);
    });
    core.on(CoreEvents.LOADED_PLAYBACK, () => {
      // Error needs to be reset on loaded instead of reset because we reset the player after an error
      setError(null);
      setControls(core.getControls());
    });
    core.on(CoreEvents.ERROR, ({ error: e }) => {
      setError(e);
    });
    core.on(CoreEvents.LOAD_PLAYBACK_ERROR, ({ error: e }) => {
      setError(e);
    });
    core.on(CoreEvents.CORE_OPTIONS_CHANGED, () => {
      setOptions(core.getOptions());
    });
    core.on(CoreEvents.AD_START, ({ ad }) => {
      setCurrentAd(ad);
    });
    core.on(CoreEvents.AD_RESUME, ({ ad }) => {
      setCurrentAd(ad);
    });
    core.on(CoreEvents.AD_MARKERS_UPDATED, (payload) => {
      setAdMarkers(payload.adMarkers);
    });
    core.on(CoreEvents.BREAK_START, ({ adBreak }) => {
      setCurrentAdBreak(adBreak);
    });
    core.on(CoreEvents.BREAK_END, () => {
      setCurrentAdBreak(undefined);
      setCurrentAd(undefined);
    });
    core.on(CoreEvents.CHROMECAST_AD_START, ({ ad }) => {
      setCurrentAd(ad);
    });
    core.on(CoreEvents.CHROMECAST_SENDER_LOADED, () => {
      setChromeCastManager(core.getChromeCastManager());
    });
  }, [core]);

  useEffect(() => {
    if (!chromeCastManager) return;

    chromeCastManager.on(
      CoreEvents.CHROMECAST_CONNECTION_STATUS,
      ({ isConnected }) => {
        setControls(
          isConnected ? chromeCastManager.getControls() : core.getControls()
        );
      }
    );
  }, [chromeCastManager, core]);

  return {
    controls,
    playerState,
    adMarkers,
    currentAdBreak,
    currentAd,
    chromeCastManager,
    options,
    error,
  };
};

export default useCore;
