import Core from '../core/Core';
import Util from '../core/utils/Util';
import version from '../analytics/version';
import RequestMethod from '../analytics/constants/requestMethod';
import UUIDGenerator from '../analytics/deviceUUID/hashgenerator';
export default class CommonGetters {
    constructor(options) {
        this.options = options;
    }
    /**
     * Returns Username
     */
    getUsername() {
        if (this.options['userId']) {
            return this.options['userId'];
        }
        return this.options['user.name'];
    }
    /**
     * Returns ProfileID
     */
    getProfileId() {
        return this.options['profileId'];
    }
    /**
     * Returns content's PluginVersion
     *
     */
    getPluginVersion() {
        return Core.getPluginVersion();
    }
    /**
     * Returns requestNumber value, to prevent /data calls being cached
     *
     */
    getRequestNumber() {
        return Math.random();
    }
    /** COMMONS GETTERS */
    getUserType() {
        return this.options['user.type'];
    }
    /**
     * Returns AnonymousUser
     *
     */
    getAnonymousUser() {
        return this.options['user.anonymousId'];
    }
    /**
     * Get URL referer.
     *
     */
    getReferer() {
        let ret = this.options.referer || '';
        if (!ret && typeof window !== 'undefined' && window.location) {
            ret = window.location.href;
        }
        return ret;
    }
    /**
     * Get URL referral.
     * Previous page url.
     */
    getReferral() {
        let ret = this.options.referral || '';
        if (!ret && typeof document !== 'undefined') {
            ret = document.referrer;
        }
        return ret;
    }
    getPageName() {
        let ret = this.options.title || '';
        if (!ret && typeof document !== 'undefined') {
            ret = document.title;
        }
        return ret;
    }
    /**
     * Get Browser language
     *
     */
    getLanguage() {
        return typeof navigator !== 'undefined' ? navigator.language : undefined;
    }
    /**
     * Get Screen resolution
     *
     */
    getScreenResolution() {
        let ret = undefined;
        try {
            if (window && window.screen) {
                const devicePixelRatio = window.devicePixelRatio || 1;
                ret = (window.screen.width * devicePixelRatio) + 'x' + (window.screen.height * devicePixelRatio);
            }
        }
        catch (e) {
            /* empty */
        }
        return ret;
    }
    /** Returns deviceInfo json
     *
     */
    getDeviceInfo() {
        let info = {};
        if (this.getDeviceCode())
            info.deviceCode = this.getDeviceCode();
        if (this.getModel())
            info.model = this.getModel();
        if (this.getBrand())
            info.brand = this.getBrand();
        if (this.getDeviceType())
            info.deviceType = this.getDeviceType();
        if (this.getDeviceName())
            info.deviceName = this.getDeviceName();
        if (this.getOsName())
            info.osName = this.getOsName();
        if (this.getOsVersion())
            info.osVersion = this.getOsVersion();
        if (this.getBrowserName())
            info.browserName = this.getBrowserName();
        if (this.getBrowserVersion())
            info.browserVersion = this.getBrowserVersion();
        if (this.getBrowserType())
            info.browserType = this.getBrowserType();
        if (this.getBrowserEngine())
            info.browserEngine = this.getBrowserEngine();
        return Object.keys(info).length !== 0 ? info : undefined;
    }
    /**
     * Returns DeviceCode
     *
     */
    getDeviceCode() {
        return this.options['device.code'];
    }
    /**
     * Returns Device model
     *
     */
    getModel() {
        return this.options['device.model'];
    }
    /**
     * Returns Device brand
     *
     */
    getBrand() {
        return this.options['device.brand'];
    }
    /**
     * Returns Device type
     *
     */
    getDeviceType() {
        return this.options['device.type'];
    }
    /**
     * Returns Device name
     *
     */
    getDeviceName() {
        return this.options['device.name'];
    }
    /**
     * Returns Device OS name
     *
     */
    getOsName() {
        return this.options['device.osName'];
    }
    /**
     * Returns Device OS Version
     *
     */
    getOsVersion() {
        return this.options['device.osVersion'];
    }
    /**
     * Returns Device browser name
     *
     */
    getBrowserName() {
        return this.options['device.browserName'];
    }
    /**
     * Returns Device browser version
     *
     */
    getBrowserVersion() {
        return this.options['device.browserVersion'];
    }
    /**
     * Returns Device browser type
     *
     */
    getBrowserType() {
        return this.options['device.browserType'];
    }
    /**
     * Returns DeviceCode
     *
     */
    getBrowserEngine() {
        return this.options['device.browserEngine'];
    }
    getIsBlocked() {
        return this.options['ad.blockerDetected'];
    }
    isMethodPostEnabled() {
        return (this.options.method && (this.options.method.toUpperCase() === RequestMethod.POST));
    }
    getDeviceUUID() {
        let id = undefined;
        if (!this.options['device.isAnonymous']) {
            id = this.options['device.id'] || new UUIDGenerator().getKey();
        }
        return id;
    }
    /**
     * Returns content's PluginVersion
     *
     */
    getLibVersion() {
        return version;
    }
    getAppName() {
        return this.options['app.name'];
    }
    getAppReleaseVersion() {
        return this.options['app.releaseVersion'];
    }
    /**
     * Returns Ip
     *
     */
    getIp() {
        return this.options['network.ip'];
    }
    /**
     * Returns Isp
     *
     */
    getIsp() {
        return this.options['network.isp'];
    }
    /**
     * Returns ConnectionType
     *
     */
    getConnectionType() {
        return this.options['network.connectionType'];
    }
    /** Returns EDID object
     *
     */
    getEDID() {
        const edid = this.options['device.EDID'];
        return edid ? edid.toString() : undefined;
    }
    /**
     * Returns obfuscateIp option
     *
     */
    getObfuscateIp() {
        return this.options['user.obfuscateIp'];
    }
    /**
     * Returns privacy protocol option
     *
     */
    getPrivacyProtocol() {
        let ret = this.options['user.privacyProtocol'];
        if (typeof ret === 'string') {
            ret = ret.toLowerCase();
        }
        return ret === 'optin' || ret === 'optout' ? ret : undefined;
    }
    /**
     * Return custom dimensions
     *
     */
    getCustomDimensions() {
        const dim = this.options['content.customDimensions'];
        return typeof dim === 'object' ? dim : undefined;
    }
    /**
     * Returns content's Extraparam1
     *
     */
    getExtraparam1() {
        return this.options['content.customDimension.1'];
    }
    /**
     * Returns content's Extraparam2
     *
     */
    getExtraparam2() {
        return this.options['content.customDimension.2'];
    }
    /**
     * Returns content's Extraparam3
     *
     */
    getExtraparam3() {
        return this.options['content.customDimension.3'];
    }
    /**
     * Returns content's Extraparam4
     *
     */
    getExtraparam4() {
        return this.options['content.customDimension.4'];
    }
    /**
     * Returns content's Extraparam5
     *
     */
    getExtraparam5() {
        return this.options['content.customDimension.5'];
    }
    /**
     * Returns content's Extraparam6
     *
     */
    getExtraparam6() {
        return this.options['content.customDimension.6'];
    }
    /**
     * Returns content's Extraparam7
     *
     */
    getExtraparam7() {
        return this.options['content.customDimension.7'];
    }
    /**
     * Returns content's Extraparam8
     *
     */
    getExtraparam8() {
        return this.options['content.customDimension.8'];
    }
    /**
     * Returns content's Extraparam9
     *
     */
    getExtraparam9() {
        return this.options['content.customDimension.9'];
    }
    /**
     * Returns content's Extraparam10
     *
     */
    getExtraparam10() {
        return this.options['content.customDimension.10'];
    }
    /**
     * Returns content's Extraparam11
     *
     */
    getExtraparam11() {
        return this.options['content.customDimension.11'];
    }
    /**
     * Returns content's Extraparam12
     *
     */
    getExtraparam12() {
        return this.options['content.customDimension.12'];
    }
    /**
     * Returns content's Extraparam13
     *
     */
    getExtraparam13() {
        return this.options['content.customDimension.13'];
    }
    /**
     * Returns content's Extraparam14
     *
     */
    getExtraparam14() {
        return this.options['content.customDimension.14'];
    }
    /**
     * Returns content's Extraparam15
     *
     */
    getExtraparam15() {
        return this.options['content.customDimension.15'];
    }
    /**
     * Returns content's Extraparam16
     *
     */
    getExtraparam16() {
        return this.options['content.customDimension.16'];
    }
    /**
     * Returns content's Extraparam17
     *
     */
    getExtraparam17() {
        return this.options['content.customDimension.17'];
    }
    /**
     * Returns content's Extraparam18
     *
     */
    getExtraparam18() {
        return this.options['content.customDimension.18'];
    }
    /**
     * Returns content's Extraparam19
     *
     */
    getExtraparam19() {
        return this.options['content.customDimension.19'];
    }
    /**
     * Returns content's Extraparam20
     *
     */
    getExtraparam20() {
        return this.options['content.customDimension.20'];
    }
    /**
     * Returns parse Manifest Flag
     *
     */
    isParseManifest() {
        return this.options['parse.manifest'];
    }
    /**
     * Returns parse CdnNode Flag
     *
     */
    isParseCdnNode() {
        return this.options['parse.cdnNode'];
    }
    /**
     * Returns parse cdn switch Flag
     *
     */
    isCdnSwitch() {
        return this.options['parse.cdnSwitchHeader'];
    }
    /**
     * Returns Cdn list
     *
     */
    getParseCdnNodeList() {
        return this.options['parse.cdnNode.list'];
    }
    /**
     * Returns Cdn header name
     *
     */
    getParseCdnNodeNameHeader() {
        return this.options['parse.cdnNameHeader'];
    }
    /**
     * Returns header node name
     *
     */
    getParseNodeHeader() {
        return this.options['parse.cdnNodeHeader'];
    }
    /**
     * Return if is possible to overwrite adapters-json
     *
     * @returns {boolean}
     */
    canOverwriteAdapters() {
        return Util.parseBoolean(this.options['adapters.overwrite']);
    }
    /**
     * Get defined Adapter properties
     * @returns {object}
     */
    getAdaptersProperties() {
        return this.options['adapters.properties'];
    }
    /**
     * Check if AppAnalytics has auto begin before plugin creation
     * @returns {boolean}
     */
    isAppAnalyticsAutoBegin() {
        return Util.parseBoolean(this.options['check.appAnalytics.autoBegin']);
    }
    /**
     * Is AppAnalytics enabled
     * @returns {boolean}
     */
    isAppAnalyticsEnabled() {
        return Util.parseBoolean(this.options['check.appAnalytics.enabled']);
    }
    /**
     * Is VideoAnalytics enabled
     * @returns {boolean}
     */
    isVideoAnalyticsEnabled() {
        return Util.parseBoolean(this.options['check.videoAnalytics.enabled']);
    }
    /**
     * Is Configuration Refresh from LMA
     * @returns {boolean}
     */
    isRefreshLMAConfigurationEnabled() {
        return Util.parseBoolean(this.options['lma.refresh.configuration.enabled']);
    }
    /**
     * Is Plugin Logs enabled (to report QA Tools additional info)
     * @returns {boolean|*}
     */
    isPluginLogsEnabled() {
        return Util.parseBoolean(this.options['debug.pluginLogs.enabled']);
    }
    /**
     * Is Player Event Logs enabled (to report QA Tools additional info)
     * @returns {boolean|*}
     */
    isPlayerLogsEnabled() {
        return Util.parseBoolean(this.options['debug.playerLogs.enabled']);
    }
    /**
     * Time to refresh configuration object from LMA
     * @returns {boolean}
     */
    refreshLMAConfigurationSeconds() {
        return this.options['lma.refresh.configuration.seconds'];
    }
    /**
     * Return if playhead monitor is enabled
     * @returns {boolean}
     */
    getPlayheadMonitorEnabled() {
        return this.options['playhead.monitor'];
    }
    /**
     * Return if readyState monitor/check method are enabled
     * @returns {boolean}
     */
    getReadyStateMonitorEnabled() {
        return this.options['readyState.monitor'];
    }
}
