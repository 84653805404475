import {
  Asset,
  CoreEvents,
  DEFAULT_PLAYER_CONFIG,
  PlaybackState,
  PlayerState,
  TControls,
} from "@tv4/avod-web-player-common";
import type { Core } from "@tv4/avod-web-player-core";
import hotkeys, { HotkeysEvent } from "hotkeys-js";

export interface KeyboardShortcutsOptions {
  playerCore: Core;
  onPlayWhenEnded?: () => void;
  asset?: Asset;
}

enum KeyboardBindings {
  TOGGLE_PLAY = "space",
  FORWARD = "right",
  BACKWARD = "left",
  VOLUME_UP = "up",
  VOLUME_DOWN = "down",
  TOGGLE_MUTE = "m",
  TOGGLE_FULLSCREEN = "f",
  DEBUG_OVERLAY = "ctrl+alt+d",
}

const SEEK_AMOUNT = DEFAULT_PLAYER_CONFIG.seekAmount;
const VOLUME_STEP = 0.1;

export class KeyboardShortcuts {
  private playerCore: Core;
  private asset?: Asset;

  private playerControls?: TControls;
  private playerState?: PlayerState;
  private onPlayWhenEnded?: () => void;

  private shortcuts: Array<KeyboardBindings>;

  constructor({
    playerCore,
    onPlayWhenEnded,
    asset,
  }: KeyboardShortcutsOptions) {
    this.playerCore = playerCore;
    this.asset = asset;

    this.shortcuts = [
      KeyboardBindings.TOGGLE_PLAY,
      KeyboardBindings.TOGGLE_FULLSCREEN,
      KeyboardBindings.TOGGLE_MUTE,
      KeyboardBindings.VOLUME_UP,
      KeyboardBindings.VOLUME_DOWN,
      KeyboardBindings.FORWARD,
      KeyboardBindings.BACKWARD,
      KeyboardBindings.DEBUG_OVERLAY,
    ];

    this.onPlayWhenEnded = onPlayWhenEnded;
    this.setupKeyboardControls();
    this.setupStateListener();
    this.setupKeyListeners();
  }

  private setupKeyboardControls(): void {
    this.playerCore.on(CoreEvents.LOADED_PLAYBACK, () => {
      this.playerControls = this.playerCore.getControls();
    });

    this.playerCore.on(
      CoreEvents.CHROMECAST_CONNECTION_STATUS,
      ({ isConnected }) => {
        this.playerControls = isConnected
          ? this.playerCore.getChromeCastManager()?.getControls()
          : this.playerCore.getControls();
      }
    );
  }

  private setupStateListener(): void {
    this.playerCore.on(
      CoreEvents.STATE_CHANGED,
      ({ state }: { state: PlayerState }) => {
        this.playerState = state;
      }
    );
  }

  private setupKeyListeners(): void {
    hotkeys(
      this.shortcuts.join(","),
      { keyup: true },
      this.onShortcutPressed.bind(this)
    );
  }

  public updateKeyboardShortcutsOptions(
    keyboardShortcutsOptions: Partial<KeyboardShortcutsOptions>
  ) {
    this.asset = keyboardShortcutsOptions.asset ?? this.asset;
  }
  private isSeekAllowed() {
    return this.playerState?.canSeek && !this.playerState?.isPauseAd;
  }

  private onShortcutPressed(e: KeyboardEvent, handler: HotkeysEvent): void {
    if (!this.playerState) return;

    if (e.type === "keyup") {
      this.blockDefault(e);
      if (!this.isSeekAllowed() || !this.playerState?.currentTime) return;
      switch (handler.key) {
        case KeyboardBindings.FORWARD:
        case KeyboardBindings.BACKWARD:
          this.playerControls?.applyPreSeek?.();
          break;
      }
      return;
    }

    switch (handler.key) {
      case KeyboardBindings.TOGGLE_PLAY:
        this.blockDefault(e);
        if (e.repeat) {
          // Prevent multiple play/pause events when holding down the space key
          // as this triggers multiple tracking events and event requests to get new
          // pause ads.
          return;
        }
        if (this.playerState?.playbackState === PlaybackState.PLAYING) {
          this.playerControls?.pause?.({
            programmatic: false,
          });
        } else if (this.playerState?.playbackState === PlaybackState.ENDED) {
          this.onPlayWhenEnded?.();
        } else {
          this.playerControls?.play?.();
        }
        break;
      case KeyboardBindings.FORWARD:
        this.blockDefault(e);
        if (!this.isSeekAllowed() || !this.playerState?.currentTime) return;
        this.playerControls?.preSeek?.(SEEK_AMOUNT);
        break;
      case KeyboardBindings.BACKWARD:
        this.blockDefault(e);
        if (!this.isSeekAllowed() || !this.playerState?.currentTime) return;
        this.playerControls?.preSeek?.(0 - SEEK_AMOUNT);
        break;
      case KeyboardBindings.TOGGLE_MUTE:
        this.blockDefault(e);
        this.playerControls?.toggleMute?.();
        break;
      case KeyboardBindings.VOLUME_DOWN:
        this.blockDefault(e);
        this.playerControls?.setVolume?.(this.playerState.volume - VOLUME_STEP);
        break;
      case KeyboardBindings.VOLUME_UP:
        this.blockDefault(e);
        this.playerControls?.setVolume?.(this.playerState.volume + VOLUME_STEP);
        break;
      case KeyboardBindings.TOGGLE_FULLSCREEN:
        this.playerControls?.toggleFullscreen?.();
        break;
      case KeyboardBindings.DEBUG_OVERLAY:
        window.dispatchEvent(new CustomEvent("showDebugOverlay"));
        break;
      default:
        break;
    }
  }

  private blockDefault(e: KeyboardEvent): void {
    e.preventDefault();
  }

  public destroy(): void {
    this.shortcuts.forEach((shortcut) => hotkeys.unbind(shortcut));
  }
}
