import {
  Asset,
  createPendingPromise,
  PlayerMode,
  Service,
  ThumbnailVariants,
  TPendingPromise,
  VisualElement,
} from "@tv4/avod-web-player-common";
import type { Core } from "@tv4/avod-web-player-core";
import { createRef, ReactNode } from "react";
import { createRoot, Root } from "react-dom/client";

import { ContentMetadata } from "./components/CurrentProgramMetaData";
import { KeyboardShortcuts } from "./KeyboardShortcuts";
import { AccessTokenContext } from "./providers/AccessTokenContext";
import { ClientCallbackContext } from "./providers/ClientCallbackContext";
import { CustomButtonContext } from "./providers/CustomButtonContext";
import { LayoutProvider } from "./providers/LayoutProvider";
import { MetadataProvider } from "./providers/MetadataProvider";
import { TranslationsProvider } from "./providers/TranslationsProvider";
import Skin from "./Skin";
import SkinPreview from "./SkinPreview";

export { CustomButton } from "./components/CustomButton";
export type { ReactNode } from "react";

export type { ContentMetadata };

type TUpdateProps = {
  asset?: Asset;
  poster?: string;
  forceShowSkin?: boolean;
  showInactivePrompt?: boolean;
  enableVisuals?: VisualElement[];
  onBackClick?: () => void;
  onCloseClick?: () => void;
  onExitClick?: () => void;
  onVideoClick?: () => void;
  onPlayWhenEnded?: () => void;
  onUserSelectedPlaybackRate?: (rate: number) => void;
  getContentMetadata?: (assetId: string) => Promise<ContentMetadata>;
  customButton?: ReactNode;
  service?: Service;
  playerMode?: PlayerMode;
  onShareClick?: () => void;
  hideMetadataOutsideFullscreen?: boolean;
  hideSubtitlesMenu?: boolean;
  showMobileMuteButton?: boolean;
  playerProgressTintColor?: string;
  thumbnails?: ThumbnailVariants;
  getAccessToken?: () => string | undefined;
  enableFullscreen?: boolean;
  noInitialLoadSpinner?: boolean;
};

type SkinControllerProps = {
  core: Core;
  root: HTMLElement;
  onPlayWhenEnded?: () => void;
  enableShortcuts: boolean;
};

export class SkinController {
  private core: Core;
  private keyboardShortcuts?: KeyboardShortcuts;
  private enabledVisuals?: VisualElement[] = [];
  private onPlayWhenEnded?: () => void;

  private elementRoot: HTMLElement;
  private reactRoot: Root;

  private pendingRender: TPendingPromise<void>;
  private skinComponentRef: React.RefObject<HTMLElement>;
  private rafId: number = 0;

  private props: TUpdateProps = {};

  constructor({
    enableShortcuts,
    core,
    root,
    onPlayWhenEnded,
  }: SkinControllerProps) {
    this.core = core;

    const elementRoot = document.createElement("div");
    elementRoot.classList.add("avod-web-player-skin");

    root.appendChild(elementRoot);

    this.elementRoot = elementRoot;
    this.reactRoot = createRoot(elementRoot);
    this.onPlayWhenEnded = onPlayWhenEnded;

    if (enableShortcuts) {
      this.keyboardShortcuts = new KeyboardShortcuts({
        playerCore: this.core,
        onPlayWhenEnded,
        asset: this.props.asset,
      });
    }

    this.pendingRender = createPendingPromise<void>();
    this.skinComponentRef = createRef();

    this.update();
  }

  public getEnabledVisuals() {
    return this.enabledVisuals;
  }

  public async awaitRender(): Promise<void> {
    return this.pendingRender.promise;
  }

  // createRoot().render is asynchronous and seems to have no way to report when render has complete. monitor is used to detect when dom has rendered.
  private monitorRender() {
    if (this.rafId !== 0) {
      window.cancelAnimationFrame(this.rafId);
    }
    if (!this.pendingRender.completed) {
      // render should be complete when ref has a value
      if (this.skinComponentRef.current) {
        this.pendingRender.resolve();
      }
      // keep monitoring
      else {
        this.rafId = window.requestAnimationFrame(() => this.monitorRender());
      }
    }
  }

  public update(updateProps: TUpdateProps = {}) {
    this.props = {
      ...this.props,
      ...updateProps,
    };

    this.keyboardShortcuts?.updateKeyboardShortcutsOptions({
      asset: this.props.asset,
    });

    const {
      forceShowSkin,
      showInactivePrompt,
      onBackClick,
      onCloseClick,
      onExitClick,
      onVideoClick,
      enableVisuals,
      onShareClick,
      service = Service.DEFAULT,
      playerMode,
      hideMetadataOutsideFullscreen,
      hideSubtitlesMenu,
      showMobileMuteButton,
      playerProgressTintColor,
      thumbnails,
      getAccessToken,
      enableFullscreen,
      onUserSelectedPlaybackRate,
      getContentMetadata,
      customButton,
      noInitialLoadSpinner,
    } = this.props;

    this.enabledVisuals = enableVisuals;
    const isPreviewMode = playerMode === PlayerMode.PREVIEW;
    if (isPreviewMode) this.keyboardShortcuts?.destroy();

    const SkinComponent = isPreviewMode ? SkinPreview : Skin;

    this.reactRoot.render(
      <TranslationsProvider service={service}>
        <ClientCallbackContext.Provider
          value={{
            onUserSelectedPlaybackRate,
            getContentMetadata,
          }}
        >
          <CustomButtonContext.Provider value={{ customButton }}>
            <AccessTokenContext.Provider
              value={getAccessToken || (() => undefined)}
            >
              <MetadataProvider
                asset={this.props.asset}
                poster={this.props.poster}
              >
                <LayoutProvider
                  root={this.elementRoot}
                  enableVisuals={enableVisuals}
                  playerMode={playerMode}
                  onShareClick={onShareClick}
                  hideMetadataOutsideFullscreen={hideMetadataOutsideFullscreen}
                  hideSubtitlesMenu={hideSubtitlesMenu}
                  showMobileMuteButton={showMobileMuteButton}
                  playerProgressTintColor={playerProgressTintColor}
                  enableFullscreen={enableFullscreen}
                  noInitialLoadSpinner={noInitialLoadSpinner}
                >
                  <SkinComponent
                    ref={this.skinComponentRef}
                    core={this.core}
                    thumbnails={thumbnails}
                    forceShowSkin={isPreviewMode ? true : forceShowSkin}
                    showInactivePrompt={showInactivePrompt}
                    onBackClick={onBackClick}
                    onCloseClick={onCloseClick}
                    onExitClick={onExitClick}
                    onVideoClick={onVideoClick}
                    onPlayWhenEnded={this.onPlayWhenEnded}
                  />
                </LayoutProvider>
              </MetadataProvider>
            </AccessTokenContext.Provider>
          </CustomButtonContext.Provider>
        </ClientCallbackContext.Provider>
      </TranslationsProvider>
    );

    this.monitorRender();
  }

  public destroy() {
    this.keyboardShortcuts?.destroy();
    this.reactRoot.unmount();
  }
}
