import { clamp, PlaybackState } from "@tv4/avod-web-player-common";
import { isMobile } from "@tv4/avod-web-player-device-capabilities";
import { useCallback, useEffect, useRef, useState } from "react";

import useDragging from "../../hooks/useDragging";
import {
  useAdMarkers,
  useControls,
  usePlayerState,
} from "../../providers/CoreProvider";
import { useLayoutSettings } from "../../providers/LayoutProvider";
import { useThumbnailCues } from "../../providers/ThumbnailsProvider";
import {
  AdMarker,
  AdMarkers,
  Handle,
  Progress,
  ProgressLine,
  ProgressWrapper,
} from "./styles";
import { TimeThumbnailOverlay } from "./TimeThumbnailOverlay";

const ProgressBar = () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const controls = useControls();
  const adMarkers = useAdMarkers();

  const [hovering, setHovering] = useState(false);
  const [scrubPercentage, setScrubPercentage] = useState<number>(0);

  const playerState = usePlayerState();
  const thumbnailCues = useThumbnailCues();
  const { currentTime, duration, playbackState, preSeeking, utcStartTimeMs } =
    playerState;
  const { playerProgressTintColor } = useLayoutSettings();

  const getDragPercentage = (dragPosition: number) => {
    if (!wrapperRef.current) return 0;
    const progressbarContainer = wrapperRef.current.getBoundingClientRect();
    const handlePosition = dragPosition - progressbarContainer.left;

    return clamp(handlePosition / progressbarContainer.width) * 100;
  };

  const onDragChange = useCallback((evt: PointerEvent) => {
    const percentage = getDragPercentage(evt.clientX);
    setScrubPercentage(percentage);
  }, []);

  useEffect(() => {
    if (!preSeeking) return;
    setScrubPercentage((currentTime / duration) * 100);
  }, [preSeeking, currentTime, duration]);

  const onDragStop = useCallback(() => {
    const percentage: number = scrubPercentage;
    const secondsIntoVideo = (percentage / 100) * duration;

    controls?.seekTo?.(secondsIntoVideo);
  }, [scrubPercentage, controls, duration]);

  const { dragging, startDragging } = useDragging({ onDragChange, onDragStop });

  const onHoverMove = useCallback(
    (percentage?: number) => {
      if (preSeeking) return;
      setScrubPercentage(percentage ?? 0);
    },
    [preSeeking]
  );

  const onPointerMove = useCallback(
    (evt: React.PointerEvent<HTMLDivElement>) => {
      const percentage =
        (evt.nativeEvent.offsetX / evt.currentTarget.clientWidth) * 100;
      setHovering(true);
      onHoverMove(clamp(percentage, 100));
    },
    [onHoverMove]
  );

  const onPointerLeave = useCallback(() => {
    setHovering(false);
    onHoverMove(undefined);
  }, [onHoverMove]);

  let progress = 0;
  // TODO: Clamp() the divison so that it doesn't go over 100 or under 0
  progress = (currentTime / duration) * 100;

  let progressWrapperProps = {};
  if (playbackState !== PlaybackState.SEEKING) {
    progressWrapperProps = {
      onPointerDown: startDragging,
      onPointerMove: onPointerMove,
      onPointerLeave: onPointerLeave,
    };
  }

  return (
    <ProgressWrapper
      // nordic-web needs to add a touch interaction exception to one of its
      // views. The dependency used by the client wants classes to add exceptions.
      className={"web-player-interaction-listener"}
      ref={wrapperRef}
      grabbing={dragging}
      {...progressWrapperProps}
    >
      <ProgressLine>
        <Progress
          style={{
            width: `${dragging || preSeeking ? scrubPercentage : progress}%`,
          }}
          playerProgressTintColor={playerProgressTintColor}
        />
        <AdMarkers>
          {adMarkers.map((adMarker, index) => {
            const relativeOffset = adMarker.start / duration;
            const defaultMarkerEmWidth = 0.5625;
            let left = `${relativeOffset * 100}%`;
            let width = `${defaultMarkerEmWidth}em`;
            if (adMarker.duration) {
              width = `${(adMarker.duration / duration) * 100}%`;
            } else if (relativeOffset) {
              // subtract the width of the ad marker relative to the offset to center it
              left = `calc(${left} - ${relativeOffset * defaultMarkerEmWidth}em)`;
            }
            // Only mark as watched if required and watched, because otherwise we would mark watched for svod users for either all or the watched ads
            const opacity =
              adMarker.required && adMarker.watched ? 0.5 : undefined;
            return (
              <AdMarker
                key={`${adMarker.start}-${index}`}
                style={{ left, width, opacity }}
              />
            );
          })}
        </AdMarkers>
        <Handle />
      </ProgressLine>
      {(hovering || dragging || preSeeking) && scrubPercentage > 0 && (
        <TimeThumbnailOverlay
          left={isMobile() ? 50 : scrubPercentage}
          utcStartTimeMs={utcStartTimeMs}
          time={(scrubPercentage / 100) * duration}
          thumbnailCues={thumbnailCues}
        />
      )}
    </ProgressWrapper>
  );
};

export default ProgressBar;
