import { isMobile } from "@tv4/avod-web-player-device-capabilities";
import styled from "styled-components";

export const OverlayContainer = styled.div`
  font-family: monospace;
  position: relative;
  box-sizing: border-box;
  inset: 0;
  max-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5em;
  z-index: 100;
  overflow: auto;
  th {
    white-space: nowrap;
  }
`;

export const Table = styled.table`
  font-size: 12px;
`;

export const TableHeading = styled.th`
  vertical-align: top;
  text-align: right;
  padding-right: 10px;
  width: ${isMobile() ? 30 : 15}%;
`;

export const Title = styled.h5`
  margin: ${isMobile() ? 0.25 : 1}em;
`;

export const TableValue = styled.td`
  white-space: pre;
  vertical-align: top;
  text-align: left;
  padding-left: 10px;
`;
