enum MediaErrorCode {
  MEDIA_ERR_ABORTED = 1,
  MEDIA_ERR_NETWORK = 2,
  MEDIA_ERR_DECODE = 3,
  MEDIA_ERR_SRC_NOT_SUPPORTED = 4,
}

export function mapMediaErrorCode(errorCode: number): string {
  const mediaErrorCode = MediaErrorCode[errorCode];
  return mediaErrorCode || "Unknown Native Media Error Code";
}
