import StarsIconSvg from "../icons/stars.svg";
import { PlayerControlButton as Button } from "../styles";
const VotingButton = () => {
  const sendToggleEvent = (e: React.MouseEvent) => {
    e.stopPropagation();
    document.dispatchEvent(new CustomEvent("toggleVote"));
  };

  return (
    <Button aria-label={`toggle vote`} onClick={sendToggleEvent}>
      <StarsIconSvg />
    </Button>
  );
};

export { VotingButton };
