import {
  defaultTextSizes,
  HTMLTextTrackSize,
  localPreferences,
} from "@tv4/avod-web-player-common";
import { useState } from "react";

import { useControls, usePlayerState } from "../../providers/CoreProvider";
import { useTranslations } from "../../providers/TranslationsProvider";
import {
  TextSizeOptions,
  TextTrackSize,
  TrackListContainer,
  TrackTitle,
} from "./TracksMenu/TracksMenu.styles";

const getPreviewSizeByFullSize = (size?: number) => {
  const previewSize = Object.values(defaultTextSizes).find(
    (textSize) => textSize.size === size
  )?.previewSize;

  return previewSize || defaultTextSizes[HTMLTextTrackSize.LARGE].previewSize;
};

const TextTrackControls = ({
  $stackVertically,
}: {
  $stackVertically: boolean;
}) => {
  const {
    textTrackSettings: { textSize },
  } = usePlayerState();
  const translations = useTranslations();
  const controls = useControls();
  const [activeTextSize, setActiveTextSize] = useState<
    number | HTMLTextTrackSize
  >(getPreviewSizeByFullSize(textSize));

  const setTextTrackSize = (fontSize: number) => {
    controls?.setSubtitleTextSize?.(fontSize);
  };

  return (
    <TrackListContainer>
      <TrackTitle $stackVertically={$stackVertically}>
        {translations("language__subtitles_size")}
      </TrackTitle>
      <TextSizeOptions>
        {Object.entries(defaultTextSizes).map(
          ([htmlTextTrackSize, { size, previewSize, previewLineHeight }]) => (
            <TextTrackSize
              key={htmlTextTrackSize}
              $size={previewSize}
              $lineHeight={previewLineHeight}
              $active={activeTextSize === previewSize}
              onClick={() => {
                setActiveTextSize(previewSize);
                setTextTrackSize(size);
                localPreferences.setPreferredTextSize(htmlTextTrackSize);
              }}
            >
              Abc
            </TextTrackSize>
          )
        )}
      </TextSizeOptions>
    </TrackListContainer>
  );
};

export default TextTrackControls;
