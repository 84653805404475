import {
  AdBreakPayload,
  AdClickPayload,
  AdPayload,
  CdnChangedPayload,
  DroppedFramesPayload,
  EngineSelectedPayload,
  ErrorPayload,
  FullscreenPayload,
  Media,
  MediaLoadingPayload,
  Metadata,
  PlaybackErrorPayload,
  PlaybackRateChangePayload,
  PlayerMode,
  TimePayload,
  TrackPayload,
  VolumePayload,
} from "@tv4/avod-web-player-common";

export interface ITrackerSetupOptions {
  assetId?: string;
  media?: Media;
  metadata?: Metadata;
  sessionId: string;
  userId: string;
  [key: string]: unknown;
  playerMode?: PlayerMode;
}

export interface ITracker {
  setup(options: ITrackerSetupOptions): void;
  update?(options: Partial<ITrackerSetupOptions>): void;

  onEngine?(data: EngineSelectedPayload): void;

  onLoading?(): void;
  onContentMediaLoading?(data: MediaLoadingPayload): void;
  onLoaded?(data: TimePayload): void;
  onLoadError?(data: ErrorPayload): void;

  // Start indicates video is playing for the first time
  onStart?(data: TimePayload): void;
  // Play indicates a request to start playing has been made,
  // but video is not yet playing
  onPlay?(data: TimePayload): void;
  onPause?(data: TimePayload): void;
  // Resume indicates video is playing, but not for the first time
  onResume?(data: TimePayload): void;
  onEnded?(): void;
  onReset?(): void;
  onError?(data: PlaybackErrorPayload): void;
  onSeek?(data: TimePayload): void;
  onSeeked?(data: TimePayload): void;
  onBuffering?(data: TimePayload): void;
  onBuffered?(data: TimePayload): void;
  onTimeUpdate?(data: TimePayload): void;

  onTrackChanged?(data: TrackPayload): void;
  onDroppedFrames?(data: DroppedFramesPayload): void;
  onCdnChanged?(data: CdnChangedPayload): void;

  onAdBreakStart?(data: AdBreakPayload): void;
  onAdBreakEnd?(): void;
  onAdLoading?(data: AdPayload): void;
  onAdMediaLoading?(data: MediaLoadingPayload): void;
  onAdLoaded?(data: AdPayload): void;
  onAdPlaying?(data: AdPayload): void;
  onAdPaused?(data: TimePayload): void;
  onAdResume?(data: TimePayload): void;
  onAdTimeUpdate?(data: TimePayload): void;
  onAdBuffering?(data: TimePayload): void;
  onAdBuffered?(data: TimePayload): void;
  onAdError?(data: ErrorPayload): void;
  onAdEnded?(): void;
  onAdClick?(data: AdClickPayload): void;

  onFullscreenChanged?(data: FullscreenPayload): void;
  onVolumeChanged?(data: VolumePayload): void;
  onPlaybackRateChanged?(data: PlaybackRateChangePayload): void;

  destroy(): void;
}

export abstract class Tracker implements ITracker {
  public abstract destroy(): void;

  public setup(_: ITrackerSetupOptions): void {
    // noop
  }
}
