export const createArrowIcon = () => {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("width", "32");
  svg.setAttribute("height", "32");
  svg.setAttribute("viewBox", "0 0 32 32");

  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute(
    "d",
    "M18.0803 8.79289C17.6942 8.40237 17.0682 8.40237 16.6821 8.79289C16.2961 9.18342 16.2961 9.81658 16.6821 10.2071L21.4205 15H7.98862C7.44262 15 7 15.4477 7 16C7 16.5523 7.44262 17 7.98862 17H21.4205L16.6821 21.7929C16.2961 22.1834 16.2961 22.8166 16.6821 23.2071C17.0682 23.5976 17.6942 23.5976 18.0803 23.2071L24.8559 16.3536C25.0489 16.1583 25.0489 15.8417 24.8559 15.6464L18.0803 8.79289Z"
  );

  svg.appendChild(path);

  return svg;
};
