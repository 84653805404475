import {
  FilteredMediaEvent,
  getMediaEventFilter,
  MediaEvent,
  TMediaEventFilter,
} from "@eyevinn/media-event-filter";
import {
  IQualityLevel,
  SafariBitrateMonitor,
} from "@eyevinn/safari-bitrate-monitor";
import {
  AWPAudioTrack,
  AWPError,
  AWPTextTrack,
  AWPTextTrackKind,
  clamp,
  DEFAULT_PLAYER_CONFIG,
  EngineName,
  ERROR_CATEGORY,
  ErrorLevel,
  EventEmitter,
  Media,
  MediaType,
  NO_TEXT_TRACK,
  PauseOptions,
  TimePayload,
  TrackPayload,
} from "@tv4/avod-web-player-common";

import { VideoEngineDebugInfo } from "../utils/debug";
import { byteToStr } from "./utils/id3";
import {
  MediaEvents,
  MediaEventsMap,
  MediaID3Payload,
  MediaLoadingPayload,
  ONE_MINUTE_MS,
} from "./utils/mediaConstants";
import { mapMediaErrorCode } from "./utils/mediaErrorCodeMappings";

declare global {
  interface HTMLVideoElement {
    webkitShowPlaybackTargetPicker: () => void;
  }
}

interface DataCue extends TextTrackCue {
  value: {
    key: string;
    data: ArrayBuffer;
  };
}

interface IHTMLMediaAudioTrack {
  id: string;
  language: string;
  label: string;
  enabled: boolean;
}

type EventCallback = (event?: Event) => void;

function createTextTrack(textTrack: TextTrack): AWPTextTrack {
  return {
    id: textTrack.id || textTrack.language,
    label: textTrack.label,
    language: textTrack.language,
    kind:
      textTrack.kind === "captions"
        ? AWPTextTrackKind.CC
        : AWPTextTrackKind.SUBTITLES,
  };
}

function createAudioTrack(audioTrack: IHTMLMediaAudioTrack): AWPAudioTrack {
  return {
    id: audioTrack.id,
    label: audioTrack.label,
    language: audioTrack.language,
  };
}

// how long should an id3 payload be considered valid to emit
// e.g. if the currentTime is 78 and the startTime of the id3 is 76
// should it still be emitted if it's in the queue?
const ID3_VALID_DURATION = 2;

export type EngineOptions = {
  videoElement: HTMLVideoElement;
  enableAirplay: boolean;
  useNativeErrorListener?: boolean;
  useMediaEventFilterMp4Mode?: boolean;
};

export class HtmlVideoEngine extends EventEmitter<MediaEventsMap> {
  public readonly engineName: EngineName = EngineName.NATIVE;
  protected readonly engineVersion?: string;
  private videoListeners: {
    type: string;
    handler: EventCallback;
    callback: EventCallback;
    once: boolean;
  }[] = [];
  private mediaEventFilter: TMediaEventFilter;
  private safariBitrateMonitor?: SafariBitrateMonitor;

  private droppedFrames: number = 0;
  private seeking: boolean = false;
  private isSeekable: boolean = true;
  private lastCurrentTime: number = 0;
  private activeCdn?: string;
  private hardDisallowAirplay: boolean;
  private cachedPlaybackRate: number | null = null;

  protected activeTextTrack?: AWPTextTrack;
  protected activeAudioTrack?: AWPAudioTrack;
  private autoplayBlocked = false;
  protected id3Queue: MediaID3Payload[] = [];
  protected destroyed = false;
  public videoElement: HTMLVideoElement;
  private isProgrammaticPause = false;

  protected useUtcCurrentTime = false;

  public get estimatedBitrate(): number {
    return NaN;
  }

  public set estimatedBitrate(_value: number) {
    // noop
  }

  constructor({
    videoElement,
    enableAirplay,
    useNativeErrorListener = true,
    useMediaEventFilterMp4Mode = false,
  }: EngineOptions) {
    super();

    this.videoElement = videoElement;

    this.hardDisallowAirplay = !enableAirplay;

    // Call to set up initial airplay settings
    // since this.hardDisallowAirplay overrides
    // this.airplayAllowed, which otherwise defaults
    // to true.
    this.setAirplayAllowed(this.airplayAllowed);

    this.mediaEventFilter = getMediaEventFilter({
      mediaElement: videoElement,
      mp4Mode: useMediaEventFilterMp4Mode,
      callback: (event) => {
        switch (event) {
          case FilteredMediaEvent.BUFFERED:
            this.onBuffered();
            break;
          case FilteredMediaEvent.BUFFERING:
            this.onBuffering();
            break;
          case FilteredMediaEvent.ENDED:
            this.onEnded();
            break;
          case FilteredMediaEvent.LOADED:
            this.onLoaded();
            break;
          case FilteredMediaEvent.PAUSE:
            this.onPaused({ programmatic: this.isProgrammaticPause });
            break;
          case FilteredMediaEvent.PLAY:
            this.onPlay();
            break;
          case FilteredMediaEvent.PLAYING:
            this.onPlaying();
            break;
          case FilteredMediaEvent.SEEKED:
            this.onSeeked();
            break;
          case FilteredMediaEvent.SEEKING:
            this.onSeeking();
            break;
          case FilteredMediaEvent.TIME_UPDATE:
            this.onTimeUpdate();
            break;
        }
      },
    });

    if (useNativeErrorListener) {
      this.addVideoListener(MediaEvent.error, this.onError);
    }

    this.addVideoListener(MediaEvent.ratechange, this.onRateChange);
    this.addVideoListener(MediaEvent.volumechange, this.onVolumeChange);

    this.videoElement?.textTracks?.addEventListener(
      "addtrack",
      (this.onAddTrack = this.onAddTrack.bind(this))
    );

    this.videoElement?.textTracks?.addEventListener(
      "change",
      (this.onTextTracksChange = this.onTextTracksChange.bind(this))
    );

    if ("audioTracks" in this.videoElement) {
      (this.videoElement as any).audioTracks?.addEventListener(
        "change",
        (this.onAudioTrackChange = this.onAudioTrackChange.bind(this))
      );
    }
  }

  protected addVideoListener(
    type: string,
    handler: EventCallback,
    once: boolean = false
  ) {
    const callback = (event?: Event) => {
      if (once) {
        this.removeVideoListener(type, handler, once);
      }
      return handler.call(this, event);
    };
    this.videoElement.addEventListener(type, callback);
    this.videoListeners.push({
      type,
      handler,
      callback,
      once,
    });
  }

  protected removeVideoListener(
    type: string,
    handler: EventCallback,
    once: boolean = false
  ) {
    const index = this.videoListeners.findIndex(
      (listener) =>
        listener.type === type &&
        listener.handler === handler &&
        listener.once === once
    );
    if (index !== -1) {
      this.videoElement.removeEventListener(
        this.videoListeners[index].type,
        this.videoListeners[index].callback
      );
      this.videoListeners.splice(index, 1);
    }
  }

  public load(media: Media, startTime?: number) {
    if (media.isLive) {
      // use utcCurrentTime for assets that _started_ as live, when they switch
      // to vod, utcCurrentTime should still be used.
      this.useUtcCurrentTime = true;
    }

    this.onLoadStart({
      src: media.manifestUrl,
      startTime: startTime,
    });

    this.videoElement.src = media.manifestUrl;

    if (!this.isSeekable) {
      this.resetPlaybackRate();
    }

    this.monitorCdnChanged(media.manifestUrl);
    this.monitorBitrateChanged(media);
    this.videoElement.load();

    if (typeof startTime !== "number" || this.videoElement.currentTime) {
      return;
    }

    this.applyStartTime(startTime);
  }

  public setPlaybackRate(rate: number) {
    if (this.isSeekable) {
      this.videoElement.playbackRate = rate;
    }
  }

  protected applyStartTime(startTime: number) {
    if (typeof startTime === "number") {
      this.lastCurrentTime = startTime;
      this.videoElement.currentTime = startTime;
    }
  }

  public setAudioTrack(track: AWPAudioTrack) {
    if ("audioTracks" in this.videoElement) {
      const nativeAudioTracks: IHTMLMediaAudioTrack[] = (
        this.videoElement as any
      ).audioTracks;
      if (nativeAudioTracks && nativeAudioTracks.length > 0) {
        let audioSet = false;
        // if multiple tracks for one language exist, only set one
        Array.from(nativeAudioTracks).forEach((t) => {
          if (!audioSet && t.id === track.id) {
            t.enabled = true;
            audioSet = true;
          } else {
            t.enabled = false;
          }
        });
        // if nothing is to be set, set the first one as enabled
        if (!audioSet) {
          nativeAudioTracks[0].enabled = true;
        }
      }
    }
  }

  public getAudioTracks(): AWPAudioTrack[] {
    if ("audioTracks" in this.videoElement) {
      const nativeAudioTracks: IHTMLMediaAudioTrack[] = (
        this.videoElement as any
      ).audioTracks
        ? Array.from((this.videoElement as any).audioTracks)
        : [];
      return nativeAudioTracks
        .map((track) => createAudioTrack(track))
        .filter(
          (track, index, array) =>
            array.findIndex(
              (compTrack) =>
                track.language === compTrack.language &&
                track.label === compTrack.label
            ) === index
        );
    } else {
      return [];
    }
  }

  public getActiveAudioTrack(): AWPAudioTrack | undefined {
    if ("audioTracks" in this.videoElement) {
      const audioTrack = Array.from(
        (this.videoElement as any).audioTracks
      ).find((track) => (track as any).enabled);
      if (audioTrack) {
        return createAudioTrack(audioTrack as IHTMLMediaAudioTrack);
      }
    }
    return;
  }

  public setTextTrack(track: AWPTextTrack) {
    const nativeTextTracks: TextTrackList = this.videoElement.textTracks || [];
    // if multiple tracks for one language exist, only set one
    let textTrackSet = false;

    Array.from(nativeTextTracks).forEach((nativeTrack) => {
      if (nativeTrack.kind === "metadata") return;
      if (!textTrackSet && nativeTrack.language === track?.language) {
        nativeTrack.mode = "showing";
        textTrackSet = true;
      } else {
        nativeTrack.mode = "disabled";
      }
    });
  }

  private hideNativeTextTracks(): void {
    const nativeTextTracks: TextTrackList = this.videoElement.textTracks || [];
    Array.from(nativeTextTracks).forEach((nativeTrack) => {
      if (nativeTrack.mode === "showing") {
        nativeTrack.mode = "hidden";
      }
    });
  }

  public getTextTracks(): AWPTextTrack[] {
    const nativeTextTracks: TextTrack[] = this.videoElement.textTracks
      ? Array.from(this.videoElement.textTracks)
      : [];

    const customTextTracks = nativeTextTracks
      .filter(this.filterOutUnsupportedTextTracks)
      .map((track) => createTextTrack(track))
      .filter(
        (track, index, array) =>
          array.findIndex(
            (compTrack) =>
              track.language === compTrack.language &&
              track.label === compTrack.label
          ) === index
      );
    customTextTracks.unshift(NO_TEXT_TRACK);
    return customTextTracks;
  }

  public getActiveTextTrack(): AWPTextTrack {
    const textTrack = Array.from(this.videoElement.textTracks).find(
      (track) =>
        (track as any).mode !== "disabled" && (track as any).kind !== "metadata"
    );
    if (textTrack) {
      return createTextTrack(textTrack);
    }
    return NO_TEXT_TRACK;
  }

  public play() {
    if (this.videoElement.readyState >= 3) {
      this.playVideo();
    } else {
      this.addVideoListener("canplay", this.playVideo.bind(this), true);
    }
  }

  private playVideo() {
    this.videoElement.play().then(
      () => {
        if (this.destroyed) return;

        if (this.autoplayBlocked) {
          this.emit(MediaEvents.SUCCESSFUL_PLAY, undefined);
          this.autoplayBlocked = false;
        }
      },
      (error) => {
        if (this.destroyed) return;
        console.warn(error);
        if (error.name === "NotAllowedError") {
          this.emit(MediaEvents.AUTOPLAY_BLOCKED, undefined);
          this.autoplayBlocked = true;
        }
      }
    );
  }

  public pause(options: PauseOptions) {
    this.isProgrammaticPause = options.programmatic;

    this.videoElement.pause();
  }

  public getSeekable(): { start: number; end: number } {
    const seekable = this.videoElement.seekable;
    if (!seekable.length) return { start: 0, end: 0 };
    return {
      start: seekable.start(0),
      end: seekable.end(0),
    };
  }

  public seekTo(position: number) {
    const seekable = this.getSeekable();
    if (!seekable) return;
    if (position > seekable.end) {
      this.videoElement.currentTime = this.isLive()
        ? seekable.end - DEFAULT_PLAYER_CONFIG.liveEdgeSeekPadding
        : seekable.end;
    } else if (position < seekable.start) {
      this.videoElement.currentTime = seekable.start;
    } else {
      this.videoElement.currentTime = position;
    }
  }

  // controlling if user is allowed to use airplay
  private airplayAllowed = true;
  private airplayObserver?: MutationObserver;

  private setAllowAirplay() {
    this.airplayAllowed = true;
    if (this.isAirplaySupported()) {
      this.videoElement.removeAttribute("x-webkit-airplay");
      this.videoElement.removeAttribute("disableremoteplayback");
      this.videoElement.removeAttribute(
        "x-webkit-wirelessvideoplaybackdisabled"
      );
    }
  }

  private setDisallowAirplay() {
    this.airplayAllowed = false;
    if (this.isAirplaySupported()) {
      if (this.videoElement.getAttribute("x-webkit-airplay") !== "deny") {
        this.videoElement.setAttribute("x-webkit-airplay", "deny");
      }
      if (!this.videoElement.getAttribute("disableremoteplayback")) {
        this.videoElement.setAttribute("disableremoteplayback", "");
      }
      if (
        !this.videoElement.getAttribute(
          "x-webkit-wirelessvideoplaybackdisabled"
        )
      ) {
        this.videoElement.setAttribute(
          "x-webkit-wirelessvideoplaybackdisabled",
          ""
        );
      }
    }
  }

  private shouldIgnoreTimeUpdate(currentTime: number): boolean {
    if (this.seeking) return true;
    // For Apple native playback (MacOS/iOS/iPadOS) we sometimes (especially after seeking) receive a currentTime
    // that is smaller than the lastCurrentTime by up to 500(?)ms. This should not occur,
    // so we ignore these timeUpdates, as the difference in ms will cause issues for Yospace
    // (which operates with ms precision for start/duration of adBreaks)
    if (currentTime > this.lastCurrentTime) return false;

    return currentTime - this.lastCurrentTime >= -0.5;
  }

  public setAirplayAllowed(allowed: boolean) {
    const allow = this.hardDisallowAirplay ? false : allowed;

    if (this.isAirplaySupported()) {
      this.airplayObserver?.disconnect();
      if (allow) {
        this.setAllowAirplay();
      } else {
        this.setDisallowAirplay();
        this.airplayObserver = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type !== "attributes") {
              return;
            }
            const target = mutation.target as HTMLVideoElement;
            if (
              mutation.attributeName &&
              !target.hasAttribute(mutation.attributeName)
            ) {
              this.setDisallowAirplay();
            }
            if (target.attributes["x-webkit-airplay"] !== "deny") {
              this.setDisallowAirplay();
            }
          });
        });
        this.airplayObserver.observe(this.videoElement, {
          attributeFilter: [
            "x-webkit-airplay",
            "disableremoteplayback",
            "x-webkit-wirelessvideoplaybackdisabled",
          ],
        });
      }
    }
  }

  private isAirplaySupported(): boolean {
    return !!this.videoElement.webkitShowPlaybackTargetPicker;
  }

  public isAirplayAvailable(): boolean {
    return this.airplayAllowed && this.isAirplaySupported();
  }

  public toggleAirplay() {
    if (this.isAirplayAvailable()) {
      this.videoElement.webkitShowPlaybackTargetPicker();
    }
  }

  public setIsSeekable(isSeekable: boolean) {
    const currentRate = this.videoElement.playbackRate;

    // Cache playback rate if isSeekable switches from true to false
    // and currentRate is a value worth caching
    if (
      this.isSeekable !== isSeekable &&
      !isSeekable &&
      currentRate !== 0 &&
      currentRate !== 1
    ) {
      this.cachedPlaybackRate = this.videoElement.playbackRate;
    }

    // Apply playback rate if isSeekable switches from false to true
    // and currentRate is a value worth modifying
    if (isSeekable && this.cachedPlaybackRate) {
      this.videoElement.playbackRate = this.cachedPlaybackRate;
      this.cachedPlaybackRate = null;
    }

    this.isSeekable = isSeekable;

    if (!this.isSeekable) {
      this.resetPlaybackRate();
    }
  }

  public getCurrentTime() {
    return this.videoElement.currentTime;
  }

  public getLastCurrentTime() {
    return this.lastCurrentTime;
  }

  private resetPlaybackRate() {
    const currentRate = this.videoElement.playbackRate;

    // Do not reset playbackRate 0 since shaka uses that value to buffer
    // Reset all other values to 1, which generally happens if the user
    // tries to increase playback rate during ads, or in a non-seekable
    // live stream.
    if (currentRate > 0 && currentRate !== 1) {
      this.videoElement.playbackRate = 1;
    }
  }

  public toggleMute() {
    const isMuted = this.videoElement.muted;
    this.videoElement.muted = !isMuted;
  }

  public mute() {
    this.videoElement.muted = true;
  }

  public unmute() {
    this.videoElement.muted = false;
  }

  public setVolume(volume: number) {
    this.videoElement.volume = clamp(volume);
  }

  public getMuted(): boolean {
    return this.videoElement.muted;
  }

  public getVolume(): number {
    return this.videoElement.volume;
  }

  public getDuration(): number {
    if (this.isLive()) {
      const seekable = this.getSeekable();
      if (seekable) return seekable.end;
    }
    return this.videoElement.duration;
  }

  public isPlaying(): boolean {
    return !this.videoElement.paused;
  }

  protected getTimePayload(): TimePayload {
    return {
      currentTime: this.videoElement.currentTime,
      duration: this.getDuration(),
      utcCurrentTimeMs: this.useUtcCurrentTime
        ? this.getUtcCurrentTimeMs()
        : undefined,
      isInAdBreak: false,
    };
  }

  protected onLoadStart(payload: MediaLoadingPayload) {
    this.emit(MediaEvents.LOADING, payload);
  }

  protected onLoaded() {
    /**
     * native text tracks are not used,
     * subtitles are displayed in html elements.
     * hideNativeTextTracks can interfere with
     * shaka player so this should be done before
     * other subtitle handling -
     *   hideNativeTextTracks();
     *   shakaPlayer.setTextTrackVisibility(true)
     *   shakaPlayer.isTextTrackVisible() // true
     * when called in incorrect order -
     *   shakaPlayer.setTextTrackVisibility(true)
     *   hideNativeTextTracks();
     *   shakaPlayer.isTextTrackVisible() // false
     *   shakaPlayer.setTextTrackVisibility(true)
     *   shakaPlayer.isTextTrackVisible() // false
     */
    this.hideNativeTextTracks();
    this.emit(MediaEvents.LOADED, this.getTimePayload());
    this.onTextTracksChange();
    this.onAudioTrackChange();
  }

  protected onPlay() {
    this.emit(MediaEvents.PLAY, this.getTimePayload());
  }

  protected onPlaying() {
    this.emit(MediaEvents.PLAYING, this.getTimePayload());
  }

  protected onPaused(options: PauseOptions) {
    this.emit(MediaEvents.PAUSED, { ...options, ...this.getTimePayload() });
  }

  protected onTimeUpdate() {
    const currentTime = this.videoElement.currentTime;
    if (this.shouldIgnoreTimeUpdate(currentTime)) return;

    this.lastCurrentTime = currentTime;
    this.emit(MediaEvents.TIME_UPDATE, this.getTimePayload());
    this.checkDroppedFrames();
    this.iterateID3Queue();
  }

  protected onSeeking() {
    this.seeking = true;
    if (this.isSeekable) {
      this.emit(MediaEvents.SEEKING, {
        ...this.getTimePayload(),
        playing: !this.videoElement.paused,
      });
    } else {
      // when `seeked` is finally called the engine will seek back to the previous time
      // the reason we're not doing that here is because if seeking is called _alot_
      // the evenflow will sometimes be seeking -> seeked -> timeupdate -> seeking...
      // this causes the evenflow to break.
    }
  }

  protected onSeeked() {
    this.seeking = false;
    if (this.isSeekable) {
      this.emit(MediaEvents.SEEKED, {
        ...this.getTimePayload(),
        playing: !this.videoElement.paused,
      });
    } else if (
      // don't restore gap seeks
      Math.abs(this.videoElement.currentTime - this.lastCurrentTime) > 0.5
    ) {
      this.seekTo(this.lastCurrentTime);
    }
  }

  protected onRateChange() {
    if (!this.isSeekable) {
      this.resetPlaybackRate();
    }

    this.emit(MediaEvents.PLAYBACK_RATE_CHANGE, {
      playbackRate: this.videoElement.playbackRate,
    });
  }

  protected onVolumeChange() {
    this.emit(MediaEvents.VOLUME_CHANGED, {
      volume: this.videoElement.volume,
      muted: this.videoElement.muted,
    });
  }

  protected onBuffering() {
    this.emit(MediaEvents.BUFFERING, {
      ...this.getTimePayload(),
      playing: !this.videoElement.paused,
    });
  }

  protected onBuffered() {
    this.emit(MediaEvents.BUFFERED, {
      ...this.getTimePayload(),
      playing: !this.videoElement.paused,
    });
  }

  protected onEnded() {
    this.emit(MediaEvents.END_OF_STREAM, {
      ...this.getTimePayload(),
      currentTime: this.getDuration(),
      error: this.videoElement.error,
    });
  }

  protected onError(error?: unknown) {
    if (
      this.videoElement.error &&
      (!error || (error instanceof Event && error.target === this.videoElement))
    ) {
      // handle videoElement.error if available
      error = this.videoElement.error;
    }

    const errorCode = (error instanceof MediaError && error.code) || -1;
    const message =
      ((error instanceof MediaError || error instanceof Error) &&
        error.message) ||
      "Unknown Native Media Error";

    this.emit(MediaEvents.ERROR, {
      ...this.getTimePayload(),
      error: new AWPError({
        context: "engine",
        code: `NativeMediaError:${mapMediaErrorCode(errorCode)}:${message}`,
        message: message,
        raw: error,
        details: {
          url: this.videoElement.src,
        },
        category: ERROR_CATEGORY.MEDIA,
        errorLevel: ErrorLevel.PLAYER,
      }),
    });
  }

  protected setActiveCdn(cdn?: string) {
    if (cdn && this.activeCdn !== cdn) {
      this.activeCdn = cdn;
      this.emit(MediaEvents.CDN_CHANGED, { cdn });
    }
  }

  private cdnChangedTimeout?: number;
  private monitorCdnChanged(manifestUrl: string): void {
    fetch(manifestUrl, {
      method: "HEAD",
    })
      .then((response) => {
        if (this.destroyed) return;

        try {
          if (response.ok) {
            const cdn = response.headers.get("x-cdn-forward");
            if (cdn) {
              clearTimeout(this.cdnChangedTimeout);
              this.cdnChangedTimeout = window.setTimeout(() => {
                if (this.destroyed) return;
                this.monitorCdnChanged(manifestUrl);
              }, ONE_MINUTE_MS);
              return this.setActiveCdn(cdn);
            }
          }
        } catch (_) {
          /*no-op */
        }
        this.setActiveCdn("UNKNOWN");
      })
      .catch(() => {
        this.setActiveCdn("UNKNOWN");
      });
  }

  private monitorBitrateChanged(media: Media): void {
    if (media.type === MediaType.HLS) {
      this.safariBitrateMonitor = new SafariBitrateMonitor({
        videoElement: this.videoElement,
        hlsManifestUrl: media.manifestUrl,
        handler: (quality: IQualityLevel) => {
          this.emit(MediaEvents.TRACK_CHANGED, quality);
        },
      });
    }
  }

  private checkDroppedFrames(): void {
    if (!this.videoElement.getVideoPlaybackQuality) return;
    const quality = this.videoElement.getVideoPlaybackQuality();
    const newDroppedFrames = quality.droppedVideoFrames;
    if (newDroppedFrames !== this.droppedFrames) {
      this.droppedFrames = newDroppedFrames;
      this.emit(MediaEvents.DROPPED_FRAMES, {
        droppedFrames: this.droppedFrames,
      });
    }
  }

  protected getUtcCurrentTimeMs(): number | undefined {
    if ("getStartDate" in this.videoElement) {
      const startDateTime = (this.videoElement as any).getStartDate();
      if (!startDateTime) return;
      const startTime = startDateTime.getTime();
      const time = startTime + this.videoElement.currentTime * 1000;
      return time;
    }
    return undefined;
  }

  private getMetadataTrack() {
    return Array.from(this.videoElement.textTracks).find(
      (track) => track.kind === "metadata"
    );
  }

  protected onAddTrack({ track }) {
    if (track?.kind === "subtitles" || track?.kind === "captions") {
      track.addEventListener("cuechange", this.onSubtitlesCueChange.bind(this));
    }
    if (track?.kind === "metadata") {
      track.mode = "hidden";
      track.addEventListener("cuechange", this.onCueChange.bind(this));
      track.addEventListener(
        "cuechange",
        this.checkInStreamCueMetadata.bind(this)
      );
    }
  }

  protected onCueChange() {
    const metadataTrack = this.getMetadataTrack();
    if (!metadataTrack?.activeCues?.length) return;

    const startTime = metadataTrack.activeCues[0].startTime;
    const id3 = Array.from(
      metadataTrack.activeCues as unknown as DataCue[]
    ).reduce((result, cue) => {
      if (cue.value?.key && cue.value?.data) {
        result[cue.value.key] = byteToStr(new Uint8Array(cue.value.data)).slice(
          1
        );
      }
      return result;
    }, {});
    this.id3Queue.push({
      id3,
      startTime,
    } as MediaID3Payload);
  }

  protected onSubtitlesCueChange(cueEvent) {
    const activeCues: VTTCue[] = cueEvent.currentTarget.activeCues;
    const cue = activeCues[0];
    if (!cue) return;
    this.emit(MediaEvents.TEXT_CUE_ENTERED, { text: cue.text });

    cue.addEventListener("exit", () =>
      this.emit(MediaEvents.TEXT_CUE_EXITED, undefined)
    );
  }

  private checkInStreamCueMetadata(cueChangeEvent: TrackEvent): void {
    if (cueChangeEvent.target && (cueChangeEvent.target as any).activeCues) {
      const cues: TextTrackCueList = (cueChangeEvent.target as any).activeCues;
      Array.from(cues).forEach((cue) => {
        const cueValue = (cue as any).value;
        if (!cueValue) return;
        if (cueValue.key === "X-TITLE") {
          const contentTitle = cueValue.data;
          this.emit(MediaEvents.IN_STREAM_METADATA, { title: contentTitle });
        }
      });
    }
  }

  protected iterateID3Queue() {
    if (!this.id3Queue.length) return;
    const currentTime = this.videoElement.currentTime;
    const removeIndexes: number[] = [];

    // check the quene and see if any id3 event should be emitted
    this.id3Queue.forEach((id3Payload, index) => {
      if (currentTime >= id3Payload.startTime) {
        removeIndexes.unshift(index); // DESC order of indexes.

        if (currentTime <= id3Payload.startTime + ID3_VALID_DURATION) {
          this.emit(MediaEvents.ID3, id3Payload);
        }
      }
    });

    // remove id3Payloads that have been emitted,
    // NOTE! It's crucial that the removal happens in DESC order
    // otherwise the wrong things will be removed.
    removeIndexes.forEach((index) => {
      this.id3Queue.splice(index, 1);
    });
  }

  private filterOutUnsupportedTextTracks(track: TextTrack) {
    return track.kind !== "metadata" && track.label && track.language;
  }

  protected onTextTracksChange(): void {
    this.emit(MediaEvents.TEXT_TRACK_CHANGED, {
      activeTextTrack: this.getActiveTextTrack(),
      textTracks: this.getTextTracks(),
    });
  }

  protected onAudioTrackChange(): void {
    this.emit(MediaEvents.AUDIO_TRACK_CHANGED, {
      activeAudioTrack: this.getActiveAudioTrack(),
      audioTracks: this.getAudioTracks(),
    });
  }

  public isLive() {
    // this will be true for hls live on SafariEngine.
    return this.videoElement.duration === Infinity;
  }

  protected emitDrmLicenseError(error: AWPError): void {
    this.emit(MediaEvents.DRM_LICENSE_ERROR, {
      ...this.getTimePayload(),
      error,
    });
  }

  protected getBitrates(): TrackPayload[] {
    return [];
  }

  public getDebugInfo(): VideoEngineDebugInfo {
    return {
      bitrates: this.getBitrates(),
      engineName: this.engineName,
      engineVersion: this.engineVersion,
    };
  }

  public override destroy() {
    super.destroy();
    this.videoElement.textTracks?.removeEventListener(
      "addtrack",
      this.onAddTrack
    );
    this.videoElement.textTracks?.removeEventListener(
      "change",
      this.onTextTracksChange
    );
    if ("audioTracks" in this.videoElement) {
      (this.videoElement as any).audioTracks.removeEventListener(
        "change",
        this.onAudioTrackChange
      );
    }
    this.videoListeners.forEach(({ type, handler }) => {
      this.videoElement.removeEventListener(type, handler);
    });
    this.mediaEventFilter.teardown();
    if (this.safariBitrateMonitor) {
      this.safariBitrateMonitor.destroy();
    }

    clearTimeout(this.cdnChangedTimeout);

    this.videoElement.src = "";
    this.videoElement.load();
    this.destroyed = true;
  }
}
