import {
  AdBreakPayload,
  AdClickPayload,
  AdPayload,
  AWPError,
  CdnChangedPayload,
  DroppedFramesPayload,
  EngineSelectedPayload,
  ERROR_CATEGORY,
  ErrorPayload,
  FullscreenPayload,
  IgnoredErrorsList,
  Media,
  MediaLoadingPayload,
  Metadata,
  PlaybackErrorPayload,
  PlaybackMode,
  PlaybackRateChangePayload,
  PLAYER_ERROR,
  PlayerMode,
  TimePayload,
  TrackPayload,
  UserTier,
  uuid,
  VolumePayload,
} from "@tv4/avod-web-player-common";
// IMPORTANT: Do not use "npaw-plugin" since it allows JSON injection
// as a potential end-user attack vector. Instead, use "npaw-plugin-nwf".
//
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval#never_use_direct_eval!
import NpawPlugin from "npaw-plugin-nwf";

import {
  AdapterStateManager,
  ADS_ADAPTER_INITIAL_STATE,
  AdsAdapterState,
  CONTENT_ADAPTER_INITIAL_STATE,
  ContentAdapterState,
} from "./npaw-utils/AdapterStateManager";
import {
  AdsAdapter,
  IAdsAdapter,
  NpawAdReports,
} from "./npaw-utils/AdsAdapter";
import { ContentAdapter, IContentAdapter } from "./npaw-utils/ContentAdapter";
import {
  convertAWPErrorToNpaw,
  ErrorSeverity,
  ignore,
} from "./npaw-utils/error";
import {
  getNpawPlaybackType,
  getPlayerId,
  UNKNOWN_VALUE,
  UNSET_VALUE,
} from "./npaw-utils/helpers";
import { ITrackerSetupOptions, Tracker } from "./tracker";

// LogLevel enum export is "not available" even if the tsd says it is:
// https://documentation.npaw.com/integration-docs/docs/enable-debug-js

enum LogLevel {
  /** No console outputs */
  SILENT = 6,
  /** Console will show errors */
  ERROR = 5,
  /** Console will show warnings */
  WARNING = 4,
  /** Console will show notices (ie: life-cycle logs) */
  NOTICE = 3,
  /** Console will show debug messages (ie: player events) */
  DEBUG = 2,
  /** Console will show verbose messages (ie: Http Requests) */
  VERBOSE = 1,
}

// NPAW plugin teardown is async, it is not possible to destroy
// it and create a new playback session synchronously and
// still have functioning tracking. Use it as a singleton.
let plugin: NpawPlugin;

let cdnBalancerAllowed = true;

export function disableCdnBalancer() {
  cdnBalancerAllowed = false;

  plugin?.setBalancerOptions({
    profileName: "some-un-configured-string",
    bucketName: "some-un-configured-string",
    domainWhitelist: [],
    domainWhitelistRegex: [],
  });
}

export function enableCdnBalancer() {
  cdnBalancerAllowed = true;
}

export interface INpawTrackerInstanceOptions {
  debug?: boolean;
  appVersion: string;
  appName: string;
  playerName: string;
  playerVersion: string;
  service: string;
  platform: string;
  senderType?: string;
  receiverType?: string;
  userId?: string;
  assetId: string;
  account: "mtvoy" | "mtvoydev";
}

export interface INpawTrackerSetupOptions extends ITrackerSetupOptions {
  media?: Media;
  metadata?: Metadata;
  playbackMode?: PlaybackMode | undefined;
  npawSessionGroupId: string | null;
  userTier?: UserTier;
}

export class NpawTracker extends Tracker {
  private readonly debug: boolean = false;

  private contentAdapter: IContentAdapter;
  private adsAdapter: IAdsAdapter;

  private readonly service: string;
  private readonly platform: string;

  private readonly adapterUuid = uuid();

  private ongoingAdReported: NpawAdReports[] = [];

  private readonly contentAdapterStateManager =
    new AdapterStateManager<ContentAdapterState>(CONTENT_ADAPTER_INITIAL_STATE);
  private readonly adsAdapterStateManager =
    new AdapterStateManager<AdsAdapterState>(ADS_ADAPTER_INITIAL_STATE);

  constructor({
    debug,
    playerVersion,
    appVersion,
    appName,
    playerName,
    service,
    platform,
    senderType,
    receiverType,
    userId,
    assetId,
    account,
  }: INpawTrackerInstanceOptions) {
    super();

    if (typeof debug === "boolean") {
      this.debug = debug;
      console.debug("NpawTracker initialized");
    }

    if (!plugin) {
      plugin = new NpawPlugin(account);

      // shared by all registered video adapters
      // do not use this mthod for content specific
      // metadata
      plugin.setAnalyticsOptions({
        "app.https": true,
        "background.settings": "",
        "background.settings.android": undefined,
        "background.settings.iOS": undefined,
        "background.settings.tv": undefined,
        "background.settings.playstation": undefined,

        "content.isLive.noMonitor": true,
        "parse.manifest": false,
        "parse.cdnNode": false,

        "app.name": appName,
        "app.releaseVersion": appVersion,
        "user.name": userId || -1,

        ...(senderType ? { "content.customDimension.5": senderType } : {}),
        ...(receiverType ? { "device.model": receiverType } : {}),
      });

      plugin.setLogLevel(this.debug ? LogLevel.DEBUG : LogLevel.SILENT);
    }

    this.service = service;
    this.platform = platform;

    const adapterState = {
      appName,
      appVersion,
      playerVersion,
      playerName,
    };

    // Set available adapter state before .fireInit()
    this.contentAdapterStateManager.updateState(adapterState);

    this.adsAdapterStateManager.updateState(adapterState);

    // Due to the way NPAW plugin merges the custom adapter
    // class instance, custom properties are stripped from it,
    // making it inelegant to try to handle state inside the
    // custom adapter. Instead, use the "player" argument, which
    // is accessible from the content adapter, as the adapter state
    // handler.
    plugin.registerAdapterFromClass(
      this.contentAdapterStateManager,
      ContentAdapter,
      {},
      this.adapterUuid
    );

    this.contentAdapter = plugin.getAdapter(
      this.adapterUuid
    ) as unknown as IContentAdapter;

    plugin.setVideoOptions(
      {
        "content.title": assetId,
        "content.id": assetId,

        "content.cdn": UNKNOWN_VALUE,

        // Player ID
        "content.customDimension.1": getPlayerId(
          this.service,
          this.platform,
          null
        ),

        // Service
        "content.customDimension.3": service,

        // Network Connection
        "content.customDimension.6": UNSET_VALUE, // set to navigator.onLine status at onError

        // Player Engine
        "content.customDimension.7": UNSET_VALUE,

        "content.transactionCode": "AVOD",
      },
      this.adapterUuid
    );

    // fireInit() as early as possible.
    this.contentAdapter.fireInit();

    plugin.registerAdsAdapterFromClass(
      this.adsAdapterStateManager,
      AdsAdapter,
      {},
      this.adapterUuid
    );

    this.adsAdapter = plugin.getAdsAdapter(
      this.adapterUuid
    ) as unknown as IAdsAdapter;
  }

  public override setup(options: INpawTrackerSetupOptions): void {
    super.setup(options);

    if (
      cdnBalancerAllowed &&
      options.metadata?.tracking.youbora &&
      "bucketName" in options.metadata.tracking.youbora &&
      "domainWhitelistRegex" in options.metadata.tracking.youbora &&
      "profileName" in options.metadata.tracking.youbora
    ) {
      plugin.setBalancerOptions({
        ...options.metadata.tracking.youbora,
      });
    } else {
      disableCdnBalancer();
    }

    this.contentAdapterStateManager.setConfig(options);
    this.adsAdapterStateManager.setConfig(options);

    const { metadata, media, assetId, playerMode, npawSessionGroupId } =
      options;

    const trackingData = metadata?.tracking.youbora;

    this.contentAdapterStateManager.updateState({
      isLive: trackingData?.isLive,
    });

    plugin.setVideoOptions(
      {
        "user.type": options.userTier || UNSET_VALUE,
        "content.id": trackingData?.id,
        "content.isLive": trackingData?.isLive,
        "content.metadata": trackingData && { trackingData },
        "content.program": metadata?.asset.title,
        "content.streamingProtocol": media?.type.toUpperCase(),
        "content.genre": trackingData?.category,
        "content.type": trackingData?.channelType ?? metadata?.asset.type,
        "content.playbackType": getNpawPlaybackType(metadata, media),

        "content.title": trackingData?.title || assetId,
        "content.drm": media?.license?.type
          ? `license.type: ${media?.license?.type}`
          : "none",

        // Player Mode
        "content.customDimension.2": playerMode ?? PlayerMode.DEFAULT,

        // Session Group ID
        "content.customDimension.4": npawSessionGroupId,
      },
      this.adapterUuid
    );
  }

  /**
   * Content Related events
   */

  public onLoading(): void {
    this.log("onLoading");
    // .fireStart means the user showed intent to start a video,
    // in other words: when they pick something to watch on the CDP.
    //
    // The duration between .fireStart and .fireJoin becomes the
    // video join time.
    this.contentAdapter.fireStart();
  }

  public onContentMediaLoading(data: MediaLoadingPayload) {
    this.contentAdapterStateManager.updateState({
      manifestUrl: data.src,
      ...(data.startTime ? { playhead: data.startTime } : {}),
    });
  }

  public onStart(data: TimePayload): void {
    this.log("onStart");

    this.contentAdapterStateManager.updateState({
      playhead: data.currentTime,
      duration: data.duration,
    });

    // onStart indicates video is visible to the user for the first
    // time.
    //
    // .fireJoin should trigger when the first frame is visible to
    // the user.
    //
    // At this time, bitrate, rendition, and codec should be available.
    this.contentAdapter.fireJoin();
  }

  public onResume(data: TimePayload): void {
    this.log("onResume");

    this.contentAdapterStateManager.updateState({
      playhead: data.currentTime,
      duration: data.duration,
    });

    this.contentAdapter.fireResume();
  }

  public onTimeUpdate(data: TimePayload): void {
    this.log("onTimeUpdate");

    this.contentAdapterStateManager.updateState({
      playhead: data.currentTime,
      duration: data.duration,
    });
  }

  public onPause(data: TimePayload): void {
    this.log("onPause");

    this.contentAdapterStateManager.updateState({
      playhead: data.currentTime,
      duration: data.duration,
    });
    this.contentAdapter.firePause();
  }

  public onBuffering(data: TimePayload): void {
    this.log("onBuffering");

    this.contentAdapterStateManager.updateState({
      playhead: data.currentTime,
      duration: data.duration,
    });
    this.contentAdapter.fireBufferBegin();
  }

  public onBuffered(data: TimePayload): void {
    this.log("onBuffered");

    this.contentAdapterStateManager.updateState({
      playhead: data.currentTime,
      duration: data.duration,
    });
    this.contentAdapter.fireBufferEnd();
  }

  public onSeek(data: TimePayload): void {
    this.log("onSeeking");

    this.contentAdapterStateManager.updateState({
      playhead: data.currentTime,
      duration: data.duration,
    });
    this.contentAdapter.fireSeekBegin();
  }

  public onSeeked(data: TimePayload): void {
    this.log("onSeeked");

    this.contentAdapterStateManager.updateState({
      playhead: data.currentTime,
      duration: data.duration,
    });
    this.contentAdapter.fireSeekEnd();
  }

  public onEnded(): void {
    this.log("onEnded");
    console.log("dbg onEnded fireStop");
    this.contentAdapter.fireStop();
  }

  public onError(data: PlaybackErrorPayload): void {
    if (this.errorShouldBeIgnored(data)) return;

    const { error } = data;

    this.log("onError");

    plugin.setVideoOptions(
      {
        "content.customDimension.6": navigator.onLine ? "ONLINE" : "OFFLINE",
      },
      this.adapterUuid
    );

    if (!ignore(error)) {
      const convertedError = convertAWPErrorToNpaw(error);

      // Do not report errors that are considered non-fatal.
      // This primarily excludes errors thrown after "beforeunload"
      // has been triggered.
      if (convertedError.errorLevel === ErrorSeverity.ERROR) {
        return;
      }

      this.contentAdapter.fireError(convertedError);
    }

    if (error.fatal) {
      this.contentAdapter.fireStop();
    }
  }

  public onTrackChanged(data: TrackPayload): void {
    this.log("onTrackChanged");

    this.contentAdapterStateManager.updateState({
      bitrate: data.bitrate,
      videoWidth: data.width,
      videoHeight: data.height,
      videoCodec: data.videoCodec,
      audioCodec: data.audioCodec,
      fps: data.fps,
    });

    plugin.setVideoOptions(
      {
        "encoding.videoCodec": data.videoCodec,
        "encoding.audioCodec": data.audioCodec,
        fps: data.fps,
        "content.bitrate": data.bitrate,
        "content.rendition": `${data.width}x${data.height}@${Number((data.bitrate / 1000 / 1000).toFixed(2))}Mbps`,
      },
      this.adapterUuid
    );
  }

  public onDroppedFrames(data: DroppedFramesPayload): void {
    this.log("onDroppedFrames");

    this.contentAdapterStateManager.updateState({
      droppedFrames: data.droppedFrames,
    });
  }

  public onCdnChanged(data: CdnChangedPayload): void {
    // @ts-expect-error temporary poc to test if disabling cdn detection when active switching solves errors
    // eslint-disable-next-line no-underscore-dangle -- same as above
    if (plugin?.cdnBalancer?.getLoader?.()?.CDNLoader?._activeSwitching) {
      return;
    }
    plugin.setVideoOptions({ "content.cdn": data.cdn }, this.adapterUuid);
  }

  /**
   * Ad Related events
   */

  public onAdBreakStart({
    adBreak: { insertionType: adInsertionType, breakType, ads },
  }: AdBreakPayload) {
    this.log("onAdBreakStart");

    this.adsAdapterStateManager.updateState({
      numberOfAds: ads.length,
      adBreakPosition: breakType.includes("pre")
        ? // Unsure where/if the constants are exported from NPAW plugin
          "pre"
        : "mid",
      adInsertionType,
    });

    this.adsAdapter.fireAdBreakStart();
  }

  public onAdBreakEnd(): void {
    this.log("onAdBreakEnd");

    this.adsAdapterStateManager.updateState(ADS_ADAPTER_INITIAL_STATE);

    this.adsAdapter.fireAdBreakStop();
  }

  public onAdLoading(data: AdPayload): void {
    this.log("onAdLoading");

    this.adsAdapterStateManager.updateState({
      ongoingAdDetails: data.ad,
    });
  }

  public onAdMediaLoading(data: MediaLoadingPayload) {
    this.log("onAdMediaLoading");

    this.adsAdapterStateManager.updateState({
      source: data.src,
    });

    this.adsAdapter.fireStart();
  }

  public onAdPlaying(data: AdPayload): void {
    this.log("onAdPlaying");

    this.adsAdapterStateManager.updateState({
      ongoingAdDetails: data.ad,
    });

    // Reset reported quartiles
    this.ongoingAdReported = [];

    this.adsAdapter.fireJoin();
  }

  public onAdTimeUpdate({ currentTime, duration }: TimePayload): void {
    this.log("onAdTimeUpdate");

    const ad = this.adsAdapterStateManager.getState().ongoingAdDetails;

    if (!ad) return;

    this.adsAdapterStateManager.updateState({
      playhead: currentTime,
      duration: duration,
    });

    const progress = currentTime / duration;

    if (
      progress > NpawAdReports.FIRST_QUARTILE &&
      !this.ongoingAdReported.includes(NpawAdReports.FIRST_QUARTILE)
    ) {
      this.ongoingAdReported.push(NpawAdReports.FIRST_QUARTILE);
      this.adsAdapter.fireQuartile(1);
    }
    if (
      progress > NpawAdReports.SECOND_QUARTILE &&
      !this.ongoingAdReported.includes(NpawAdReports.SECOND_QUARTILE)
    ) {
      this.ongoingAdReported.push(NpawAdReports.SECOND_QUARTILE);
      this.adsAdapter.fireQuartile(2);
    }
    if (
      progress > NpawAdReports.THIRD_QUARTILE &&
      !this.ongoingAdReported.includes(NpawAdReports.THIRD_QUARTILE)
    ) {
      this.ongoingAdReported.push(NpawAdReports.THIRD_QUARTILE);
      this.adsAdapter.fireQuartile(3);
    }
  }

  public onAdPaused({ currentTime, duration }: TimePayload): void {
    this.log("onAdPaused");

    this.adsAdapterStateManager.updateState({
      playhead: currentTime,
      duration: duration,
    });

    this.adsAdapter.firePause();
  }

  public onAdResume({ currentTime, duration }: TimePayload): void {
    this.log("onAdResume");

    this.adsAdapterStateManager.updateState({
      playhead: currentTime,
      duration: duration,
    });

    this.adsAdapter.fireResume();
  }

  public onAdBuffering({ currentTime, duration }: TimePayload): void {
    this.log("onAdBuffering");

    this.adsAdapterStateManager.updateState({
      playhead: currentTime,
      duration: duration,
    });

    this.adsAdapter.fireBufferBegin();
  }

  public onAdBuffered({ currentTime, duration }: TimePayload): void {
    this.log("onAdBuffered");

    this.adsAdapterStateManager.updateState({
      playhead: currentTime,
      duration: duration,
    });

    this.adsAdapter.fireBufferEnd();
  }

  public onAdEnded(): void {
    this.log("onAdEnded");

    this.adsAdapter.fireStop();

    this.adsAdapterStateManager.updateState({
      ongoingAdDetails: undefined,
      duration: 0,
      playhead: 0,
      bitrate: 0,
    });
  }

  public onAdError(data: ErrorPayload): void {
    this.log("onAdError");

    const error = new AWPError({
      context: data.error.context,
      code: data.error.code || PLAYER_ERROR.AD_TIMEOUT,
      category: data.error.category,
      message: `${data.error.message}, or blocked, during load`,
      details: data.error.details,
      raw: data.error.raw,
      errorLevel: data.error.errorLevel,
      fatal: false,
    });

    if (!error || ignore(error)) return;

    this.adsAdapter.fireError(convertAWPErrorToNpaw(error));
    this.onAdEnded();
  }

  public onAdClick({ url }: AdClickPayload): void {
    this.log("onAdClick");
    this.adsAdapter.fireClick(url);
  }

  public onFullscreenChanged({ fullscreen }: FullscreenPayload) {
    this.log("onFullscreenChanged");
    this.adsAdapterStateManager.updateState({
      fullscreen,
    });
  }

  public onVolumeChanged({ muted }: VolumePayload) {
    this.log("onVolumeChanged");
    this.adsAdapterStateManager.updateState({
      muted,
    });
  }

  public onPlaybackRateChanged(data: PlaybackRateChangePayload) {
    this.contentAdapterStateManager.updateState({
      playrate: data.playbackRate,
    });
  }

  private log(eventType: string): void {
    if (this.debug) {
      console.debug(`NpawTracker - ${eventType}`);
    }
  }

  private errorShouldBeIgnored({ error }: PlaybackErrorPayload): boolean {
    return (
      !error ||
      !error.fatal ||
      error.category === ERROR_CATEGORY.USER ||
      !error.code ||
      IgnoredErrorsList.includes(error.code)
    );
  }

  public onEngine(data: EngineSelectedPayload) {
    plugin.setVideoOptions(
      {
        "content.customDimension.1": getPlayerId(
          this.service,
          this.platform,
          data.name
        ),
        "content.customDimension.7": data.name,
      },
      this.adapterUuid
    );
  }

  public destroy(): void {
    if (this.adsAdapterStateManager.getState().ongoingAdDetails) {
      this.adsAdapter.fireStop();
      this.adsAdapter.fireAdBreakStop();
    }

    this.onEnded();

    plugin.removeAdapter(this.adapterUuid);
    plugin.removeAdsAdapter(this.adapterUuid);

    disableCdnBalancer();

    // Do not destroy npaw plugin here.
    // The .destroy() call appears synchronous, but the
    // plugin de-registers itself from various DOM APIs
    // asynchronously.
    //
    // Re-instantiating the plugin before teardown is
    // finished will fail silently, making tracking fail
    // without any indication that it's not working.
    //
    // It's not possible to safely create-destroy-create
    // the plugin, since it's not possible to await the
    // teardown to know when it's safe to re-instantiate the
    // plugin again.
  }
}
