import AirplaySvg from "../icons/airplay.svg";
import { useControls } from "../providers/CoreProvider";
import { PlayerControlButton as Button } from "../styles";

export const AirplayButton = () => {
  const controls = useControls();

  if (!controls?.toggleAirplay) {
    return null;
  }
  return (
    <Button aria-label="airplay" onClick={controls?.toggleAirplay}>
      <AirplaySvg />
    </Button>
  );
};

export default AirplayButton;
