import { ProductAnalyticsTag } from '../common/Constants';
import Log from '../common/log';
/**
 * NpawProductAnalytics class is the base of npaw productAnalytics.
 * Every plugin will have an instance.
 */
export default class ProductAnalyticsSettings {
    /**
     * Constructor
     */
    constructor() {
        this.autoTrackNavigation = true;
        this.autoTrackAttribution = true;
        this.highlightContentAfter = 1000;
        this.pageRules = [];
    }
    /**
     * Build settings from generic object
     * @param settings
     * @returns
     */
    static buildFromObject(settings) {
        let res;
        if (typeof settings === 'object' && settings !== null) {
            res = new ProductAnalyticsSettings();
            if (typeof settings.autoTrackNavigation === 'boolean') {
                res.autoTrackNavigation = settings.autoTrackNavigation;
            }
            if (typeof settings.autoTrackAttribution === 'boolean') {
                res.autoTrackAttribution = settings.autoTrackAttribution;
            }
            if (typeof settings.highlightContentAfter === 'number') {
                res.highlightContentAfter = settings.highlightContentAfter;
            }
            if (typeof settings.pageRules === 'object' && Array.isArray(settings.pageRules)) {
                res.pageRules = settings.pageRules;
            }
        }
        else {
            res = null;
        }
        return res;
    }
    /**
     * Validate settings
     */
    validate() {
        const defaultSettings = new ProductAnalyticsSettings();
        if (this.highlightContentAfter < 1000) {
            this.highlightContentAfter = defaultSettings.highlightContentAfter;
            Log.warn(ProductAnalyticsTag, 'Invalid higlightContentAfter value. Using default value instead.');
        }
    }
}
