// This file is designed to add extra functionalities to Plugin class

/** @lends npaw.Plugin.prototype */
var PluginFireMixin = {
  /**
   * Sends /init. Should be called once the user has requested the content. Does not need
   * a working adapter or player to work. it won't sent start if isInitiated is true.
   *
   * @param {Object} [params] Object of key:value params.
   * @param {string} [triggeredEvent]
   * @param {string} videoKey
   *
   * @memberof npaw.Plugin.prototype
   */
  fireInit: function (params, triggeredEvent, videoKey) {
    videoKey = videoKey || 'default'
    this.videos.getVideo(videoKey).fireInit(params, triggeredEvent)
  },

  /**
   * Sends /error. Should be used when the error is related to out-of-player errors: like async
   * resource load or player loading errors.
   *
   * @param {String|Object} [code] Error Code, if an object is sent, it will be treated as params.
   * @param {String} [msg] Error Message
   * @param {Object} [metadata] Object defining error metadata
   * @param {String} [level] Level of the error. Currently, supports 'error' and 'fatal'
   * @param {string} [triggeredEvent]
   * @param {string} videoKey
   *
   * @memberof npaw.Plugin.prototype
   */
  fireError: function (object, msg, metadata, level, triggeredEvent, videoKey) {
    videoKey = videoKey || 'default'
    this.videos.getVideo(videoKey).fireError(object, msg, metadata, level, triggeredEvent)
  },

  /**
   * Calls fireErrors and then stops pings.
   *
   * @param {String|Object} [code] Error Code, if an object is sent, it will be treated as params.
   * @param {String} [msg] Error Message
   * @param {Object} [metadata] Object defining error metadata
   * @param {String} [level] Level of the error. Currently, supports 'error' and 'fatal'
   * @param {string} [triggeredEvent]
   * @param {string} videoKey
   *
   * @memberof npaw.Plugin.prototype
   */
  fireFatalError: function (object, msg, metadata, level, triggeredEvent, videoKey) {
    videoKey = videoKey || 'default'
    this.videos.getVideo(videoKey).fireFatalError(object, msg, metadata, level, triggeredEvent)
  },

  /**
   * Fires /stop. Should be used to terminate sessions once the player is gone or if
   * plugin.fireError() is called.
   *
   * @param {Object} [params] Object of key:value params.
   * @param {string} [triggeredEvent]
   * @param {string} videoKey
   *
   * @memberof npaw.Plugin.prototype
   */
  fireStop: function (params, triggeredEvent, videoKey) {
    videoKey = videoKey || 'default'
    this.videos.getVideo(videoKey).fireStop(params, triggeredEvent)
  },

  /**
   * Fires /offlineEvents. If offline is disabled, will try to send all the views stored.
   *
   * @param {Object} [params] Object of key:value params.
   * @param {string} videoKey
   *
   * @memberof npaw.Plugin.prototype
   */
  fireOfflineEvents: function (params, videoKey) {
    videoKey = videoKey || 'default'
    this.videos.getVideo(videoKey).fireOfflineEvents(params)
  }
}

module.exports = PluginFireMixin
