import { DEFAULT_PLAYER_CONFIG } from "@tv4/avod-web-player-common";
import { isMobile } from "@tv4/avod-web-player-device-capabilities";
import styled from "styled-components";

import GoToLiveArrow from "../icons/goToLiveArrow.svg";
import { useControls, usePlayerState } from "../providers/CoreProvider";
import { useLayoutSize } from "../providers/LayoutProvider";
import { useTranslations } from "../providers/TranslationsProvider";
import { StartOverButtons } from "../styles";
import { LayoutSize } from "../util/device";

const Button = styled.button<{ $opacity: number }>`
  ${StartOverButtons}
  opacity: ${({ $opacity }) => $opacity};
  svg {
    margin-left: 5px;
  }
`;

const GoToLiveButton = () => {
  const translations = useTranslations();
  const controls = useControls();
  const { currentTime, duration } = usePlayerState();
  const layoutSize = useLayoutSize();
  const useShortText =
    !isMobile() && [LayoutSize.SMALL, LayoutSize.SMALLEST].includes(layoutSize);
  const buttonText = translations(
    useShortText ? "start_over__watch_live_shortened" : "start_over__watch_live"
  );
  const isAtLiveEdge =
    duration - currentTime < DEFAULT_PLAYER_CONFIG.liveEdgeThreshold;

  const handleGoToLiveClick = () => {
    controls?.seekToLive?.();
  };

  return (
    <Button
      aria-label="navigate live"
      onClick={handleGoToLiveClick}
      $opacity={isAtLiveEdge ? 0.5 : 1}
    >
      <span>{buttonText}</span>
      <GoToLiveArrow />
    </Button>
  );
};

export default GoToLiveButton;
