import {
  defaultTextSizes,
  HTMLTextTrackSize,
  localPreferences,
} from "@tv4/avod-web-player-common";
import { useEffect } from "react";

import { useControls } from "../../../providers/CoreProvider";

export const usePreferredTextSize = () => {
  const controls = useControls();

  useEffect(() => {
    if (!controls) return;
    const size = localPreferences.getPreferredTextSize();

    controls.setSubtitleTextSize?.(
      defaultTextSizes[size || HTMLTextTrackSize.LARGE].size
    );
  }, [controls]);
};
