import { usePlayerState } from "../../providers/CoreProvider";
import { useLayoutSize } from "../../providers/LayoutProvider";
import { LayoutSize } from "../../util/device";
import CurrentProgramMetaData from "../CurrentProgramMetaData";
import {
  Body,
  Bottom,
  ButtonGroup,
  ButtonsContainer,
  ControlsContainer,
  Middle,
  Top,
} from "../DefaultControls/DefaultControls.styles";
import VolumeController from "../VolumeController/VolumeController";

export function SkinPreviewControls() {
  const { isFullscreen } = usePlayerState();
  const size = useLayoutSize();
  const isSmallestLayout = size === LayoutSize.SMALLEST;

  const showMetaData =
    isFullscreen && [LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size);

  return (
    <ControlsContainer $compact={isSmallestLayout}>
      <Body $isFullscreen={isFullscreen}>
        {showMetaData && (
          <>
            <Top>
              <CurrentProgramMetaData hidden={false} />
            </Top>
            <Middle />
            <Bottom />
          </>
        )}
      </Body>

      <div></div>

      <ButtonsContainer>
        <ButtonGroup $position="left" />
        <ButtonGroup $position="center" />
        <ButtonGroup $position="right">
          <VolumeController />
        </ButtonGroup>
      </ButtonsContainer>
    </ControlsContainer>
  );
}
