import { EventEmitter, IAd } from "@tv4/avod-web-player-common";

const BUFFERING_EVENTS_THRESHOLD = 5;
const BUFFERING_TIME_THRESHOLD = 2000;

export enum AdProgressMonitorEvents {
  FAILED = "FAILED",
}

export enum AdProgressFailureReason {
  TIMEOUT = "TIMEOUT",
  BUFFERING_EVENTS = "BUFFERING_EVENTS",
}

export type AdProgressFailurePayload = {
  reason: AdProgressFailureReason;
  videoAd?: IAd;
};

export interface AdProgressMonitorEventsMap {
  [AdProgressMonitorEvents.FAILED]: AdProgressFailurePayload;
}

export class AdProgressMonitor extends EventEmitter<AdProgressMonitorEventsMap> {
  private ongoingVideoAd?: IAd;

  private bufferingEvents: number;
  private bufferingTime: number;

  private bufferStart?: number;
  private bufferingTimeout?: number;

  constructor() {
    super();
    this.bufferingEvents = 0;
    this.bufferingTime = 0;
    this.bufferStart = undefined;
  }

  public adStarted(videoAd: IAd): void {
    this.flush();
    this.ongoingVideoAd = videoAd;
  }

  public adEnded(): void {
    this.flush();
  }

  private flush(): void {
    window.clearTimeout(this.bufferingTimeout);
    this.bufferingEvents = 0;
    this.bufferingTime = 0;
    this.bufferStart = undefined;
    this.ongoingVideoAd = undefined;
  }

  public bufferingStarted(): void {
    this.bufferingEvents += 1;
    this.bufferStart = Date.now();
    this.bufferingTimeout = window.setTimeout(() => {
      this.emit(AdProgressMonitorEvents.FAILED, {
        videoAd: this.ongoingVideoAd,
        reason: AdProgressFailureReason.TIMEOUT,
      });

      this.flush();
    }, BUFFERING_TIME_THRESHOLD);
    this.statusCheck();
  }

  public bufferingEnded(): void {
    window.clearTimeout(this.bufferingTimeout);
    if (this.bufferStart) {
      const bufferEnd = Date.now();
      const diff = bufferEnd - this.bufferStart;
      this.bufferingTime += diff;
    }
    this.statusCheck();
  }

  private statusCheck(): void {
    if (
      this.ongoingVideoAd &&
      (this.bufferingEvents >= BUFFERING_EVENTS_THRESHOLD ||
        this.bufferingTime >= BUFFERING_TIME_THRESHOLD)
    ) {
      this.emit(AdProgressMonitorEvents.FAILED, {
        videoAd: this.ongoingVideoAd,
        reason: AdProgressFailureReason.BUFFERING_EVENTS,
      });

      this.flush();
    }
  }
}
