import styled from "styled-components";

import { colors } from "../../theme/colors";

export const ProgressWrapper = styled.div<{ grabbing?: boolean }>`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 20px;
  width: 100%;
  margin: 0 0.75em;

  cursor: ${({ grabbing }) => (grabbing ? "grabbing" : "pointer")};
  touch-action: none;
  user-select: none;
`;

export const ProgressLine = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  height: 0.25em;
  width: 100%;

  background-color: rgba(140, 140, 140, 0.3);
  border-radius: 0.625em;
  overflow: visible;
`;

export const Progress = styled.div<{ playerProgressTintColor?: string }>`
  height: 100%;
  width: 0%;

  border-radius: 0.625em;
  background: ${({ playerProgressTintColor }) =>
    playerProgressTintColor ?? colors.primary};
`;

export const Handle = styled.div.attrs({
  className: "progressbar-handle", // this class is only used to override the opacity for the demo
})`
  position: relative;

  width: 1em;
  height: 1em;
  margin-left: -0.5em;
  border-radius: 0.9375em;

  user-select: none;
  pointer-events: none;

  background-color: #ffffff;
`;

export const AdMarkers = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0.625em;
`;

export const AdMarker = styled.div`
  position: absolute;
  top: 0;
  height: 0.25em;
  background: #edc54d;
  pointer-events: none;
`;

export const ScrubbingOverlayContainer = styled.div`
  position: absolute;
  left: 0;
  top: -1.5em;

  width: 100%;

  user-select: none;
`;

export const ScrubbingTime = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);

  color: #ffffff;
`;

export const ThumbnailImage = styled.div`
  position: absolute;
  bottom: 0.75em;
  user-select: none;

  border: 0.125em solid #ffffff;
  border-radius: 0.5em;
  background-color: #000000;
`;
