import {
  DEFAULT_PLAYER_CONFIG,
  PlayerMode,
  storage,
  StorageKey,
} from "@tv4/avod-web-player-common";

export function createElement<K extends keyof HTMLElementTagNameMap>(options: {
  type: K;
  parent: HTMLElement;
  classes?: string[];
}): HTMLElementTagNameMap[K] {
  const element = document.createElement(options.type);
  element.classList.add(...(options.classes || []));
  options.parent.appendChild(element);
  return element;
}

export function createVideoElement({
  muted = false,
  playerMode = PlayerMode.DEFAULT,
  loop,
  ...elementOptions
}: {
  muted: boolean;
  parent: HTMLElement;
  loop: boolean;
  playerMode?: PlayerMode;
  classes?: string[];
}): HTMLVideoElement {
  const videoElement = createElement({ type: "video", ...elementOptions });

  const storedVolumeLevel = storage.getData<number>(
    playerMode === PlayerMode.PREVIEW
      ? StorageKey.VOLUME_PREVIEW
      : StorageKey.VOLUME
  );
  const storedMutedSetting = storage.getData<boolean>(
    playerMode === PlayerMode.PREVIEW
      ? StorageKey.MUTED_PREVIEW
      : StorageKey.MUTED
  );
  if (storedMutedSetting) {
    videoElement.muted = true;
  } else {
    videoElement.muted = muted;
  }

  videoElement.volume = storedVolumeLevel ?? DEFAULT_PLAYER_CONFIG.volumeLevel;

  videoElement.loop = loop;

  // Only preload "none" is fairly consistent between browsers
  videoElement.preload = "none";
  videoElement.autoplay = false;
  videoElement.playsInline = true;

  return videoElement;
}
