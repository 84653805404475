/* global atv */
var NpawObject = require('./object')
var Chrono = require('./chrono')

var Timer = NpawObject.extend(
  /** @lends npaw.Timer.prototype */
  {
    /**
     * An Utility class that provides timed events in a defined time interval.
     *
     * @param {function} callback The callback to call every due interval.
     * Callback will receive lapsed ms between calls.
     * @param {int} [interval=5000] Milliseconds between each call.
     *
     * @constructs Timer
     * @extends npaw.NpawObject
     * @memberof npaw
     */
    constructor: function (callback, interval) {
      this.callback = callback;
      this.interval = interval || 5000;
      this.isRunning = false;
      this._timer = null;

      this.chrono = new Chrono();
    },

    /**
     * Starts the timer.
     */
    start: function () {
      if (!this.isRunning) {
        this.isRunning = true;
        this._setTick();
      }
    },

    /**
     * Stops the timer.
     */
    stop: function () {
      this.isRunning = false;
      if (this._timer) {
        try {
          clearTimeout(this._timer);
          this._timer = null;
        } catch (err) {
          if (typeof atv !== 'undefined') {
            atv.clearTimeout(this._timer);
            this._timer = null;
          }
        }
      }
    },

    /**
     * Sets the next tick execution.
     * @private
     */
    _setTick: function () {
      if (this.isRunning) {
        this.chrono.start();
        try {
          this._timer = setTimeout(
            function () {
              this.callback(this.chrono.stop());
              this._setTick();
            }.bind(this),
            this.interval
          );
        } catch (err) {
          if (typeof atv !== 'undefined') {
            this._timer = atv.setTimeout(
              function () {
                this.callback(this.chrono.stop());
                this._setTick();
              }.bind(this),
              this.interval
            );
          }
        }
      }
    }
  }
);

module.exports = Timer;
