var assign = require('./mixins/assign')
var createObject = require('./mixins/create')

var NpawObject = function () { }

NpawObject.prototype = {
  /**
   * A base class that other Classes should inherit from.
   * It has a no-op constructor intended to be overridden by classes that extend from this.
   *
   * @constructs NpawObject
   * @abstract
   * @memberof npaw
   */
  constructor: function () { }
}

/**
 * This method allows the extension of the current class, emulating 1-level inheritance.
 * It is inspired by Backbone's extend, but removing all references to underscore.
 *
 * @see http://backbonejs.org/#Model-extend
 *
 * @example
 * var A = NpawObject.extend({ a: 1, b: 1, c: { d: 0 } });
 * var a = new A(); // { a: 1, b: 1, c: { d: 0 } }
 *
 * var B = A.extend({ b: 2, c: { e: 3 } });
 * var b = new B(); // { a: 1, b: 2, c: { e: 3 } }
 *
 * @param  {Object} protoProps  Prototype properties (available on the instances)
 * @param  {Object} staticProps Static properties (available on the contructor)
 * @return {Object}             New sub class
 */
NpawObject.extend = function (protoProps, staticProps) {
  var parent = this
  var child

  // The constructor function for the new subclass is either defined by you
  // (the "constructor" property in your `extend` definition), or defaulted
  // by us to simply call the parent constructor.
  if (protoProps && protoProps.hasOwnProperty('constructor')) { // eslint-disable-line no-prototype-builtins
    child = protoProps.constructor
  } else {
    child = function () { return parent.apply(this, arguments) }
  }

  // Add static properties to the constructor function, if supplied.
  assign(child, parent, staticProps)

  // Set the prototype chain to inherit from `parent`, without calling
  // `parent`'s constructor function and add the prototype properties.
  child.prototype = createObject(parent.prototype)
  if (protoProps) assign(child.prototype, protoProps)
  child.prototype.constructor = child

  // Set a convenience property in case the parent's prototype is needed
  // later.
  child.__super__ = parent.prototype
  return child
}

module.exports = NpawObject
