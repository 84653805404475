import { PlaybackState, VisualElement } from "@tv4/avod-web-player-common";
import { useCallback, useState } from "react";

import { useControls, usePlayerState } from "../../providers/CoreProvider";
import { useCustomButton } from "../../providers/CustomButtonContext";
import {
  useLayoutOrientation,
  useLayoutSettings,
  useLayoutSize,
  useVisuals,
} from "../../providers/LayoutProvider";
import { LayoutOrientation, LayoutSize } from "../../util/device";
import { getDurationString, getTimeString } from "../../util/time";
import { useTracksMenu } from "../audio-text-tracks/TracksMenu/TracksMenu";
import { ChannelsButton } from "../ChannelsButton";
import CurrentProgramMetaData from "../CurrentProgramMetaData";
import GoToLiveButton from "../GoToLiveButton";
import GoToStartOverButton from "../GoToStartOverButton";
import LiveStatus from "../LiveStatus";
import { PlaybackRateButton } from "../PlaybackRateButton";
import { PlaybackRateSelector } from "../PlaybackRateSelector";
import PlayPauseButton from "../PlayPauseButton";
import ProgressBar from "../progress-bar/ProgressBar";
import SeekBackwardButton from "../SeekBackwardButton";
import SeekForwardButton from "../SeekForwardButton";
import { ShareButton } from "../ShareButton";
import { TimeLabel } from "../TimeLabel";
import VolumeController from "../VolumeController/VolumeController";
import {
  Body,
  Bottom,
  ButtonGroup,
  ButtonsContainer,
  ControlsContainer,
  LargeButtonsContainer,
  ProgressContainer,
  Top,
} from "./MobileControls.styles";

export function MobileControls() {
  const {
    canGoToLive,
    canSeek,
    canStartOver,
    canPause,
    currentTime,
    duration,
    isLive,
    isAd,
    isPauseAd,
    isFullscreen,
    playbackState,
    utcCurrentTimeMs,
  } = usePlayerState();
  const orientation = useLayoutOrientation();
  const size = useLayoutSize();
  const [showPlaybackRateSelector, setShowPlaybackRateSelector] =
    useState(false);

  const controls = useControls();

  const { onShareClick } = useLayoutSettings();

  const showTimeline = canSeek && !isPauseAd;
  const showSeekButtons = canSeek && !isPauseAd;
  // startover streams have no DVR window, and doesn't handle pausing well
  const showPlayPauseButton =
    canPause || playbackState === PlaybackState.PAUSED;

  const showGoToLiveButton = canGoToLive && !isPauseAd;
  const showStartOverButton = canStartOver && !isPauseAd;

  const showMetaData =
    orientation === LayoutOrientation.PORTRAIT &&
    [LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size);
  const { showMobileMuteButton } = useLayoutSettings();
  const showRemainingTime = !isLive && size !== LayoutSize.SMALLEST;

  const visuals = useVisuals();
  const showChannelsButton = visuals?.includes(VisualElement.CHANNELS_BUTTON);
  const { closeTracksMenu, TracksMenu, TracksButton } = useTracksMenu();
  const { customButton } = useCustomButton();

  const showPlaybackRateButton =
    controls?.setPlaybackRate && !isAd && !isPauseAd && !isLive;

  const playbackRateClickHandler = useCallback(() => {
    setShowPlaybackRateSelector((prev) => !prev);
  }, []);

  return (
    <ControlsContainer>
      <Body
        isFullscreen={isFullscreen}
        orientation={orientation}
        onClick={closeTracksMenu}
      >
        <Top>
          {showMetaData && <CurrentProgramMetaData hidden={!isFullscreen} />}
        </Top>
        <LargeButtonsContainer>
          {showSeekButtons ? <SeekBackwardButton /> : <div />}
          {showPlayPauseButton && <PlayPauseButton />}
          {showSeekButtons ? <SeekForwardButton /> : <div />}
        </LargeButtonsContainer>
        <Bottom>
          {showPlaybackRateButton && showPlaybackRateSelector && (
            <PlaybackRateSelector
              isMobile
              onSelected={() => setShowPlaybackRateSelector(false)}
            />
          )}
        </Bottom>
      </Body>

      <ProgressContainer>
        {showTimeline && (
          <>
            <TimeLabel>
              {utcCurrentTimeMs
                ? getTimeString(utcCurrentTimeMs)
                : getDurationString(currentTime)}
            </TimeLabel>
            <ProgressBar />
            {isLive && <LiveStatus />}
            {showRemainingTime && (
              <TimeLabel>{getDurationString(currentTime - duration)}</TimeLabel>
            )}
          </>
        )}
      </ProgressContainer>

      <ButtonsContainer>
        <ButtonGroup $position="left">
          {showStartOverButton && <GoToStartOverButton />}
        </ButtonGroup>
        <ButtonGroup $position="center">
          {showPlaybackRateButton && (
            <PlaybackRateButton onClick={playbackRateClickHandler} />
          )}
          {TracksButton && <TracksButton />}
          {customButton}
          {onShareClick && <ShareButton onClick={onShareClick} />}
          {showChannelsButton && <ChannelsButton />}
        </ButtonGroup>
        <ButtonGroup $position="right">
          {showGoToLiveButton && <GoToLiveButton />}
          {showMobileMuteButton && <VolumeController />}
        </ButtonGroup>
      </ButtonsContainer>
      {TracksMenu && <TracksMenu />}
    </ControlsContainer>
  );
}
