export default class CoreConstants {
}
CoreConstants.Products = {
    VIDEO_ANALYTICS: 'videoAnalytics',
    BALANCER: 'balancer',
    DEVICE_ANALYTICS: 'deviceAnalytics'
};
CoreConstants.AnalyticsVariables = {
    VIEW_CODE: 'viewCode',
    METHOD_OPTION: 'methodOption'
};
CoreConstants.BalancerVariables = {
    AS_ENABLED: 'activeSwitchingEnabled',
    PROFILE_NAME: 'profileName',
    BUCKET: 'bucket',
    DECISION_FINISHED: 'decisionFinished',
    LAST_USED_CDN: 'lastUsedCdn',
    BALANCER_TRAFFIC: 'balancerTraffic',
    BALANCER_BITRATE: 'balancerBitrate'
};
CoreConstants.Consumer = {
    NQS: 'NQS',
    PERSISTENT: 'PERSISTENT',
    BLACK_HOLE: 'BLACK_HOLE'
};
CoreConstants.DeviceVariables = {
    APP_ANALYTICS_DEVICE_STRING: 'deviceString'
};
