/**
 * no gtm code is loaded by this file,
 * gtm is expected to already be loaded
 * in the environment where this code
 * is used.
 */

import {
  AdBreakPayload,
  AdClickPayload,
  AdPayload,
  AdVideoVariant,
  DroppedFramesPayload,
  ErrorPayload,
  GATrackingMetadata,
  Metadata,
  PlaybackErrorPayload,
  TimePayload,
  TrackPayload,
} from "@tv4/avod-web-player-common";
import { isChromecast } from "@tv4/avod-web-player-device-capabilities";

import {
  CoreTrackingEvent,
  getAdEventType,
  IGTMCoreTrackerInstanceOptions,
  TAssetData,
  TDataLayerEventTypes,
  TPlayerData,
} from "./gtm-utils/tracking_events";
import { ITrackerSetupOptions, Tracker } from "./tracker";

declare global {
  interface Window {
    dataLayer: Array<TDataLayerEventTypes>;
  }
}

export class GTMCoreTracking extends Tracker {
  private debug: boolean = false;

  private sessionId: string = "";
  private metadata?: Metadata;
  private trackingMetadata: GATrackingMetadata;

  private additionalCoreTrackingParams: Record<string, unknown>;

  private adBreakOngoing?: boolean;
  private ongoingAdId?: string;
  private adBreakTimeElapsed?: number;

  constructor(options: IGTMCoreTrackerInstanceOptions) {
    super();
    if (options?.debug) {
      this.debug = options.debug;
      console.debug("GTMCoreTracking initialized");
    }
    this.additionalCoreTrackingParams =
      options?.additionalCoreTrackingParams || {};

    this.trackingMetadata = options.trackingMetadata;
  }

  public override setup(options: ITrackerSetupOptions): void {
    this.metadata = options.metadata;
    this.sessionId = options.sessionId || "";

    this.setupUserId(options.userId);
  }

  public onLoading(): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_LOADING);
  }

  public onLoaded(): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_START_INIT);
  }

  public onStart(): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_START);
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_PLAY);
  }

  public onPause(_data: TimePayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_PAUSE);
  }

  public onResume(_data: TimePayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_RESUME);
  }

  public onReset(): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_RESET);
  }

  public onSeek(_data: TimePayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_SEEK);
  }

  public onSeeked(_data: TimePayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_SEEKED);
  }

  public onBuffering(_data: TimePayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_BUFFERING);
  }

  public onBuffered(_data: TimePayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_BUFFERED);
  }

  public onError(data: PlaybackErrorPayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_ERROR, { ...data });
    this.destroy();
  }

  public onEnded(): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_ENDED);
    this.destroy();
  }

  public onTrackChanged(data: TrackPayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_BITRATE_CHANGED, {
      ...data,
    });
  }

  public onDroppedFrames(data: DroppedFramesPayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_DROPPED_FRAMES, {
      ...data,
    });
  }

  public onAdBreakStart(_data: AdBreakPayload): void {
    this.adBreakOngoing = true;
    this.adBreakTimeElapsed = 0;

    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_ADBREAK_START, {
      ad_break_time_elapsed: this.adBreakTimeElapsed,
    });
  }

  public onAdBreakEnd(): void {
    if (!this.adBreakOngoing) return;

    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_ADBREAK_END, {
      ad_break_time_elapsed: this.adBreakTimeElapsed,
    });

    this.adBreakOngoing = false;
    this.adBreakTimeElapsed = undefined;
    this.ongoingAdId = undefined;

    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_RESUME);
  }

  public onAdLoading(data: AdPayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_AD_LOADING, {
      ...data,
    });
  }

  public onAdLoaded(data: AdPayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_AD_LOADED, {
      ...data,
    });
  }

  public onAdPlaying(data: AdPayload): void {
    this.ongoingAdId = data.ad.universalAdId || data.ad.id;
    this.pushMergedDataToDataLayer(getAdEventType(data.ad), {
      ad_id: this.ongoingAdId,
      ...(data.ad.variant === AdVideoVariant.TRAILER && {
        ad_id: undefined,
        trailer_id: data.ad.id,
      }),
    });
  }

  public onAdPaused(_data: TimePayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_AD_PAUSED);
  }

  public onAdResume(_data: TimePayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_AD_RESUME);
  }

  public onAdBuffering(_data: TimePayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_AD_BUFFERING);
  }

  public onAdBuffered(_data: TimePayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_AD_BUFFERED);
  }

  public onAdEnded(): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_AD_ENDED);
  }

  public onAdError(data: ErrorPayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_AD_ERROR, {
      ...data,
    });
  }

  public onAdClick(data: AdClickPayload): void {
    this.pushMergedDataToDataLayer(CoreTrackingEvent.STREAM_AD_CLICK, {
      ...data,
    });
  }

  private setupUserId(userId: string): void {
    if (isChromecast() && userId && this.sessionId) {
      this.pushToDataLayer({
        event: CoreTrackingEvent.SET_USER_PROPERTIES,
        user_id: userId,
        sessionId: this.sessionId,
      });
    }
  }

  private getAssetData(): TAssetData {
    return {
      content_asset_id: this.trackingMetadata.id,
      content_asset_title: this.trackingMetadata.title,
      content_asset_category: this.trackingMetadata.category ?? "",
      content_asset_season: this.trackingMetadata.seasonNumber,
      content_asset_type: this.trackingMetadata.videoType,
      content_program_nid: this.metadata?.asset.programNid || "",
      content_series_title: this.metadata?.asset.seriesTitle,
      content_asset_episode: this.metadata?.asset.episodeNumber ?? undefined,
      content_asset_duration: this.metadata?.asset.duration ?? 0,
    };
  }

  private getPlayerData(): TPlayerData {
    return {
      player_is_autoplay: false, // TODO: how should we set whether autplay succeeded?,
    };
  }

  private getAdditionalCoreTrackingParams(): Record<string, unknown> {
    return this.additionalCoreTrackingParams;
  }

  private pushMergedDataToDataLayer(
    event: CoreTrackingEvent,
    additionalData?: Record<string, unknown>
  ): void {
    this.pushToDataLayer({
      event,
      ...this.getAssetData(),
      ...this.getPlayerData(),
      ...this.getAdditionalCoreTrackingParams(),
      ...additionalData,
      sessionId: this.sessionId,
    });
  }

  private static triggerEvent = (payload: TDataLayerEventTypes): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(payload);
  };

  private pushToDataLayer = (payload: TDataLayerEventTypes): void => {
    if (this.debug) {
      console.debug(`GTMCoreTracking - ${payload.event}`, payload);
    }
    GTMCoreTracking.triggerEvent(payload);
  };

  public destroy(): void {
    // nothing to destroy
  }
}
