import { isSupportedOS as isDesktopOS } from "@tv4/avod-web-player-device-capabilities";
import styled, { css, keyframes } from "styled-components";

import { PlayerControlButton } from "../../styles";
import { colors } from "../../theme/colors";

const fadeOut = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 0; width: 0; }
`;

const slideDown = keyframes`
    0% { max-height: 0; }
    100% { max-height: 2.5em }
`;

const Message = styled.div``;
const MinimizedMessage = styled.div`
  margin-right: 0.2em;
`;
const CloseButton = styled(PlayerControlButton)``;
const AnimationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5em;
  background-color: rgba(62, 62, 61, 0.7);
  transition: all 0.5s ease-in-out;
  height: 2.5em;
`;

const Container = styled.div<{
  visible: boolean;
  open?: boolean;
  isSmallestLayout?: boolean;
}>`
  width: 100%;
  color: ${colors.white};
  ${({ visible }) => !visible && "overflow: hidden;"}
  max-height: 0;
  ${({ visible }) =>
    visible &&
    css`
      animation: ${slideDown} 0.5s ease-in-out forwards;
    `}

  ${Message} {
    padding: 0.5em 0;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: opacity 0.4s ease-in-out;
    font-weight: 500;
  }

  ${AnimationContainer} {
    ${(options) =>
      !options.open &&
      !options.isSmallestLayout &&
      css`
        margin-left: calc(100% - ${isDesktopOS() ? "100px" : "85px"});
        ${Message} {
          animation: ${fadeOut} 0.2s ease-in-out forwards;
        }
      `}
    ${(options) =>
      options.isSmallestLayout &&
      css`
        margin-left: calc(100% - 55px);
        font-size: 0.6em;
        --button-size: 12px;
      `};
  }

  ${CloseButton} {
    svg {
      max-height: 1.5em;
    }
  }
`;

export {
  AnimationContainer,
  CloseButton,
  Container,
  Message,
  MinimizedMessage,
};
