var NpawObject = require('../object')
var Storage = require('./storage')

/**
 * This class manages data storage for offline events in an storage.
 *
 * @extends npaw.Emitter
 * @memberof npaw
 */
var OfflineStorage = NpawObject.extend({
  constructor: function () {
    this.storage = new Storage('npawOffline', true) // No cookies for offline
    this.actualView = null
    this.viewList = []
    this.givenIds = []
    this._getOldViewList()
  },

  _newView: function () {
    // search for first unused id
    var viewId = this._getValidId()

    // create empty view
    this.storage.setLocal(viewId, '')

    // add to viewlist
    this.viewList.push(viewId)
    this.storage.setLocal('views', this.viewList)

    // save the id
    this.actualView = viewId
  },

  addEvent: function (event, params) {
    // if event is start, create new view
    if (event === '/start') this._newView()

    // if event is init, ignore
    if (event === '/init') return null

    // if we have no view discard
    if (!this.actualView) return null

    // create event object
    var fullEvent = '{"request":"' + event.slice(1) + '",' // {"request":"start",
    fullEvent += '"unixtime":' + Date.now() + ',' // "unixtime":1499876515,
    for (var param in params) {
      if (params[param] === undefined) break
      fullEvent += '"' + param + '":' // "aParam":
      if (param === 'code') {
        fullEvent += '"' + 'CODE_PLACEHOLDER' + '",' // "CODE_PLACEHOLDER",
      } else if (param === 'sessionId') {
        fullEvent += '"' + 'SESSION_PLACEHOLDER' + '",' // "SESSION_PLACEHOLDER",
      } else if (param === 'sessionRoot') {
        fullEvent += '"' + 'ROOT_PLACEHOLDER' + '",' // "ROOT_PLACEHOLDER",
      } else if (typeof params[param] === 'string') {
        fullEvent += '"' + params[param] + '",' // "aStringValue",
      } else if (typeof params[param] === 'object') {
        fullEvent += '"' + JSON.stringify(params[param]).replace(/"/g, '\\"') + '",' // json object
      } else {
        fullEvent += params[param] + ',' // 123, for example
      }
    }
    fullEvent = fullEvent.slice(0, -1) // remove the last comma
    fullEvent += '}'

    // get accumulated view object
    var temp = this.storage.getLocal(this.actualView)
    if (temp !== '') temp = temp + ',' // comma between events

    // add the event
    this.storage.setLocal(this.actualView, temp + fullEvent)
  },

  getView: function () {
    if (this.viewList.length > this.givenIds.length) { // if we have any view not sent yet
      var position = 0
      while (true) {
        var idToSend = this.viewList[position]
        if (this.givenIds.indexOf(idToSend) < 0) {
          this.givenIds.push(idToSend)
          return ['[' + this.storage.getLocal(idToSend) + ']', idToSend]
        }
        position++
      }
    }
    return [null, null]
  },

  removeView: function (id) {
    this.storage.removeLocal(id)
    var position = this.viewList.indexOf(id)
    if (position !== -1) this.viewList.splice(position, 1)
    position = this.givenIds.indexOf(id)
    if (position !== -1) this.givenIds.splice(position, 1)
    this.storage.setLocal('views', this.viewList.toString())
    if (id === this.actualView) this.actualView = null
  },

  _getOldViewList: function () {
    var stringList = ''
    if (this.storage.getLocal('views')) {
      stringList = this.storage.getLocal('views')
    } else {
      this.storage.setLocal('views', '')
    }

    if (stringList === '') {
      this.viewList = []
    } else if (typeof stringList.split === 'function') {
      this.viewList = stringList.split(',')
    }
  },

  _getValidId: function () {
    // get a value not in the list
    var id = Math.floor(Math.random() * 1e8).toString()
    if (this.viewList.indexOf(id) >= 0) {
      return this._getValidId()
    }
    return id
  },

  sent: function () {
    this.givenIds = []
  }
})

module.exports = OfflineStorage
