import {
  AdVideoVariant,
  GATrackingMetadata,
  IAd,
} from "@tv4/avod-web-player-common";

export enum CoreTrackingEvent {
  SET_USER_PROPERTIES = "set_user_properties",
  STREAM_START = "stream_start",
  STREAM_PLAY = "stream_play",
  STREAM_PAUSE = "stream_pause",
  STREAM_RESUME = "stream_resume",
  STREAM_RESET = "stream_reset",
  STREAM_SEEK = "stream_seek",
  STREAM_SEEKED = "stream_seeked",
  STREAM_BUFFERING = "stream_buffering",
  STREAM_BUFFERED = "stream_buffered",
  STREAM_ERROR = "stream_error",
  STREAM_ENDED = "stream_ended",
  STREAM_BITRATE_CHANGED = "stream_bitrate_changed",
  STREAM_DROPPED_FRAMES = "stream_dropped_frames",
  STREAM_LOADING = "stream_loading",
  STREAM_START_INIT = "stream_start_init",
  STREAM_PROGRESS = "stream_progress",
  CHROMECAST_CONNECT = "chromecast_connect",
  CHROMECAST_DISCONNECT = "chromecast_disconnect",
  STARTOVER_DIALOGUE = "start_over_dialogue",
  STARTOVER_DIALOGUE_INTERACTION_LIVE_AUTOPLAY = "start_over_dialogue_interaction_live_autoplay",
  STARTOVER_DIALOGUE_INTERACTION_LIVE = "start_over_dialogue_interaction_live",
  STARTOVER_DIALOGUE_INTERACTION_START_OVER = "start_over_dialogue_interaction_start_over",
  STREAM_ADBREAK_START = "ad_break_start",
  STREAM_ADBREAK_END = "ad_break_end",
  STREAM_ADBREAK_PROGRESS = "ad_break_progress",
  STREAM_AD_LOADING = "stream_ad_loading",
  STREAM_AD_LOADED = "stream_ad_loaded",
  STREAM_AD_START = "stream_ad_start",
  STREAM_AD_PAUSED = "stream_ad_paused",
  STREAM_AD_RESUME = "stream_ad_resume",
  STREAM_AD_BUFFERING = "stream_ad_buffering",
  STREAM_AD_BUFFERED = "stream_ad_buffered",
  STREAM_AD_ENDED = "stream_ad_ended",
  STREAM_AD_ERROR = "stream_ad_error",
  STREAM_AD_CLICK = "stream_ad_click",
  STREAM_SPONSOR_START = "stream_sponsor_start",
  STREAM_TRAILER_START = "stream_trailer_start",
  STREAM_JINGLE_START = "stream_jingle_start",
  STREAM_FILLER_START = "stream_filler_start",
  EXPERIMENT = "experiment",
}

export interface IGTMCoreTrackerInstanceOptions {
  additionalCoreTrackingParams?: Record<string, unknown>;
  debug?: boolean;
  trackingMetadata: GATrackingMetadata;
}

export type TPlayerData = {
  player_is_autoplay: boolean;
};

export type TAssetData = {
  content_program_nid: string;
  content_series_title: string | undefined;
  content_asset_id: string;
  content_asset_title: string;
  content_asset_category: string;
  content_asset_season: number | undefined;
  content_asset_episode: number | undefined;
  content_asset_type: GATrackingMetadata["videoType"];
  content_asset_duration: number;
};

type TDataLayerEvent = {
  event: CoreTrackingEvent;
  sessionId: string;
};

type TDataLayerProgressEvent = {
  event: CoreTrackingEvent.STREAM_PROGRESS;
  stream_time_elapsed: number;
  sessionId: string;
};

type TDataLayerAdBreakEvent = {
  event:
    | CoreTrackingEvent.STREAM_ADBREAK_START
    | CoreTrackingEvent.STREAM_ADBREAK_PROGRESS
    | CoreTrackingEvent.STREAM_ADBREAK_END;
  ad_break_time_elapsed?: number;
  sessionId: string;
};

type TDataLayerAdEvent = {
  event: CoreTrackingEvent.STREAM_AD_START;
  ad_id: string;
  sessionId: string;
};

type TDataLayerTrailerEvent = {
  event: CoreTrackingEvent.STREAM_TRAILER_START;
  trailer_id: string;
  sessionId: string;
};

type TDataLayerUserEvent = {
  event: CoreTrackingEvent.SET_USER_PROPERTIES;
  user_id: string;
  sessionId: string;
};

type TDataLayerExperimentEvent = {
  event: string;
  experiment_name: string;
  experiment_group: string;
  sessionId: string;
};

type TDataLayerCustomEvent = TPlayerData & TAssetData & TDataLayerEvent;

export type TDataLayerEventTypes =
  | TDataLayerEvent
  | TDataLayerUserEvent
  | TDataLayerCustomEvent
  | TDataLayerProgressEvent
  | TDataLayerAdBreakEvent
  | TDataLayerAdEvent
  | TDataLayerTrailerEvent
  | TDataLayerExperimentEvent;

export const getAdEventType = (ad: IAd) => {
  if (ad.variant === AdVideoVariant.SPONSOR)
    return CoreTrackingEvent.STREAM_SPONSOR_START;

  // The "bumper" title is controlled on our side. The
  // code that generates the title has a comment in it that
  // we use it for tracking purposes. It should not change
  // unexpectedly.
  if (ad.variant === AdVideoVariant.VIGNETTE)
    return CoreTrackingEvent.STREAM_JINGLE_START;

  // yospace controls the "filler" title. If it changes
  // on their side, fillers will start being tracked as
  // regular ads.
  if (ad.title.indexOf("filler") === 0)
    return CoreTrackingEvent.STREAM_FILLER_START;

  // Again, this may or may not remain true in the future.
  // Best effort tracking to differentiate from "regular"
  // ads.
  if (ad.variant === AdVideoVariant.TRAILER)
    return CoreTrackingEvent.STREAM_TRAILER_START;

  return CoreTrackingEvent.STREAM_AD_START;
};
