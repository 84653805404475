var NpawObject = require('../object')
var Chrono = require('../chrono')

var PlaybackChronos = NpawObject.extend(
  /** @lends npaw.PlaybackChronos.prototype */
  {
    /**
     * This class contains all the {@link Chrono}s related to view status.
     * Chronos measure time lapses between events.
     * ie: between start and join, between seek-begin and seek-end, etc.
     * Each plugin will have an instance of this class.
     *
     * @constructs PlaybackChronos
     * @extends npaw.NpawObject
     * @memberof npaw
     */
    constructor: function () {
      this.reset()
    },

    /** Reset flag values */
    reset: function () {
      /** Chrono between start and joinTime. */
      this.join = new Chrono()

      /** Chrono between seek-begin and seek-end. */
      this.seek = new Chrono()

      /** Chrono between pause and resume. */
      this.pause = new Chrono()

      /** Chrono between buffer-begin and buffer-end. */
      this.buffer = new Chrono()

      /** Chrono for the totality of the view. */
      this.total = new Chrono()

      // For ads

      /** List of chronos with seen intervals */
      this.viewedMax = []
    }
  }
)

module.exports = PlaybackChronos
