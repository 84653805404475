import {
  AssetType,
  AWPAudioTrack,
  AWPTextTrack,
  AWPTextTrackKind,
  BufferSeekingPayload,
  Capabilities,
  CoreEvents,
  CoreEventsMap,
  DEFAULT_PLAYER_CONFIG,
  DEFAULT_PLAYER_STATE,
  DISABLED_TEXT,
  EventEmitter,
  getInitialAudioLanguage,
  getInitialTextLanguage,
  LoadedPlaybackPayload,
  localPreferences,
  Media,
  Metadata,
  PauseOptions,
  PausePayload,
  PlaybackMode,
  PlaybackRateChangePayload,
  PlaybackRestrictionsPayload,
  PlayerMode,
  Service,
  StreamInfoPayload,
  TControls,
  TimePayload,
  Track,
} from "@tv4/avod-web-player-common";

import type { HtmlVideoEngine } from "../media_engine/HtmlVideoEngine";
import {
  MediaAudioTrackPayload,
  MediaDroppedFramesPayload,
  MediaErrorPayload,
  MediaEvents,
  MediaEventsMap,
  MediaID3Payload,
  MediaInStreamMetadataPayload,
  MediaLoadingPayload,
  MediaTextTrackCueEnteredPayload,
  MediaTextTrackPayload,
  MediaTrackPayload,
  MediaVolumePayload,
} from "../media_engine/utils/mediaConstants";
import { FullscreenController } from "../utils/FullscreenController";
import { syncSessionPlaybackSettings } from "./sync-session-playback-settings";

type PlaybackRestrictions = {
  canSeek: boolean;
  canPause: boolean;
  canGoToLive: boolean;
  canStartOver: boolean;
};

export type PlaybackOptions = {
  mediaEngine: HtmlVideoEngine;
  media: Media;
  availablePlaybackModes: PlaybackMode[];
  capabilities: Capabilities;
  metadata?: Metadata;
  fullscreenElementId: string;
  iosNativeFullscreen?: boolean;
  service: Service;
  enableFullscreen: boolean;
  playerMode: PlayerMode;
};

export type PlaybackLoadOptions = {
  autoplay: boolean;
  startTime?: number;
  useInitialAdImmunity?: boolean;
};
const PLAYBACK_MODE_SWITCH_THRESHOLD =
  DEFAULT_PLAYER_CONFIG.playbackModeSwitchThreshold;

const getTrackByLanguage = (
  language: string | null | undefined,
  tracks: Track[]
): Track | undefined => tracks.find((track) => track.language === language);

const getTextTrackByKind = (
  kind: string,
  textTracks: AWPTextTrack[]
): AWPTextTrack | undefined => textTracks.find((track) => track.kind === kind);

export class BasePlayback extends EventEmitter<CoreEventsMap> {
  public readonly name: string = "BasePlayback";
  private fullscreenController?: FullscreenController;

  protected media: Media;
  protected availablePlaybackModes: PlaybackMode[];
  protected capabilities: Capabilities;
  protected metadata?: Metadata;
  protected mediaEngine: HtmlVideoEngine;
  protected playerMode: PlayerMode;
  private hasStarted: boolean = false;
  private endCreditsEmitted = false;

  private playbackMode: PlaybackMode = DEFAULT_PLAYER_STATE.playbackMode!;
  private streamInfo?: StreamInfoPayload;
  private streamInfoMediaOffset: number | undefined;
  protected preSeekAmount = 0;

  protected restrictions: PlaybackRestrictions;

  protected destroyed = false;

  protected service: Service;

  constructor({
    media,
    availablePlaybackModes,
    capabilities,
    metadata,
    mediaEngine,
    fullscreenElementId,
    iosNativeFullscreen,
    service,
    enableFullscreen,
    playerMode,
  }: PlaybackOptions) {
    super();

    this.media = media;
    this.availablePlaybackModes = availablePlaybackModes;
    this.metadata = metadata;
    this.mediaEngine = mediaEngine;
    this.playerMode = playerMode;
    this.service = service;
    this.capabilities = capabilities;
    const liveSeekable = !!metadata?.asset.isLive && capabilities.seek;
    this.restrictions = {
      canSeek: this.capabilities.seek,
      canPause: this.capabilities.pause,
      canGoToLive: liveSeekable,
      canStartOver: liveSeekable && metadata.asset.type !== AssetType.CHANNEL,
    };

    const fullscreenElement = document.getElementById(fullscreenElementId);
    this.fullscreenController =
      enableFullscreen && fullscreenElement
        ? new FullscreenController(fullscreenElement, iosNativeFullscreen)
        : undefined;

    this.fullscreenController?.on(CoreEvents.FULLSCREEN_CHANGED, (data) => {
      this.emit(CoreEvents.FULLSCREEN_CHANGED, data);
    });

    this.addListener(MediaEvents.LOADING, this.onLoading);
    this.addListener(MediaEvents.LOADED, this.onLoaded);
    this.addListener(MediaEvents.PLAYING, this.onPlaying);
    this.addListener(MediaEvents.PAUSED, this.onPaused);
    this.addListener(MediaEvents.TIME_UPDATE, this.onTimeUpdate);
    this.addListener(MediaEvents.SEEKING, this.onSeeking);
    this.addListener(MediaEvents.SEEKED, this.onSeeked);
    this.addListener(MediaEvents.BUFFERING, this.onBuffering);
    this.addListener(MediaEvents.BUFFERED, this.onBuffered);
    this.addListener(MediaEvents.END_OF_STREAM, this.onEnded);
    this.addListener(MediaEvents.ID3, this.onID3);
    this.addListener(MediaEvents.VOLUME_CHANGED, this.onVolumeChanged);
    this.addListener(MediaEvents.TRACK_CHANGED, this.onTrackChanged);
    this.addListener(MediaEvents.DROPPED_FRAMES, this.onDroppedFrames);
    this.addListener(MediaEvents.AUDIO_TRACK_CHANGED, this.onAudioTrackChanged);
    this.addListener(MediaEvents.TEXT_TRACK_CHANGED, this.onTextTrackChanged);
    this.addListener(MediaEvents.TEXT_CUE_ENTERED, this.onSubtitleTextChanged);
    this.addListener(MediaEvents.TEXT_CUE_EXITED, this.onSubtitleTextChanged);
    this.addListener(MediaEvents.IN_STREAM_METADATA, this.onInStreamMetadata);
    this.addListener(MediaEvents.ERROR, this.onError);
    this.addListener(MediaEvents.AUTOPLAY_BLOCKED, this.onAutoplayBlocked);
    this.addListener(MediaEvents.SUCCESSFUL_PLAY, this.onSuccessfulPlay);
    this.addListener(
      MediaEvents.PLAYBACK_RATE_CHANGE,
      this.onPlaybackRateChange
    );
    this.addListener(MediaEvents.DRM_LICENSE_ERROR, this.onDrmLicenseExpired);
    this.addListener(MediaEvents.CDN_CHANGED, ({ cdn }) => {
      this.emit(CoreEvents.CDN_CHANGED, { cdn });
    });
  }

  private addListener<K extends keyof MediaEventsMap>(
    type: K,
    handler: (event: MediaEventsMap[K]) => void
  ) {
    this.mediaEngine.on(type, handler.bind(this));
  }

  protected emitLoadedPlayback(payload: Partial<LoadedPlaybackPayload>): void {
    this.emit(CoreEvents.LOADED_PLAYBACK, {
      currentTime: payload.currentTime ?? 0,
      duration: payload.duration ?? 0,
      isInAdBreak: payload.isInAdBreak ?? false,
      // mediaEngine.isLive() is not reliably returned for short origin live streams (loaded event happens before mediaEngine can return correct value)
      // so go by metadata instead. Maybe always go by metadata, since this is only emitted at startup?
      isLive: this.mediaEngine.isLive() || !!this.metadata?.asset.isLive,
      volume: this.mediaEngine.getVolume(),
      muted: this.mediaEngine.getMuted(),
      ...payload,
    });
  }

  protected setRestrictions({
    canSeek,
    canPause,
    canGoToLive,
    canStartOver,
  }: Partial<PlaybackRestrictionsPayload> = {}) {
    this.restrictions = {
      canGoToLive: canGoToLive ?? this.restrictions.canGoToLive,
      canStartOver: canStartOver ?? this.restrictions.canStartOver,
      canSeek: this.capabilities.seek && (canSeek ?? this.restrictions.canSeek),
      canPause:
        this.capabilities.pause && (canPause ?? this.restrictions.canPause),
    };

    this.mediaEngine.setIsSeekable(this.restrictions.canSeek);

    this.emit(CoreEvents.PLAYBACK_RESTRICTIONS, {
      ...this.restrictions,
    });
  }

  /** resets the runtime restrictions to reflect the initial stream capabilities only */
  private resetRestrictions() {
    this.setRestrictions({
      canSeek: this.capabilities.seek,
      canPause: this.capabilities.pause,
    });
  }

  protected onLoading(payload: MediaLoadingPayload) {
    this.emit(CoreEvents.CONTENT_MEDIA_LOADING, payload);
  }

  protected onLoaded(payload: TimePayload) {
    this.setInitialAudioTrack(); // need to set audio first so initial text track can be decided based on current audio
    this.setInitialTextTrack();
    this.resetRestrictions();
    this.emitLoadedPlayback(payload);
  }

  protected onPlaybackRateChange(data: PlaybackRateChangePayload) {
    this.emit(CoreEvents.PLAYBACK_RATE_CHANGE, data);
  }

  protected onPlaying(payload: TimePayload) {
    if (!this.hasStarted) {
      this.hasStarted = true;
      this.emit(CoreEvents.START, payload);
    } else {
      this.emit(CoreEvents.RESUME, payload);
    }
  }

  protected onPaused(payload: PausePayload) {
    this.emit(CoreEvents.PAUSED, payload);
  }

  protected onTimeUpdate(payload: TimePayload) {
    this.emit(CoreEvents.TIME_UPDATED, payload);
    this.emitCreditsStartIfPastCreditsTimestamp(payload.currentTime);
  }

  protected onSeeking(payload: BufferSeekingPayload) {
    this.emit(CoreEvents.SEEKING, payload);

    this.setSubtitleText("");
  }

  protected onSeeked(payload: BufferSeekingPayload) {
    this.emit(CoreEvents.SEEKED, payload);
  }

  protected onBuffering(payload: BufferSeekingPayload) {
    this.emit(CoreEvents.BUFFERING, payload);
  }

  protected onBuffered(payload: BufferSeekingPayload) {
    this.emit(CoreEvents.BUFFERED, payload);
  }

  protected onEnded(payload: TimePayload) {
    this.emit(CoreEvents.ENDED, payload);
  }

  protected onID3(_id3: MediaID3Payload) {
    /*no-op*/
  }

  protected onVolumeChanged({ volume, muted }: MediaVolumePayload) {
    this.emit(CoreEvents.VOLUME_CHANGED, { volume, muted });
  }

  protected onTrackChanged(data: MediaTrackPayload) {
    this.emit(CoreEvents.TRACK_CHANGED, data);
  }

  protected onDroppedFrames({ droppedFrames }: MediaDroppedFramesPayload) {
    this.emit(CoreEvents.DROPPED_FRAMES, { droppedFrames });
  }

  protected onAudioTrackChanged({
    activeAudioTrack,
    audioTracks,
  }: MediaAudioTrackPayload) {
    this.emit(CoreEvents.AUDIO_TRACK_CHANGED, {
      activeAudioTrack,
      audioTracks,
    });
  }

  protected onTextTrackChanged({
    activeTextTrack,
    textTracks,
  }: MediaTextTrackPayload) {
    this.emit(CoreEvents.TEXT_TRACK_CHANGED, {
      activeTextTrack,
      textTracks,
    });

    this.setSubtitleText("");
  }

  protected onSubtitleTextChanged(payload?: MediaTextTrackCueEnteredPayload) {
    this.setSubtitleText(payload?.text || "");
  }

  protected onInStreamMetadata({ title }: MediaInStreamMetadataPayload) {
    this.emit(CoreEvents.IN_STREAM_METADATA, { title });
  }

  protected onError(payload: MediaErrorPayload) {
    this.emit(CoreEvents.ERROR, payload);
  }

  protected onAutoplayBlocked() {
    this.emit(CoreEvents.AUTOPLAY_BLOCKED, undefined);
  }

  protected onSuccessfulPlay() {
    this.emit(CoreEvents.SUCCESSFUL_PLAY, undefined);
  }

  protected onDrmLicenseExpired(payload: MediaErrorPayload) {
    this.emit(CoreEvents.DRM_LICENSE_ERROR, payload);
  }

  protected loadEngine(media: Media, startTime?: number) {
    if (media) {
      this.mediaEngine.load(media, startTime);
    }
  }

  // set initial text track based on local preferences and current service and audio language
  private setInitialTextTrack(): void {
    const preferenceLanguage: string | null =
      localPreferences.getPreferredText(this.mediaEngine.isLive()) || null;

    const textTracks = this.mediaEngine.getTextTracks();

    const language = getInitialTextLanguage(
      this.service,
      textTracks.map((track) => track.language),
      this.mediaEngine.getActiveAudioTrack()?.language || null,
      preferenceLanguage
    );

    const textTrack: Track | undefined =
      language === DISABLED_TEXT
        ? getTextTrackByKind(AWPTextTrackKind.NOTHING, textTracks)
        : /**
           * getTrackByLanguage will return undefined if no track is found,
           * but language here will be one of the available languages from
           * textTracks, so a track should always be found
           */
          getTrackByLanguage(language, textTracks);

    if (textTrack!.id !== this.mediaEngine.getActiveTextTrack()?.id) {
      this.mediaEngine.setTextTrack(textTrack as AWPTextTrack);
    }
  }

  private setInitialAudioTrack(): void {
    const audioTracks = this.mediaEngine.getAudioTracks();
    const language = getInitialAudioLanguage(
      this.service,
      audioTracks.map((track) => track.language),
      localPreferences.getPreferredAudio()
    );
    const audioTrack: Track | undefined = getTrackByLanguage(
      language,
      audioTracks
    );

    if (
      audioTrack &&
      audioTrack.id !== this.mediaEngine.getActiveAudioTrack()?.id
    ) {
      this.mediaEngine.setAudioTrack(audioTrack);
    }
  }

  private emitCreditsStartIfPastCreditsTimestamp(currentTime: number) {
    const isLive = this.mediaEngine.isLive();
    const creditsTriggered = this.endCreditsEmitted;
    const creditsTimestamp = this.metadata?.asset.endCreditsStart;

    if (isLive || creditsTriggered || !creditsTimestamp) return;

    if (currentTime >= creditsTimestamp) {
      this.endCreditsEmitted = true;
      this.emit(CoreEvents.CREDITS_START, {
        creditsStartTime: creditsTimestamp,
      });
    }
  }

  public async load({ startTime, autoplay }: PlaybackLoadOptions) {
    this.emit(CoreEvents.LOADING_PLAYBACK, undefined);
    if (autoplay) {
      this.mediaEngine.once(MediaEvents.LOADED, this.play.bind(this));
    }

    this.hasStarted = false;
    this.loadEngine(this.media, startTime);
  }

  public getControls(): TControls {
    let controls: TControls = {
      play: this.play.bind(this),
      pause: this.pause.bind(this),
      preSeek: this.preSeek.bind(this),
      applyPreSeek: this.applyPreSeek.bind(this),
      seekTo: this.seekTo.bind(this),
      mute: this.mute.bind(this),
      unmute: this.unmute.bind(this),
      toggleMute: this.toggleMute.bind(this),
      setVolume: this.setVolume.bind(this),
      seekForward: this.seekForward.bind(this),
      seekBackward: this.seekBackward.bind(this),
      setAudioTrack: this.setAudioTrack.bind(this),
      setTextTrack: this.setTextTrack.bind(this),
      setSubtitleText: this.setSubtitleText.bind(this),
      setSubtitleTextSize: this.setSubtitleTextSize.bind(this),
      seekToLive: this.seekToLive.bind(this),
      seekToStartOver: this.seekToStartOver.bind(this),
      toggleAirplay: this.mediaEngine.isAirplayAvailable()
        ? this.toggleAirplay.bind(this)
        : undefined,
      setPlaybackRate: this.setPlaybackRate.bind(this),
    };

    if (this.fullscreenController) {
      controls = {
        ...controls,
        toggleFullscreen: this.fullscreenController.toggle.bind(
          this.fullscreenController
        ),
        enterFullscreen: this.fullscreenController.enter.bind(
          this.fullscreenController
        ),
        exitFullscreen: this.fullscreenController.exit.bind(
          this.fullscreenController
        ),
      };
    }

    return controls;
  }

  protected play() {
    syncSessionPlaybackSettings({
      mode: this.playerMode,
      mediaEngine: this.mediaEngine,
    });

    const currentTime = this.getCurrentTime();
    const switchedPlaybackMode = this.evaluatePositionAndSwitchPlaybackMode(
      currentTime,
      true
    );

    if (switchedPlaybackMode) {
      return;
    }

    this.mediaEngine.play();
  }

  protected pause(options: PauseOptions) {
    if (!this.restrictions.canPause) return;
    this.mediaEngine.pause(options);
  }

  protected setPlaybackRate(rate: number) {
    this.mediaEngine.setPlaybackRate(rate);
  }

  public setPlaybackMode(playbackMode: PlaybackMode) {
    this.playbackMode = playbackMode;
  }

  public setStreamInfo(streamInfo: StreamInfoPayload) {
    this.streamInfo = streamInfo;
    this.setStreamInfoMediaOffset();

    this.evaluateStreamSwitchCapabilities();
  }

  protected getCurrentTime(): number {
    return this.mediaEngine.getCurrentTime();
  }

  /**
   * Sets the time offset between the stream info and the playing stream.
   * Only set once because the streamInfo duration and media engine seek-range are updated
   * at different times so continously updating this will result in values that goes back and forth.
   * @returns void
   */
  private setStreamInfoMediaOffset() {
    if (this.streamInfoMediaOffset !== undefined) return; // already set don't do anything

    const { streamInfo } = this;
    const seekable = this.mediaEngine.getSeekable();

    let streamInfoMediaOffset: number | undefined;
    if (
      streamInfo &&
      seekable.end &&
      this.playbackMode === PlaybackMode.LIVE_DAI
    ) {
      streamInfoMediaOffset = streamInfo.duration - seekable.end;
    } else if (this.playbackMode !== PlaybackMode.LIVE_DAI) {
      streamInfoMediaOffset = 0;
    }
    if (this.streamInfoMediaOffset !== streamInfoMediaOffset) {
      this.streamInfoMediaOffset = streamInfoMediaOffset;
      this.emit(CoreEvents.STREAM_INFO_OFFSET_UPDATED, {
        offset: this.streamInfoMediaOffset,
      });
    }
  }

  protected getStreamPosition(absolutePosition: number): number {
    let streamPosition = absolutePosition;
    if (this.streamInfoMediaOffset !== undefined) {
      streamPosition = absolutePosition - this.streamInfoMediaOffset;
    }
    return streamPosition;
  }

  protected getAbsolutePosition(streamPosition: number): number {
    if (this.streamInfoMediaOffset !== undefined) {
      return streamPosition + this.streamInfoMediaOffset;
    }
    return streamPosition;
  }

  /**
   * @param position
   * @returns true if playback mode was switched
   */
  protected evaluatePositionAndSwitchPlaybackMode(
    streamPosition: number,
    autoplay?: boolean
  ): boolean {
    const { streamInfo, playbackMode } = this;
    const seekable = this.mediaEngine.getSeekable();
    const isWithinSeekableRange =
      streamPosition >= seekable.start && streamPosition <= seekable.end;
    const duration =
      streamInfo?.duration || this.mediaEngine.getDuration() || 0;

    if (
      [PlaybackMode.START_OVER, PlaybackMode.LIVE_DAI].includes(playbackMode) &&
      isWithinSeekableRange
    ) {
      return false;
    }

    if (
      streamInfo?.duration !== undefined &&
      ![PlaybackMode.DEFAULT, PlaybackMode.ORIGIN_FALLBACK].includes(
        playbackMode
      )
    ) {
      let newPlaybackMode: PlaybackMode;
      let originStartTime: number | undefined;

      const absolutePosition = this.getAbsolutePosition(streamPosition);

      if (
        this.availablePlaybackModes.includes(PlaybackMode.START_OVER) &&
        absolutePosition < PLAYBACK_MODE_SWITCH_THRESHOLD
      ) {
        newPlaybackMode = PlaybackMode.START_OVER;
      } else if (
        this.availablePlaybackModes.includes(PlaybackMode.LIVE_DAI) &&
        absolutePosition + PLAYBACK_MODE_SWITCH_THRESHOLD > duration
      ) {
        newPlaybackMode = PlaybackMode.LIVE_DAI;
      } else {
        newPlaybackMode = PlaybackMode.ORIGIN;
        originStartTime = absolutePosition;
      }

      if (
        newPlaybackMode !== playbackMode ||
        // if outside of seekable range, trigger mode switch to the same mode to restart the same stream
        (!isWithinSeekableRange && newPlaybackMode !== PlaybackMode.ORIGIN)
      ) {
        this.emit(CoreEvents.PLAYBACK_MODE_CHANGE, {
          playbackMode: newPlaybackMode,
          originStartTime,
          autoplay: autoplay ?? this.mediaEngine.isPlaying(),
        });
        return true;
      }
    }
    return false;
  }

  /**
   * Evaluate the current stream info and playback state to determine what type of stream switching is allowed.
   */
  private evaluateStreamSwitchCapabilities() {
    if (this.playbackMode === PlaybackMode.DEFAULT || !this.streamInfo) return;

    if (
      !this.capabilities.seek ||
      !this.streamInfo.duration ||
      !this.streamInfo.ended
    ) {
      return;
    }

    // Stream has ended, we can only switch to origin or default mode.
    this.availablePlaybackModes = this.availablePlaybackModes.filter(
      (playbackMode) =>
        playbackMode !== PlaybackMode.START_OVER &&
        playbackMode !== PlaybackMode.LIVE_DAI
    );

    if (this.playbackMode === PlaybackMode.START_OVER) return;

    if (this.playbackMode === PlaybackMode.ORIGIN) {
      this.emit(CoreEvents.PLAYBACK_MODE_SET, {
        playbackMode: PlaybackMode.DEFAULT,
      });
    } else if (this.playbackMode === PlaybackMode.LIVE_DAI) {
      const absolutePosition = this.getAbsolutePosition(this.getCurrentTime());

      if (absolutePosition > this.streamInfo.duration) {
        // the stream has ended, we are no longer capable of seeking in a LIVE DAI stream.
        this.capabilities.seek = false;
        this.setRestrictions({ canSeek: this.capabilities.seek });

        this.emit(CoreEvents.PLAYBACK_MODE_SET, {
          playbackMode: PlaybackMode.DEFAULT,
        });
      }
    }
  }

  /**
   * Conditionally switch mode instead of seeking normally
   */
  protected handleSeek(streamPosition: number) {
    const switchedPlaybackMode =
      this.evaluatePositionAndSwitchPlaybackMode(streamPosition);
    if (switchedPlaybackMode) {
      return;
    }

    this.mediaEngine.seekTo(streamPosition);
  }

  protected preSeek(amount: number) {
    if (!this.restrictions.canSeek) return;
    this.preSeekAmount += amount;
    this.emit(CoreEvents.PRE_SEEKING, { amount });
  }

  protected applyPreSeek() {
    if (!this.restrictions.canSeek) return;
    this.handleSeek(this.getCurrentTime() + this.preSeekAmount);
    this.preSeekAmount = 0;
  }

  protected seekTo(absolutePosition: number) {
    const streamPosition = this.getStreamPosition(absolutePosition);
    if (!this.restrictions.canSeek) {
      // even if we can't seek, we may still be able to switch playback mode.
      this.evaluatePositionAndSwitchPlaybackMode(streamPosition);
      return;
    }
    this.handleSeek(streamPosition);
  }

  protected seekForward(amount: number) {
    if (!this.restrictions.canSeek) return;
    this.handleSeek(this.getCurrentTime() + amount);
  }

  protected seekBackward(amount: number) {
    if (!this.restrictions.canSeek) return;
    this.handleSeek(this.getCurrentTime() - amount);
  }

  protected seekToLive() {
    const seekable = this.mediaEngine.getSeekable();
    if (seekable.end) {
      this.resetRestrictions();
      this.seekTo(this.streamInfo?.duration || seekable.end);
    }
  }

  protected seekToStartOver() {
    this.resetRestrictions();
    this.seekTo(0);
  }

  protected toggleMute() {
    this.mediaEngine.toggleMute();
  }

  protected mute() {
    this.mediaEngine.mute();
  }

  protected unmute() {
    this.mediaEngine.unmute();
  }

  protected setVolume(volume: number) {
    this.mediaEngine.setVolume(volume);
  }

  protected setAudioTrack(track: AWPAudioTrack) {
    this.mediaEngine.setAudioTrack(track);
  }

  protected setTextTrack(track: AWPTextTrack) {
    this.mediaEngine.setTextTrack(track);
  }

  protected setSubtitleText(text: string) {
    this.emit(CoreEvents.SUBTITLE_TEXT_CHANGED, { text });
  }

  protected setSubtitleTextSize(size: number) {
    this.emit(CoreEvents.SUBTITLE_TEXT_SIZE_CHANGED, { size });
  }

  protected toggleAirplay() {
    this.mediaEngine.toggleAirplay();
  }

  public handleCoreEvents(
    _eventType: CoreEvents,
    _data: CoreEventsMap[CoreEvents]
  ) {
    return;
  }

  public drawVideoFrameOnCanvas(canvas: HTMLCanvasElement) {
    const context = canvas.getContext("2d");
    const videoElement = this.mediaEngine.videoElement;
    if (!videoElement || !context) return;

    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;

    context.drawImage(
      videoElement,
      0,
      0,
      videoElement.videoWidth,
      videoElement.videoHeight
    );
  }

  public override destroy() {
    this.fullscreenController?.destroy();
    this.mediaEngine.destroy();
    super.destroy();
  }
}
