"use strict";

exports.__esModule = true;
exports.millisToTimeString = millisToTimeString;
exports.millisToTimestamp = millisToTimestamp;
exports.pdtToMillis = pdtToMillis;
exports.stringToIntegerNumber = stringToIntegerNumber;
exports.timeStringToMillis = timeStringToMillis;
/*
 * COPYRIGHT 2020-2023 YOSPACE TECHNOLOGIES LTD. ALL RIGHTS RESERVED.
 * The contents of this file are proprietary and confidential.
 * Unauthorised copying of this file, via any medium is strictly prohibited.
 */

function pdtToMillis(time, error) {
  if (!time || time.length === 0 || time.indexOf('T') === -1) {
    return error;
  }

  // Check whether no timezone is specified:
  // i. No '+' or '-' chars after the 'T'
  // ii. Final character is an integer
  //
  // If check passes, append a 'Z' to specify UTC
  var checkStr = time.substring(time.indexOf('T'), time.length - 1);
  if (checkStr.indexOf("+") === -1 && checkStr.indexOf("-") === -1 && Number.isInteger(Number.parseInt(time.charAt(time.length - 1)))) {
    time = time + 'Z';
  }
  var millis = Date.parse(time);
  return Number.isNaN(millis) ? error : millis;
}
function timeStringToMillis(time, error) {
  var err = error !== undefined ? error : -1;
  if (!(typeof time === "string" && time.length > 0)) {
    return err;
  }
  var components = time.split(":");
  if (components.length !== 3) {
    return err;
  }
  var hours = parseInt(components[0]);
  var minutes = parseInt(components[1]);
  if (Number.isNaN(hours) || Number.isNaN(minutes)) {
    return err;
  }
  var seconds = 0;
  var milliseconds = 0;
  var secondsMs = components[2].split('\.');
  if (secondsMs.length !== 2) {
    seconds = parseInt(components[2]);
  } else {
    seconds = parseInt(secondsMs[0]);
    milliseconds = parseInt(secondsMs[1]);
  }
  if (Number.isNaN(seconds) || Number.isNaN(milliseconds)) {
    return err;
  }
  if (hours > 23 || minutes > 59 || seconds > 59) {
    return err;
  }
  return hours * 3600000 + minutes * 60000 + seconds * 1000 + milliseconds;
}
function millisToTimestamp(millis) {
  return new Date(millis).toISOString();
}
function millisToTimeString(millis) {
  var second = Math.floor(millis / 1000 % 60);
  var minute = Math.floor(millis / (1000 * 60) % 60);
  var hour = Math.floor(millis / (1000 * 60 * 60) % 24);
  millis = Math.round(millis % 1000);
  return "" + (hour < 10 ? 0 : '') + hour + ":" + (minute < 10 ? 0 : '') + minute + ":" + (second < 10 ? 0 : '') + second + "." + (millis < 10 ? "00" : millis < 100 ? "0" : '') + millis;
}
function stringToIntegerNumber(value, error) {
  var num = Number.parseInt(value);
  return Number.isInteger(num) ? num : error;
}