import {
  AssetType,
  EngineName,
  Media,
  Metadata,
} from "@tv4/avod-web-player-common";

export const UNSET_VALUE = "Unset";
export const UNKNOWN_VALUE = "Unknown";

function getLegacyPlayerEngineName(engineName: EngineName | null): string {
  switch (engineName) {
    case EngineName.NATIVE:
      return "NativeHLS";
    case EngineName.HLS_JS:
      return "HlsJs";
    case EngineName.SHAKA:
      return "Shaka";
    default: {
      if (typeof engineName === "string") {
        // This is needed to highlight if new engine names are added
        // without setting a "legacy" name for it. The legacy name
        // mapping is required because Player ID is sensitive to
        // EngineName changes. For example, if we introduce "dashjs"
        // as a new value in the EngineName enum and then decide to
        // rename it to "dash.js" it should not generate a new
        // Player ID.
        return "UNHANDLED_ENGINE_NAME_FOR_LEGACY_PLAYER_ID";
      } else {
        return UNSET_VALUE;
      }
    }
  }
}

export function getPlayerId(
  domain: string,
  platform: string,
  playerEngineName: EngineName | null
): string {
  const engine = getLegacyPlayerEngineName(playerEngineName);
  return `${domain} ${platform} (${engine})`;
}

export function getNpawPlaybackType(
  metadata?: Metadata,
  media?: Media
): string {
  if (!media) return UNSET_VALUE;

  if (!media.isLive) {
    return media.isStitched ? "vod-ssai" : "vod-origin";
  }

  if (!metadata) return UNSET_VALUE;

  if (metadata.asset.type === AssetType.CHANNEL) {
    return media.isStitched ? "channel-ssai" : "channel-origin";
  }

  if (media.isStartOver) {
    // startover streams should always be ssai, but better to handle all cases
    // and track this in case of future changes
    return media.isStitched ? "event-startover-ssai" : "event-startover-origin";
  }

  return media.isStitched ? "event-live-ssai" : "event-live-origin";
}

export const slugify = (...args: (string | number)[]): string => {
  const value = args.join(" ");

  return value
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, "")
    .replace(/\s+/g, "-");
};
