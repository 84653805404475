import {
  AWPError,
  BufferSeekingPayload,
  convertVodIAdBreaksToAdMarkers,
  CoreEvents,
  CoreEventsMap,
  ERROR_CATEGORY,
  ErrorLevel,
  getRemoteConfigValue,
  LoadedPlaybackPayload,
  Metadata,
  PauseOptions,
  PausePayload,
  TimePayload,
  VolumePayload,
} from "@tv4/avod-web-player-common";
import { FreeWheelAdServer } from "@tv4/avod-web-player-http";

import { CSAIAdEngine } from "../ads_engine/CSAIAdEngine";
import { MediaEvents } from "../media_engine/utils/mediaConstants";
import {
  BasePlayback,
  type PlaybackLoadOptions,
  type PlaybackOptions,
} from "./BasePlayback";

type CSAIPlaybackOptions = PlaybackOptions & {
  root: HTMLElement;
  metadata: Metadata;
  adServerInstance: FreeWheelAdServer;
  muted: boolean;
};

export class CSAIPlayback extends BasePlayback {
  public override readonly name = "CSAIPlayback";
  protected adMediaEngine: CSAIAdEngine;

  private ongoing = false;
  private pausingForAdBreak = false;
  private startTime?: number;
  private loaded: boolean = false;

  constructor(options: CSAIPlaybackOptions) {
    super(options);

    const { root, metadata, adServerInstance, muted, service } = options;

    this.mediaEngine.setAirplayAllowed(false);

    this.adMediaEngine = new CSAIAdEngine({
      service,
      muted,
      parent: root,
      adImmunityDuration: getRemoteConfigValue("AD_IMMUNITY_DURATION"),
      adServerInstance,
      metadata,
    });

    this.adMediaEngine.onAll((evt, data) => {
      if (evt !== CoreEvents.VOLUME_CHANGED) {
        this.emit(evt, data as any);
      }
      if (evt === CoreEvents.BREAK_END) {
        this.setRestrictions({ canSeek: true });
        // start/resume main content after break ends
        this.loadContent();
      } else if (evt === CoreEvents.BREAK_START) {
        this.setRestrictions({
          canSeek: false,
        });
        // pause main content if needed when break starts
        this.handleAdBreakStart();
      }
    });

    // We want to align volume between ad element and content element
    this.adMediaEngine.on(
      CoreEvents.VOLUME_CHANGED,
      this.alignVolumeFromAdEngine.bind(this)
    );
  }

  public override async load({ startTime, autoplay }: PlaybackLoadOptions) {
    // startTime can be undefined, and needs to be undefined when starting live stream at live position
    this.emit(CoreEvents.LOADING_PLAYBACK, undefined);

    this.loaded = false;

    let playingAdBreak: boolean;

    try {
      playingAdBreak = await this.adMediaEngine.load({
        contentStartTime: startTime,
        autoplay,
      });
    } catch (adBreakError: unknown) {
      if (this.destroyed) return;

      const error =
        adBreakError instanceof AWPError
          ? adBreakError
          : new AWPError({
              context: "player",
              code: "ERROR",
              category: ERROR_CATEGORY.ADS,
              message: "ad media engine load failed for an unknown reason",
              raw: adBreakError,
              fatal: false,
              errorLevel: ErrorLevel.PLAYER,
            });

      return this.emit(CoreEvents.LOAD_PLAYBACK_ERROR, {
        error,
      });
    }

    if (this.destroyed) return;

    this.startTime = startTime;

    if (playingAdBreak) {
      // at the moment the breaks are loaded at startup so this can be emitted now.
      this.emitLoadedPlayback({
        currentTime: 0,
        duration: this.metadata?.asset.duration || 0,
      });
    } else {
      this.loadContent(autoplay);
    }
  }

  protected override emitLoadedPlayback(
    payload: Partial<LoadedPlaybackPayload>
  ): void {
    if (!this.loaded) {
      super.emitLoadedPlayback(payload);
      if (!this.metadata?.asset.isLive) {
        const adMarkers = convertVodIAdBreaksToAdMarkers(
          this.adMediaEngine.adBreaks || []
        );
        super.emit(CoreEvents.AD_MARKERS_UPDATED, { adMarkers });
      }
    }
    this.loaded = true;
  }

  private loadContent(autoplay = true) {
    if (!this.ongoing) {
      if (autoplay) {
        this.mediaEngine.once(MediaEvents.LOADED, this.play.bind(this));
      }
      this.loadEngine(this.media, this.startTime);
      this.ongoing = true;
    } else if (autoplay) {
      this.play();
    }
  }

  private handleAdBreakStart(): void {
    if (this.ongoing) {
      /**
       * passing bitrate estimation from content player to ad player.
       * handleBreakStart is triggered by event from CSAIAdEngine,
       * but the execution order is synchronous so this logic is
       * completed before the logic after the event in CSAIAdEngine,
       * so the bitrate should be updated before it is used when the
       * ad video needs to start.
       */
      this.adMediaEngine.setEstimatedBitrate(this.mediaEngine.estimatedBitrate);

      this.pausingForAdBreak = true;
      this.mediaEngine.once(MediaEvents.PAUSED, () => {
        this.pausingForAdBreak = false;
      });
      super.pause({ programmatic: true });
    }
  }

  protected override onPaused(payload: PausePayload): void {
    if (!this.pausingForAdBreak) {
      super.onPaused(payload);
    }
  }

  protected override onTimeUpdate(payload: TimePayload): void {
    if (this.pausingForAdBreak) {
      return;
    }

    super.onTimeUpdate(payload);
    this.adMediaEngine.onContentTimeUpdate(payload.currentTime);
  }

  protected override onSeeked(payload: BufferSeekingPayload): void {
    super.onSeeked(payload);

    const seekedBackwards =
      payload.currentTime < this.mediaEngine.getLastCurrentTime();

    this.adMediaEngine[
      seekedBackwards
        ? "onContentSeekBackwardComplete"
        : "onContentSeekForwardComplete"
    ](payload.currentTime);
  }

  public override play() {
    if (this.adMediaEngine.currentAdBreak) {
      this.adMediaEngine.play();
    } else {
      super.play();
    }
  }

  public override pause(options: PauseOptions) {
    if (!this.restrictions.canPause) return;

    if (this.adMediaEngine.currentAdBreak) {
      this.adMediaEngine.pause(options);
    } else {
      super.pause(options);
    }
  }

  public override handleCoreEvents(
    eventType: CoreEvents,
    data: CoreEventsMap[CoreEvents]
  ): void {
    this.adMediaEngine.handleCoreEvent(eventType, data);
  }

  public override mute(): void {
    this.adMediaEngine.mute();
  }

  public override unmute(): void {
    this.adMediaEngine.unmute();
  }

  public override toggleMute() {
    this.adMediaEngine.toggleMute();
  }

  public override setVolume(volume: number) {
    this.adMediaEngine.setVolume(volume);
  }

  private alignVolumeFromAdEngine({ volume, muted }: VolumePayload) {
    super.setVolume(volume);
    if (muted !== this.mediaEngine.getMuted()) {
      if (muted) {
        super.mute();
      } else {
        super.unmute();
      }
    }
  }

  public override destroy() {
    this.adMediaEngine.destroy();
    super.destroy();
    this.destroyed = true;
  }
}
