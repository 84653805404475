/**
 * List of streaming protocols
 *   - HDS (Adobe HDS)
 *   - HLS (Apple HLS)
 *   - MSS (Microsoft Smooth Streaming)
 *   - DASH (MPEG-DASH)
 *   - RTMP (Adobe RTMP)
 *   - RTP (RTP)
 *   - RTSP (RTSP)
 *   - MULTICAST (Multicast)
 *   - DVB (DVB)
 *   - DVBC (DVB-C)
 *   - DVBT (DVB-T)
 *   - DVBT2 (DVB-T2)
 */
var StreamingProtocol = {
  HDS: 'HDS',
  HLS: 'HLS',
  MSS: 'MSS',
  DASH: 'DASH',
  RTMP: 'RTMP',
  RTP: 'RTP',
  RTSP: 'RTSP',
  DVB: 'DVB',
  DVBC: 'DVB-C',
  DVBT: 'DVB-T',
  DVBT2: 'DVB-T2',
  MULTICAST: 'MULTICAST'
}

module.exports = StreamingProtocol
