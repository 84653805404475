import Util from '../analytics/util';
import Timer from '../analytics/timer';
import Constants from '../analytics/constants';
import Core from '../core/Core';
import Options from '../analytics/plugin/options';
import RequestBuilder from '../analytics/plugin/requestbuilder';
import SessionsRequest from './SessionsRequest';
import { ExpireDefault, Method, SessionListeners, SessionsTag, CoreEvents } from '../common/Constants';
import SessionsRequestHandler from './comm/SessionsRequestHandler';
import { POST } from '../analytics/constants/requestMethod';
import CommonGetters from '../common/CommonGetters';
import Log from '../common/log';
import ExpirationManager from '../common/ExpirationManager';
/**
 * Session is the generic class to handle Session management for all the applications.
 * Every plugin will have an instance.
 */
export default class Session {
    /**
     * Constructs the Sessions class
     */
    constructor(accountCode) {
        this.options = new Options();
        this.listeners = {};
        this.core = Core.getInstance();
        this.coreStorage = this.core.getCoreStorage();
        this.requestHandler = new SessionsRequestHandler();
        this.accountCode = accountCode;
        this.requestBuilder = new RequestBuilder();
        this._beat = new Timer(this._sendBeat.bind(this), Session.BEAT_INTERVAL);
        this.expirationManager = new ExpirationManager(this.coreStorage.getSessionExpire() || ExpireDefault.DEFAULT_SESSION_TIMEOUT_MS, ExpireDefault.DEFAULT_MAX_DURATION_SESSION_MS, this.coreStorage);
        this._lastNavigation = { page: '', route: '' };
        this._registeredProperties = null;
    }
    addListener(eventName, callback) {
        if (!this.listeners[eventName]) {
            this.listeners[eventName] = [];
        }
        this.listeners[eventName].push(callback);
    }
    fireListener(eventName, ...args) {
        const eventListeners = this.listeners[eventName] || [];
        for (const listener of eventListeners) {
            listener(...args);
        }
    }
    /**
     * Starts a new session. If a session exists, stops it and starts a new one.
     *
     * @param options - Object of key:value options (optional).
     * @param dimensions - Object of key:value params to add to the request (optional).
     * @param onSuccess - Optional callback function to be executed if the session start is successful.
     * @param onFail - Optional callback function to be executed if the session start fails.
     * @param forceStart - Optional parameter to force sending the /start NQS event
     */
    start(options, dimensions = {}, onSuccess, onFail, forceStart = false) {
        if (this.core.isUsingLegacy()) {
            Log.warn(SessionsTag, 'Cannot start session since session is using legacy');
            onFail === null || onFail === void 0 ? void 0 : onFail();
            return;
        }
        if (this.isActive() && !forceStart) {
            Log.notice(SessionsTag, 'Session already started');
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
            return;
        }
        if (options) {
            this.setOptions(options);
        }
        this._registeredProperties = this.coreStorage.getAppAnalyticsRegisteredProperties();
        // Send start event - Start
        this.fireListener(SessionListeners.START_BEFORE, options, dimensions);
        this._logFireSessionStartEvent(dimensions);
        /*
          Requests are queued by the request queue handler when the session token is unavailable. The
          request handler queue is only processed when a new request is added to the queue.
          When a session start is called right after a session stop the session token is unavailable so
          the start request is queued. It won't be sent until another request is queued and the token
          is available. We set here the beat timer interval to 1s so that beat events are queued making
          the session start event to be sent as soon as possible.
        */
        this._beat.stop();
        this._beat = new Timer(this._sendBeat.bind(this), 1000);
        // Prepare params and send request
        let params = this._getParamsJson(dimensions, null, null, true, true);
        params = this._parseInternalParams(params);
        this._sendSession(Constants.Service.SESSION_START, params, () => {
            // Revert the beat timer interval to 30s once the session gets started
            this._beat.stop();
            this._beat = new Timer(this._sendBeat.bind(this), Session.BEAT_INTERVAL);
            this._beat.start();
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        }, () => {
            // Stop the transient beat timer if the session start has failed
            this._beat.stop();
            this._beat = new Timer(this._sendBeat.bind(this), Session.BEAT_INTERVAL);
            onFail === null || onFail === void 0 ? void 0 : onFail();
        });
        Log.notice(SessionsTag, '[' + this.core.getFastDataSessionToken() + '] ' + Constants.Service.SESSION_START + ' ' + params.route);
        this.fireListener(SessionListeners.START_AFTER, options, dimensions);
        // Send start event - End
    }
    /**
     * Stop session
     * @param params
     * @param onSuccess
     * @param onFail
     * @param forceStop
     * @param sessionStarted
     * @returns
     */
    stop(params = {}, onSuccess, onFail, forceStop, sessionStarted = true) {
        if (this.core.isUsingLegacy()) {
            Log.warn(SessionsTag, 'Cannot stop session since session is using legacy');
            onFail === null || onFail === void 0 ? void 0 : onFail();
            return;
        }
        if (!forceStop && !this.isActive()) {
            Log.notice(SessionsTag, 'Session already stopped');
            if (this.expirationManager) {
                this.expirationManager.reset();
            }
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
            return;
        }
        // Reset Expiration Manager object
        if (this.expirationManager) {
            this.expirationManager.reset();
        }
        // Send stop event - Start
        this.fireListener(SessionListeners.STOP_BEFORE, params);
        this._logFireSessionStopEvent(params);
        if (sessionStarted) {
            params = this._parseInternalParams(params);
            this._sendSession(Constants.Service.SESSION_STOP, params, onSuccess, onFail);
        }
        this._beat.stop();
        Log.notice(SessionsTag, '[' + this.core.getFastDataSessionToken() + '] ' + Constants.Service.SESSION_STOP + ' ' + params.route);
        this.coreStorage.removeStoredData();
        this.coreStorage.removeSession();
        this.coreStorage.removeLastActive();
        this.refreshSessionToken();
        this.fireListener(SessionListeners.STOP_AFTER, params);
        // Send stop event - End
    }
    /**
     * Sends session event
     * @param eventName
     * @param dimensions
     * @param values
     * @param topLevelDimensions
     * @param onSuccess
     * @param onFail
     * @returns
     */
    sendEvent(eventName = '', dimensions = {}, values = {}, topLevelDimensions = {}, onSuccess, onFail) {
        if (this.core.isUsingLegacy()) {
            Log.warn(SessionsTag, 'Cannot send session event since session is using legacy');
            onFail === null || onFail === void 0 ? void 0 : onFail();
            return;
        }
        if (this.expiredSession({})) {
            onFail === null || onFail === void 0 ? void 0 : onFail();
            return;
        }
        if (!this.isActive()) {
            Log.warn(SessionsTag, 'Cannot send session event since session is not started');
            onFail === null || onFail === void 0 ? void 0 : onFail();
            return;
        }
        // Send session event - Start
        this.fireListener(SessionListeners.EVENT_BEFORE, eventName, dimensions, values, topLevelDimensions);
        this._logFireEventListener(eventName);
        const builtParams = this._getParamsJson(dimensions, values, eventName);
        Util.assign(builtParams.params, topLevelDimensions || {});
        const params = this._parseInternalParams(builtParams);
        this._sendSession(Constants.Service.EVENT, params, onSuccess, onFail);
        Log.notice(SessionsTag, '[' + this.core.getFastDataSessionToken() + '] ' + Constants.Service.EVENT + ' ' + params.name);
        this.fireListener(SessionListeners.EVENT_AFTER, eventName, dimensions, values, topLevelDimensions);
        // Send session event - End
    }
    /**
     * Emits session start request.
     *
     * @param dimensions - Object of key:value params to add to the request.
     * @param onSuccess - Optional callback function to be executed if the request is successful.
     * @param onFail - Optional callback function to be executed if the request fails.
     */
    fireNav(dimensions = {}, onSuccess, onFail) {
        if (this.core.isUsingLegacy()) {
            Log.warn(SessionsTag, 'Cannot send session navigation since session is using legacy');
            onFail === null || onFail === void 0 ? void 0 : onFail();
            return;
        }
        if (this.expiredSession({})) {
            onFail === null || onFail === void 0 ? void 0 : onFail();
            return;
        }
        if (!this.isActive()) {
            Log.warn(SessionsTag, 'Cannot send session navigation since session is not started');
            onFail === null || onFail === void 0 ? void 0 : onFail();
            return;
        }
        // Send session navigation - Start
        this._logFireNavListener(dimensions);
        let params = this._getParamsJson(dimensions, null, null, true);
        params = this._parseInternalParams(params);
        if (this._checkDifferentNavigation(params)) {
            this._sendSession(Constants.Service.NAV, params, onSuccess, onFail);
            Log.notice(SessionsTag, '[' + this.core.getFastDataSessionToken() + '] ' + Constants.Service.NAV + ' ' + params.route);
            this._lastNavigation.page = params.page;
            this._lastNavigation.route = params.route;
        }
        else {
            Log.warn(SessionsTag, 'Same navigation detected and ignored for Page and Route');
            onFail === null || onFail === void 0 ? void 0 : onFail();
        }
        // Send session navigation - End
    }
    /**
     * Destroy session object
     */
    destroy() {
        this._beat.stop();
        this.requestHandler.destroy();
    }
    /**
     * Sets Analytics options. See {@link Options.setOptions}.
     *
     * @internal
     * @param options
     */
    setOptions(options) {
        try {
            if (options && Object.keys(options).length > 0) {
                if (this.options && Object.keys(this.options).length > 0) {
                    Object.assign(this.options, options);
                }
                else {
                    this.options = options;
                }
            }
        }
        catch (e) { }
    }
    /**
     * Gets Analytics options. See {@link Options}.
     */
    getOptions() {
        return this.options;
    }
    isUsingLegacy() {
        return this.core.isUsingLegacy();
    }
    refreshSessionToken() {
        this.core.refreshSessionToken();
    }
    /**
     * Get last activity timestamp
     */
    _setLastActive() {
        this.coreStorage.setLastActive(new Date().getTime().toString());
    }
    getAccountCode() {
        return this.accountCode;
    }
    /** Register properties sent by the User, to send in all the events
     *
     * @param dimensions - Object of key:value dimensions.
     * @param values - Object of key:value values.
     */
    register(dimensions = {}, values = {}) {
        this._registeredProperties = { dimensions: dimensions, values: values };
        this.coreStorage.setAppAnalyticsRegisteredProperties(JSON.stringify(this._registeredProperties));
    }
    /** Calls register if registeredProperties is empty
     *
     * @param dimensions - Object of key:value dimensions.
     * @param values - Object of key:value values.
     */
    registerOnce(dimensions, values) {
        if (!this._registeredProperties) {
            this.register(dimensions, values);
        }
    }
    /** Unregister all properties registered with register() */
    unregister() {
        this._registeredProperties = null;
        this.coreStorage.removeAppAnalyticsRegisteredProperties();
    }
    /**
     * Splits params in dimensions (strings) and values (numbers)
     *
     * @param dimensions - Object of key:value dimensions to split before adding to request.
     * @param values - Object of key:value values to split before adding to request.
     * @param eventName - Event name.
     * @param isNavigation - Boolean to check if it's a navigation event.
     * @param isStart - Boolean to check if it's a start event.
     */
    _getParamsJson(dimensions, values, eventName, isNavigation, isStart) {
        const returnparams = {};
        if (eventName)
            returnparams.name = eventName;
        returnparams.dimensions = dimensions || {};
        returnparams.values = values || {};
        if (this._registeredProperties) {
            for (const key in this._registeredProperties.dimensions) {
                returnparams.dimensions[key] = this._registeredProperties.dimensions[key];
            }
            for (const key2 in this._registeredProperties.values) {
                returnparams.values[key2] = this._registeredProperties.values[key2];
            }
        }
        const paramsObject = { params: returnparams };
        if (isNavigation) {
            if (paramsObject.params.dimensions.page) {
                paramsObject.params.page = paramsObject.params.dimensions.page;
                delete paramsObject.params.dimensions.page;
            }
            if (paramsObject.params.dimensions.route) {
                paramsObject.params.route = paramsObject.params.dimensions.route;
                delete paramsObject.params.dimensions.route;
            }
            if (!isStart) {
                delete paramsObject.params.dimensions;
            }
            delete paramsObject.params.values;
        }
        return paramsObject;
    }
    /**
     * Checks whether the session is active or not
     * @returns
     */
    isActive() {
        if (this.core.isUsingLegacy()) {
            return false;
        }
        if (this.expirationManager && this.expirationManager.isExpired()) {
            return false;
        }
        if (this.expirationManager) {
            return this.expirationManager.isActive();
        }
        return this.coreStorage.getLastActive() + (this.coreStorage.getSessionExpire() || ExpireDefault.DEFAULT_SESSION_TIMEOUT_MS) > new Date().getTime();
    }
    /**
     * Send a session request
     * @param service
     * @param params
     * @param onSuccess
     * @param onFail
     * @private
     */
    _sendSession(service, params, onSuccess, onFail) {
        if (this.core.isUsingLegacy()) {
            return;
        }
        /*
         Enable beat timer in case it is not:
          In non SPA, the session/start event may not be fired. This is why we need to start the beat
          timer when any request is triggered. The session/stop stops the timer when needed.
         */
        if (!this._beat.isRunning) {
            this._beat.start();
        }
        // Prepare params
        params = this.requestBuilder.buildParams(params, service, [this, new CommonGetters(this.options)]);
        // Check params and send session request
        if (params !== null && this.options.enabled) {
            const request = new SessionsRequest(service, params, undefined, onSuccess, onFail, service !== Constants.Service.SESSION_STOP ? this.expirationManager : undefined);
            if (this.isMethodPostEnabled()) {
                request.setMethod(Method.POST);
            }
            this.requestHandler.prepareParams(request);
            this.requestHandler.sendRequest(request, this.isActive());
            this._setLastActive();
        }
        else {
            Log.warn(SessionsTag, 'Cannot send session request since either params are not available or options are disabled');
            onFail === null || onFail === void 0 ? void 0 : onFail();
        }
    }
    // ---------------------------------------- LISTENERS -----------------------------------------
    /**
     * Adds a listener for analytics request events.
     * @param listener A function of type `(serviceName: string, params: Map<string, string>) => void` to be called when an analytics request is about to be sent.
     */
    addOnWillSendRequestListener(listener) {
        var _a;
        (_a = this.requestHandler) === null || _a === void 0 ? void 0 : _a.onWillSendAnalyticsRequestListeners.push(listener);
    }
    /**
     * Removes a previously added listener for analytics request events.
     * @param listener The listener function of type `(serviceName: string, params: Map<string, string>) => void` to remove from the list of analytics request listeners.
     */
    removeOnWillSendRequestListener(listener) {
        var _a;
        if (this.requestHandler)
            this.requestHandler.onWillSendAnalyticsRequestListeners =
                (_a = this.requestHandler) === null || _a === void 0 ? void 0 : _a.onWillSendAnalyticsRequestListeners.filter((l) => l !== listener);
    }
    /**
     * Process Internal Params object
     * @param params
     * @returns {{}|*|{}}
     * @private
     */
    _parseInternalParams(params) {
        if (params) {
            return params.params || params || {};
        }
        else {
            return {};
        }
    }
    /**
     * Sends beat request
     *
     * @param diffTime - Time since the last ping
     *
     * @private
     */
    _sendBeat(diffTime) {
        if (this.expiredSession({})) {
            return;
        }
        const params = {
            diffTime: diffTime
        };
        this._sendSession(Constants.Service.BEAT, params);
        Log.verbose(SessionsTag, Constants.Service.BEAT);
    }
    /**
     * Check if Session is expired
     * If is true, restart the session automatically
     *
     * @returns
     */
    expiredSession(dimensions = {}, sessionStarted = true) {
        try {
            if (this.expirationManager && this.expirationManager.isStarted() && this.expirationManager.isExpired()) {
                const options = this.getOptions();
                this.stop({}, undefined, undefined, true, sessionStarted);
                this.start(options, dimensions);
                setTimeout(() => {
                    try {
                        if (this.core) {
                            this.core.throwEvent(CoreEvents.SESSION_EXPIRE);
                        }
                    }
                    catch (e) { }
                }, ExpireDefault.STOP_VIEWS_AFTER_SESSION_EXPIRE_MS);
                return true;
            }
        }
        catch (e) { }
        return false;
    }
    /**
     * Check if change page/route (to avoid duplicated navigations)
     * @param params
     * @returns {boolean}
     * @private
     */
    _checkDifferentNavigation(params) {
        params = params || {};
        const sameRoute = this._lastNavigation && params.route === this._lastNavigation.route;
        const samePage = this._lastNavigation && params.page === this._lastNavigation.page;
        return !sameRoute || !samePage;
    }
    // ---------------------------------------- GETTERS -----------------------------------------
    /**
     * Returns a json with the metrics to be reported in beats when changed
     *
     */
    getSessionMetrics() {
        return Util.getMetricsFrom(this.options['session.metrics']);
    }
    /**
     * Is player event logs enabled (to report QA Tools additional info)
     *
     * @internal
     * @returns {boolean}
     */
    isPlayerLogsEnabled() {
        Log.debug(SessionsTag, 'isPlayerLogsEnabled: ' + this.options['debug.playerLogs.enabled']);
        return Util.parseBoolean(this.options['debug.playerLogs.enabled']);
    }
    /**
     * Check if is set protection on newSession method, to avoid stop the active session on newSession call
     *
     * @internal
     * @returns {boolean}
     */
    isSimpleNewSession() {
        if (this.options) {
            return Util.parseBoolean(this.options['simpleNewSession']);
        }
        return false;
    }
    /**
     * Is plugin pogs enabled (to report QA Tools additional info)
     *
     * @internal
     * @returns {boolean}
     */
    isPluginLogsEnabled() {
        Log.debug(SessionsTag, 'isPluginLogsEnabled: ' + this.options['debug.pluginLogs.enabled']);
        return Util.parseBoolean(this.options['debug.pluginLogs.enabled']);
    }
    /**
     * Is post method enabled
     * @returns {boolean}
     */
    isMethodPostEnabled() {
        return this.options.method && this.options.method.toUpperCase() === POST;
    }
    getSessionRoot() {
        return this.core.getFastDataSessionToken();
    }
    getSessionHost() {
        return this.core.getFastDataSessionHost();
    }
    // ----------------------------------------- LOGS -------------------------------------------
    /**
     *
     * @param willSendLog
     * @param service
     * @param params
     */
    _sendPluginLogs(willSendLog, service, params) {
        if (this.core.isUsingLegacy())
            return;
        if (this.isPluginLogsEnabled()) {
            try {
                if (params && params.logType && params.logAction) {
                    const token = this.core.getFastDataSessionToken();
                    Log.notice(SessionsTag, token.length ? '[' + token + '] ' : '' + params.logType + ': Action ' + params.logAction);
                }
            }
            catch (e) {
                /* empty */
            }
            params['timemark'] = new Date().getTime();
            params = this.requestBuilder.buildParams(params, service);
            if (params !== null && this.options.enabled) {
                const request = new SessionsRequest(service, params);
                if (this.isMethodPostEnabled()) {
                    request.setMethod(Method.POST);
                }
                this.requestHandler.prepareParams(request);
                this.requestHandler.sendRequest(request, this.isActive());
            }
        }
    }
    _logFireSessionStartEvent(dimensions) {
        const params = {
            logs: {
                data: dimensions
            },
            logAction: 'startSession',
            logType: 'pluginMethod'
        };
        this._sendPluginLogs(Constants.WillSendLog.WILL_SEND_LOG_INFINITY_START, Constants.Service.APP_ANALYTICS_PLUGIN_LOGS, params);
    }
    _logFireSessionStopEvent(eventParams) {
        const params = {
            logs: {
                data: eventParams
            },
            logAction: 'stopSession',
            logType: 'pluginMethod'
        };
        this._sendPluginLogs(Constants.WillSendLog.WILL_SEND_LOG_INFINITY_START, Constants.Service.APP_ANALYTICS_PLUGIN_LOGS, params);
    }
    _logFireEventListener(eventName) {
        const params = {
            logs: {
                data: eventName
            },
            logAction: 'eventSession',
            logType: 'pluginMethod'
        };
        this._sendPluginLogs(Constants.WillSendLog.WILL_SEND_LOG_INFINITY_EVENT, Constants.Service.APP_ANALYTICS_PLUGIN_LOGS, params);
    }
    _logFireNavListener(dimensions) {
        const params = {
            logs: {
                data: dimensions
            },
            logAction: 'nav',
            logType: 'pluginMethod'
        };
        this._sendPluginLogs(Constants.WillSendLog.WILL_SEND_LOG_INFINITY_NAV, Constants.Service.APP_ANALYTICS_PLUGIN_LOGS, params);
    }
}
Session.BEAT_INTERVAL = 30000;
