import {
  AWPTextTrackKind,
  CoreEvents,
  PlaybackState,
  ThumbnailVariants,
} from "@tv4/avod-web-player-common";
import type { Core } from "@tv4/avod-web-player-core";
import { isMobile } from "@tv4/avod-web-player-device-capabilities";
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { AdBreakOverlay } from "./components/AdBreakOverlay/AdBreakOverlay";
import { AdShowingIndicator } from "./components/AdShowingIndicator/AdShowingIndicator";
import HtmlTextTracks from "./components/audio-text-tracks/HtmlTextTracks";
import { ChromeCastControls } from "./components/ChromeCastControls";
import ChromeCastOverlay from "./components/ChromeCastOverlay";
import { DebugOverlay } from "./components/DebugOverlay/DebugOverlay";
import { DefaultControls } from "./components/DefaultControls/DefaultControls";
import ErrorMessage from "./components/ErrorMessage/ErrorMessage";
import { Header } from "./components/Header";
import IconButton from "./components/IconButton";
import { InactivePrompt } from "./components/InactivePrompt/InactivePrompt";
import { MobileControls } from "./components/MobileControls/MobileControls";
import { PlaybackButton } from "./components/PlaybackButton";
import Poster from "./components/Poster";
import SkipButton from "./components/SkipButton";
import { Spinner } from "./components/spinner/Spinner";
import useAutoHideUI from "./hooks/useAutoHideUI";
import useCore from "./hooks/useCore";
import useSkinVisibilityChangeEvent from "./hooks/useSkinVisibilityChangeEvent";
import ChevronLeftSvg from "./icons/ChevronLeft.svg";
import { CoreProvider } from "./providers/CoreProvider";
import {
  useLayoutSettings,
  useTracksMenuOpen,
  useUIVisibility,
} from "./providers/LayoutProvider";
import { useMetadata } from "./providers/MetadataProvider";
import { ThumbnailsProvider } from "./providers/ThumbnailsProvider";
import {
  FloatingBackButton,
  GradientMode,
  SkinContainer,
  SkinWrapper,
} from "./styles";

export type TSkinProps = {
  core: Core;
  thumbnails?: ThumbnailVariants;
  forceShowSkin?: boolean;
  showInactivePrompt?: boolean;
  onExitClick?: () => void;
  onBackClick?: () => void;
  onCloseClick?: () => void;
  onVideoClick?: () => void;
  onPlayWhenEnded?: () => void;
  ref: React.RefObject<HTMLElement | null>;
};

const HIDE_SKIP_BUTTON_DELAY = 10 * 1000;
let skipButtonTimeout: number;

const PosterBackButton = ({ onBackClick }: { onBackClick: () => void }) => (
  <FloatingBackButton>
    <IconButton label="navigate back" onClick={onBackClick}>
      <ChevronLeftSvg />
    </IconButton>
  </FloatingBackButton>
);

const Skin = forwardRef(
  (
    {
      core,
      thumbnails,
      forceShowSkin,
      showInactivePrompt,
      onBackClick,
      onCloseClick,
      onExitClick,
      onPlayWhenEnded,
    }: TSkinProps,
    skinWrapperRef: ForwardedRef<HTMLElement>
  ) => {
    const { poster } = useMetadata();
    const {
      playerState,
      controls,
      adMarkers,
      currentAdBreak,
      currentAd,
      chromeCastManager,
      options,
      error,
    } = useCore(core);

    const { uiVisible, setUIVisible } = useUIVisibility();
    const { noInitialLoadSpinner } = useLayoutSettings();
    const [isSkipButtonActive, setIsSkipButtonActive] = useState(false);
    const [buttonsEnabled, setButtonsEnabled] = useState(false);
    const { tracksMenuOpen } = useTracksMenuOpen();

    const [started, setStarted] = useState(false);

    const {
      playbackState,
      isCasting,
      autoplayBlocked,
      activeTextTrack,
      isFullscreen,
      isPauseAd,
      debugOverlay,
    } = playerState;

    const idle = playbackState === PlaybackState.IDLE;
    const loading = playbackState === PlaybackState.LOADING;
    const seeking = playbackState === PlaybackState.SEEKING;
    const buffering = playbackState === PlaybackState.BUFFERING;
    const ended = playbackState === PlaybackState.ENDED;

    useEffect(() => {
      const handleToggleVote = () => {
        core.emitCoreEvent(CoreEvents.TOGGLE_VOTING, undefined);
      };

      document.addEventListener("toggleVote", handleToggleVote);

      return () => document.removeEventListener("toggleVote", handleToggleVote);
    }, [core]);

    useEffect(() => {
      const handleToggleChannelsOverlay = () => {
        core.emitCoreEvent(CoreEvents.TOGGLE_CHANNELS_OVERLAY, undefined);
      };

      document.addEventListener(
        "toggleChannelsOverlay",
        handleToggleChannelsOverlay
      );

      return () =>
        document.removeEventListener(
          "toggleChannelsOverlay",
          handleToggleChannelsOverlay
        );
    }, [core]);

    useEffect(() => {
      if (
        skinWrapperRef &&
        "current" in skinWrapperRef &&
        skinWrapperRef.current
      ) {
        const handleTouchStart = (e: TouchEvent) => {
          const container = skinWrapperRef.current;
          if (!container?.contains(e.target as Node)) {
            setUIVisible(false);
          }
        };

        document.addEventListener("touchstart", handleTouchStart);

        return () =>
          document.removeEventListener("touchstart", handleTouchStart);
      }
    }, [setUIVisible, skinWrapperRef]);

    const onInteractionStart = useCallback(() => {
      setIsSkipButtonActive(true);
      clearTimeout(skipButtonTimeout);
    }, [setIsSkipButtonActive]);

    const activeRef = useRef(false);

    const onInteractionEnd = useCallback(() => {
      skipButtonTimeout = window.setTimeout(
        () => setIsSkipButtonActive(false),
        HIDE_SKIP_BUTTON_DELAY
      );
      // using ref so timeout is not reset when active changes
      if (!activeRef.current) {
        // hide ui if not active
        setUIVisible(false);
      }
    }, [setIsSkipButtonActive, setUIVisible]);

    const { handleInteraction, interacting } = useAutoHideUI({
      onInteractionStart,
      onInteractionEnd,
    });

    const handleTouchUI = () => {
      // consider active when using touch
      activeRef.current = true;
      handleInteraction();
    };

    // clear timeout on unmount
    useEffect(() => () => clearTimeout(skipButtonTimeout), []);

    // mouse leave on computer will hide ui when moving mouse pointer away from player. pointerleave is not used because it is triggered by touch actions on touch devices.
    const handleMouseLeave = useCallback(() => {
      activeRef.current = false;
      // don't hide if still dragging
      if (!interacting()) {
        setUIVisible(false);
      }
    }, [setUIVisible, interacting]);

    const handlePointerOver = () => (activeRef.current = true);
    const handleBackClick = () => {
      if (isFullscreen) controls?.toggleFullscreen?.();
      onBackClick?.();
    };

    useEffect(() => {
      setTimeout(
        () => {
          setButtonsEnabled(uiVisible);
        },
        uiVisible ? 500 : 0
      );
    }, [uiVisible]);

    useEffect(() => {
      if (
        playbackState === PlaybackState.IDLE ||
        playbackState === PlaybackState.ENDED
      ) {
        setStarted(false);
      } else if (playbackState === PlaybackState.PLAYING) {
        setStarted(true);
      }
    }, [playbackState]);

    const displayPosterContent = () => {
      if (isCasting || (started && !autoplayBlocked)) return null;

      const hasError = error && Boolean(error);
      const isBlurred = Boolean(error);
      const isLoading = idle || loading;
      const canPlayback = !started || autoplayBlocked;

      return (
        <>
          <Poster poster={poster} blurred={isBlurred} />
          {hasError && <ErrorMessage error={error} onExitClick={onExitClick} />}
          {!hasError && !isLoading && canPlayback && (
            <PlaybackButton onPlayWhenEnded={onPlayWhenEnded} />
          )}
          {shouldShowBackButton && (
            <PosterBackButton onBackClick={handleBackClick} />
          )}
        </>
      );
    };

    const shouldShowSkin = useMemo(() => {
      if (forceShowSkin) return true;
      if (isCasting || tracksMenuOpen || playerState.preSeeking) return true;

      return started && !idle && !loading && uiVisible;
    }, [
      isCasting,
      forceShowSkin,
      started,
      idle,
      loading,
      uiVisible,
      tracksMenuOpen,
      playerState.preSeeking,
    ]);

    useSkinVisibilityChangeEvent(core, shouldShowSkin);

    const shouldShowBackButton = useMemo(() => {
      return onBackClick !== undefined || isFullscreen;
    }, [isFullscreen, onBackClick]);

    const emitAdClickEventAndPauseIfAllowed = (url: string) => {
      core.emitCoreEvent(CoreEvents.AD_CLICK, {
        url: url,
      });
      if (playerState.canPause) {
        controls?.pause?.({ programmatic: true });
      }
    };

    const emitInactiveAnswer = (answer: boolean) => {
      if (answer) {
        core.emitCoreEvent(CoreEvents.USER_ACTIVE_CONFIRM, undefined);
      } else {
        core.emitCoreEvent(CoreEvents.USER_ACTIVE_DECLINE, undefined);
      }
    };

    const getCurrentCoreState = () => {
      return core.getState();
    };

    const getDebugInfo = () => {
      return core.getDebugInfo();
    };

    const showTextTracks =
      activeTextTrack?.kind !== AWPTextTrackKind.NOTHING &&
      !playerState.isPauseAd &&
      !playerState.isAd;

    let Controls = DefaultControls;

    if (isCasting) {
      Controls = ChromeCastControls;
    } else if (isMobile()) {
      Controls = MobileControls;
    }
    return (
      <CoreProvider
        playerState={playerState}
        controls={controls}
        adMarkers={adMarkers}
        currentAdBreak={currentAdBreak}
        currentAd={currentAd}
        options={options}
        chromeCastManager={chromeCastManager}
        getCurrentState={getCurrentCoreState}
        getDebugInfo={getDebugInfo}
      >
        <SkinWrapper
          ref={skinWrapperRef}
          onClick={handleInteraction}
          onPointerMove={handleInteraction}
          onTouchStart={handleTouchUI}
          onPointerOver={handlePointerOver}
          onMouseLeave={handleMouseLeave}
          $isActive={uiVisible}
        >
          <AdShowingIndicator visible={isPauseAd} maximized={shouldShowSkin} />
          {isCasting && <ChromeCastOverlay />}
          {!ended && <SkipButton isSkipButtonActive={isSkipButtonActive} />}
          {!ended && (
            <SkinContainer
              id="skin-container"
              visible={shouldShowSkin}
              gradientMode={isPauseAd ? GradientMode.BOTTOM : GradientMode.BOTH}
              buttonsEnabled={buttonsEnabled}
            >
              <Header onBackClick={onBackClick} onCloseClick={onCloseClick} />
              <ThumbnailsProvider thumbnails={thumbnails} useImageProxy>
                <Controls />
              </ThumbnailsProvider>
            </SkinContainer>
          )}
          {showInactivePrompt && (
            <InactivePrompt onAnswer={emitInactiveAnswer} />
          )}
          {displayPosterContent()}
          {((!noInitialLoadSpinner && loading) || buffering || seeking) && (
            <Spinner />
          )}
          {!autoplayBlocked && (
            <AdBreakOverlay
              visible={shouldShowSkin}
              makeSpaceForBackButton={shouldShowBackButton}
              onAdButtonClick={emitAdClickEventAndPauseIfAllowed}
            />
          )}
        </SkinWrapper>
        {showTextTracks && <HtmlTextTracks isRaised={shouldShowSkin} />}
        {debugOverlay && <DebugOverlay />}
      </CoreProvider>
    );
  }
);

export default Skin;
