import { useCallback, useContext, useEffect } from "react";

import { LayoutContext } from "../providers/LayoutProvider";

type TCallback = () => void;

type TUseAutoHideUIProps = {
  onInteractionStart?: TCallback;
  onInteractionEnd?: TCallback;
};

const interactionStartCallbacks: TCallback[] = [];
const interactionEndCallbacks: TCallback[] = [];

function addCallback(
  callbacks: TCallback[],
  callback: TCallback | undefined
): void {
  if (callback) {
    callbacks.unshift(callback);
  }
}

function removeCallback(
  callbacks: TCallback[],
  callback: TCallback | undefined
): void {
  let index = -1;
  if (callback) {
    index = callbacks.indexOf(callback);
  }
  if (index !== -1) {
    callbacks.splice(index, 1);
  }
}

function triggerCallbacks(
  callbacks: typeof interactionStartCallbacks | typeof interactionEndCallbacks
): void {
  let i = callbacks.length;
  while (i--) {
    callbacks[i]();
  }
}

export default function useAutoHideUI({
  onInteractionStart,
  onInteractionEnd,
}: TUseAutoHideUIProps = {}) {
  const { interacting, handleInteractionStart, handleInteractionEnd } =
    useContext(LayoutContext);

  const interactionStart = useCallback(() => {
    handleInteractionStart();
    triggerCallbacks(interactionStartCallbacks);
  }, [handleInteractionStart]);

  const interactionEnd = useCallback(() => {
    handleInteractionEnd();
    if (!interacting()) {
      triggerCallbacks(interactionEndCallbacks);
    }
  }, [interacting, handleInteractionEnd]);

  const handleInteraction = useCallback(() => {
    interactionStart();
    interactionEnd();
  }, [interactionStart, interactionEnd]);

  useEffect(() => {
    addCallback(interactionEndCallbacks, onInteractionEnd);
    addCallback(interactionStartCallbacks, onInteractionStart);
    if (onInteractionEnd || onInteractionStart) {
      return () => {
        removeCallback(interactionEndCallbacks, onInteractionEnd);
        removeCallback(interactionStartCallbacks, onInteractionStart);
      };
    }
  }, [onInteractionStart, onInteractionEnd]);

  return {
    interacting,
    handleInteraction,
    handleInteractionStart: interactionStart,
    handleInteractionEnd: interactionEnd,
  };
}
