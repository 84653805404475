import {
  Asset,
  getTopLocationHost,
  LinkPulseTrackingMetadata,
} from "@tv4/avod-web-player-common";

import { ITracker, ITrackerSetupOptions } from "./tracker";

export interface IKilkayaTrackerInstanceOptions {
  trackingMetadata: LinkPulseTrackingMetadata;
  channel: string;
}

export class KilkayaTracking implements ITracker {
  private contentMetadata: LinkPulseTrackingMetadata;
  private asset: Asset | undefined;
  private isLive = false;
  private duration = 0;
  private liveDuration = 0;

  private videoUrl: string;
  private videoTitle: string;
  private pageSection?: string;
  private videoImage?: string;

  private pageReferrer;
  private pageUrl;
  private pageType;
  private channel: string;

  private started = false;
  private progressHeartbeatInterval: number = -1;
  private secondsWatched = 0;
  private lastTimePinged = 0;
  private livePing = 0;
  private livePingHeartbeatInterval: number = -1;
  private pingInterval = 60;

  constructor(options: IKilkayaTrackerInstanceOptions) {
    this.channel = options.channel;
    this.contentMetadata = options.trackingMetadata;
    this.videoUrl = this.contentMetadata.videoUrl;
    this.videoTitle = this.contentMetadata.title;
    this.pageSection = this.contentMetadata.videoSection ?? undefined;
    this.videoImage = this.contentMetadata.image ?? undefined;
  }

  public setup(options: ITrackerSetupOptions): void {
    this.setGlobalData();
    this.asset = options.metadata?.asset;
    this.isLive = !!this.asset?.isLive;
    // TODO: options.metadata is optional, but should always be provided.
    // If it's not, TrackingManager won't be initialized, however the old event listeners
    // remain and will send events with the PREVIOUS metadata (if Kilkaya was initialized previously)
    this.duration = this.isLive ? this.liveDuration : this.asset?.duration || 0;
    if (this.isLive) {
      this.isLive = true;
      this.liveDuration = 0;
      this.livePing = 0;
      this.livePingHeartbeatInterval = window.setInterval(() => {
        this.livePing += 1;
      }, 1000);
    }
  }

  public setGlobalData(): void {
    if ("k5aMeta" in window) {
      const k5aMeta = window.k5aMeta as any;
      this.pageReferrer = k5aMeta?.referrer ?? getTopLocationHost();
      this.pageUrl = k5aMeta?.url ?? window.location.href;
      this.pageType = k5aMeta?.type ?? this.contentMetadata.videoType;
    }
  }

  private trackSecondsWatched(): void {
    clearInterval(this.progressHeartbeatInterval);
    this.progressHeartbeatInterval = window.setInterval(() => {
      this.secondsWatched += 1;
    }, 1000);
  }

  public onStart(): void {
    if (!this.started) {
      this.started = true;
      this.secondsWatched = 0;
      this.lastTimePinged = 0;
      this.reportStart();
    }
    this.trackSecondsWatched();
  }

  public onPause(): void {
    clearInterval(this.progressHeartbeatInterval);
  }

  public onResume(): void {
    // Pressing the replay button triggers a "resume" event rather than a "start" event,
    // which is why we need to send a start event assuming the previous play has finished
    this.onStart();
  }

  public onAdBreakStart(): void {
    clearInterval(this.progressHeartbeatInterval);
  }
  public onAdBreakEnd(): void {
    this.onStart();
  }

  // In case we load new content in the same player instance rather than creating a new one,
  // it should count as the video ending
  public onReset(): void {
    this.onEnded();
  }

  public onEnded(): void {
    if (this.started) {
      this.started = false;
      this.reportStop();
      clearInterval(this.progressHeartbeatInterval);
      clearInterval(this.livePingHeartbeatInterval);
    }
  }

  public onTimeUpdate(): void {
    if (
      this.isLive &&
      this.livePing !== this.lastTimePinged &&
      this.livePing % this.pingInterval === 0
    ) {
      this.reportActive();
      this.lastTimePinged = this.livePing;
    }
  }

  public onAdTimeUpdate(): void {
    this.onTimeUpdate();
  }

  private reportStart(): void {
    return this.report({
      l: "v",
      vvt: "0",
      vst: "auto",
    });
  }

  private reportStop(): void {
    return this.report({
      l: "v",
      vvt: this.secondsWatched.toString(),
      vst: "stop",
    });
  }

  private reportActive(): void {
    return this.report({
      l: "a",
      vvt: this.secondsWatched.toString(),
      vst: "auto",
    });
  }

  private report(params?: Record<string, string>): void {
    fetch(
      "https://cl-eu2.k5a.io?" +
        new URLSearchParams({
          i: "61823e7e1ee74e7c3a3f67a2",
          u: this.pageUrl,
          c: this.channel,
          r: this.pageReferrer,
          ...(this.pageSection && { psn: this.pageSection }),
          ptp: this.pageType,
          vid: this.videoUrl,
          vtl: this.videoTitle,
          drn: this.duration.toString(),
          ...(this.videoImage && { vig: this.videoImage }),
          _s: "js",
          _h: "video",
          ...params,
        })
    );
  }

  public destroy(): void {
    this.onEnded();
  }
}
