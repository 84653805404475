import {
  AWPAudioTrack,
  AWPError,
  AWPTextTrack,
  BufferSeekingPayload,
  NoPayload,
  PausePayload,
  TimePayload,
} from "@tv4/avod-web-player-common";

export enum MediaEvents {
  LOADING = "media:loading",
  LOADED = "media:loaded",
  PLAY = "media:play",
  PLAYING = "media:playing",
  PAUSED = "media:paused",
  TIME_UPDATE = "media:time_update",
  SEEKING = "media:seeking",
  SEEKED = "media:seeked",
  BUFFERING = "media:buffering",
  BUFFERED = "media:buffered",
  END_OF_STREAM = "media:ended",
  ERROR = "media:error",
  AUTOPLAY_BLOCKED = "media:autoplay_blocked",
  SUCCESSFUL_PLAY = "media:successful_play",
  ID3 = "media:id3",
  VOLUME_CHANGED = "media:volume_changed",
  TRACK_CHANGED = "media:track_changed",
  DROPPED_FRAMES = "media:dropped_frames",
  TEXT_TRACK_CHANGED = "media:text_track_changed",
  TEXT_CUE_ENTERED = "media:text_cue_entered",
  TEXT_CUE_EXITED = "media:text_cue_exited",
  AUDIO_TRACK_CHANGED = "media:audio_track_changed",
  DRM_LICENSE_ERROR = "media:drm_license_error",
  PLAYBACK_RATE_CHANGE = "media:playback_rate_change",

  IN_STREAM_METADATA = "media:in_stream_metadata",
  CDN_CHANGED = "media:cdn_changed",
}

export type MediaNoPayload = undefined;
export type MediaLoadingPayload = {
  src: string;
  startTime?: number;
};
export type MediaPlaybackEndedPayload = TimePayload & {
  error?: MediaError | null;
};
export type MediaID3Payload = {
  startTime: number;
  id3: Record<string, any>;
};
export type MediaTrackPayload = {
  width: number;
  height: number;
  bitrate: number;
  fps: number;
  audioCodec?: string;
  videoCodec?: string;
};
export type MediaDroppedFramesPayload = { droppedFrames: number };
export type MediaErrorPayload = TimePayload & { error: AWPError };

export type MediaVolumePayload = { volume: number; muted: boolean };
export type MediaTextTrackPayload = {
  activeTextTrack: AWPTextTrack;
  textTracks: AWPTextTrack[];
};

export type MediaTextTrackCueEnteredPayload = {
  text: string;
};
export type MediaAudioTrackPayload = {
  activeAudioTrack?: AWPAudioTrack;
  audioTracks?: AWPAudioTrack[];
};

export type MediaPlaybackRateChange = {
  playbackRate: number;
};

export type MediaInStreamMetadataPayload = { title: string };
export type MediaCdnChangedPayload = { cdn: string };

export interface MediaEventsMap {
  [MediaEvents.LOADING]: MediaLoadingPayload;
  [MediaEvents.LOADED]: TimePayload;
  [MediaEvents.PLAY]: TimePayload;
  [MediaEvents.PLAYING]: TimePayload;
  [MediaEvents.PAUSED]: PausePayload;
  [MediaEvents.TIME_UPDATE]: TimePayload;
  [MediaEvents.SEEKING]: BufferSeekingPayload;
  [MediaEvents.SEEKED]: BufferSeekingPayload;
  [MediaEvents.BUFFERING]: BufferSeekingPayload;
  [MediaEvents.BUFFERED]: BufferSeekingPayload;
  [MediaEvents.END_OF_STREAM]: MediaPlaybackEndedPayload;
  [MediaEvents.ERROR]: MediaErrorPayload;
  [MediaEvents.AUTOPLAY_BLOCKED]: MediaNoPayload;
  [MediaEvents.SUCCESSFUL_PLAY]: MediaNoPayload;
  [MediaEvents.ID3]: MediaID3Payload;
  [MediaEvents.VOLUME_CHANGED]: MediaVolumePayload;

  [MediaEvents.TEXT_TRACK_CHANGED]: MediaTextTrackPayload;
  [MediaEvents.TEXT_CUE_ENTERED]: MediaTextTrackCueEnteredPayload;
  [MediaEvents.TEXT_CUE_EXITED]: NoPayload;
  [MediaEvents.AUDIO_TRACK_CHANGED]: MediaAudioTrackPayload;
  [MediaEvents.TRACK_CHANGED]: MediaTrackPayload;
  [MediaEvents.DRM_LICENSE_ERROR]: MediaErrorPayload;
  [MediaEvents.DROPPED_FRAMES]: MediaDroppedFramesPayload;

  [MediaEvents.IN_STREAM_METADATA]: MediaInStreamMetadataPayload;
  [MediaEvents.CDN_CHANGED]: MediaCdnChangedPayload;
  [MediaEvents.PLAYBACK_RATE_CHANGE]: MediaPlaybackRateChange;
}

export const STALE_SESSION = "STALE SESSION";

export const ONE_MINUTE_MS = 60_000;
export const SESSION_TIMEOUT_MS = 30 * ONE_MINUTE_MS;

export type DEBUG_SEGMENT_DATA = {
  cdn?: string;
  path?: string;
  headers: Record<string, string>;
};

export const CAST_LABS_ERROR = {
  DRM_DEVICE_REVOKED: "40001",
  DRM_DEVICE_REVOKED_PERMANENT: "40002",
};
