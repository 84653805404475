import AgeRating7Svg from "../icons/mtv/7.svg";
import AgeRating12Svg from "../icons/mtv/12.svg";
import AgeRating16Svg from "../icons/mtv/16.svg";
import AgeRating18Svg from "../icons/mtv/18.svg";
import AgeRatingAnxietySvg from "../icons/mtv/anxiety.svg";
import ProductPlacementSvg from "../icons/mtv/product.svg";
import AgeRatingSSvg from "../icons/mtv/S.svg";
import AgeRatingSexSvg from "../icons/mtv/sex.svg";
import AgeRatingSubstancesSvg from "../icons/mtv/substances.svg";
import AgeRatingViolenceSvg from "../icons/mtv/violence.svg";

const ageIcons = [
  { component: AgeRatingSSvg, age: "S" },
  { component: AgeRating7Svg, age: "7" },
  { component: AgeRating12Svg, age: "12" },
  { component: AgeRating16Svg, age: "16" },
  { component: AgeRating18Svg, age: "18" },
];

const descriptorIcons = [
  { component: AgeRatingAnxietySvg, name: "anxiety" },
  { component: AgeRatingSubstancesSvg, name: "intoxicants" },
  { component: AgeRatingSexSvg, name: "sex" },
  { component: AgeRatingViolenceSvg, name: "violence" },
];

export { ageIcons, descriptorIcons, ProductPlacementSvg };
