"use strict";

exports.__esModule = true;
exports.VISIBLE_EVENTS = exports.VERIFICATION_EVENTS = exports.TIMELINE_EVENTS = exports.Payload = exports.PLAYER_OPERATION_EVENTS = exports.NON_LINEAR_EVENTS = exports.LINEAR_EVENTS = exports.LEGACY_EVENT_MAPPING = exports.INTERACTIVE_EVENTS = exports.Constant = exports.COMPANION_EVENTS = exports.CAT_TIMELINE_EVENTS = exports.CAT_AD_BREAK_EVENTS = exports.AD_BREAK_EVENTS = void 0;
exports.getLogTag = getLogTag;
/*
 * COPYRIGHT 2020-2022 YOSPACE TECHNOLOGIES LTD. ALL RIGHTS RESERVED.
 * The contents of this file are proprietary and confidential.
 * Unauthorised copying of this file, via any medium is strictly prohibited.
 */

var USER_AGENT = process.env.HTTP_AGENT ? process.env.HTTP_AGENT : "Yospace SDK/3.0";
var PLAYER_OPERATION_EVENTS = exports.PLAYER_OPERATION_EVENTS = ["mute", "unmute", "pause", "resume", "rewind", "playerExpand", "playerCollapse", "closeLinear", "skip"];
var COMPANION_EVENTS = exports.COMPANION_EVENTS = ["creativeView"];
var INTERACTIVE_EVENTS = exports.INTERACTIVE_EVENTS = ["interactiveStart"];
var LINEAR_EVENTS = exports.LINEAR_EVENTS = ["loaded", "start", "firstQuartile", "midpoint", "thirdQuartile", "complete", "progress", "mute", "unmute", "pause", "resume", "rewind", "playerExpand", "playerCollapse", "closeLinear", "skip", "otherAdInteraction"];
var NON_LINEAR_EVENTS = exports.NON_LINEAR_EVENTS = ["creativeView", "mute", "unmute", "pause", "resume", "rewind", "skip", "playerExpand", "playerCollapse", "adExpand", "adCollapse", "minimize", "overlayViewDuration", "acceptInvitation", "close", "otherAdInteraction"];
var VISIBLE_EVENTS = exports.VISIBLE_EVENTS = ["creativeView", "close", "interactiveStart"];
var VERIFICATION_EVENTS = exports.VERIFICATION_EVENTS = ["verificationNotExecuted"];
var TIMELINE_EVENTS = exports.TIMELINE_EVENTS = ["loaded", "start", "firstQuartile", "midpoint", "thirdQuartile", "complete", "progress"];
var AD_BREAK_EVENTS = exports.AD_BREAK_EVENTS = ["breakStart", "breakEnd"];
var LEGACY_EVENT_MAPPING = exports.LEGACY_EVENT_MAPPING = {
  creativeView: "start",
  fullscreen: "playerExpand",
  exitFullscreen: "playerCollapse",
  expand: "playerExpand",
  collapse: "playerCollapse"
};
var Constant = exports.Constant = Object.freeze({
  ADVERT_START_TIMESLOT: 5,
  DEFAULT_POLLING_DELAY: 11000,
  EVENT_CUEPOINT_TOL: 10,
  EXPECTED_POLL_COMPLETE_TIME: 250,
  INTERSEGMENT_TIMEOUT: 2700,
  REQUEST_TIMEOUT: 5000,
  MAX_CADENCE: 250,
  MIN_INTRASEGMENT_TIMEOUT: 2500,
  MIN_SEGMENT_LENGTH: 2000,
  USER_AGENT: USER_AGENT
});
function getLogTag() {
  return "YoAdManagement: ";
}

/**
 * Event category used for suppression of analytics: Ad break events defined in the VMAP specification
 * @category Suppression Categories
 */
var CAT_AD_BREAK_EVENTS = exports.CAT_AD_BREAK_EVENTS = 1;

/**
 * Event category used for suppression of analytics: timeline events ('quartiles') defined in the VAST specification
 * @category Suppression Categories
 */
var CAT_TIMELINE_EVENTS = exports.CAT_TIMELINE_EVENTS = 1 << 1;

// parsing map keys
var Payload = exports.Payload = Object.freeze({
  IDENTIFIER: "SESSIONIDENTIFIER",
  LOCATION: "LOCATION",
  HOSTNODE: "HOST",
  PORT: "PORT",
  ANALYTICURL: "ANALYTICURL",
  PAUSEURL: "PAUSEURL",
  SCHEME: "SCHEME",
  QS: "QUERYSTRING",
  AVAILABILITYSTART: "AVAILABILITYSTARTTIME"
});