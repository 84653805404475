import {
  AWPError,
  CoreEvents,
  CoreEventsMap,
  ERROR_CATEGORY,
  ErrorLevel,
  EventEmitter,
  USER_ERROR,
} from "@tv4/avod-web-player-common";

import { SESSION_TIMEOUT_MS } from "./media_engine/utils/mediaConstants";

type TOptions = {
  on: EventEmitter<CoreEventsMap>["on"];
  off: EventEmitter<CoreEventsMap>["off"];
  emit: EventEmitter<CoreEventsMap>["emit"];
};

export class PauseHandler {
  private readonly off: EventEmitter<CoreEventsMap>["off"];
  private readonly emit: EventEmitter<CoreEventsMap>["emit"];
  private pauseTimestamp: number | undefined;
  private interval: number | undefined;

  constructor({ on, off, emit }: TOptions) {
    on(CoreEvents.PAUSED, this.onPause);
    on(CoreEvents.RESET, this.onReset);
    on(CoreEvents.ENDED, this.onEnded);
    on(CoreEvents.LOADED_PLAYBACK, this.onLoaded);
    on(CoreEvents.RESUME, this.onResume);

    this.off = off;
    this.emit = emit;
  }

  private checkIsInactive() {
    if (
      this.pauseTimestamp &&
      Date.now() - this.pauseTimestamp > SESSION_TIMEOUT_MS
    ) {
      this.emit(CoreEvents.ERROR, {
        currentTime: 0,
        duration: 0,
        isInAdBreak: false,
        error: new AWPError({
          code: USER_ERROR.USER_INACTIVITY_STALE_SESSION,
          category: ERROR_CATEGORY.USER,
          errorLevel: ErrorLevel.USER,
          context: "unknown",
          message: "Session closed due to user inactivity.",
        }),
      });
    }
  }

  private onLoaded = () => {
    document.addEventListener("visibilitychange", this.checkIsInactive);

    this.interval = window.setInterval(() => {
      this.checkIsInactive();
    }, 1000);
  };

  private onEnded = () => {
    this.reset();
  };

  private onResume = () => {
    this.pauseTimestamp = undefined;
  };

  private onPause = () => {
    this.pauseTimestamp = Date.now();
  };

  private onReset = () => {
    this.reset();
  };

  private reset() {
    if (this.interval !== undefined) {
      window.clearInterval(this.interval);
    }

    document.removeEventListener("visibilitychange", this.checkIsInactive);

    this.pauseTimestamp = undefined;
  }

  public destroy() {
    this.reset();

    this.off(CoreEvents.PAUSED, this.onPause);
    this.off(CoreEvents.RESET, this.onReset);
    this.off(CoreEvents.ENDED, this.onEnded);
    this.off(CoreEvents.LOADED_PLAYBACK, this.onLoaded);
    this.off(CoreEvents.RESUME, this.onResume);
  }
}
