import { PosterImage } from "../styles";

export const Poster = ({
  poster,
  blurred,
}: {
  poster?: string;
  blurred?: boolean;
}) => {
  if (!poster) {
    return null;
  }
  return <PosterImage src={poster} blurred={blurred} />;
};

export default Poster;
