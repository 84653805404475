import {
  emptyFetch,
  isMtvOrUutisetService,
  Service,
} from "@tv4/avod-web-player-common";

import { Tracker } from "./tracker";

export interface IFinnpanelTrackerInstanceOptions {
  deviceId: string;
  service: Service;
}

const FINNPANEL_URL: string = "https://endpoint.finnpanel.fi/?vid=";

export class FinnpanelTracking extends Tracker {
  private deviceId: string;
  private service: Service;

  constructor({ deviceId, service }: IFinnpanelTrackerInstanceOptions) {
    super();

    this.deviceId = deviceId;
    this.service = service;
  }

  public onStart(): void {
    /**
     * intention is that this is called each time a video start,
     * and also should be synched with wire session starting,
     * which will happen when onStart are called for both trackers.
     */
    if (isMtvOrUutisetService(this.service)) {
      emptyFetch(FINNPANEL_URL + this.deviceId);
    }
  }

  public destroy(): void {
    // noop
  }
}
