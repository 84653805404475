var Emitter = require('../../emitter')

var Transform = Emitter.extend(
  /** @lends npaw.Transform.prototype */
  {
    /**
     * Transform classes in NPAW help the library parse and work with data.
     *
     * A Transform makes some kind of task that may block requests until it's done, or applies changes
     * to the requests right before they're finally sent.
     *
     * {@link ResourceTransform}, {@link FastDataTransform}... all extend from this class.
     *
     * @constructs Transform
     * @extends npaw.Emitter
     * @memberof npaw
     * @abstract
     */
    constructor: function () {
      /**
       * Whether the Transform is currently working or not.
       * @private
       */
      this._isBusy = true
      this._sendRequest = true
      this.transformName = 'Transform'
    },

    /**
     * Override this method to transform info.
     *
     * @param {YBRequest} request Request to transform.
     */
    parse: function (request) { },

    /**
     * By default this will return true until {@link #done()} is called. This can be overridden
     * in order to block {@link Request}s based on any criteria. For instance its
     * {@link Request#getService()}.
     *
     * @param {YBRequest} request Request to transform.
     * @return {bool} True if queue shall be blocked.
     */
    isBlocking: function (request) {
      return this._isBusy
    },

    /**
     * Emits DONE event
     */
    done: function () {
      this._isBusy = false
      this.emit(Transform.Event.DONE)
    },

    // offline
    hasToSend: function (request) {
      return this._sendRequest
    },

    getState: function () {
      if (!this._sendRequest) {
        return this.STATE_OFFLINE
      }
      if (this._isBusy) {
        return this.STATE_BLOCKED
      }
      return this.STATE_NO_BLOCKED
    }

  },

  /** @lends npaw.Transform */
  {
    // Static members

    STATE_OFFLINE: 2,
    STATE_BLOCKED: 1,
    STATE_NO_BLOCKED: 0,
    /**
     * List of events that could be fired from this class.
     * @enum
     */
    Event: {
      /** Notifies that this Transform is done processing. */
      DONE: 'done'
    }
  }
)

module.exports = Transform
