import {
  DESKTOP,
  EXTRA_LARGE_DESKTOP,
  LARGE_DESKTOP,
  PHONE_LANDSCAPE_BREAKPOINT,
  TABLET,
} from "../util/media-queries";

export const breakpoints = [
  `${PHONE_LANDSCAPE_BREAKPOINT}px`,
  `${TABLET}px`,
  `${DESKTOP}px`,
  `${LARGE_DESKTOP}px`,
  `${EXTRA_LARGE_DESKTOP}px`,
];
