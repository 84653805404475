import {
  AdBreakPayload,
  AdPayload,
  ErrorPayload,
  TimePayload,
} from "@tv4/avod-web-player-common";

import { ITracker, ITrackerSetupOptions } from "./tracker";

export class DebugTracker implements ITracker {
  constructor() {
    console.log("DebugTracker initialized");
  }

  public setup(options: ITrackerSetupOptions): void {
    console.log("DebugTracker setup", options);
  }

  public onLoading(): void {
    console.log("DebugTracker onLoading");
  }

  public onLoaded(data: TimePayload): void {
    console.log("DebugTracker onLoaded", data);
  }

  public onLoadError(data: ErrorPayload): void {
    console.log("DebugTracker onLoadError", data);
  }

  public onStart(): void {
    console.log("DebugTracker onStart");
  }

  public onPlay(data: TimePayload): void {
    console.log("DebugTracker onPlay", data);
  }

  public onPause(data: TimePayload): void {
    console.log("DebugTracker onPause", data);
  }

  public onResume(data: TimePayload): void {
    console.log("DebugTracker onResume", data);
  }

  public onEnded(): void {
    console.log("DebugTracker onEnded");
  }

  public onError(): void {
    console.log("DebugTracker onError");
  }

  public onSeek(data: TimePayload): void {
    console.log("DebugTracker onSeek", data);
  }

  public onSeeked(data: TimePayload): void {
    console.log("DebugTracker onSeeked", data);
  }

  public onBuffering(data: TimePayload): void {
    console.log("DebugTracker onBuffering", data);
  }

  public onBuffered(data: TimePayload): void {
    console.log("DebugTracker onBuffered", data);
  }

  public onTimeUpdate(data: TimePayload): void {
    console.log("DebugTracker onTimeUpdate", data);
  }

  public onAdBreakStart(data: AdBreakPayload): void {
    console.log("DebugTracker onAdBreakStart", data);
  }

  public onAdBreakEnd(): void {
    console.log("DebugTracker onAdBreakEnd");
  }

  public onAdLoading(data: AdPayload): void {
    console.log("DebugTracker onAdLoading", data);
  }

  public onAdLoaded(data: AdPayload): void {
    console.log("DebugTracker onAdLoaded", data);
  }

  public onAdPlaying(data: AdPayload): void {
    console.log("DebugTracker onAdPlaying", data);
  }

  public onAdPaused(data: TimePayload): void {
    console.log("DebugTracker onAdPaused", data);
  }

  public onAdResume(data: TimePayload): void {
    console.log("DebugTracker onAdResume", data);
  }

  public onAdTimeUpdate(data: TimePayload): void {
    console.log("DebugTracker onAdTimeUpdate", data);
  }

  public onAdBuffering(data: TimePayload): void {
    console.log("DebugTracker onAdBuffering", data);
  }

  public onAdBuffered(data: TimePayload): void {
    console.log("DebugTracker onAdBuffered", data);
  }

  public onAdError(): void {
    console.log("DebugTracker onAdError");
  }

  public onAdEnded(): void {
    console.log("DebugTracker onAdEnded");
  }

  public destroy(): void {
    console.log("DebugTracker destroy");
  }
}
