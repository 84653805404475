var NpawObject = require('./object')

var Chrono = NpawObject.extend(
  /** @lends npaw.Chrono.prototype */
  {
    /**
     * This class calculates time lapses between two points in time.
     *
     * @constructs Chrono
     * @extends npaw.NpawObject
     * @memberof npaw
     */
    constructor: function () {
      this.reset()
    },

    /** Reset chrono values. */
    reset: function () {
      /** Start time */
      this.startTime = 0

      /** Stop time */
      this.stopTime = 0

      /** Pause time */
      this.pauseTime = 0

      /** Offset to be added to deltaTime and stop. in ms. */
      this.offset = 0
    },

    /**
     * Returns the time between start() and the last stop() in ms. Returns -1 if start wasn't
     * called.
     * @param {boolean} [stop=true] If true, it will force a stop() if it wasn't called before.
     * @return {number} Time lapse in ms.
     */
    getDeltaTime: function (stop) {
      var retValue = -1
      var now = new Date().getTime()
      if (this.startTime) {
        if (stop && !this.stopTime) {
          this.stopTime = now
        }
        var tempOffset = this.pauseTime ? now - this.pauseTime : 0
        var tempStop = this.stopTime ? this.stopTime : now
        retValue = (this.offset - tempOffset) + (tempStop - this.startTime)
      }
      return retValue
    },

    /**
     * Starts the chrono.
     */
    start: function () {
      this.startTime = new Date().getTime()
      this.stopTime = 0
      this.offset = 0
    },

    /**
     * Stops the timer and returns current delta time.
     * @return {number} Returns the delta time
     */
    stop: function () {
      if (this.pauseTime) this.resume()
      this.stopTime = new Date().getTime()
      return this.startTime ? this.offset + (this.stopTime - this.startTime) : -1
    },

    pause: function () {
      this.pauseTime = new Date().getTime()
    },

    resume: function () {
      this.offset -= (new Date().getTime() - this.pauseTime)
      this.pauseTime = 0
    },

    /**
     * Creates a copy of the chrono.
     */
    clone: function () {
      var chrono = new Chrono()
      chrono.startTime = this.startTime
      chrono.stopTime = this.stopTime
      chrono.offset = this.offset
      return chrono
    }
  }
)

module.exports = Chrono
