"use strict";

exports.__esModule = true;
exports.getClickTrackingReport = getClickTrackingReport;
exports.isValidSize = isValidSize;
exports.prefetch = prefetch;
var _StringUtils = require("./StringUtils");
var _TrackingReport = require("../Core/TrackingReport");
function _createForOfIteratorHelperLoose(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; } /*
 * COPYRIGHT 2022 YOSPACE TECHNOLOGIES LTD. ALL RIGHTS RESERVED.
 * The contents of this file are proprietary and confidential.
 * Unauthorised copying of this file, via any medium is strictly prohibited.
 */
function getClickTrackingReport(urls, id) {
  return urls.length > 0 ? new _TrackingReport.TrackingReport(id, urls) : null;
}
function isValidSize(props) {
  if (!props) {
    return false;
  }
  var width = null;
  var height = null;
  for (var _iterator = _createForOfIteratorHelperLoose(props), _step; !(_step = _iterator()).done;) {
    var prop = _step.value;
    var name = prop.getName();
    var value = prop.getValue();
    if (name === "width" && !(0, _StringUtils.isNullOrEmpty)(value)) {
      width = prop;
    } else if (name === "height" && !(0, _StringUtils.isNullOrEmpty)(value)) {
      height = prop;
    }
  }
  return width && height ? true : false;
}
function prefetch(resources, timeout) {
  for (var _iterator2 = _createForOfIteratorHelperLoose(resources), _step2; !(_step2 = _iterator2()).done;) {
    var _step2$value = _step2.value,
      value = _step2$value[1];
    value.prefetch(timeout);
  }
}