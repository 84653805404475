import { ProductAnalyticsTag, SessionListeners } from '../common/Constants';
import Log from '../common/log';
import Constants from '../analytics/constants';
import ProductAnalyticsSettings from './ProductAnalyticsSettings';
import ProductAnalyticsConfig from './ProductAnalyticsConfig';
import ProductAnalyticsEventType from './ProductAnalyticsEventTypes';
/**
 * NpawProductAnalytics class is the base of npaw productAnalytics.
 * Every plugin will have an instance.
 */
export default class ProductAnalytics {
    /**
     * Constructs the NpawProductAnalytics class
     */
    constructor(session, getReferral, setAnalyticsOptions, addVideoRequestListener, removeVideoRequestListener) {
        this.listeners = [
            { event: SessionListeners.START_BEFORE, handler: this.beforeStartListener.bind(this) },
            { event: SessionListeners.START_AFTER, handler: this.afterStartListener.bind(this) },
            { event: SessionListeners.STOP_BEFORE, handler: this.beforeStopListener.bind(this) },
            { event: SessionListeners.STOP_AFTER, handler: this.afterStopListener.bind(this) },
            { event: SessionListeners.EVENT_BEFORE, handler: this.beforeSessionEventListener.bind(this) },
            { event: SessionListeners.EVENT_AFTER, handler: this.afterSessionEventListener.bind(this) }
        ];
        this.session = session;
        this.setListeners();
        this.initialized = false;
        this.settings = new ProductAnalyticsSettings();
        this.page = null;
        this.config = null;
        this.searchQuery = '';
        this.url = null;
        if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
            try {
                this.url = new URL(window.location.href);
            }
            catch (ex) {
                this.url = null;
            }
        }
        this.contentHighlighted = null;
        this.contentHighlightTimeout = null;
        this.pendingVideoEvents = {};
        this.adapters = {};
        this.getReferral = getReferral;
        this.setAnalyticsOptions = setAnalyticsOptions;
        this.addVideoRequestListener = addVideoRequestListener;
        this.removeVideoRequestListener = removeVideoRequestListener;
        /*
        We have to create a bound accessor to allow this.removeVideoRequestListener recognize and
        remove the listener successfully. Calling removeVideoRequestListener supplying
        this.videoListener.bind(this) as an argument does not work.
        */
        this.videoListenerAccessor = this.videoListener.bind(this);
    }
    /**
     * Initializes product analytics
     * @param {Object} productAnalyticsSettings product analytics settings
     * @param {Object} [npawtmConfigValue] configuration settings
     */
    initialize(productAnalyticsSettings) {
        let query;
        // Load settings
        if (typeof productAnalyticsSettings === 'undefined') {
            this.settings = new ProductAnalyticsSettings();
            Log.warn(ProductAnalyticsTag, 'Settings not supplied: using default ones.');
        }
        else if (productAnalyticsSettings instanceof ProductAnalyticsSettings) {
            this.settings = productAnalyticsSettings;
        }
        else {
            const settings = ProductAnalyticsSettings.buildFromObject(productAnalyticsSettings);
            if (settings === null) {
                this.settings = new ProductAnalyticsSettings();
                Log.warn(ProductAnalyticsTag, 'Invalid settings supplied: using default ones.');
            }
            else {
                this.settings = settings;
            }
        }
        // Validate settings
        this.settings.validate();
        // Identify current page
        this.config = new ProductAnalyticsConfig(this.fireEventInternal.bind(this));
        this.identify();
        // Set as initialized
        this.initialized = true;
        /*
          SPA: initialize will be called once at the beginning of the execution. Here we will start a brand new session.
          Non-SPA: initialize will be called on every page load. We must not close the previous session since, otherwise, we will have one-page sessions.
          This is why we are calling here `this.session.start` instead of `this.newSession`.
        */
        this.session.start(undefined, undefined, () => {
            // Track navigation
            if (this.settings.autoTrackNavigation) {
                if (typeof window === 'undefined') {
                    Log.warn(ProductAnalyticsTag, 'Cannot auto track cached navigation since DOM is not available.');
                }
                else {
                    window.removeEventListener('pageshow', this.trackNavigationCached);
                    window.addEventListener('pageshow', this.trackNavigationCached.bind(this));
                }
                this.trackNavigationInternal();
            }
            // Track attribution
            if (this.settings.autoTrackAttribution) {
                query = this.url === null ? '' : this.url.search;
                this.trackAttributionInternal(new URLSearchParams(query));
            }
        });
    }
    /**
     * Identify current page
     * @private
     */
    identify() {
        let pageRule;
        let regExp;
        let i;
        // Try to identify using config approach
        if (this.config === null) {
            this.page = null;
        }
        else {
            this.page = this.config.identify(this.url);
        }
        // Try to identify using pageRules setting
        for (i = 0; i < this.settings.pageRules.length && this.page === null && this.url !== null; i++) {
            pageRule = this.settings.pageRules[i];
            if (typeof pageRule.rule !== 'undefined' && typeof pageRule.page !== 'undefined') {
                try {
                    regExp = new RegExp(pageRule.rule);
                }
                catch (ex) {
                    regExp = null;
                    Log.warn(ProductAnalyticsTag, 'Error processing rule', pageRule.rule, pageRule.page);
                }
                if (regExp !== null && this.url.pathname.match(regExp) !== null) {
                    this.page = this.url.pathname.replace(regExp, pageRule.page);
                }
            }
        }
        // If identication has failed, set pathname as page name
        if (this.page === null && this.url !== null) {
            this.page = this.url.pathname;
        }
    }
    /**
     * Destroy
     */
    destroy() {
        Log.warn(ProductAnalyticsTag, 'ProductAnalytics instance destroyed through destroy');
        // End session
        this.endSession();
        // Remove adapters
        for (const videoKey of Object.keys(this.adapters)) {
            this.removeAdapter(videoKey);
        }
    }
    // -----------------------------------------------------------------------------------------------
    // ADAPTER
    // -----------------------------------------------------------------------------------------------
    /**
     * Track adapter start
     * @private
     */
    adapterTrackStart(videoKey) {
        if (this.initialized) {
            this.trackPlayerInteraction('start', {}, {}, videoKey);
        }
    }
    /**
     * Execute after adapter is set to plugin
     * @param {object} adapter Video adapter
     * @param {string} [videoKey] Custom video identifier
     */
    registerAdapter(adapter, videoKey) {
        if (!this.initialized) {
            // Nothing to do
        }
        else if (!adapter) {
            Log.warn(ProductAnalyticsTag, 'Cannot bind adapter start since adapter is unavailable.');
        }
        else {
            // Remove previous adapter
            this.removeAdapter(videoKey);
            // Set adapter
            this.adapters[this.getVideoKey(videoKey)] = adapter;
            /*
              Add request listener to monitor START events. We have to add just one listener.
              Otherwise, START events would be fired multiple times.
             */
            if (Object.keys(this.adapters).length === 1) {
                this.addVideoRequestListener(this.videoListenerAccessor);
            }
        }
    }
    /**
     * Execute before removing adapter from plugin
     * @param {string} [videoKey] Custom video identifier
     */
    removeAdapter(videoKey) {
        videoKey = this.getVideoKey(videoKey);
        // Remove adapter from the list
        delete this.adapters[videoKey];
        // Remove pending video events for the adapter being removed
        delete this.pendingVideoEvents[videoKey];
        // Remove video listener when there are no adapters registered left
        if (Object.keys(this.adapters).length === 0) {
            this.removeVideoRequestListener(this.videoListenerAccessor);
        }
    }
    /**
     * Get adapter bound to video key
     * @param videoKey
     * @returns
     */
    getAdapter(videoKey) {
        return this.adapters[this.getVideoKey(videoKey)] || null;
    }
    /**
     * Get video key
     * @param videoKey
     * @returns
     */
    getVideoKey(videoKey) {
        return videoKey || ProductAnalytics.VIDEOKEY_DEFAULT_IDENTIFIER;
    }
    /**
     * Video listener
     * @param serviceName
     * @param videoKey
     * @param params
     */
    videoListener(serviceName, videoKey, params) {
        switch (serviceName) {
            case Constants.Service.START:
                this.adapterTrackStart(videoKey);
                break;
        }
    }
    // -----------------------------------------------------------------------------------------------
    // SESSION
    // -----------------------------------------------------------------------------------------------
    /**
     * New user session
     */
    newSession(onSuccess, onFail) {
        if (!this.initialized) {
            Log.warn(ProductAnalyticsTag, 'Cannot start a new session since Product Analytics is uninitialized.');
            onFail === null || onFail === void 0 ? void 0 : onFail();
        }
        else {
            this.endSession(() => {
                this.session.start(undefined, undefined, onSuccess, onFail);
            }, () => {
                Log.warn(ProductAnalyticsTag, 'Cannot start a new session since there has been a problem stopping the previous one');
                onFail === null || onFail === void 0 ? void 0 : onFail();
            });
        }
    }
    /**
     * Ends user session
     */
    endSession(onSuccess, onFail) {
        if (!this.initialized) {
            Log.warn(ProductAnalyticsTag, 'Cannot end session since Product Analytics is uninitialized.');
            onFail === null || onFail === void 0 ? void 0 : onFail();
        }
        else {
            this.session.stop(undefined, onSuccess, onFail);
        }
    }
    // ---------------------------------------------------------------------------------------------
    // LOGIN / LOGOUT
    // ---------------------------------------------------------------------------------------------
    /**
     * Successful login
     * @param {string} userId The unique identifier of the user
     * @param {string} [profileId] The unique identifier of the profile
     * @param {string} [profileType] The profile type
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    loginSuccessful(userId, profileId, profileType, dimensions, metrics, onSuccess, onFail) {
        if (!this.checkState('log in successfully')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof userId !== 'string' && typeof userId !== 'number') {
            Log.warn(ProductAnalyticsTag, 'Cannot log in successfully since userId is unavailable.');
        }
        else if (userId === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot log in successfully since userId is unset.');
        }
        else {
            // Send user login event
            this.fireEventInternal('User Login Successful', ProductAnalyticsEventType.user, {
                username: userId
            }, dimensions, metrics);
            // Send profile selection event (in case it has been supplied)
            if (typeof profileId !== 'undefined' && profileId !== null && profileId !== '') {
                this._userProfileSelectedEvent(profileId, profileType, dimensions, metrics);
            }
            // Set the userId (and profileId) options
            const options = { userId: userId };
            if (typeof profileId !== 'undefined' && profileId !== null && profileId !== '') {
                options['profileId'] = profileId;
            }
            this.setAnalyticsOptions(options);
            // Close the current session and start a new one
            this.newSession(onSuccess, onFail);
        }
    }
    /**
     * Login unsuccessful
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    loginUnsuccessful(dimensions, metrics) {
        if (this.checkState('log in unsuccessful')) {
            // Send an event informing that we are closing the session because of a profile change
            this.fireEventInternal('User Login Unsuccessful', ProductAnalyticsEventType.user, {}, dimensions, metrics);
        }
    }
    /**
     * Logout
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    logout(dimensions, metrics, onSuccess, onFail) {
        if (this.checkState('log out')) {
            // Send an event informing that we are closing the session
            this.fireEventInternal('User Logout', ProductAnalyticsEventType.user, {}, dimensions, metrics);
            // Set the userId (and profileId) options
            const options = { userId: null };
            options['profileId'] = null;
            this.setAnalyticsOptions(options);
            // Close the current session and start a new one
            this.newSession(onSuccess, onFail);
        }
    }
    // ---------------------------------------------------------------------------------------------
    // PROFILE
    // ---------------------------------------------------------------------------------------------
    /**
     * User profile created
     * @param {string} profileId The unique identifier of the profile
     * @param {string} [profileType] The profile type
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    userProfileCreated(profileId, profileType, dimensions, metrics) {
        if (!this.checkState('create user profile')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof profileId !== 'string' && typeof profileId !== 'number') {
            Log.warn(ProductAnalyticsTag, 'Cannot create user profile since profileId is unavailable.');
        }
        else if (profileId === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot create user profile since profileId is unset.');
        }
        else {
            const dimensionsInternal = this._getUserProfileDimensions(profileId, profileType);
            this.fireEventInternal('User Profile Created', ProductAnalyticsEventType.userProfile, dimensionsInternal, dimensions, metrics);
        }
    }
    /**
     * User profile selected
     * @param {string} profileId The unique identifier of the profile
     * @param {string} [profileType] The profile type
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    userProfileSelected(profileId, profileType, dimensions, metrics, onSuccess, onFail) {
        if (!this.checkState('select user profile')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof profileId !== 'string' && typeof profileId !== 'number') {
            Log.warn(ProductAnalyticsTag, 'Cannot select user profile since profileId is unavailable.');
        }
        else if (profileId === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot select user profile since profileId is unset.');
        }
        else {
            // Send an event informing that we are closing the session because of a profile change
            this._userProfileSelectedEvent(profileId, profileType, dimensions, metrics);
            // Set the profileId option
            const options = { profileId: profileId };
            this.setAnalyticsOptions(options);
            // Close the current session and open a new one
            this.newSession(onSuccess, onFail);
        }
    }
    /**
     * Fire user profile selected event
     * @param {string} profileId The unique identifier of the profile
     * @param {string} [profileType] The profile type
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     * @private
     */
    _userProfileSelectedEvent(profileId, profileType, dimensions, metrics) {
        const dimensionsInternal = this._getUserProfileDimensions(profileId, profileType);
        this.fireEventInternal('User Profile Selected', ProductAnalyticsEventType.userProfile, dimensionsInternal, dimensions, metrics);
    }
    /**
     * User profile deleted
     * @param {string} profileId The unique identifier of the profile
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    userProfileDeleted(profileId, dimensions, metrics) {
        if (!this.checkState('delete user profile')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof profileId !== 'string' && typeof profileId !== 'number') {
            Log.warn(ProductAnalyticsTag, 'Cannot delete user profile since profileId is unavailable.');
        }
        else if (profileId === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot delete user profile since profileId is unset.');
        }
        else {
            const dimensionsInternal = this._getUserProfileDimensions(profileId, null);
            this.fireEventInternal('User Profile Deleted', ProductAnalyticsEventType.userProfile, dimensionsInternal, dimensions, metrics);
        }
    }
    /**
     * Get user profile dimensions
     * @param {string} profileId
     * @param {string} profileType
     * @returns
     * @private
     */
    _getUserProfileDimensions(profileId, profileType) {
        const dimensions = {
            profileId: profileId
        };
        if (typeof profileType !== 'undefined' && profileType !== null) {
            dimensions['profileType'] = profileType;
        }
        return dimensions;
    }
    // -----------------------------------------------------------------------------------------------
    // NAVIGATION
    // -----------------------------------------------------------------------------------------------
    /**
     * Tracks navigation by route
     * @param {string} pageName The unique name to identify a page of the application.
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    trackNavByRoute(route, dimensions, metrics) {
        if (!this.checkState('track navigation')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof route !== 'string' || route === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track navigation since route has not been supplied.');
        }
        else {
            let url;
            try {
                url = new URL(route);
            }
            catch (ex) {
                url = null;
            }
            if (url === null) {
                Log.warn(ProductAnalyticsTag, 'Cannot track navigation since route is invalid.');
            }
            else {
                this.url = url;
                this.identify();
                this.trackNavigationInternal(dimensions, metrics);
            }
        }
    }
    /**
     * Tracks navigation
     * @param {string} pageName The unique name to identify a page of the application.
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    trackNavByName(pageName, dimensions, metrics) {
        if (!this.checkState('track navigation')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof pageName !== 'string' || pageName === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track navigation since page is invalid.');
        }
        else {
            this.page = pageName;
            this.trackNavigationInternal(dimensions, metrics);
        }
    }
    /**
     * Automatically tracks navigation when page is cached by browser
     * @param {Object} event
     * @private
     */
    trackNavigationCached(event) {
        if (event.persisted) {
            this.trackNavigationInternal();
        }
    }
    /**
     * Tracks navigation (either manually or automatically)
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     * @private
     */
    trackNavigationInternal(dimensions, metrics) {
        let route;
        let path;
        let host;
        // Get UTM Options
        if (this.url === null) {
            route = '';
            path = '';
            host = '';
        }
        else {
            route = this.url.href;
            path = this.url.pathname;
            host = this.url.hostname;
        }
        if (this.checkState('track navigation')) {
            this.fireEventInternal('paNavigation', ProductAnalyticsEventType.navigation, {
                paRoute: path,
                paRouteDomain: host,
                paFullRoute: route,
                paReferrer: this.getReferral() || ''
            }, dimensions, metrics);
        }
    }
    // -----------------------------------------------------------------------------------------------
    // ATTRIBUTION
    // -----------------------------------------------------------------------------------------------
    /**
     * Tracks attribution
     * @param {string} utmSource The UTM Source parameter. It is commonly used to identify a search engine, newsletter, or other source (i.e., Google, Facebook, etc.).
     * @param {string} utmMedium The UTM Medium parameter. It is commonly used to identify a medium such as email or cost-per-click (cpc).
     * @param {string} utmCampaign The UTM Campaign parameter. It is commonly used for campaign analysis to identify a specific product promotion or strategic campaign (i.e., spring sale).
     * @param {string} utmTerm The UTM Term parameter. It is commonly used with paid search to supply the keywords for ads (i.e., Customer, NonBuyer, etc.).
     * @param {string} utmContent The UTM Content parameter. It is commonly used for A/B testing and content-targeted ads to differentiate ads or links that point to the same URL (i.e., Banner1, Banner2, etc.)
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    trackAttribution(utmSource, utmMedium, utmCampaign, utmTerm, utmContent, dimensions, metrics) {
        if (!this.checkState('track attribution')) {
            // Product Analytics is not ready for sending events
        }
        else if (this.settings.autoTrackAttribution) {
            Log.warn(ProductAnalyticsTag, "Automatic attribution tracking is enabled: this request won't be processed.");
        }
        else {
            const params = {};
            if (typeof utmSource === 'string')
                params.utm_source = utmSource;
            if (typeof utmMedium === 'string')
                params.utm_medium = utmMedium;
            if (typeof utmCampaign === 'string')
                params.utm_campaign = utmCampaign;
            if (typeof utmTerm === 'string')
                params.utm_term = utmTerm;
            if (typeof utmContent === 'string')
                params.utm_content = utmContent;
            if (Object.keys(params).length > 0) {
                this.trackAttributionInternal(new URLSearchParams(params), dimensions, metrics);
            }
            else {
                Log.warn(ProductAnalyticsTag, 'Cannot track attribution since parameters are invalid.');
            }
        }
    }
    /**
     * Tracks attribution (either manually or automatically)
     * @param {URLSearchParams} params Object where to look for UTM params.
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     * @private
     */
    trackAttributionInternal(params, dimensions, metrics) {
        // Get UTM parameters
        const utmParams = this._getUTMParams(params);
        const url = this.url === null ? '' : this.url.href;
        // Track attribution
        if (!this.checkState('track attribution')) {
            // Product Analytics is not ready for sending events
        }
        else if (utmParams) {
            this.fireEventInternal('Attribution', ProductAnalyticsEventType.attribution, Object.assign({
                utmUrl: url
            }, utmParams), dimensions, metrics);
        }
    }
    /**
     * Retrieves UTM params from querystring (utm_source, utm_medium, utm_campaign, utm_term, utm_content) and returns an object containing them but
     * with key formatted as specified by utmFormatDot.
     * @param {URLSearchParams} params Object where to look for UTM params
     * @returns {{}}
     * @private
     */
    _getUTMParams(params) {
        let keySplitted;
        let keyIndex;
        let key;
        const keys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
        const utmParams = {};
        // Look for UTM params and add them to the newly constructed object converting key as specified by utmFormatDot
        params.forEach(function (paramValue, paramKey) {
            keyIndex = keys.indexOf(paramKey.toLowerCase());
            if (keyIndex !== -1) {
                key = keys[keyIndex];
                keySplitted = key.split('_');
                if (keySplitted.length > 1) {
                    key = keySplitted[0] + keySplitted[1].charAt(0).toUpperCase() + keySplitted[1].slice(1);
                }
                utmParams[key] = paramValue;
            }
        });
        return Object.keys(utmParams).length > 0 ? utmParams : null;
    }
    // -----------------------------------------------------------------------------------------------
    // SECTION
    // -----------------------------------------------------------------------------------------------
    /**
     * Section goes into viewport.
     * @param {string} section The section title. It is commonly used to indicate the section title presented over a grid layout (e.g. Recommended Movies, Continue Watching, etc).
     * @param {Number} sectionOrder The section order within the page.
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    trackSectionVisible(section, sectionOrder, dimensions, metrics) {
        if (!this.checkState('track section visible')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof section !== 'string' || section === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track section visible since section is invalid.');
        }
        else if (typeof sectionOrder !== 'number' || sectionOrder < 1) {
            Log.warn(ProductAnalyticsTag, 'Cannot track section visible since sectionOrder is invalid.');
        }
        else {
            this.fireEventInternal('Section Visible', ProductAnalyticsEventType.section, {
                sectionOrder: sectionOrder,
                section: section
            }, dimensions, metrics);
        }
    }
    /**
     * Section goes out of viewport.
     * @param {string} section The section title. It is commonly used to indicate the section title presented over a grid layout (e.g. Recommended Movies, Continue Watching, etc).
     * @param {Number} sectionOrder The section order within the page.
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    trackSectionHidden(section, sectionOrder, dimensions, metrics) {
        if (!this.checkState('track section hidden')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof section !== 'string' || section === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track section hidden since section is invalid.');
        }
        else if (typeof sectionOrder !== 'number' || sectionOrder < 1) {
            Log.warn(ProductAnalyticsTag, 'Cannot track section hidden since sectionOrder is invalid.');
        }
        else {
            this.fireEventInternal('Section Hidden', ProductAnalyticsEventType.section, {
                sectionOrder: sectionOrder,
                section: section
            }, dimensions, metrics);
        }
    }
    // -----------------------------------------------------------------------------------------------
    // CONTENT
    // -----------------------------------------------------------------------------------------------
    /**
     * Sends a content highlight event if content is focused during, at least, highlightContentAfter ms.
     * @param {string} section The section title. It is commonly used to indicate the section title presented over a grid layout (e.g. Recommended Movies, Continue Watching, etc).
     * @param {Number} sectionOrder The section order within the page.
     * @param {integer} column Used to indicate the column number where content is placed in a grid layout The first column is number 1.
     * @param {integer} row Used to indicate the row number where content is placed in a grid layout. The first row is number 1. In the case of a horizontal list instead of a grid, the row parameter should be set to 1.
     * @param {string} contentID The unique content identifier of the content linked.
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    contentFocusIn(section, sectionOrder, column, row, contentID, dimensions, metrics) {
        this.contentFocusOut();
        if (!this.checkState('track content highlight')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof section !== 'string' || section === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track content highlight since section is invalid.');
        }
        else if (typeof sectionOrder !== 'number' || sectionOrder < 1) {
            Log.warn(ProductAnalyticsTag, 'Cannot track content highlight since sectionOrder is invalid.');
        }
        else if (typeof column !== 'number' || column < 1) {
            Log.warn(ProductAnalyticsTag, 'Cannot track content highlight since column is invalid.');
        }
        else if (typeof row !== 'number' || row < 1) {
            Log.warn(ProductAnalyticsTag, 'Cannot track content highlight since row is invalid.');
        }
        else if (typeof contentID !== 'string' || contentID === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track content highlight since contentID is invalid.');
        }
        else {
            this.contentHighlighted = {
                sectionOrder: sectionOrder,
                section: section,
                column: column,
                row: row,
                contentID: contentID,
                dimensions: dimensions,
                metrics: metrics
            };
            this.contentHighlightTimeout = setTimeout(this._trackContentHiglight.bind(this), this.settings.highlightContentAfter);
        }
    }
    /**
     * Content loses focus
     * @private
     */
    contentFocusOut() {
        if (this.contentHighlightTimeout !== null) {
            clearTimeout(this.contentHighlightTimeout);
        }
        this.contentHighlighted = null;
        this.contentHighlightTimeout = null;
    }
    /**
     * Sends a content highlight event using selected content info
     * @private
     */
    _trackContentHiglight() {
        if (this.contentHighlighted) {
            this.fireEventInternal('Section Content Highlight', ProductAnalyticsEventType.section, {
                sectionOrder: this.contentHighlighted.sectionOrder,
                section: this.contentHighlighted.section,
                column: this.contentHighlighted.column,
                row: this.contentHighlighted.row,
                contentId: this.contentHighlighted.contentID
            }, this.contentHighlighted.dimensions, this.contentHighlighted.metrics);
        }
        else {
            Log.warn(ProductAnalyticsTag, 'Cannot highlight content since no content is selected');
        }
    }
    /**
     * Tracks the location of user clicks.
     * @param {string} section The section title. It is commonly used to indicate the section title presented over a grid layout (e.g. Recommended Movies, Continue Watching, etc).
     * @param {Number} sectionOrder The section order within the page.
     * @param {integer} column Used to indicate the column number where content is placed in a grid layout The first column is number 1.
     * @param {integer} row Used to indicate the row number where content is placed in a grid layout. The first row is number 1. In the case of a horizontal list instead of a grid, the row parameter should be set to 1.
     * @param {string} contentID The unique content identifier of the content linked.
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    trackContentClick(section, sectionOrder, column, row, contentID, dimensions, metrics) {
        if (!this.checkState('track content click')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof section !== 'string' || section === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track content click since section is invalid.');
        }
        else if (typeof sectionOrder !== 'number' || sectionOrder < 1) {
            Log.warn(ProductAnalyticsTag, 'Cannot track content click since sectionOrder is invalid.');
        }
        else if (typeof column !== 'number' || column < 1) {
            Log.warn(ProductAnalyticsTag, 'Cannot track content click since column is invalid.');
        }
        else if (typeof row !== 'number' || row < 1) {
            Log.warn(ProductAnalyticsTag, 'Cannot track content click since row is invalid.');
        }
        else if (typeof contentID !== 'string' || contentID === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track content click since contentID is invalid.');
        }
        else {
            this.fireEventInternal('Section Content Click', ProductAnalyticsEventType.section, {
                sectionOrder: sectionOrder,
                section: section,
                column: column,
                row: row,
                contentId: contentID
            }, dimensions, metrics);
        }
    }
    // -----------------------------------------------------------------------------------------------
    // CONTENT PLAYBACK
    // -----------------------------------------------------------------------------------------------
    /**
     * Tracks when a content starts playing be it automatically or through a user interaction.
     * @param {string} contentID The unique content identifier of the content being played.
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     * @param {string} [videoKey] Custom video identifier
     * @note Apparently, VideoAnalyticsRequestHandler queues requests until all mandatory events
     * (INIT / START) are sent. Then, it sends all the queued events. This is why there is no need
     * of queueing events here.
     */
    trackPlay(contentID, dimensions, metrics, videoKey) {
        const eventName = 'Content Play';
        if (!this.checkState('track play')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof contentID !== 'string' || contentID === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track play since contentID is invalid.');
        }
        else {
            this.handlePlayerEvent(eventName, contentID, dimensions || {}, metrics || {}, this.getVideoKey(videoKey));
        }
    }
    /**
     * Tracks content watching events.
     * TODO: add (2nd) argument to tell whether user state must be updated or not
     * @param {string} eventName The name of the interaction (i.e., Pause, Seek, Skip Intro, Skip Ads, Switch Language, etc.).
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     * @param {string} [videoKey] Custom video identifier
     * @param {boolean} [startEvent] Internal param informing that current interaction is responsible of first player start
     * @note Apparently, VideoAnalyticsRequestHandler queues requests until all mandatory events
     * (INIT / START) are sent. Then, it sends all the queued events. This is why there is no need
     * of queueing events here.
     */
    trackPlayerInteraction(eventName, dimensions, metrics, videoKey) {
        if (!this.checkState('track player interaction')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof eventName !== 'string' || eventName === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track player interaction since interaction name is invalid.');
        }
        else {
            const eventNameComplete = 'Content Play ' + eventName;
            const contentID = null;
            this.handlePlayerEvent(eventNameComplete, contentID, dimensions || {}, metrics || {}, this.getVideoKey(videoKey));
        }
    }
    /**
     * Handle player event (queueing event in case video has not started yet or flushing the event
     * queue in case video starts)
     */
    handlePlayerEvent(eventName, contentID, dimensions, metrics, videoKey) {
        var _a;
        const videoAdapter = this.getAdapter(videoKey);
        if (videoAdapter == null) {
            // Adapter is not available: cannot track event
            Log.warn(ProductAnalyticsTag, 'Cannot track player event since video adapter is not available');
        }
        else if (!videoAdapter.flags.isStarted) {
            // Player has not started: queue request
            if (this.pendingVideoEvents[videoKey] == null) {
                this.pendingVideoEvents[videoKey] = [];
            }
            this.pendingVideoEvents[videoKey].push({
                eventName: eventName,
                contentID: contentID,
                dimensions: dimensions,
                metrics: metrics,
                videoKey: videoKey
            });
        }
        else {
            // Track pending events
            (_a = this.pendingVideoEvents[videoKey]) === null || _a === void 0 ? void 0 : _a.forEach((event) => {
                this.trackPlayerEvent(event.eventName, event.contentId, event.dimensions, event.metrics, event.videoKey);
            });
            delete this.pendingVideoEvents[videoKey];
            // Track current event
            this.trackPlayerEvent(eventName, contentID, dimensions, metrics, videoKey);
        }
    }
    /**
     * Track player event
     *
     * @param eventName The name of the interaction (i.e., Pause, Seek, Skip Intro, Skip Ads, Switch
     *   Language, etc.).
     * @param contentID The unique content identifier of the content being played.
     * @param dimensions Dimensions to track
     * @param metrics Metrics to track
     * @param videoKey Video adapter identifier
     */
    trackPlayerEvent(eventName, contentID, dimensions, metrics, videoKey) {
        // Prepare dimensions
        const dimensionsInternal = {};
        if (contentID !== null) {
            dimensionsInternal['contentId'] = contentID;
        }
        // Fire event
        this.fireEventAdapter(eventName, dimensionsInternal, dimensions, metrics, videoKey);
    }
    // -----------------------------------------------------------------------------------------------
    // CONTENT SEARCH
    // -----------------------------------------------------------------------------------------------
    /**
     * Tracks search query events.
     * @param {string} searchQuery The search term entered by the user.
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    trackSearchQuery(searchQuery, dimensions, metrics) {
        if (!this.checkState('track search query')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof searchQuery !== 'string' || searchQuery === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track search query since no searchQuery has been supplied.');
        }
        else {
            this.searchQuery = searchQuery;
            this.fireEventInternal('Search Query', ProductAnalyticsEventType.search, {
                query: this.searchQuery
            }, dimensions, metrics);
        }
    }
    /**
     * Tracks search result events.
     * @param {integer} resultCount The number of search results returned by a search query.
     * @param {String} [searchQuery] The search term entered by the user.
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    trackSearchResult(resultCount, searchQuery, dimensions, metrics) {
        if (!this.checkState('track search result')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof resultCount !== 'number') {
            Log.warn(ProductAnalyticsTag, 'Cannot track search result since resultCount is invalid.');
        }
        else {
            const query = typeof searchQuery === 'string' && searchQuery !== '' ? searchQuery : this.searchQuery;
            this.fireEventInternal('Search Results', ProductAnalyticsEventType.search, {
                query: query,
                resultCount: resultCount
            }, dimensions, metrics);
        }
    }
    /**
     * Tracks user interactions with search results.
     * @param {string} section The section title. It is commonly used to indicate the section title presented over a grid layout (e.g. Recommended Movies, Continue Watching, etc).
     * @param {Number} sectionOrder The section order within the page.
     * @param {integer} column The content placement column. It is commonly used to indicate the column number where content is placed in a grid layout (i.e.1, 2, etc..).
     * @param {integer} row The content placement row. It is commonly used to indicate the row number where content is placed in a grid layout (i.e.1, 2, etc..).
     * @param {string} contentID The content identifier. It is used for internal content unequivocally identification (i.e., AAA000111222).
     * @param {String} [searchQuery] The search term entered by the user.
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    trackSearchClick(section, sectionOrder, column, row, contentID, searchQuery, dimensions, metrics) {
        if (!this.checkState('track search click')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof column !== 'number' || column < 1) {
            Log.warn(ProductAnalyticsTag, 'Cannot track search click since column is invalid.');
        }
        else if (typeof row !== 'number' || row < 1) {
            Log.warn(ProductAnalyticsTag, 'Cannot track search click since row is invalid.');
        }
        else if (typeof contentID !== 'string' || contentID === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track search click since contentID is invalid.');
        }
        else {
            const query = typeof searchQuery === 'string' && searchQuery !== '' ? searchQuery : this.searchQuery;
            if (typeof section !== 'string' || section === '') {
                section = 'Search';
            }
            if (typeof sectionOrder !== 'number' || sectionOrder < 1) {
                sectionOrder = 1;
            }
            this.fireEventInternal('Search Result Click', ProductAnalyticsEventType.search, {
                query: query,
                section: section,
                sectionOrder: sectionOrder,
                column: column,
                row: row,
                contentId: contentID
            }, dimensions, metrics);
        }
    }
    // -----------------------------------------------------------------------------------------------
    // EXTERNAL APPLICATIONS
    // -----------------------------------------------------------------------------------------------
    /**
     * Tracks external app start events.
     * @param {string} appName The name of the application being used to deliver the content to the end-user (i.e., Netflix).
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    trackExternalAppLaunch(appName, dimensions, metrics) {
        if (!this.checkState('track external application launch')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof appName !== 'string' || appName === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track external application launch since appName is invalid.');
        }
        else {
            this.fireEventInternal('External Application Launch', ProductAnalyticsEventType.externalApplication, {
                paExtAppName: appName
            }, dimensions, metrics);
        }
    }
    /**
     * Tracks external app stop events.
     * @param {string} appName The name of the application being used to deliver the content to the end-user (i.e., Netflix).
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    trackExternalAppExit(appName, dimensions, metrics) {
        if (!this.checkState('track external application exit')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof appName !== 'string' || appName === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track external application exit since appName is invalid.');
        }
        else {
            this.fireEventInternal('External Application Exit', ProductAnalyticsEventType.externalApplication, {
                paExtAppName: appName
            }, dimensions, metrics);
        }
    }
    // -----------------------------------------------------------------------------------------------
    // ENGAGEMENT
    // -----------------------------------------------------------------------------------------------
    /**
     * Tracks engagement events.
     * @param {string} eventName The name of the engagement event (i.e., Share, Save, Rate, etc.).
     * @param {string} contentID The unique content identifier of the content the user is engaging with.
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    trackEngagementEvent(eventName, contentID, dimensions, metrics) {
        if (!this.checkState('track engagement')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof eventName !== 'string' || eventName === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track engagement event since eventName is invalid.');
        }
        else if (typeof contentID !== 'string' || contentID === '') {
            Log.warn(ProductAnalyticsTag, 'Cannot track engagement event since contentID is invalid.');
        }
        else {
            this.fireEventInternal('Engagement ' + eventName, ProductAnalyticsEventType.engagement, {
                contentId: contentID
            }, dimensions, metrics);
        }
    }
    // -----------------------------------------------------------------------------------------------
    // CUSTOM EVENT
    // -----------------------------------------------------------------------------------------------
    /**
     * Track custom event
     * @param {string} eventName Name of the event to track
     * @param {Object} [dimensions] Dimensions to track
     * @param {Object} [metrics] Metrics to track
     */
    trackEvent(eventName, dimensions, metrics) {
        if (!this.checkState('track custom event')) {
            // Product Analytics is not ready for sending events
        }
        else if (typeof eventName !== 'string' || eventName === '') {
            Log.warn(ProductAnalyticsTag, 'Event cannot be tracked since eventName is invalid.');
        }
        else {
            this.fireEventInternal('Custom ' + eventName, ProductAnalyticsEventType.custom, {}, dimensions, metrics);
        }
    }
    // -----------------------------------------------------------------------------------------------
    // INTERNAL
    // -----------------------------------------------------------------------------------------------
    /**
     * Fires an event
     * @param {string} eventName Name of the event to be fired
     * @param {ProductAnalyticsEventType} eventType Type of the event being tracked
     * @param {Object} dimensionsInternal Dimensions supplied by user
     * @param {Object} dimensionsUser Specific event dimensions
     * @param {Object} metrics Metrics to track
     * @private
     */
    fireEventInternal(eventName, eventType, dimensionsInternal = {}, dimensionsUser = {}, metrics = {}) {
        Log.notice(ProductAnalyticsTag, eventName);
        // Extract top level dimensions from custom dimensions
        const dimensions = this.buildDimensions(eventType, dimensionsInternal, dimensionsUser);
        // Track event
        this.session.sendEvent(eventName, dimensions.custom, metrics, dimensions.top);
    }
    /**
     * Fires an adapter event (in case it is available)
     * @param {string} eventName Event name
     * @param {Object} dimensionsInternal Dimensions supplied by user
     * @param {Object} dimensionsUser Specific event dimensions
     * @param {Object} metrics Metrics to track
     * @param {string} [videoKey] Custom video identifier
     * @private
     */
    fireEventAdapter(eventName, dimensionsInternal, dimensionsUser, metrics, videoKey) {
        Log.notice(ProductAnalyticsTag, eventName);
        const adapter = this.getAdapter(videoKey);
        if (adapter) {
            const dimensions = this.buildDimensions(ProductAnalyticsEventType.contentPlayback, dimensionsInternal, dimensionsUser);
            adapter.fireEvent(eventName, dimensions.custom, metrics, dimensions.top);
        }
        else {
            Log.warn(ProductAnalyticsTag, 'Cannot fire ' + eventName + ' event since adapter is unavailable.');
        }
    }
    /**
     * Builds a list of top level and custom dimensions
     * @param {ProductAnalyticsEventType} eventType Type of the event being tracked
     * @param {Object} dimensionsInternal Object containing list of internal dimensions
     * @param {Object} dimensionsUser Object containing list of custom dimensions
     * @private
     */
    buildDimensions(eventType, dimensionsInternal = {}, dimensionsUser = {}) {
        let dimensionsCustom;
        // Build custom event dimensions
        dimensionsCustom = { paPage: this.page };
        dimensionsCustom = Object.assign(dimensionsCustom, dimensionsInternal);
        dimensionsCustom = Object.assign(dimensionsCustom, dimensionsUser);
        dimensionsCustom = Object.assign(dimensionsCustom, { eventType: eventType, eventSource: 'Product Analytics' });
        // List of Top Level Dimension keys
        const dimensionsTopLevelKeys = [
            'contentid',
            'contentId',
            'contentID',
            'utmSource',
            'utmMedium',
            'utmCampaign',
            'utmTerm',
            'utmContent',
            'profileId',
            'profile_id',
            'username'
        ];
        // Create object with top level dimensions
        const dimensionsTopLevel = {};
        for (const key in dimensionsCustom) {
            if (Object.prototype.hasOwnProperty.call(dimensionsCustom, key) && dimensionsTopLevelKeys.indexOf(key) !== -1) {
                dimensionsTopLevel[key] = dimensionsCustom[key];
            }
        }
        // Remove top level dimensions from custom dimensions list
        dimensionsTopLevelKeys.forEach(function (key) {
            delete dimensionsCustom[key];
        });
        return { custom: dimensionsCustom, top: dimensionsTopLevel };
    }
    /**
     * Check state before sending an event
     * @param {String} message Message to show in case validation fails
     * @private
     */
    checkState(message) {
        let valid;
        if (!this.initialized) {
            valid = false;
            Log.warn('Cannot ' + message + ' since Product Analytics is uninitialized.');
        }
        else if (!this.session.isActive()) {
            valid = false;
            Log.warn('Cannot ' + message + ' since session is closed.');
        }
        else {
            valid = true;
        }
        return valid;
    }
    // -----------------------------------------------------------------------------------------------
    // SESSION LISTENERS
    // -----------------------------------------------------------------------------------------------
    // Listeners
    setListeners() {
        for (const { event, handler } of this.listeners) {
            this.session.addListener(event, handler);
        }
    }
    beforeStartListener(options, dimensions) {
        Log.debug(ProductAnalyticsTag, SessionListeners.START_BEFORE, options, dimensions);
    }
    afterStartListener(options, dimensions) {
        Log.debug(ProductAnalyticsTag, SessionListeners.START_AFTER, options, dimensions);
    }
    beforeStopListener(params) {
        Log.debug(ProductAnalyticsTag, SessionListeners.STOP_BEFORE, params);
    }
    afterStopListener(params) {
        Log.debug(ProductAnalyticsTag, SessionListeners.STOP_AFTER, params);
    }
    beforeSessionEventListener(eventName, dimensions, values, topLevelDimensions) {
        Log.debug(ProductAnalyticsTag, SessionListeners.EVENT_BEFORE, eventName, dimensions, values, topLevelDimensions);
    }
    afterSessionEventListener(eventName, dimensions, values, topLevelDimensions) {
        Log.debug(ProductAnalyticsTag, SessionListeners.EVENT_AFTER, eventName, dimensions, values, topLevelDimensions);
    }
}
ProductAnalytics.VIDEOKEY_DEFAULT_IDENTIFIER = 'default';
