// {@see CdnParser}

module.exports = {
  parsers: [{
    element: 'name',
    headerName: null,
    regex: /(.+)/
  },
  {
    element: 'host',
    headerName: null,
    regex: /(.+)/
  }]
}
