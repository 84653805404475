import { createContext, useContext } from "react";

import { ContentMetadata } from "../components/CurrentProgramMetaData";

export const ClientCallbackContext = createContext<{
  onUserSelectedPlaybackRate?: (rate: number) => void;
  getContentMetadata?: (assetId: string) => Promise<ContentMetadata>;
}>({});

export const useClientCallback = () => useContext(ClientCallbackContext);
