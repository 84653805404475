var ProductAnalyticsEventType;
(function (ProductAnalyticsEventType) {
    ProductAnalyticsEventType["userProfile"] = "User Profile";
    ProductAnalyticsEventType["user"] = "User";
    ProductAnalyticsEventType["navigation"] = "Navigation";
    ProductAnalyticsEventType["attribution"] = "Attribution";
    ProductAnalyticsEventType["section"] = "Section";
    ProductAnalyticsEventType["contentPlayback"] = "Content Playback";
    ProductAnalyticsEventType["search"] = "Search";
    ProductAnalyticsEventType["externalApplication"] = "External Application";
    ProductAnalyticsEventType["engagement"] = "Engagement";
    ProductAnalyticsEventType["custom"] = "Custom Event";
})(ProductAnalyticsEventType || (ProductAnalyticsEventType = {}));
export default ProductAnalyticsEventType;
