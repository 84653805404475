import {
  DrmIssueResponse,
  getSelectedDrm,
  isbot,
  Orientation,
  PlayerMode,
  PlayResponse,
  request,
  Service,
  supportedFormats,
} from "@tv4/avod-web-player-common";
import {
  getBrowserName,
  isAndroid,
  isChromecast,
  isIOS,
  isIpadOS,
} from "@tv4/avod-web-player-device-capabilities";

export const DEFAULT_CAPABILITIES = ["live-drm-adstitch-2", "yospace3"];

type TDevice = "chromecast" | "browser-ios" | "browser-android" | "browser";

function getDevice(): TDevice {
  if (isChromecast()) return "chromecast";
  if (isIOS() || isIpadOS()) return "browser-ios";
  if (isAndroid()) return "browser-android";
  return "browser";
}

type PlaybackAPIOptions = {
  url: string;
  getAccessToken: () => string | undefined;
  includeCredentials?: boolean;
  playerMode?: PlayerMode;
  service: Service;
};

export class PlaybackAPI {
  private baseUrl: string;
  private includeCredentials: boolean;
  public getAccessToken: () => string | undefined;
  private destroyed = false;

  private protocols = supportedFormats();
  private playerMode: PlayerMode;
  private service: Service;
  private device = getDevice();

  constructor(options: PlaybackAPIOptions) {
    this.includeCredentials = !!options.includeCredentials;
    this.baseUrl = options.url;
    this.getAccessToken = options.getAccessToken;
    this.playerMode = options.playerMode || PlayerMode.DEFAULT;
    this.service = options.service;
  }

  private async request<T>(url: URL): Promise<T> {
    if (await isbot()) {
      return new Promise(() => {
        // promise will never resolve for bot
      });
    } else {
      const [response, error] = await request<T>(
        url,
        {
          method: "GET",
          credentials: this.includeCredentials ? "include" : "same-origin",
        },
        this.getAccessToken,
        () => this.destroyed
      );

      if (error || !response) {
        throw error;
      }
      return response;
    }
  }

  private async getUrl(path: string): Promise<URL> {
    const url = new URL(path, this.baseUrl);

    url.searchParams.append("service", this.service);
    url.searchParams.append("device", this.device || getDevice());
    url.searchParams.append("protocol", this.protocols.join(","));
    url.searchParams.append("drm", (await getSelectedDrm()) ?? "");
    url.searchParams.append("browser", getBrowserName());

    return url;
  }

  public async playRequest(id: string): Promise<PlayResponse> {
    const url = await this.getUrl(`play/${id}`);

    url.searchParams.append("capabilities", DEFAULT_CAPABILITIES.join(","));
    url.searchParams.append(
      "preview",
      this.playerMode === PlayerMode.PREVIEW ? "true" : "false"
    );

    return this.request<PlayResponse>(url);
  }

  public async shortRequest(
    id: string,
    orientation: Orientation
  ): Promise<PlayResponse> {
    const url = await this.getUrl(`play/${id}/short`);

    url.searchParams.append("orientation", orientation);
    url.searchParams.append(
      "preview",
      this.playerMode === PlayerMode.PREVIEW ? "true" : "false"
    );

    return this.request<PlayResponse>(url);
  }

  public async drmRequest(id: string): Promise<DrmIssueResponse> {
    const url = await this.getUrl(`drm/${id}/issue`);
    return this.request<DrmIssueResponse>(url);
  }

  public destroy() {
    this.destroyed = true;
  }
}
