import Constants from '../../core/nqs/Services';
import Core from '../../core/Core';
import VideoAnalyticsRequest from './VideoAnalyticsRequest';
import { Method } from '../../common/Constants';
import CoreConstants from '../../core/utils/CoreConstants';
export default class VideoAnalyticsRequestHandler {
    /**
     * Npaw Communication implements an abstraction layer over API requests.
     * Internally, Communication implements queues of {@link Request} objects.
     * This queue can be blocked using {@link Transform}
     *
     * @param plugin Reference to video analytics plugin
     */
    constructor(plugin) {
        /** Array of {@link Transform}, only when the array is empty the request Queues will begin sending. */
        // this.transforms = []
        this.readyToSend = false;
        this.servicesToWait = [Constants.Service.INIT, Constants.Service.START];
        this.onWillSendVideoRequestListeners = [];
        this.pluginRef = plugin;
        /**
         * Queue of {@link VideoAnalyticsRequest}
         */
        this._requests = [];
        this._waitingForToken = [];
        this._waitingForMetadata = [];
    }
    shouldSendStats() {
        return this.readyToSend;
    }
    /**
     * Enqueues the provided request
     *
     * @param {VideoAnalyticsRequest} request Request to be enqueued
     */
    sendRequest(request) {
        if (Core.getInstance().getFastDataSessionToken() === '') {
            this._waitingForToken.push(request);
            return;
        }
        this._waitingForToken.forEach((request) => this.prepareParams(request));
        this._requests.push(...this._waitingForToken);
        this._waitingForToken = [];
        let videoKey = request.getVideoKey();
        let params = request.getParams();
        let serviceName = request.getService();
        for (const listener of this.onWillSendVideoRequestListeners) {
            listener(serviceName, videoKey, params);
        }
        this.prepareParams(request);
        this._requests.push(request);
        this._processRequests();
    }
    prepareParams(request) {
        const service = request.getService();
        if (service == Constants.Service.START || service == Constants.Service.PING) {
            request.setParam('pingTime', request.getParam('pingTime') || Core.getInstance().getFastDataService().getPingTime());
            if (Core.getInstance().getCommonVariable('appAnalytics', 'isEnabled')) {
                request.setParam('sessionParent', request.getParam('sessionParent') || Core.getInstance().getFastDataSessionToken());
            }
        }
        const videoKey = request.getVideoKey();
        if (this.pluginRef.videos.existsVideo(videoKey)) {
            request.setParam('code', request.getParam('code') || this.pluginRef.videos.getVideo(videoKey).getViewCode());
        }
        if (service === Constants.Service.INIT ||
            service === Constants.Service.START ||
            service === Constants.Service.ERROR) {
            if (Core.getInstance().getCommonVariable('appAnalytics', 'isEnabled')) {
                request.setParam('parentId', request.getParam('sessionRoot'));
                request.setParam('navContext', request.getParam('navContext') || Core.getInstance().getCommonVariable('appAnalytics', 'context'));
            }
        }
    }
    /**
     * Build a generic request to the given host.
     *
     * @param {Service} service A string with the service to be called. ie...
     * @param {Object} [params] Object of key:value params.
     * @param {string} method Type of request (GET, POST, ...)
     * @param {any} body body of the request
     * @param {function} [onSuccessCallback] The defined onSuccess callback to the request
     * @param {function} [onFailCallback] The defined onFail callback to the request
     * @param {ExpirationManager} [expirationManager] Expiration Manager
     */
    buildRequest(service, params, method, body, onSuccessCallback, onFailCallback, expirationManager) {
        method = method !== null && method !== void 0 ? method : Method.GET;
        const request = new VideoAnalyticsRequest(service, params, '', method, body, onSuccessCallback, onFailCallback, expirationManager);
        request.setMethod(method);
        return request;
    }
    /**
     * Execute pending requests in the queue. Returns rejected ones to the queue.
     */
    _processRequests() {
        const workingQueue = this._requests;
        this._requests = [];
        workingQueue.forEach((request) => {
            // Apply logic of blocking requests before init or start or if we are in offline mode
            if (this.servicesToWait.indexOf(request.getService()) !== -1) {
                this.readyToSend = true;
            }
            if (this.readyToSend) {
                while (this._waitingForMetadata.length) {
                    const r = this._waitingForMetadata.shift();
                    Core.getInstance().pushData(Core.getInstance().getOptions().offline ? CoreConstants.Consumer.PERSISTENT : CoreConstants.Consumer.NQS, r.getService(), r.getMethod(), r.getParams(), r.getOnSuccess(), r.getOnFail());
                }
                const expirationManager = request.getExpirationManager();
                if (expirationManager) {
                    expirationManager.onRequestSent();
                }
                Core.getInstance().pushData(Core.getInstance().getOptions().offline ? CoreConstants.Consumer.PERSISTENT : CoreConstants.Consumer.NQS, request.getService(), request.getMethod(), request.getParams(), request.getOnSuccess(), request.getOnFail());
            }
            else {
                this._waitingForMetadata.push(request);
            }
        });
    }
}
