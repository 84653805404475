import { Media, MediaType } from "@tv4/avod-web-player-common";
import {
  isIOS,
  isIpadOS,
  isMacOS,
  isSafari,
} from "@tv4/avod-web-player-device-capabilities";

import { HtmlVideoEngine } from "../HtmlVideoEngine";

export async function getMediaEngine(
  media: Media
): Promise<typeof HtmlVideoEngine> {
  switch (media.type) {
    case MediaType.HLS:
      if ((isSafari() && isMacOS()) || isIOS() || isIpadOS()) {
        const { SafariEngine } = await import(
          /* webpackChunkName: "SafariEngine" */ "../SafariEngine"
        );
        return SafariEngine;
      }
      if (!media.isLive && media.loadPreference === "stability") {
        const { ShakaEngine } = await import(
          /* webpackChunkName: "ShakaEngine" */ "../ShakaEngine"
        );
        return ShakaEngine;
      } else {
        const { HlsJsEngine } = await import(
          /* webpackChunkName: "HlsJsEngine" */ "../HlsJsEngine"
        );
        return HlsJsEngine;
      }
    case MediaType.DASH: {
      const { ShakaEngine } = await import(
        /* webpackChunkName: "ShakaEngine" */ "../ShakaEngine"
      );
      return ShakaEngine;
    }
  }

  return HtmlVideoEngine;
}
