import { useTranslations } from "../../providers/TranslationsProvider";
import {
  ButtonsContainer,
  Container,
  Message,
  ResponseButton,
} from "./InactivePrompt.styles";

type InactivePromptProps = {
  onAnswer: (isActive: boolean) => void;
};

const InactivePrompt = ({ onAnswer }: InactivePromptProps) => {
  const translations = useTranslations();
  return (
    <Container>
      <Message>{translations("inactive__continue_watching_question")}</Message>
      <ButtonsContainer>
        <ResponseButton onClick={() => onAnswer(true)} confirm>
          {translations("inactive__continue_watching_yes")}
        </ResponseButton>
        <ResponseButton onClick={() => onAnswer(false)}>
          {translations("inactive__continue_watching_no")}
        </ResponseButton>
      </ButtonsContainer>
    </Container>
  );
};

export { InactivePrompt };
