import { DEFAULT_PLAYER_CONFIG } from "@tv4/avod-web-player-common";
import { isMobile } from "@tv4/avod-web-player-device-capabilities";
import styled from "styled-components";

import GoToStartOverArrow from "../icons/goToStartOverArrow.svg";
import { useControls, usePlayerState } from "../providers/CoreProvider";
import { useLayoutSize } from "../providers/LayoutProvider";
import { useTranslations } from "../providers/TranslationsProvider";
import { StartOverButtons } from "../styles";
import { LayoutSize } from "../util/device";

const Button = styled.button<{ isStartOver?: boolean }>`
  ${StartOverButtons}
  opacity: ${({ isStartOver }) => (isStartOver ? 0.7 : 1)};
  svg {
    margin-right: 5px;
  }
`;

const GoToStartOverButton = () => {
  const translations = useTranslations();
  const controls = useControls();
  const { currentTime } = usePlayerState();
  const layoutSize = useLayoutSize();
  const useShortText =
    !isMobile() && [LayoutSize.SMALL, LayoutSize.SMALLEST].includes(layoutSize);
  const handleGoToStartOverClick = () => {
    controls?.seekToStartOver?.();
  };

  const buttonText = translations(
    useShortText ? "start_over__start_over_shortened" : "start_over__start_over"
  );

  return (
    <Button
      aria-label="navigate start"
      onClick={handleGoToStartOverClick}
      isStartOver={
        currentTime < DEFAULT_PLAYER_CONFIG.playbackModeSwitchThreshold
      }
    >
      <GoToStartOverArrow />
      <span>{buttonText}</span>
    </Button>
  );
};

export default GoToStartOverButton;
