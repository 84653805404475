// {@see CdnParser}

module.exports = {
  cdnName: 'CLOUDFRT',
  parsers: [{
    element: 'host',
    headerName: 'X-Amz-Cf-Id',
    regex: /(.+)/
  }, {
    element: 'type',
    headerName: 'X-Cache',
    regex: /(\S+)\s.+/
  }],
  parseType: function (type) {
    switch (type) {
      case 'Hit':
        return 1
      case 'Miss':
        return 2
      default:
        return 0
    }
  }
}
