// {@see CdnParser}

module.exports = {
  cdnName: 'EDGECAST',
  parsers: [{
    element: 'host',
    headerName: 'Server',
    regex: /\((.+)\/.+\)/
  }, {
    element: 'type',
    headerName: 'X-Cache',
    regex: /(.+)/
  }],
  parseType: function (type) {
    switch (type) {
      case 'HIT':
        return 1
      case 'MISS':
      default:
        return 2
    }
  }
}
