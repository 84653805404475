export var Method;
(function (Method) {
    Method["GET"] = "GET";
    Method["POST"] = "POST";
})(Method || (Method = {}));
export var ExpireDefault;
(function (ExpireDefault) {
    ExpireDefault[ExpireDefault["DEFAULT_VIEW_TIMEOUT_MS"] = 120000] = "DEFAULT_VIEW_TIMEOUT_MS";
    ExpireDefault[ExpireDefault["DEFAULT_MAX_DURATION_VIEW_MS"] = 86400000] = "DEFAULT_MAX_DURATION_VIEW_MS";
    ExpireDefault[ExpireDefault["DEFAULT_SESSION_TIMEOUT_MS"] = 180000] = "DEFAULT_SESSION_TIMEOUT_MS";
    ExpireDefault[ExpireDefault["DEFAULT_MAX_DURATION_SESSION_MS"] = 172800000] = "DEFAULT_MAX_DURATION_SESSION_MS";
    ExpireDefault[ExpireDefault["STOP_VIEWS_AFTER_SESSION_EXPIRE_MS"] = 2000] = "STOP_VIEWS_AFTER_SESSION_EXPIRE_MS";
})(ExpireDefault || (ExpireDefault = {}));
export var CoreEvents;
(function (CoreEvents) {
    CoreEvents["SESSION_EXPIRE"] = "SessionExpire";
})(CoreEvents || (CoreEvents = {}));
export const SessionsTag = 'Sessions';
export const AnalyticsTag = 'Analytics';
export const AppAnalyticsTag = 'AppAnalytics';
export const ProductAnalyticsTag = 'ProductAnalytics';
export const BalancerTag = 'Balancer';
export const CoreTag = 'Core';
export var SessionListeners;
(function (SessionListeners) {
    SessionListeners["START_BEFORE"] = "beforeSessionStart";
    SessionListeners["START_AFTER"] = "afterSessionStart";
    SessionListeners["STOP_BEFORE"] = "beforeSessionStop";
    SessionListeners["STOP_AFTER"] = "afterSessionStop";
    SessionListeners["EVENT_BEFORE"] = "beforeSessionEvent";
    SessionListeners["EVENT_AFTER"] = "afterSessionEvent";
})(SessionListeners || (SessionListeners = {}));
