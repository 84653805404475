var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import NQSRequest from './NQSRequest';
import { AnalyticsTag, CoreTag } from '../../common/Constants';
import Constants from './Services';
import Log from '../../common/log';
import { Services } from '../../balancer/Utils/Constants';
// Only retry /error events (to inform of network error)
const retryServices = [Constants.Service.ERROR, Constants.Service.AD_ERROR];
const MAX_RETRY_REQUESTS = 25;
export default class NQSAnalyticsService {
    constructor(options, coreStorage, fastDataService) {
        this.freshRequests = [];
        this.retryRequests = [];
        this.databaseSendingInterval = 2 * 60 * 1000;
        this.isDestroyed = false;
        this.servicesToWait = [Constants.Service.INIT, Constants.Service.START];
        this.sentStart = false;
        this.options = options;
        this.coreStorage = coreStorage;
        this.fastDataService = fastDataService;
    }
    destroy() {
        this.freshRequests = [];
        this.retryRequests = [];
        this.isDestroyed = true;
        this.timeWhenLastSentFromDatabase = new Date().getTime();
    }
    send(service, method, params, onSuccessCallback, onFailCallback, expirationManager) {
        var _a;
        if (this.isDestroyed)
            return;
        const request = new NQSRequest((_a = this.options['app.https']) !== null && _a !== void 0 ? _a : true, this.coreStorage.getHost(), service, method, params !== null && params !== void 0 ? params : {}, onSuccessCallback, onFailCallback, expirationManager);
        if (typeof this.options.authToken !== 'undefined' && typeof this.options.authType !== 'undefined' && service !== Services.CDN) {
            Log.verbose(AnalyticsTag, "Adding Authorization header with type " + this.options.authType);
            request.addHeader('Authorization', this.options.authType + ' ' + this.options.authToken);
        }
        this.freshRequests.push(request);
        this._processRequests();
    }
    getTimeOfLastSentRequest() {
        return this.timeOfLastSentRequest;
    }
    wasStartSent() {
        return this.sentStart;
    }
    _processRequests() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isDestroyed)
                return;
            const retryWorkingQueue = this.retryRequests.splice(0, this.retryRequests.length);
            const freshWorkingQueue = this.freshRequests.splice(0, this.freshRequests.length);
            if (retryWorkingQueue.length > 0) {
                Log.verbose(CoreTag, 'Retrying to send these requests:', ...retryWorkingQueue.map((request) => request.getService()));
            }
            const requestsToRetryLater = [];
            let retryRequestFailed = false;
            for (const request of retryWorkingQueue) {
                // Do not retry rest of requests if one of them failed
                if (retryRequestFailed) {
                    requestsToRetryLater.push(request);
                    continue;
                }
                try {
                    yield request.asyncSend();
                    this.timeOfLastSentRequest = new Date().getTime();
                    this.coreStorage.setDataTime(this.timeOfLastSentRequest.toString());
                }
                catch (_) {
                    retryRequestFailed = true;
                    requestsToRetryLater.push(request);
                }
            }
            for (const request of freshWorkingQueue) {
                try {
                    yield request.asyncSend();
                    if (this.servicesToWait.includes(request.getService())) {
                        this.sentStart = true;
                    }
                    this.timeOfLastSentRequest = new Date().getTime();
                    this.coreStorage.setDataTime(this.timeOfLastSentRequest.toString());
                }
                catch (_) {
                    if (retryServices.includes(request.getService())) {
                        requestsToRetryLater.push(request);
                    }
                }
            }
            // Get at most the last X requests to avoid the number of retry requests from growing indefinitely
            this.retryRequests.push(...requestsToRetryLater.slice(-MAX_RETRY_REQUESTS));
        });
    }
}
