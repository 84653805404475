// {@see CdnParser}

module.exports = {
  cdnName: 'TELEFO',
  parsers: [{
    element: 'host+type',
    headerName: 'x-tcdn',
    regex: /Host:(.+)\sType:(.+)/
  }],
  requestHeaders: {
    'x-tcdn': 'host'
  },
  parseType: function (type) {
    if (type) {
      if (
        type.indexOf('p') !== -1 ||
        type.indexOf('c') !== -1
      ) {
        return 1
      } else if (type.indexOf('i') !== -1 ||
        type.indexOf('m') !== -1) {
        return 2
      }
    }
    return 0
  }
}
