import {
  DEFAULT_PLAYER_CONFIG,
  PlaybackState,
} from "@tv4/avod-web-player-common";
import styled from "styled-components";

import { usePlayerState } from "../providers/CoreProvider";
import { colors } from "../theme/colors";

const LiveLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 4px;
  margin-left: 4px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 0.75em;
  line-height: 1;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.15);
  user-select: none;
  pointer-events: none;
  &.active {
    background: ${colors.primary};
  }
`;

const Dot = styled.span`
  background: white;
  width: 4px;
  height: 4px;
  border-radius: 50%;
`;

export default function LiveStatus({ style }: { style?: React.CSSProperties }) {
  const { duration, currentTime, playbackState } = usePlayerState();

  const isAtLiveEdge =
    duration - currentTime < DEFAULT_PLAYER_CONFIG.liveEdgeThreshold;
  const isPlaying = playbackState === PlaybackState.PLAYING;

  return (
    <LiveLabel
      className={isAtLiveEdge && isPlaying ? "active" : ""}
      style={style}
    >
      <Dot />
      Live
    </LiveLabel>
  );
}
