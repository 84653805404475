import { getRemoteConfigValue } from "@tv4/avod-web-player-common";
import { getRemainingAdImmunity } from "@tv4/avod-web-player-core";

import ChromeCastConnectedIconSvg from "../icons/ChromeCastConnectedIcon.svg";
import ChromeCastDisconnectedIconSvg from "../icons/ChromeCastDisconnectedIcon.svg";
import { useAccessToken } from "../providers/AccessTokenContext";
import {
  useChromecastManager,
  useOptions,
  usePlayerState,
} from "../providers/CoreProvider";
import { useMetadata } from "../providers/MetadataProvider";
import { PlayerControlButton as Button } from "../styles";

export const ChromeCastButton = () => {
  const { asset } = useMetadata();
  const playerState = usePlayerState();
  const { activeAudioTrack, currentTime, isCasting } = playerState;
  const { refreshToken } = useOptions() || {};
  const getAccessToken = useAccessToken();

  const manager = useChromecastManager();
  const castReady = !!manager;

  const startCasting = async () => {
    if (!asset) {
      // TODO: handle src
      console.log("temp error for missing assetId, could be src");
      return;
    }

    const { id: assetId, title } = asset;

    const remainingAdImmunity = getRemainingAdImmunity(
      assetId,
      getRemoteConfigValue("AD_IMMUNITY_DURATION")
    );

    await manager?.cast({
      assetId,
      title,
      currentTime,
      accessToken: getAccessToken(),
      refreshToken,
      remainingAdImmunity,
      preferredAudioLanguage: activeAudioTrack?.language,
    });
  };

  const stopCasting = () => {
    manager?.stop();
  };

  return (
    <>
      {!isCasting && castReady && (
        <Button aria-label={`cast`} onClick={startCasting}>
          <ChromeCastDisconnectedIconSvg />
        </Button>
      )}

      {isCasting && castReady && (
        <Button aria-label={`stop cast`} onClick={stopCasting}>
          <ChromeCastConnectedIconSvg />
        </Button>
      )}
    </>
  );
};

export default ChromeCastButton;
