/*
 * COPYRIGHT 2020 YOSPACE TECHNOLOGIES LTD. ALL RIGHTS RESERVED.
 * The contents of this file are proprietary and confidential.
 * Unauthorised copying of this file, via any medium is strictly prohibited.
 */

import {
  AdBreak,
  PlaybackMode,
  PlaybackPolicyHandler,
} from "@yospace/admanagement-sdk";

export class SSAIPlaybackPolicy extends PlaybackPolicyHandler {
  private playbackMode: PlaybackMode | null;

  constructor() {
    super();
    this.playbackMode = null;
  }

  public override canChangeVolume(_mute, _playhead, _timeline) {
    return true;
  }

  public override canClickThrough(_url, _playhead, _timeline) {
    return true;
  }

  public override canPause(_playhead, _timeline) {
    // TODO: use playback-api capabilities to determine this
    return true;
  }

  public override canResize(_fullscreen, _playhead, _timeline) {
    return true;
  }

  public override canResizeCreative(_expand, _playhead, _timeline) {
    return true;
  }

  public override canSkip(playhead, timeline) {
    if (this.playbackMode !== PlaybackMode.LIVE) {
      // We can never skip live adverts
      return -1;
    } else {
      const ad = this.isInAdvert(playhead, timeline);
      if (!ad) {
        return -1;
      }

      let skipOffset = ad.getSkipOffset();
      if (skipOffset === -1) {
        // We can only skip if the advert allows it
        return -1;
      }

      if (!ad.isActive()) {
        // We can skip an inactive advert
        skipOffset = 0;
      } else if (skipOffset >= 0) {
        // Calculate the difference between offset and playhead, unless negative
        skipOffset = Math.max(0, ad.getStart() + skipOffset - playhead);
      }

      return skipOffset;
    }
  }

  public override canStop(_playhead, _timeline) {
    return true;
  }

  public closestActiveBreakPriorTo(position, timeline: AdBreak[]) {
    let closest: AdBreak | null = null;
    for (let i = 0; i < timeline.length; ++i) {
      const br = timeline[i];
      if (br.getStart() + br.getDuration() < position) {
        closest = br;
      }
    }

    return closest && closest.isActive() ? closest : null;
  }

  public override didSeek(_from, _to, _timeline) {
    // no-op
  }

  public isInActiveBreak(playhead, timeline) {
    for (let i = 0; i < timeline.length; ++i) {
      const br = timeline[i];
      if (
        br.getStart() <= playhead &&
        playhead < br.getStart() + br.getDuration()
      ) {
        return br.isActive() ? br : null;
      }
    }

    return null;
  }

  public isInAdvert(playhead, timeline) {
    for (let i = 0; i < timeline.length; ++i) {
      const br = timeline[i];
      if (
        br.getStart() <= playhead &&
        playhead < br.getStart() + br.getDuration()
      ) {
        for (let j = 0; j < br.getAdverts.length; ++j) {
          const ad = br.getAdverts()[j];
          if (
            ad.getStart() <= playhead &&
            playhead < ad.getStart() + ad.getDuration()
          ) {
            return ad;
          }
        }
      }
    }

    return null;
  }

  public override didSkip(from, to, timeline) {
    return this.didSeek(from, to, timeline);
  }

  public override setPlaybackMode(mode) {
    this.playbackMode = mode;
  }

  public override willSeekTo(position, timeline) {
    let br = this.isInActiveBreak(position, timeline);
    let actual = position;
    if (br) {
      // If we're in an ad break, return from the break start
      actual = br.getStart();
    } else {
      // If not, find the closest break prior to the playhead
      br = this.closestActiveBreakPriorTo(position, timeline);
      if (br) {
        // If there is one, return the closest active break start.
        // If not, return the position passed in (i.e., we won't seek).
        actual = br.getStart();
      }
    }

    return actual;
  }
}
