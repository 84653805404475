var NpawObject = require('./object')
var isArray = require('./mixins/isarray')

/**
 * This class extends NpawObject, adding event emitting/listening functionalities.
 *
 * @constructs Emitter
 * @extends npaw.NpawObject
 * @memberof npaw
 */
var Emitter = NpawObject.extend(
  /** @lends npaw.Emitter.prototype */
  {
    /**
     * Sets a listener to a given event. Use {@link emit} to trigger those events.
     * Pass '*' to listen ALL events.
     *
     * @param {string} event Name of the event.
     * @param {function} callback Callback of the event. Receives event and data.
     * @return this
     */
    on: function (event, callback) {
      this._listeners = this._listeners || {}
      if (typeof callback === 'function') {
        this._listeners[event] = this._listeners[event] || []
        this._listeners[event].push(callback)
        return this
      }
    },

    /**
     * Removes given callback from the listeners of this object.
     *
     * @param {string} event Name of the event.
     * @param {function} callback Callback of the event.
     * @return this
     */
    off: function (event, callback) {
      this._listeners = this._listeners || {}

      if (this._listeners[event]) {
        var index = this._listeners[event].indexOf(callback)
        if (index !== -1) {
          this._listeners[event].splice(index, 1)
        }
      }
      return this
    },

    /**
     * Emits given event, triggering all the associated callbacks.
     *
     * @param {string} event Name of the event.
     * @param {object} [data] Custom data to be sent to the callbacks.
     * @return this
     */
    emit: function (event, data) {
      this._listeners = this._listeners || {}
      data = data || {}

      if (isArray(this._listeners[event])) {
        this._listeners[event].forEach(this._eachCallback.bind(this, event, data))
      }
      if (isArray(this._listeners['*'])) {
        this._listeners['*'].forEach(this._eachCallback.bind(this, event, data))
      }
      return this
    },

    /**
     * Travels through the listener list and executes them them.
     *
     * @private
     */
    _eachCallback: function (event, data, callback) {
      if (typeof callback === 'function') {
        var callbackArguments = {
          type: event,
          data: data,
          target: this
        }
        callback(callbackArguments)
      }
    }
  }
)

module.exports = Emitter
