import {
  AudioTrackPayload,
  BufferSeekingPayload,
  ChromecastConnectionStatusPayload,
  ChromecastContentUpdatedPayload,
  clamp,
  CoreEvents,
  CoreEventsMap,
  DEFAULT_PLAYER_STATE,
  EventEmitter,
  FullscreenPayload,
  LoadedPlaybackPayload,
  PlaybackModeChangePayload,
  PlaybackModeSetPayload,
  PlaybackRateChangePayload,
  PlaybackRestrictionsPayload,
  PlaybackState,
  PlayerMode,
  PlayerState,
  PreSeekingPayload,
  storage,
  StorageKey,
  StreamInfoOffsetUpdatedPayload,
  StreamInfoPayload,
  SubTitleTextPayload,
  SubTitleTextSizePayload,
  TextTrackPayload,
  TimePayload,
  TrackPayload,
  VolumePayload,
} from "@tv4/avod-web-player-common";
import throttle from "lodash.throttle";

export class StateManager extends EventEmitter<
  Pick<CoreEventsMap, CoreEvents.STATE_CHANGED>
> {
  private state: PlayerState = DEFAULT_PLAYER_STATE;
  private isChangingPlaybackMode = false;

  private cancelSetStateThrottled: () => void;
  private showDebugOverlay: () => void;

  constructor(playerMode?: PlayerMode) {
    super();

    this.setState({
      playerMode,
    });

    window.addEventListener(
      "showDebugOverlay",
      (this.showDebugOverlay = () => {
        this.setState({ debugOverlay: !this.state.debugOverlay });
      })
    );

    const setStateThrottled = throttle(this.setState.bind(this), 250);

    this.setStateThrottled = setStateThrottled;
    this.cancelSetStateThrottled =
      setStateThrottled.cancel.bind(setStateThrottled);
  }

  private setStateThrottled: typeof this.setState;
  private setState(newState: Partial<PlayerState>) {
    this.state = {
      ...this.state,
      ...newState,
    };

    this.emit(CoreEvents.STATE_CHANGED, { state: this.state });
  }

  public getState(): PlayerState {
    return this.state;
  }

  private streamInfoMediaOffset: number | undefined;
  private getStreamInfoPosition(positionInSeconds: number): number {
    if (this.streamInfoMediaOffset !== undefined) {
      return this.streamInfoMediaOffset + positionInSeconds;
    }
    return positionInSeconds;
  }

  public handleAllEvents(type: CoreEvents, data: CoreEventsMap[CoreEvents]) {
    switch (type) {
      case CoreEvents.LOADING_PLAYBACK:
        if (this.isChangingPlaybackMode) return;
        this.setState({
          playbackState: PlaybackState.LOADING,
        });
        break;
      case CoreEvents.LOADED_PLAYBACK:
        {
          const loadedPlaybackData = data as LoadedPlaybackPayload;
          if (this.isChangingPlaybackMode) {
            this.isChangingPlaybackMode = false;
            this.setState({
              preSeeking: false,
            });
            return;
          }
          this.setState({
            playbackState: PlaybackState.PAUSED,
            currentTime: loadedPlaybackData.currentTime,
            duration: loadedPlaybackData.duration,
            isLive: loadedPlaybackData.isLive,
            volume: loadedPlaybackData.volume,
            muted: loadedPlaybackData.muted,
          });
        }
        break;
      case CoreEvents.PLAYBACK_RESTRICTIONS:
        {
          const playbackRestrictionsData = data as PlaybackRestrictionsPayload;
          this.setState({
            canPause: playbackRestrictionsData.canPause,
            canSeek: playbackRestrictionsData.canSeek,
            canGoToLive: playbackRestrictionsData.canGoToLive,
            canStartOver: playbackRestrictionsData.canStartOver,
          });
        }
        break;
      case CoreEvents.AD_START:
      case CoreEvents.START:
      case CoreEvents.AD_RESUME:
      case CoreEvents.RESUME:
        this.setState({
          playbackState: PlaybackState.PLAYING,
        });
        break;
      case CoreEvents.AD_PAUSED:
      case CoreEvents.PAUSED:
        this.setState({
          playbackState: PlaybackState.PAUSED,
        });
        break;
      case CoreEvents.SEEKING:
        {
          const seekingData = data as BufferSeekingPayload;
          this.setState({
            playbackState: PlaybackState.SEEKING,
            currentTime: this.getStreamInfoPosition(seekingData.currentTime),
            duration: this.state.streamInfo?.duration || seekingData.duration,
          });
        }
        break;
      case CoreEvents.PRE_SEEKING:
        {
          const preSeekingData = data as PreSeekingPayload;
          const { currentTime, duration } = this.state;
          this.setState({
            preSeeking: true,
            currentTime: clamp(currentTime + preSeekingData.amount, duration),
          });
        }
        break;
      case CoreEvents.SEEKED:
        {
          const seekingData = data as BufferSeekingPayload;
          this.setState({
            playbackState: seekingData.playing
              ? PlaybackState.PLAYING
              : PlaybackState.PAUSED,
            currentTime: this.getStreamInfoPosition(seekingData.currentTime),
            preSeeking: false,
            duration: this.state.streamInfo?.duration || seekingData.duration,
          });
        }
        break;
      case CoreEvents.TIME_UPDATED:
        {
          if (this.state.preSeeking) {
            return;
          }
          const timeUpdatedData = data as TimePayload;
          const { streamInfo } = this.state;

          const timeProps: Partial<PlayerState> = {
            currentTime: timeUpdatedData.currentTime,
            utcCurrentTimeMs: timeUpdatedData.utcCurrentTimeMs,
            duration: streamInfo?.duration || timeUpdatedData.duration,
          };

          if (streamInfo) {
            if (timeUpdatedData.utcCurrentTimeMs) {
              timeProps.currentTime =
                timeUpdatedData.utcCurrentTimeMs / 1000 - streamInfo.startTime;
              timeProps.utcStartTimeMs = streamInfo.startTime * 1000;
            } else {
              console.warn(
                "Time updated event without utcCurrentTimeMs, using currentTime from payload instead"
              );
            }
          } else if (timeUpdatedData.utcCurrentTimeMs) {
            timeProps.utcStartTimeMs =
              timeUpdatedData.utcCurrentTimeMs -
              timeUpdatedData.currentTime * 1000;
          }
          this.setStateThrottled(timeProps);
        }
        break;
      case CoreEvents.AD_BUFFERING:
      case CoreEvents.BUFFERING:
        this.setState({
          playbackState: PlaybackState.BUFFERING,
        });
        break;
      case CoreEvents.AD_BUFFERED:
      case CoreEvents.BUFFERED:
        {
          const { playing } = data as BufferSeekingPayload;
          this.setState({
            playbackState: playing
              ? PlaybackState.PLAYING
              : PlaybackState.PAUSED,
          });
        }
        break;
      case CoreEvents.ENDED:
        this.setState({
          playbackState: PlaybackState.ENDED,
        });
        break;
      case CoreEvents.BREAK_START: {
        this.setState({ isAd: true });
        console.log(
          "[Ad immunity]",
          new Date().toLocaleTimeString(),
          "❌ Ad break started"
        );
        break;
      }
      case CoreEvents.BREAK_END: {
        this.setState({ isAd: false });
        break;
      }
      case CoreEvents.TRACK_CHANGED: {
        const bitrateData = data as TrackPayload;
        this.setState({ currentBitrate: bitrateData.bitrate });
        break;
      }
      case CoreEvents.VOLUME_CHANGED: {
        const { volume, muted } = data as VolumePayload;
        this.setState({ volume, muted });

        // This setting is saved in both session and localstorage for separate
        // reasons. Session storage is used to sync settings between multiple
        // open player instances while local storage is used to remember
        // settings between browser and tab sessions.
        //
        // We cannot use localstorage to sync data between multiple open
        // player instances since that would cause players in other tabs
        // to be affected.

        if (typeof muted === "boolean") {
          storage.setSessionData(
            this.state.playerMode === PlayerMode.PREVIEW
              ? StorageKey.MUTED_PREVIEW
              : StorageKey.MUTED,
            muted
          );

          storage.setData(
            this.state.playerMode === PlayerMode.PREVIEW
              ? StorageKey.MUTED_PREVIEW
              : StorageKey.MUTED,
            muted
          );
        }

        if (typeof volume === "number") {
          storage.setSessionData(
            this.state.playerMode === PlayerMode.PREVIEW
              ? StorageKey.VOLUME_PREVIEW
              : StorageKey.VOLUME,
            volume
          );

          storage.setData(
            this.state.playerMode === PlayerMode.PREVIEW
              ? StorageKey.VOLUME_PREVIEW
              : StorageKey.VOLUME,
            volume
          );
        }
        break;
      }
      case CoreEvents.FULLSCREEN_CHANGED: {
        const fullscreenData = data as FullscreenPayload;
        this.setState({ isFullscreen: fullscreenData.fullscreen });
        break;
      }
      case CoreEvents.TEXT_TRACK_CHANGED:
        this.setState({
          textTracks: (data as TextTrackPayload).textTracks,
          activeTextTrack: (data as TextTrackPayload).activeTextTrack,
        });
        break;
      case CoreEvents.SUBTITLE_TEXT_CHANGED:
        this.setState({
          textTrackSettings: {
            ...this.state.textTrackSettings,
            currentSubtitles: (data as SubTitleTextPayload).text,
          },
        });
        break;
      case CoreEvents.SUBTITLE_TEXT_SIZE_CHANGED:
        this.setState({
          textTrackSettings: {
            ...this.state.textTrackSettings,
            textSize: (data as SubTitleTextSizePayload).size,
          },
        });
        break;
      case CoreEvents.AUDIO_TRACK_CHANGED:
        this.setState({
          audioTracks: (data as AudioTrackPayload).audioTracks,
          activeAudioTrack: (data as AudioTrackPayload).activeAudioTrack,
        });
        break;
      case CoreEvents.PAUSE_AD_SHOWN:
        this.setState({ isPauseAd: true });
        break;
      case CoreEvents.PAUSE_AD_HIDDEN:
        this.setState({ isPauseAd: false });
        break;
      case CoreEvents.LOAD_PLAYBACK_ERROR:
        this.isChangingPlaybackMode = false;
      // fallthrough
      case CoreEvents.RESET: {
        this.streamInfoMediaOffset = undefined;
        this.cancelSetStateThrottled();

        if (this.isChangingPlaybackMode) {
          // only reset a subset of the state when changing playback mode
          this.setState({
            isAd: false,
            isPauseAd: false,
          });
          return;
        }
        this.setState({
          ...DEFAULT_PLAYER_STATE,
          isFullscreen: this.state.isFullscreen,
          playerMode: this.state.playerMode,
        });
        break;
      }
      case CoreEvents.AUTOPLAY_BLOCKED:
        this.setState({
          autoplayBlocked: true,
        });
        break;
      case CoreEvents.SUCCESSFUL_PLAY:
        this.setState({
          autoplayBlocked: false,
        });
        break;
      case CoreEvents.CHROMECAST_CONNECTION_STATUS:
        this.setState({
          isCasting: (data as ChromecastConnectionStatusPayload).isConnected,
        });
        break;
      case CoreEvents.CHROMECAST_CONTENT_UPDATED: {
        this.setState({
          isLive: (data as ChromecastContentUpdatedPayload).isLive,
        });
        break;
      }
      case CoreEvents.PLAYBACK_MODE_CHANGE: {
        const playbackModeChangePayload = data as PlaybackModeChangePayload;
        this.isChangingPlaybackMode = true;
        this.cancelSetStateThrottled();
        this.setState({
          currentTime: playbackModeChangePayload.originStartTime,
        });
        break;
      }
      case CoreEvents.PLAYBACK_MODE_SET: {
        const { playbackMode } = data as PlaybackModeSetPayload;
        this.setState({ playbackMode });
        break;
      }
      case CoreEvents.STREAM_INFO_UPDATED: {
        this.setState({
          streamInfo: {
            ...this.state.streamInfo,
            ...(data as StreamInfoPayload),
          },
        });
        break;
      }
      case CoreEvents.PLAYBACK_RATE_CHANGE: {
        this.setState({
          playbackRate: (data as PlaybackRateChangePayload).playbackRate,
        });
        break;
      }
      case CoreEvents.STREAM_INFO_OFFSET_UPDATED: {
        this.streamInfoMediaOffset = (
          data as StreamInfoOffsetUpdatedPayload
        ).offset;
        break;
      }
    }
  }

  public override destroy(): void {
    window.removeEventListener("showDebugOverlay", this.showDebugOverlay);
    this.cancelSetStateThrottled();
    super.destroy();
  }
}
