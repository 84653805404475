import { AWPTextTrackKind, PlaybackState } from "@tv4/avod-web-player-common";
import { ForwardedRef, forwardRef, useEffect, useMemo, useState } from "react";

import { DebugOverlay } from "./components/DebugOverlay/DebugOverlay";
import ErrorMessage from "./components/ErrorMessage/ErrorMessage";
import { PlaybackButton } from "./components/PlaybackButton";
import Poster from "./components/Poster";
import { SkinPreviewControls } from "./components/SkinPreviewControls/SkinPreviewControls";
import { Spinner } from "./components/spinner/Spinner";
import useAutoHideUI from "./hooks/useAutoHideUI";
import useCore from "./hooks/useCore";
import useSkinVisibilityChangeEvent from "./hooks/useSkinVisibilityChangeEvent";
import { CoreProvider } from "./providers/CoreProvider";
import { useLayoutSize, useUIVisibility } from "./providers/LayoutProvider";
import { useMetadata } from "./providers/MetadataProvider";
import { TSkinProps } from "./Skin";
import { GradientMode, SkinContainer, SkinWrapper } from "./styles";

const SkinPreview = forwardRef(
  (
    { core, forceShowSkin, onExitClick, onVideoClick }: TSkinProps,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const { poster } = useMetadata();
    const {
      playerState,
      controls,
      adMarkers,
      currentAdBreak,
      currentAd,
      chromeCastManager,
      options,
      error,
    } = useCore(core);

    const { uiVisible } = useUIVisibility();
    const [buttonsEnabled, setButtonsEnabled] = useState(false);
    const layoutSize = useLayoutSize();

    const [started, setStarted] = useState(false);

    const { playbackState, isCasting, autoplayBlocked, debugOverlay } =
      playerState;

    const idle = playbackState === PlaybackState.IDLE;
    const loading = playbackState === PlaybackState.LOADING;
    const seeking = playbackState === PlaybackState.SEEKING;
    const buffering = playbackState === PlaybackState.BUFFERING;
    const ended = playbackState === PlaybackState.ENDED;

    useEffect(() => {
      controls?.setTextTrack?.({
        id: "-1",
        kind: AWPTextTrackKind.NOTHING,
        label: "",
        language: "",
      });
    }, [controls]);

    const { handleInteraction } = useAutoHideUI();

    useEffect(() => {
      setTimeout(
        () => {
          setButtonsEnabled(uiVisible);
        },
        uiVisible ? 500 : 0
      );
    }, [uiVisible]);

    useEffect(() => {
      if (
        playbackState === PlaybackState.IDLE ||
        playbackState === PlaybackState.ENDED
      ) {
        setStarted(false);
      } else if (playbackState === PlaybackState.PLAYING) {
        setStarted(true);
      }
    }, [playbackState]);

    const displayPosterContent = () => {
      if (isCasting || (started && !autoplayBlocked)) return null;

      const hasError = error && Boolean(error);
      const isBlurred = Boolean(error);
      const isLoading = idle || loading;
      const canPlayback = !started || autoplayBlocked;

      return (
        <>
          <Poster poster={poster} blurred={isBlurred} />
          {hasError && <ErrorMessage error={error} onExitClick={onExitClick} />}
          {!hasError && !isLoading && canPlayback && <PlaybackButton />}
        </>
      );
    };

    const shouldShowSkin = useMemo(() => {
      if (forceShowSkin && playbackState === PlaybackState.PLAYING) return true;
      if (isCasting || playerState.preSeeking) return true;
      return started && !idle && !loading && uiVisible;
    }, [
      isCasting,
      forceShowSkin,
      started,
      idle,
      loading,
      uiVisible,
      playbackState,
      playerState.preSeeking,
    ]);

    useSkinVisibilityChangeEvent(core, shouldShowSkin);

    const getCurrentCoreState = () => {
      return core.getState();
    };

    return (
      <CoreProvider
        playerState={playerState}
        controls={controls}
        adMarkers={adMarkers}
        currentAdBreak={currentAdBreak}
        currentAd={currentAd}
        options={options}
        chromeCastManager={chromeCastManager}
        getCurrentState={getCurrentCoreState}
      >
        <SkinWrapper
          ref={ref}
          onPointerMove={handleInteraction}
          onTouchStart={handleInteraction}
          $usePauseAds={false}
          onPointerUp={!error ? onVideoClick : undefined}
          $isActive={uiVisible}
        >
          {!ended && (
            <SkinContainer
              id="skin-container"
              visible={shouldShowSkin}
              size={layoutSize}
              gradientMode={GradientMode.BOTTOM}
              buttonsEnabled={buttonsEnabled}
            >
              <div></div>
              <SkinPreviewControls />
            </SkinContainer>
          )}
          {displayPosterContent()}
          {(loading || buffering || seeking) && <Spinner />}
        </SkinWrapper>
        {debugOverlay && <DebugOverlay />}
      </CoreProvider>
    );
  }
);

export default SkinPreview;
