// {@see CdnParser}

module.exports = {
  cdnName: 'AKAMAI',
  // requestMethod: 'GET',
  parsers: [{
    element: 'type+host',
    headerName: 'X-Cache',
    regex: /(.+)\sfrom.+AkamaiGHost\/(.+)\).+/
  }, {
    element: 'host',
    headerName: 'akamai-mon-iucid-del',
    regex: /(.*)/
  }, {
    element: 'type',
    headerName: 'akamai-cache-status',
    regex: /(.+)\sfrom\schild/
  }],
  requestHeaders: {
    Pragma: 'akamai-x-cache-on'
  },
  parseType: function (type) {
    var t = type.toLowerCase()
    if (t.indexOf('hit') !== -1) {
      return 1
    } else if (t.indexOf('miss') !== -1) {
      return 2
    }
    return 0
  }
}
