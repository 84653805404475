"use strict";

exports.__esModule = true;
exports.copyMap = copyMap;
exports.getKeyFromValue = getKeyFromValue;
exports.mapToString = mapToString;
exports.objectToMap = objectToMap;
var _YoLog = require("./YoLog");
function _createForOfIteratorHelperLoose(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; } /*
 * COPYRIGHT 2020,2023 YOSPACE TECHNOLOGIES LTD. ALL RIGHTS RESERVED.
 * The contents of this file are proprietary and confidential.
 * Unauthorised copying of this file, via any medium is strictly prohibited.
 */
function copyMap(m) {
  var map = new Map();
  for (var _iterator = _createForOfIteratorHelperLoose(m), _step; !(_step = _iterator()).done;) {
    var _step$value = _step.value,
      key = _step$value[0],
      value = _step$value[1];
    map.set(key, value);
  }
  return map;
}
function objectToMap(o) {
  var m = new Map();
  if (o) {
    for (var v in o) {
      m.set(v, o[v]);
    }
  }
  return m;
}
function mapToString(m) {
  if (!m) {
    return "{}";
  }
  return "{" + Array.from(m, function (_ref) {
    var name = _ref[0],
      value = _ref[1];
    return name + ': ' + value;
  }).join(', ') + "}";
}
function getKeyFromValue(value, obj) {
  if (!obj) {
    _YoLog.YoLog.e("getKeyFromValue: obj is null");
    return null;
  }
  for (var _i = 0, _Object$entries = Object.entries(obj); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = _Object$entries[_i],
      k = _Object$entries$_i[0],
      v = _Object$entries$_i[1];
    if (value === v) return k;
  }
  return null;
}