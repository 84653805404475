import {
  AdInsertionType,
  AdTrackingEvent,
  AdVideoVariant,
  IAd,
} from "@tv4/avod-web-player-common";

import ArrowRightSvg from "../../icons/ArrowRight.svg";
import {
  useCurrentAdBreak,
  usePlayerState,
} from "../../providers/CoreProvider";
import { useLayoutSize } from "../../providers/LayoutProvider";
import { useMetadata } from "../../providers/MetadataProvider";
import { useTranslations } from "../../providers/TranslationsProvider";
import {
  AdBreakOverlayContainer,
  AdCount,
  VisitAdvertiserButton,
} from "./AdBreakOverlay.styles";

const trackEvent = (event: AdTrackingEvent, ad: IAd) => {
  if (!ad || !event) return;
  let trackingUrls: string[] = [];
  switch (event) {
    case AdTrackingEvent.IMPRESSION:
      trackingUrls = ad.impressionUrlTemplates || [];
      break;
    case AdTrackingEvent.CLICK_THROUGH: {
      const clickThroughEvents = ad.creative.trackingEvents.clickThrough;
      if (Array.isArray(clickThroughEvents)) {
        clickThroughEvents.forEach((e: (typeof clickThroughEvents)[number]) => {
          if (typeof e === "string") {
            trackingUrls.push(e);
          } else {
            trackingUrls.push(e.url);
          }
        });
      }
      break;
    }
    case AdTrackingEvent.ERROR:
      trackingUrls = ad.errorUrlTemplates || [];
      break;
    case AdTrackingEvent.CLOSE:
      ad.creative.trackingEvents.close?.forEach(
        (url: (typeof ad.creative.trackingEvents.close)[number]) => {
          if (url) {
            trackingUrls.push(url as string);
          }
        }
      );
      break;
  }
  trackingUrls.forEach(async (url: string) => {
    new Image().src = url;
  });
};

type TAdBreakOverlayProps = {
  visible: boolean;
  makeSpaceForBackButton: boolean;
  onAdButtonClick: (url: string) => void;
};
const AdBreakOverlay = ({
  visible,
  makeSpaceForBackButton,
  onAdButtonClick,
}: TAdBreakOverlayProps) => {
  const { isAd } = usePlayerState();
  const { currentAdBreak, currentAd } = useCurrentAdBreak();

  const size = useLayoutSize();
  const translations = useTranslations();
  const openAdvertiserLink = () => {
    if (!currentAd?.creative.clickThroughUrlTemplate) return;

    window.open(currentAd.creative.clickThroughUrlTemplate.url, "_blank");
    trackEvent(AdTrackingEvent.CLICK_THROUGH, currentAd);
    onAdButtonClick(currentAd.creative.clickThroughUrlTemplate.url);
  };

  const showAdIndicator = !!useMetadata().asset?.isClip && visible && isAd;

  const isLinearAd = currentAdBreak?.insertionType === AdInsertionType.Linear;
  const showAdLabel =
    isLinearAd ||
    (visible && isAd && currentAd?.variant === AdVideoVariant.NORMAL);

  const isSponsorAd =
    visible && isAd && currentAd?.variant === AdVideoVariant.SPONSOR;

  if (!showAdLabel && !isSponsorAd) return null;

  return (
    <AdBreakOverlayContainer size={size}>
      {!isSponsorAd && showAdIndicator && (
        <AdCount makeSpaceForBackButton={makeSpaceForBackButton}>
          {translations("ad_indicator__ad")}
        </AdCount>
      )}
      {currentAd?.creative.clickThroughUrlTemplate &&
        currentAd?.creative.clickThroughUrlTemplate.url !== "" && (
          <VisitAdvertiserButton onClick={openAdvertiserLink} size={size}>
            {translations("ad_break_overlay__visit_advertiser")}
            <ArrowRightSvg />
          </VisitAdvertiserButton>
        )}
    </AdBreakOverlayContainer>
  );
};

export { AdBreakOverlay };
