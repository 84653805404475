import ShareSVG from "../icons/share.svg";
import { PlayerControlButton as Button } from "../styles";

export const ShareButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button aria-label="Share" onClick={onClick}>
      <ShareSVG />
    </Button>
  );
};
