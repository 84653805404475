{
    "name": "@yospace/admanagement-sdk",
    "version": "3.8.3",
    "description": "Yospace AdManagement SDK",
    "homepage": "https://yospace.com",
    "main": "lib/index.js",
    "types": "types/index.d.ts",
    "private": false,
    "dependencies": {
        "sax": "1.3.0"
    },
    "devDependencies": {
        "@babel/core": "^7.22.9",
        "@babel/plugin-transform-modules-commonjs": "^7.22.5",
        "@babel/preset-env": "^7.22.9",
        "babel-jest": "^29.6.2",
        "babel-loader": "^9.1.3",
        "babel-plugin-transform-es2015-modules-simple-commonjs": "^0.3.0",
        "documentation": "^13.2.5",
        "jest": "^29.6.2",
        "jest-jasmine2": "^29.6.2",
        "jest-junit": "^16.0.0",
        "jest-sonar-reporter": "^2.0.0",
        "jsdoc": "^4.0.2",
        "jshint": "^2.13.6",
        "msw": "^2.0.4",
        "node-fetch": "^3.3.2",
        "nodemon": "^3.0.1",
        "npm-check-updates": "^16.10.17",
        "nwb": "^0.25.2",
        "process": "0.11.10",
        "sinon": "^15.2.0",
        "stream-browserify": "3.0.0",
        "typescript": "5.1.x",
        "webpack": "^5.88.2",
        "webpack-cli": "^5.1.4",
        "ws": "^8.13.0"
    },
    "scripts": {
        "build": "webpack",
        "build-es5": "webpack --config webpack.config-es5.js",
        "build-unminified": "webpack --config webpack.config-unminified.js",
        "build:npm": "nwb build --no-demo",
        "doc": "documentation build src/** -f html -o Documentation/html --theme src-docs/apidoctemplate",
        "lint": "jshint --config .jshintrc ./src/ || true",
        "test": "jest --coverage --bail",
        "testUnit": "jest --coverage -t UNIT",
        "testStateMachine": "jest --coverage -t STATE_MACHINE",
        "testIntegration": "jest --coverage --bail -t INTEGRATION",
        "tsdefs": "npx -p typescript tsc src/index.js --stripInternal --declaration --allowJs --emitDeclarationOnly --outDir types",
        "tsdefsHosting": "npx -p typescript tsc src/index.js --stripInternal --declaration --allowJs --emitDeclarationOnly --outDir types-${npm_package_version}"
    },
    "author": {
        "name": "Yospace Technologies Ltd",
        "email": "sdk-team@yospace.com"
    },
    "license": "SEE LICENSE IN LICENCE",
    "files": [
        "lib/",
        "docs/",
        "types/"
    ]
}
