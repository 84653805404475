import {
  CoreEvents,
  CoreEventsMap,
  EventEmitter,
} from "@tv4/avod-web-player-common";
import {
  isIOS,
  isIpadOS,
  isMobile,
} from "@tv4/avod-web-player-device-capabilities";

enum FullscreenType {
  NATIVE,
  WEBKIT_NATIVE,
  FAKE,
  IOS_VIDEO,
}

declare global {
  interface HTMLVideoElement {
    webkitEnterFullscreen: () => void;
    webkitExitFullscreen: () => void;
    webkitDisplayingFullscreen: boolean;
  }
}

interface SafariHTMLDocument extends Document {
  webkitExitFullscreen: () => void;
  webkitIsFullScreen: boolean;
}

interface SafariHTMLElement extends HTMLElement {
  webkitRequestFullscreen: () => void;
}

const FULLSCREEN_CSS = "fullscreen";
const FAKE_FULLSCREEN_CSS = "avod-web-player-fake-fullscreen";

export class FullscreenController extends EventEmitter<
  Pick<CoreEventsMap, CoreEvents.FULLSCREEN_CHANGED>
> {
  private type: FullscreenType = FullscreenType.NATIVE;
  private videoElement?: HTMLVideoElement;

  constructor(
    private element: HTMLElement,
    iosNativeFullscreen?: boolean
  ) {
    super();

    if (isIpadOS()) {
      this.type = FullscreenType.FAKE;
    } else if (
      !this.element.requestFullscreen &&
      (this.element as SafariHTMLElement).webkitRequestFullscreen
    ) {
      this.type = FullscreenType.WEBKIT_NATIVE;
    } else if (isIOS()) {
      const videoElements = this.element.querySelectorAll("video");
      this.videoElement =
        iosNativeFullscreen && videoElements.length === 1
          ? videoElements[0]
          : undefined;
      this.type = this.videoElement
        ? FullscreenType.IOS_VIDEO
        : FullscreenType.FAKE;
    } else if (!document.fullscreenEnabled) {
      this.type = FullscreenType.FAKE;
    }

    this.onFullscreenChange = this.onFullscreenChange.bind(this);
    if (this.type === FullscreenType.NATIVE) {
      document.addEventListener("fullscreenchange", this.onFullscreenChange);
    } else if (this.type === FullscreenType.WEBKIT_NATIVE) {
      document.addEventListener(
        "webkitfullscreenchange",
        this.onFullscreenChange
      );
    } else if (this.type === FullscreenType.IOS_VIDEO) {
      this.videoElement?.addEventListener(
        "webkitbeginfullscreen",
        this.onFullscreenChange
      );
      this.videoElement?.addEventListener(
        "webkitendfullscreen",
        this.onFullscreenChange
      );
    }

    // TODO: Talk to UI and see if this should still be used
    if (isMobile()) {
      const portrait = window.matchMedia("(orientation: portrait)");

      portrait.addEventListener("change", (e) => {
        if (e.matches) {
          // exit here causes enter fullscreen to not work a second time
        } else {
          // !this.isFullscreen() && this.enter();
        }
      });
    }
  }

  private isFullscreen(): boolean {
    switch (this.type) {
      case FullscreenType.NATIVE:
        return !!document.fullscreenElement;
      case FullscreenType.WEBKIT_NATIVE:
        return (document as SafariHTMLDocument).webkitIsFullScreen;
      case FullscreenType.FAKE:
        return this.element.classList.contains(FAKE_FULLSCREEN_CSS);
      case FullscreenType.IOS_VIDEO:
        return this.videoElement?.webkitDisplayingFullscreen ?? false;
    }
  }

  public toggle() {
    if (this.isFullscreen()) {
      this.exit();
    } else {
      this.enter();
    }
  }

  public enter() {
    switch (this.type) {
      case FullscreenType.NATIVE:
        this.element.requestFullscreen();
        this.element.classList.add(FULLSCREEN_CSS);
        break;
      case FullscreenType.WEBKIT_NATIVE:
        (this.element as SafariHTMLElement).webkitRequestFullscreen();
        this.element.classList.add(FULLSCREEN_CSS);
        break;
      case FullscreenType.FAKE:
        this.element.classList.add(FAKE_FULLSCREEN_CSS);
        this.onFullscreenChange();
        break;
      case FullscreenType.IOS_VIDEO:
        this.videoElement?.webkitEnterFullscreen();
        break;
    }
  }

  public exit() {
    switch (this.type) {
      case FullscreenType.NATIVE:
        document.exitFullscreen();
        this.element.classList.remove(FULLSCREEN_CSS);
        break;
      case FullscreenType.WEBKIT_NATIVE:
        (document as SafariHTMLDocument).webkitExitFullscreen();
        this.element.classList.remove(FULLSCREEN_CSS);
        break;
      case FullscreenType.FAKE:
        this.element.classList.remove(FAKE_FULLSCREEN_CSS);
        this.onFullscreenChange();
        break;
      case FullscreenType.IOS_VIDEO:
        this.videoElement?.webkitExitFullscreen();
        break;
    }
  }

  private onFullscreenChange() {
    if (!this.isFullscreen()) this.element.classList.remove(FULLSCREEN_CSS);

    this.emit(CoreEvents.FULLSCREEN_CHANGED, {
      fullscreen: this.isFullscreen(),
    });
  }

  public override destroy() {
    super.destroy();
    document.removeEventListener("fullscreenchange", this.onFullscreenChange);
    document.removeEventListener(
      "webkitfullscreenchange",
      this.onFullscreenChange
    );
    this.videoElement?.removeEventListener(
      "webkitbeginfullscreen",
      this.onFullscreenChange
    );
    this.videoElement?.removeEventListener(
      "webkitendfullscreen",
      this.onFullscreenChange
    );
  }
}
