import { isSupportedOS as isDesktopOS } from "@tv4/avod-web-player-device-capabilities";
import { FC, ReactNode, useEffect, useState } from "react";
import styled from "styled-components";

import { useClientCallback } from "../providers/ClientCallbackContext";
import { usePlayerState } from "../providers/CoreProvider";
import { useLayoutSize } from "../providers/LayoutProvider";
import { useMetadata } from "../providers/MetadataProvider";
import { useTranslations } from "../providers/TranslationsProvider";
import { LayoutSize } from "../util/device";
import ContentRatings from "./ContentRatings";

export type ContentMetadata = {
  title: ReactNode;
  subtitle: ReactNode;
};

export const Metadata = styled.div<{
  isFullscreen?: boolean;
  size: LayoutSize;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ isFullscreen }) => (isDesktopOS() && isFullscreen ? 0.2 : 1)}em;

  overflow: hidden;
  transition: 0.5s ease-out;
  font-size: ${({ size }) =>
    [LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size) ? 0.7 : 1}em;
`;

export const Title = styled.span<{ size: LayoutSize }>`
  display: -webkit-box;
  width: 100%;
  font-size: ${({ size }) =>
    [LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size) ? 1.5 : 1.8}em;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ size }) =>
    [LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size) ? 2 : 3};
  padding-right: 1em;
  -webkit-box-orient: vertical;
`;

export const Subtitle = styled.span`
  display: flex;
  align-items: center;
  font-size: 1.125em;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.7;
`;

const Divider = styled.div`
  margin: 0 0.5em;
  background: white;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  opacity: 0.7;
`;

type CurrentProgramMetaDataProps = {
  hidden: boolean;
  style?: { maxWidth: string };
};

const CurrentProgramMetaData: FC<CurrentProgramMetaDataProps> = ({
  hidden,
  style,
}) => {
  const { asset } = useMetadata();
  const { isFullscreen } = usePlayerState();
  const translations = useTranslations();
  const size = useLayoutSize();

  const showDivider = Boolean(asset?.seasonNumber && asset?.episodeNumber);
  const contentRating = asset?.contentRating;
  const isClip = !!asset?.isClip;

  const [titleToRender, setTitleToRender] = useState<ReactNode>("");
  const [subtitleToRender, setSubtitleToRender] = useState<ReactNode>("");

  const { getContentMetadata } = useClientCallback();

  useEffect(() => {
    if (!asset) {
      // Reset the title and subtitle when there is no asset (for external sources)
      setTitleToRender("");
      setSubtitleToRender("");
      return;
    }

    let aborted = false;

    if (getContentMetadata) {
      getContentMetadata(asset.id)
        .then(({ title, subtitle }) => {
          if (aborted) return;

          setTitleToRender(title);
          setSubtitleToRender(subtitle);
        })
        .catch((_) => {
          // noop
        });
    } else {
      setTitleToRender((isClip && asset.title) || asset.seriesTitle || "");
      setSubtitleToRender(
        () =>
          Boolean(asset?.seasonNumber || asset?.episodeNumber) && (
            <>
              {Boolean(asset?.seasonNumber) && (
                <span>
                  {translations("program_metadata__season")}{" "}
                  {asset.seasonNumber}
                </span>
              )}
              {showDivider && <Divider />}
              {Boolean(asset?.episodeNumber) && (
                <span>
                  {translations("program_metadata__episode")}{" "}
                  {asset.episodeNumber}
                </span>
              )}
            </>
          )
      );
    }

    return () => {
      aborted = true;
    };
  }, [asset, getContentMetadata, isClip, showDivider, translations]);

  return (
    <Metadata isFullscreen={isFullscreen} size={size} style={style}>
      {!hidden && (
        <div>
          {titleToRender && <Title size={size}>{titleToRender}</Title>}
          {subtitleToRender && <Subtitle>{subtitleToRender}</Subtitle>}
          {contentRating && <ContentRatings />}
        </div>
      )}
    </Metadata>
  );
};

export default CurrentProgramMetaData;
