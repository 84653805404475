const dark = "#191817";
const primary = "#E0001C";
const white = "#FFFFFF";

export const colors = {
  primary: primary,
  background: dark,
  text: white,

  black: "rgb(0, 0, 0)",
  black10: "rgba(0, 0, 0, 0.1)",
  black20: "rgba(0, 0, 0, 0.2)",
  black30: "rgba(0, 0, 0, 0.3)",
  black50: "rgba(0, 0, 0, 0.5)",
  black60: "rgba(0, 0, 0, 0.6)",
  black70: "rgba(0, 0, 0, 0.7)",
  black80: "rgba(0, 0, 0, 0.8)",

  cream: "#F4F2F1",
  dark,
  darkish: "#2B2A2A", // dark with 10% white blend. Designed to contrast with the dark color.
  deepred: "#710923",
  green: "#1EA888",
  lightred: "#FDAFAF",
  mediumred: "#FB666A",
  yellow: "#EDC54D",

  white,
  white025: "rgba(255, 255, 255, 0.025)",
  white04: "rgba(255, 255, 255, 0.04)",
  white10: "rgba(255, 255, 255, 0.1)",
  white15: "rgba(255, 255, 255, 0.15)",
  white20: "rgba(255, 255, 255, 0.2)",
  white25: "rgba(255, 255, 255, 0.25)",
  white30: "rgba(255, 255, 255, 0.3)",
  white40: "rgba(255, 255, 255, 0.4)",
  white50: "rgba(255, 255, 255, 0.5)",
  white60: "rgba(255, 255, 255, 0.6)",
  white70: "rgba(255, 255, 255, 0.7)",
  white80: "rgba(255, 255, 255, 0.8)",
  white90: "rgba(255, 255, 255, 0.9)",
  white100: white,

  // legacy colors imported from colors.ts
  // might not be part of the new design
  grey: "#A3A3A3",
  lightGrey: "#E6E6E6",
  mediumGrey: "#616161",
  darkGrey: "#3C3C3C",
  codGrey: "#1B1B1B",
  darkRed: "#7A070F",
  darkBeige: "#F5EDE3",
};

export type Color = keyof typeof colors;
