import { Method } from '../../common/Constants';
export default class VideoAnalyticsRequest {
    /**
     * VideoAnalyticsRequest class will wrap all the information relative to a request of the Video Analytics component
     *
     * @param service Name of the service. ie '/start'
     * @param params Object of key:value params.
     * @param videoKey Video object identifier
     * @param method Request Method
     * @param body Body of the request if it exist
     * @param onSuccess Callback for a successful request
     * @param onFail Callback for a request that fails
     * @param expirationManager Expiration Manager
     *
     */
    constructor(service, params, videoKey, method, body, onSuccess, onFail, expirationManager) {
        this.method = Method.GET;
        /** Set VideoKey if exists */
        this.videoKey = videoKey;
        /** Service of the request */
        this.service = service;
        /** Method of the request */
        this.method = method;
        /** Object of params of the request */
        this.params = params || {};
        /** Expiration Manager */
        this.expirationManager = expirationManager;
        this.body = body;
        this.onSuccessCallback = onSuccess;
        this.onFailCallback = onFail;
    }
    getService() {
        return this.service;
    }
    getMethod() {
        var _a;
        return (_a = this.method) !== null && _a !== void 0 ? _a : Method.GET;
    }
    setMethod(method) {
        this.method = method;
    }
    getParams() {
        return this.params;
    }
    getOnSuccess() {
        return this.onSuccessCallback;
    }
    getOnFail() {
        return this.onFailCallback;
    }
    /** Returns video Key */
    getVideoKey() {
        return this.videoKey;
    }
    /** Returns the Expiration Manager */
    getExpirationManager() {
        return this.expirationManager;
    }
    /**
     * Returns the value of the given param, or undefined.
     * @param key Name of the param.
     */
    getParam(key) {
        return this.params[key];
    }
    /**
     * Add or set a parameter for the request.
     * ie: if you want to add 'username=user' use setParam('username', 'user').
     * @param key Name of the param.
     * @param value Name of the param.
     * @return this
     */
    setParam(key, value) {
        this.params[key] = value;
        return this;
    }
    /**
     * Sets the parameters object to be used for the request
     * @param params Parameters object
     */
    setParams(params) {
        this.params = params;
    }
    getBody() {
        return this.body;
    }
    setBody(body) {
        this.body = body;
    }
    isPost() {
        return this.method === Method.POST;
    }
}
