import {
  AdInsertionType,
  AdMarker,
  BufferSeekingPayload,
  Capabilities,
  CoreEvents,
  LoadedPlaybackPayload,
  Media,
  Metadata,
  PausePayload,
  TimePayload,
} from "@tv4/avod-web-player-common";
import type { StreamInfoService } from "@tv4/avod-web-player-http";

import { SSAIAdEngine } from "../ads_engine/SSAIAdEngine";
import { StreamInfoLinearAdEngine } from "../ads_engine/StreamInfoLinearAdEngine";
import {
  MediaErrorPayload,
  MediaEvents,
  MediaID3Payload,
} from "../media_engine/utils/mediaConstants";
import { setAdImmunity } from "../utils/adImmunity";
import {
  BasePlayback,
  type PlaybackLoadOptions,
  type PlaybackOptions,
} from "./BasePlayback";

export type TSSAISeekHandler = (position: number) => void;
export type TSSAIAdEngineParams = {
  media: Media;
  metadata: Metadata;
  seekHandler: TSSAISeekHandler;
  gdprConsent?: string;
  adUuid: string;
  sessionId: string;
  capabilities: Capabilities;
  streamInfoService?: StreamInfoService;
};

type SSAIPlaybackOptions = PlaybackOptions & {
  engine: "ssai" | "linear";
  metadata: Metadata;
  sessionId: string;
  accessToken?: string;
  gdprConsent?: string;
  adUuid: string;
  streamInfoService?: StreamInfoService;
};

export class SSAIPlayback extends BasePlayback {
  public override readonly name = "SSAIPlayback";
  private assetId: string;
  private adEngine: SSAIAdEngine | StreamInfoLinearAdEngine;
  private streamInfoService?: StreamInfoService;

  constructor(options: SSAIPlaybackOptions) {
    super(options);

    const {
      capabilities,
      media,
      metadata,
      gdprConsent,
      adUuid,
      engine,
      sessionId,
    } = options;

    this.streamInfoService = options.streamInfoService;
    this.assetId = metadata.asset.id;
    this.mediaEngine.setAirplayAllowed(true);

    const AdEngine =
      engine === "linear" ? StreamInfoLinearAdEngine : SSAIAdEngine;

    this.adEngine = new AdEngine({
      capabilities,
      media,
      metadata,
      gdprConsent,
      seekHandler: (position: number) => this.mediaEngine.seekTo(position),
      adUuid,
      sessionId,
      streamInfoService: this.streamInfoService,
    });

    this.adEngine.onAll((type, data) => {
      this.emit(type, data);
      if (type === CoreEvents.BREAK_START) {
        this.setRestrictions({
          canSeek:
            // Skip ads only supported for live content for now
            (this.capabilities.skip_ads && this.metadata?.asset.isLive) ??
            false,
        });
      }
      if (type === CoreEvents.BREAK_END) {
        const currentTime = this.mediaEngine.getCurrentTime();
        const duration = this.mediaEngine.getDuration();
        this.setRestrictions({ canSeek: true });
        super.onPlaying(this.getTimePayload({ currentTime, duration }));

        if (this.streamInfoService) {
          const breakEnd = this.getAbsolutePosition(currentTime);
          this.streamInfoService.markWatchedAdBreak(breakEnd);
        }
      }
    });
  }

  private getStreamStartTime(startTime?: number): number | undefined {
    if (this.metadata?.asset.isLive) return startTime;

    if ((startTime && startTime > 0) || this.adEngine.hasAdImmunity()) {
      return this.adEngine.getPlayheadPositionForContentPosition(
        startTime ?? 0 // if startTime is undefined we start from the beginning if we have immunity
      );
    }

    return undefined;
  }

  public override async load({
    startTime,
    autoplay,
    useInitialAdImmunity,
  }: PlaybackLoadOptions): Promise<void> {
    if (useInitialAdImmunity) {
      setAdImmunity(this.assetId);
    }
    if (this.adEngine instanceof StreamInfoLinearAdEngine) {
      return super.load({ startTime, autoplay });
    }

    this.emit(CoreEvents.LOADING_PLAYBACK, undefined);
    const manifestUrl = await this.adEngine.createSession();

    if (this.destroyed || !manifestUrl) return;

    if (autoplay) {
      this.mediaEngine.once(MediaEvents.LOADED, this.play.bind(this));
    }

    const streamStartTime = this.getStreamStartTime(startTime);

    if (streamStartTime !== undefined) {
      this.adEngine.markAdBreaksBeforeStartTimeAsWatched(streamStartTime);
      this.loadEngine(
        {
          ...this.media,
          manifestUrl,
        },
        streamStartTime
      );
    } else {
      this.loadEngine({ ...this.media, manifestUrl });
    }
  }

  protected override onDrmLicenseExpired({
    currentTime,
    duration,
    utcCurrentTimeMs,
    error,
  }: MediaErrorPayload) {
    this.emit(CoreEvents.DRM_LICENSE_ERROR, {
      ...this.getTimePayload({ currentTime, duration, utcCurrentTimeMs }),
      error,
    });
  }

  private getTimePayload({
    currentTime,
    duration,
    utcCurrentTimeMs,
  }: {
    currentTime: number;
    duration: number;
    utcCurrentTimeMs?: number;
  }): TimePayload {
    return {
      currentTime:
        this.adEngine.getContentPositionForPlayheadPosition(currentTime),
      duration: this.adEngine.getContentPositionForPlayheadPosition(duration),
      utcCurrentTimeMs,
      isInAdBreak:
        this.adEngine.isInAdBreak() &&
        this.adEngine.insertionType !== AdInsertionType.Linear,
    };
  }

  protected override applyPreSeek() {
    if (!this.restrictions.canSeek) return;
    const contentPosition = this.adEngine.getContentPositionForPlayheadPosition(
      this.getCurrentTime()
    );
    const streamPosition = this.adEngine.getPlayheadPositionForContentPosition(
      contentPosition + this.preSeekAmount
    );
    this.handleSeek(streamPosition);
    this.preSeekAmount = 0;
  }

  protected override seekTo(absolutePositionOrContentPosition: number) {
    if (!this.restrictions.canSeek) return;

    const switchedPlaybackMode = this.evaluatePositionAndSwitchPlaybackMode(
      this.getStreamPosition(absolutePositionOrContentPosition)
    );
    if (switchedPlaybackMode) {
      return;
    }

    const contentPosition = this.getStreamPosition(
      absolutePositionOrContentPosition
    );

    const streamPosition =
      this.adEngine.getPlayheadPositionForContentPosition(contentPosition);

    this.handleSeek(streamPosition);
  }

  protected override emitLoadedPlayback(
    payload: Partial<LoadedPlaybackPayload>
  ): void {
    super.emitLoadedPlayback(payload);
    // For live we should not emit as they will be empty and overrides the streaminfo breaks
    if (!this.metadata?.asset.isLive) {
      const adMarkers: AdMarker[] = this.adEngine.getVodAdMarkers();
      super.emit(CoreEvents.AD_MARKERS_UPDATED, { adMarkers });
    }
  }

  protected override onLoaded(payload: TimePayload): void {
    super.onLoaded(this.getTimePayload(payload));
  }

  protected override onTimeUpdate(payload: TimePayload): void {
    if (this.adEngine.handleTimeUpdate(payload) === false) {
      super.onTimeUpdate(this.getTimePayload(payload));
    }
  }

  protected override onPaused(payload: PausePayload): void {
    if (this.adEngine.handlePaused(payload) === false) {
      super.onPaused({
        ...payload,
        ...this.getTimePayload(payload),
      });
    }
  }

  protected override onPlaying(payload: TimePayload): void {
    if (this.adEngine.handlePlaying(payload) === false) {
      super.onPlaying(this.getTimePayload(payload));
    }
  }

  protected override onSeeking(payload: BufferSeekingPayload): void {
    if (this.adEngine.handleSeeking(payload) === false) {
      super.onSeeking({
        ...this.getTimePayload(payload),
        playing: payload.playing,
      });
    }
  }

  protected override onSeeked(payload: BufferSeekingPayload): void {
    if (this.adEngine.handleSeeked(payload) === false) {
      super.onSeeked({
        ...this.getTimePayload(payload),
        playing: payload.playing,
      });
    }
  }

  protected override onBuffering(payload: BufferSeekingPayload): void {
    if (this.adEngine.handleBuffering(payload) === false) {
      super.onBuffering({
        ...payload,
        ...this.getTimePayload(payload),
      });
    }
  }

  protected override onBuffered(payload: BufferSeekingPayload): void {
    if (this.adEngine.handleBuffered(payload) === false) {
      super.onBuffered({
        ...payload,
        ...this.getTimePayload(payload),
      });
    }
  }

  protected override onEnded(payload: TimePayload): void {
    if (this.adEngine.handleEnded(payload) === false) {
      super.onEnded(payload);
    }
  }

  protected override onID3(payload: MediaID3Payload): void {
    this.adEngine.handleID3(payload);
  }

  protected override onError(payload: MediaErrorPayload) {
    if (
      this.adEngine.isInAdBreak() &&
      this.adEngine.insertionType !== AdInsertionType.Linear
    ) {
      this.emit(CoreEvents.AD_ERROR, { error: payload.error });
    }
    super.onError(payload);
  }

  public override destroy() {
    this.adEngine.destroy();
    super.destroy();
    this.destroyed = true;
  }
}
