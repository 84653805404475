// HTMLVideoEngine with additional logic specifically for playing csai mp4 ad files

import { Media, storage, XHR } from "@tv4/avod-web-player-common";
import { browserSupportsDownlink } from "@tv4/avod-web-player-device-capabilities";

import { EngineOptions, HtmlVideoEngine } from "./HtmlVideoEngine";

type TBitrates = Array<number>;

type TEstimation = [
  number, // timestamp of estimation
  number, // estimated bitrate
];

type TEstimations = Array<TEstimation>;

const ESTIMATED_BITRATE_KEY = "ESTADBTR";

const ESTIMATION_WINDOW = 1;

// estimations older than 5s are considered stale and discarded
const ESTIMATION_VALIDITY = 5000;

export class AdVideoEngine extends HtmlVideoEngine {
  constructor(options: EngineOptions) {
    super({
      ...options,
      useMediaEventFilterMp4Mode: true,
    });
  }
  public override get estimatedBitrate(): number {
    const bitrates: TBitrates = this.estimationData.map(
      ([, bitrate]) => bitrate
    );

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/downlink
     * only supported on chrome, and reportedly capped to 10 Mbps, but ads seems to
     * be around 6000 Kbps at most, so this should not limit the ad quality that
     * can play.
     * this may also not be accurate, but is used if available and will provide some
     * indication without doing a manual calculation when there is no estimate.
     */
    if (browserSupportsDownlink(navigator)) {
      bitrates.push(navigator.connection.downlink * 1000);
    }

    // average of available values
    return (
      (bitrates.reduce((added, bitrate) => added + bitrate, 0) /
        bitrates.length) *
      0.8 // 80% of the bandwidth, to account for overhead and fluctuations
    );
    // if there are no bitrates then return value will end up NaN, indicating there is no valid estimate
  }

  public override set estimatedBitrate(value: number) {
    if (value) {
      this.estimationData = [...this.estimationData, [Date.now(), value]];
    }
  }

  private getValidEstimations(estimations: TEstimations): TEstimations {
    const now = Date.now();
    return estimations.filter(([time]) => now - time <= ESTIMATION_VALIDITY);
  }

  private get estimationData(): TEstimations {
    return this.getValidEstimations(
      storage.getSessionData<TEstimations | undefined>(ESTIMATED_BITRATE_KEY) ||
        []
    );
  }

  private set estimationData(estimations: TEstimations) {
    storage.setSessionData(
      ESTIMATED_BITRATE_KEY,
      this.getValidEstimations(estimations)
    );
  }

  private cancelAwaitEstimationWindow: (() => void) | undefined;

  private async awaitEstimationWindow(): Promise<void> {
    await new Promise<void>((resolve) => {
      if (this.destroyed) return;

      const timeUpdate = () => {
        if (
          this.videoElement.duration - this.videoElement.currentTime <=
          ESTIMATION_WINDOW
        ) {
          this.cancelAwaitEstimationWindow?.();
          resolve();
        }
      };

      this.addVideoListener("timeupdate", timeUpdate);

      this.cancelAwaitEstimationWindow = (): void => {
        this.removeVideoListener("timeupdate", timeUpdate);
        this.cancelAwaitEstimationWindow = undefined;
      };
    });
  }

  private abortEstimateBandwidth: (() => void) | undefined;

  private async estimateBandwidth(file: string): Promise<void> {
    return new Promise((resolve, reject) => {
      let estimated: boolean = false;
      let updates: number = 0;

      const completeEstimation = (): void => {
        if (xhr.estimatedBandwidth && !estimated) {
          this.estimatedBitrate = xhr.estimatedBandwidth;
          estimated = true;
          resolve();
        }
        this.stopBandwidthEstimation();
      };

      const xhr = new XHR({
        // 80% of ESTIMATION_WINDOW
        timeout: Math.floor(ESTIMATION_WINDOW * 800),
        // append query string to circumvent cache
        get:
          file +
          ((/\?/.test(file) ? "&" : "?") +
            String(Math.floor(Date.now() / 1000))),
      });

      const handleProgress = () => {
        updates++;

        if (updates >= 2 && xhr.estimatedBandwidth) {
          completeEstimation();
        }
      };

      this.abortEstimateBandwidth = (): void => {
        reject();
        xhr.abort();
        xhr.removeEventListener("progress", handleProgress);
        this.abortEstimateBandwidth = undefined;
        xhr.destroy();
      };

      xhr.addEventListener("progress", handleProgress);

      xhr.send().then(completeEstimation, completeEstimation);
    });
  }

  private async startBandwidthEstimation(): Promise<void> {
    this.stopBandwidthEstimation();

    /**
     * there is no reliable event or method to know if the current video is loaded from server or if it is cached and loaded from local storage.
     * trying to estimate bandwidth when video is cached will result in very high bandwidth estimation, but still may not be an unrealistic
     * value so cannot know if speed is from server or from local storage.
     * caching is expected to work correctly for user server interaction, so video playback will use original url that may or may not be cached.
     * bandwidth estimation is instead done by using the video url with a query string to circumvent cache and loading this manually but only a
     * small chunk of the video is loaded to get an estimation.
     */

    /**
     * progress events are not reliable so there is no way to accurately know when main video has loaded completely.
     * ESTIMATION_WINDOW is used for the estimation and is small enough that main video should be expected to have loaded completely.
     */
    await this.awaitEstimationWindow();

    this.estimateBandwidth(this.videoElement.src).catch(() => {
      // failed to estimate bitrate
    });
  }

  private stopBandwidthEstimation(): void {
    this.abortEstimateBandwidth?.();
    this.cancelAwaitEstimationWindow?.();
  }

  public async getEstimatedBitrate(url: string): Promise<number | undefined> {
    const bitrate = this.estimatedBitrate;

    // no bitrate estimate available
    if (Number.isNaN(bitrate)) {
      await this.estimateBandwidth(url).catch(() => {
        // failed to estimate bitrate
      });
      return this.estimatedBitrate;
    } else {
      return bitrate;
    }
  }

  public override load(
    media: Media,
    _startTime: number,
    estimateBandwidth: boolean = false
  ): ReturnType<HtmlVideoEngine["load"]> {
    if (estimateBandwidth !== false) {
      this.startBandwidthEstimation();
    }
    return super.load(media);
  }

  public override destroy(): ReturnType<HtmlVideoEngine["destroy"]> {
    this.stopBandwidthEstimation();
    this.destroyed = true;
    return super.destroy();
  }
}
