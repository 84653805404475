import type { NonEmptyArray } from "@tv4/avod-web-player-common";
import merge from "deepmerge";
import type { CSSObject } from "styled-components";

import { breakpoints } from "../theme/breakpoints";

export const combineResponsiveStyles = (styleObjects: CSSObject[]) => {
  return merge.all<CSSObject>(styleObjects);
};

export const withBreakpoints = <T>(
  applyStyles: (val: T) => CSSObject,
  values: T | NonEmptyArray<T>
) => {
  if (!Array.isArray(values)) return applyStyles(values);

  const defaultStyles = applyStyles(values[0]);
  const responsiveValues = values.slice(1);

  return responsiveValues.reduce<CSSObject>((styles: CSSObject, val, i) => {
    return {
      ...styles,
      [`@media screen and (min-width: ${breakpoints[i]})`]: applyStyles(val),
    };
  }, defaultStyles);
};
