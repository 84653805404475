// ExpirationManager.ts
export default class ExpirationManager {
    constructor(timeoutMs, maxDurationMs, coreStorage) {
        this.timeoutMs = timeoutMs;
        this.maxDurationMs = maxDurationMs;
        this.coreStorage = coreStorage;
        this.expired = false;
        if (coreStorage) {
            try {
                const storedFirstRequestTime = coreStorage.getLocal('firstRequestTime');
                this.firstRequestTime = storedFirstRequestTime ? Number(storedFirstRequestTime) : undefined;
                const storedLastRequestTime = coreStorage.getLocal('lastRequestTime');
                this.lastRequestTime = storedLastRequestTime ? Number(storedLastRequestTime) : undefined;
            }
            catch (e) { }
        }
    }
    /**
     * Called when a request is sent. Sets the "firstRequestTime" if not already set,
     * and updates "lastRequestTime" to now.
     */
    onRequestSent() {
        const currentTime = Date.now();
        if (this.firstRequestTime === undefined) {
            this.firstRequestTime = currentTime;
        }
        this.lastRequestTime = currentTime;
        if (this.coreStorage) {
            if (this.firstRequestTime !== undefined) {
                this.coreStorage.setLocal('firstRequestTime', this.firstRequestTime.toString());
            }
            if (this.lastRequestTime !== undefined) {
                this.coreStorage.setLocal('lastRequestTime', this.lastRequestTime.toString());
            }
        }
    }
    reset() {
        if (this.coreStorage) {
            this.coreStorage.removeLocal('firstRequestTime');
            this.coreStorage.removeLocal('lastRequestTime');
        }
        this.firstRequestTime = undefined;
        this.lastRequestTime = undefined;
        this.expired = false;
    }
    /**
     * Checks whether the session should be marked as expired, either because
     * the elapsed time since last request exceeds timeoutMs, or because the total
     * duration since first request exceeds maxDurationMs.
     *
     * @param currentExpirationTimeoutMs If provided and different from the current
     *                                   timeoutMs, update the internal timeoutMs.
     */
    checkExpiration(currentExpirationTimeoutMs) {
        // Update timeout if a new value was provided
        if (currentExpirationTimeoutMs !== undefined && currentExpirationTimeoutMs !== this.timeoutMs) {
            this.timeoutMs = currentExpirationTimeoutMs;
        }
        const currentTime = Date.now();
        let lastTime = this.lastRequestTime;
        let firstTime = this.firstRequestTime;
        if (this.coreStorage) {
            try {
                const storedLastRequestTime = this.coreStorage.getLocal('lastRequestTime');
                lastTime = storedLastRequestTime ? Number(storedLastRequestTime) : lastTime;
                const storedFirstRequestTime = this.coreStorage.getLocal('firstRequestTime');
                firstTime = storedFirstRequestTime ? Number(storedFirstRequestTime) : firstTime;
            }
            catch (e) { }
        }
        const timedOut = lastTime !== undefined && currentTime - lastTime > this.timeoutMs;
        const maxedOut = firstTime !== undefined && currentTime - firstTime > this.maxDurationMs;
        if ((timedOut || maxedOut) && !this.expired) {
            this.expired = true;
        }
    }
    /**
     * Returns whether the session or view has expired. This also implicitly
     * calls checkExpiration() once more to ensure it’s up to date.
     */
    isExpired() {
        this.checkExpiration();
        return this.expired;
    }
    /**
     * Session/View was started
     */
    isStarted() {
        return (this.firstRequestTime !== undefined && this.firstRequestTime > 0);
    }
    /**
     * Check if is Active Session
     */
    isActive() {
        if (this.firstRequestTime !== undefined && this.firstRequestTime > 0) {
            this.checkExpiration();
            return !this.expired;
        }
        return false;
    }
}
