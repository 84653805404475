import type { NonEmptyArray } from "@tv4/avod-web-player-common";
import styled from "styled-components";

import { type Color, colors } from "../theme/colors";
import { type FontSize, fontSizes } from "../theme/font-sizes";
import { fontWeights } from "../theme/font-weights";
import { lineHeights } from "../theme/line-heights";
import { withBreakpoints } from "../util/responsive-styles";

type Variant =
  | "title1"
  | "title2"
  | "title3"
  | "body1"
  | "body1Strong"
  | "body2"
  | "body2Strong"
  | "body3"
  | "body3Strong"
  | "body4"
  | "CTA"
  | "label"
  | "meta"
  | "cardDetail"
  | "category"
  | "tab";

type TextConfig = {
  fontSize: FontSize | NonEmptyArray<FontSize>;
  lineHeight?: number;
  fontWeight?: number;
};

const body1: TextConfig = {
  fontSize: [3, 4, 5],
  lineHeight: lineHeights.body,
};

const body2: TextConfig = {
  fontSize: [2, 3, 4],
  lineHeight: lineHeights.body,
};

const body3: TextConfig = {
  fontSize: [1, 2, 3],
  lineHeight: lineHeights.body,
};

const variants: Record<Variant, TextConfig> = {
  title1: {
    fontSize: [7, 8, 9],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.heading,
  },
  title2: {
    fontSize: [6, 7, 8],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.heading,
  },
  title3: {
    ...body1,
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.heading,
  },
  body1,
  body1Strong: {
    ...body1,
    fontWeight: fontWeights.medium,
  },
  body2,
  body2Strong: {
    ...body2,
    fontWeight: fontWeights.medium,
  },
  body3,
  body3Strong: {
    ...body3,
    fontWeight: fontWeights.medium,
  },
  body4: {
    fontSize: [0, 1, 2],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.body,
  },
  CTA: {
    fontSize: 2,
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.medium,
  },
  label: {
    fontSize: 0,
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.small,
  },
  meta: {
    fontSize: [1, 2, 3],
    lineHeight: lineHeights.body,
  },
  cardDetail: {
    fontSize: [1, 1, 2],
    lineHeight: lineHeights.medium,
  },
  category: {
    fontSize: [2, 3, 7],
    fontWeight: fontWeights.medium,
  },
  tab: {
    fontSize: [2, 3],
    fontWeight: fontWeights.regular,
  },
};

type TextProps = {
  variant: Variant;
  fontSize?: FontSize | NonEmptyArray<FontSize>;
  color?: Color;
};

export const TextComponent = styled.div<TextProps>(
  ({ variant, color, fontSize }) => {
    const { fontSize: variantFontSize, ...otherVariantProps } =
      variants[variant];

    const actualFontSize = fontSize ?? variantFontSize;

    return {
      ...otherVariantProps,
      ...withBreakpoints(
        (value) => ({
          fontSize: fontSizes[value],
        }),
        actualFontSize
      ),
      ...(color ? { color: colors[color] } : {}),
    };
  }
);
