/**
 * List of npaw services.
 *
 * @enum
 */
var Service = /** @type {const} */ ({
  DATA: '/data',
  ADAPTERS: '/adapters',
  CONFIGURATION: '/configuration',

  // Plugin Logs
  APP_ANALYTICS_PLUGIN_LOGS: '/infinity/session/pluginLogs',
  SESSION_PLUGIN_LOGS: '/infinity/session/pluginLogs',
  VIDEO_PLUGIN_LOGS: '/infinity/video/pluginLogs',

  // Video
  INIT: '/init',
  START: '/start',
  JOIN: '/joinTime',
  DATA_OBJECT: '/data',
  PAUSE: '/pause',
  RESUME: '/resume',
  SEEK: '/seek',
  BUFFER: '/bufferUnderrun',
  ERROR: '/error',
  STOP: '/stop',
  PING: '/ping',
  CDN: '/cdn',

  VIDEO_EVENT: '/infinity/video/event',

  // Ads
  AD_INIT: '/adInit',
  AD_START: '/adStart',
  AD_JOIN: '/adJoin',
  AD_PAUSE: '/adPause',
  AD_RESUME: '/adResume',
  AD_BUFFER: '/adBufferUnderrun',
  AD_STOP: '/adStop',
  AD_CLICK: '/adClick',
  AD_ERROR: '/adError',
  AD_MANIFEST: '/adManifest',
  AD_POD_START: '/adBreakStart',
  AD_POD_STOP: '/adBreakStop',
  AD_QUARTILE: '/adQuartile',

  // Session
  EVENT: '/infinity/session/event',
  SESSION_START: '/infinity/session/start',
  SESSION_STOP: '/infinity/session/stop',
  NAV: '/infinity/session/nav',
  BEAT: '/infinity/session/beat',

  // Offline
  OFFLINE_EVENTS: '/offlineEvents'
});

module.exports = Service;
