import { CoreEvents } from "@tv4/avod-web-player-common";
import type { Core } from "@tv4/avod-web-player-core";
import { useEffect } from "react";

export default function useSkinVisibilityChangeEvent(
  core: Core,
  visible: boolean
): void {
  useEffect(() => {
    core.emitCoreEvent(CoreEvents.SKIN_VISIBILITY_CHANGE, {
      visible,
    });
  }, [core, visible]);
}
