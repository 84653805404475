import { Ad, VASTParser, VASTResponse } from "@dailymotion/vast-client";
import VMAPParser from "@dailymotion/vmap";
import {
  AdServerOptions,
  AWPError,
  ERROR_CATEGORY,
  ErrorLevel,
  getFreeWheelConfig,
  getMRMDataProvider,
  getMRMSiteSectionId,
  IAdBreak,
  IPauseAd,
  isMtvNews247Id,
  parsePauseAds,
  PLAYER_ERROR,
  queryStringFromObject,
  Service,
} from "@tv4/avod-web-player-common";

import { mapAdBreakObject } from "./adBreakMapper";

type VastParserOptions = {
  isRootVAST: boolean;
  timeout: number;
  withCredentials: boolean;
  wrapperLimit: number;
  followAdditionalWrappers: boolean;
};

function randomNumber(): string {
  return Math.floor(Math.random() * 10000000000).toString();
}

type Parameters = Record<string, string>;

export class FreeWheelAdServer {
  private baseUrl: string;
  private pauseAdSlot: string;
  private customParameters: Record<string, Parameters> | undefined;
  private service: Service;
  private mrmNetworkId: string;
  private profileDevice: string;
  private sectionDevice: string;
  private adServerOptions?: AdServerOptions;
  private vastParser: VASTParser;
  private pid!: string; // ! because value is set in function called from constructor
  private persistentID: string;
  private wireSessionId: string = "";
  private gdprConsent?: string;
  private currentContentId?: string;
  private isLive: boolean = false;
  private destroyed = false;

  private pauseAds: IPauseAd[] = [];
  private pauseAdsLoadPromise?: Promise<void>;

  private fwPVRandom = randomNumber();
  private fwVPRandom = randomNumber();

  constructor({
    service,
    userId,
    profileDevice,
    sectionDevice,
    persistentID,
    gdprConsent,
    customParameters,
  }: {
    service: Service;
    userId?: string;
    profileDevice: string;
    sectionDevice: string;
    persistentID: string;
    gdprConsent?: string;
    customParameters?: {
      globalParameters?: Parameters;
      keyValues?: Parameters;
    };
  }) {
    const config = getFreeWheelConfig(service);
    this.vastParser = new VASTParser();
    this.persistentID = persistentID;
    this.setUserId(userId); // will set initial pid value
    this.gdprConsent = gdprConsent;
    this.service = service;
    this.mrmNetworkId = config.networkId;
    this.profileDevice = profileDevice;
    this.sectionDevice = sectionDevice;
    this.baseUrl = config.baseUrl;
    this.pauseAdSlot = config.pauseAdSlot;
    this.customParameters = customParameters;
  }

  public setUserId(userId?: string) {
    this.pid = userId || this.persistentID;
  }

  public async load({
    adServerOptions,
    isLive,
    wireSessionId,
  }: {
    adServerOptions?: AdServerOptions;
    isLive?: boolean;
    wireSessionId: string;
  }) {
    this.adServerOptions = adServerOptions;
    this.isLive = isLive === true;
    this.wireSessionId = wireSessionId || "";
    // any pending pause ads load may no longer be valid when adServerOptions change
    this.pauseAdsLoadPromise = void 0;
    // reset pause ads
    this.pauseAds = [];
    // reset video player random when content changes
    if (this.currentContentId !== this.adServerOptions?.contentId) {
      this.fwVPRandom = randomNumber();
    }
    this.currentContentId = this.adServerOptions?.contentId;
    // start loading pause ads in the background
    void this.loadPauseAds();
  }

  public async getPauseAd(): Promise<IPauseAd | undefined> {
    let pauseAd = this.pauseAds.shift();

    // start loading more ads in the background if needed
    void this.loadPauseAds();

    // if a pause ad is not available then wait until loading has completed
    if (!pauseAd) {
      await this.pauseAdsLoadPromise;
      pauseAd = this.pauseAds.shift();
    }

    return pauseAd;
  }

  private getMRMSiteSectionTag(): string {
    const liveOrVod = this.isLive ? "live" : "vod";
    return `ondomain_${getMRMSiteSectionId(this.service)}_${
      this.sectionDevice
    }_${liveOrVod}`;
  }

  private getGlobalParams(isPauseAd: boolean): Parameters {
    return {
      prof: `${this.mrmNetworkId}:${this.profileDevice}`,
      nw: this.mrmNetworkId,
      flag: isPauseAd
        ? "+sync+play"
        : "+sltp+amcb+nucr+dtrd+scpv+emcr+play+vicb+slcb",
      resp: isPauseAd ? "vast4" : "vmap1+vast4",

      ...(this.adServerOptions
        ? {
            caid: this.adServerOptions.contentId,
            csid: this.getMRMSiteSectionTag(),
            mode: this.isLive ? "live" : "on-demand",
          }
        : {}),

      vprn: this.fwVPRandom,
      pvrn: this.fwPVRandom,
      metr: "7",
      ...this.customParameters?.globalParameters,
    };
  }

  private getKeyValues(): Parameters {
    const subscriptionTag = this.adServerOptions?.tags
      .split(",")
      .find((value) => /^sub_/.test(value));

    return {
      _fw_vcid2: this.pid,
      wire_session_id: this.wireSessionId,
      _fw_continuous_play: "1",
      _fw_player_width: "1920",
      _fw_player_height: "1080",

      ...(this.gdprConsent
        ? {
            _fw_gdpr: "1",
            _fw_gdpr_consent: this.gdprConsent,
          }
        : {}),

      ...(subscriptionTag
        ? {
            _fw_am_fed_segs: `${getMRMDataProvider(this.service)}:sub=${subscriptionTag}`,
          }
        : {}),
      ...this.customParameters?.keyValues,
    };
  }

  private getAdURLExtraParams(): Parameters {
    const isMtvNews247 = isMtvNews247Id(this.adServerOptions?.contentId);

    return {
      ...(isMtvNews247
        ? {
            slid: "pre",
            maxd: "60",
            ptgt: "a",
            slau: "MTV FI_Preroll",
            maxa: "2",
          }
        : {}),
    };
  }

  private constructUrl(
    globalParams: Parameters,
    keyValues: Parameters,
    parameters?: Parameters
  ): string {
    /*
      URL STRUCTURE
      http://[environment].v.fwmrm.net/ad/g/1?[globalParams];[keyValues];[ParamsForSlot1];[ParamsForSlot2];...;[ParamsForSlotN];
    */

    let url = `${this.baseUrl}?${queryStringFromObject(
      globalParams
    )};${queryStringFromObject(keyValues)}`;

    if (parameters) {
      url += `;${queryStringFromObject(parameters)}`;
    }

    return url;
  }

  private getPauseAdUrl(): string {
    return this.constructUrl(this.getGlobalParams(true), this.getKeyValues(), {
      ptgt: "a",
      slau: this.pauseAdSlot,
      maxa: "1",
    });
  }

  private async loadPauseAds(): Promise<void> {
    // only load more pause ads when there are none available and not already loading
    if (
      this.pauseAds.length === 0 &&
      this.adServerOptions &&
      !this.pauseAdsLoadPromise
    ) {
      const pending = this.getPauseAds()
        .then((pauseAds) => {
          if (this.destroyed) return;

          // if pauseAdsLoadPromise have changed during asynchronous operation then result may not be valid
          if (pending === this.pauseAdsLoadPromise) {
            this.pauseAds = pauseAds || [];
          }
        })
        .finally(() => {
          if (this.destroyed) return;

          if (pending === this.pauseAdsLoadPromise) {
            this.pauseAdsLoadPromise = void 0;
          }
        });

      if (this.destroyed) return;

      this.pauseAdsLoadPromise = pending;
    }

    if (this.destroyed) return;

    await this.pauseAdsLoadPromise;
  }

  private async getPauseAds(): Promise<IPauseAd[]> {
    const url = this.getPauseAdUrl();

    try {
      const vastObject: VASTResponse =
        await this.vastParser.getAndParseVAST(url);

      if (this.destroyed) return [];

      const ads: Ad[] = vastObject.ads;
      return parsePauseAds(ads);
    } catch (error) {
      if (this.destroyed) return [];

      if (this.isVastParserAdBlockError(error)) {
        throw new AWPError({
          context: "http",
          message: "AdBlocker detected",
          category: ERROR_CATEGORY.ADS,
          code: PLAYER_ERROR.AD_BLOCKER,
          errorLevel: ErrorLevel.USER,
          raw: error as Error,
        });
      }
      return [];
    }
  }

  private isVastParserAdBlockError(error: Error | any): boolean {
    return error instanceof Error && error.message === "XHRURLHandler:  (0)";
  }

  public async getLinearAds(): Promise<[IAdBreak[] | null, AWPError | null]> {
    const url = this.getAdURL();

    try {
      const vmapObject = await this.fetchAdsVMAP(url);

      if (this.destroyed) return [[], null];

      const adBreaks = await this.resolveAds(vmapObject);

      if (this.destroyed) return [[], null];

      return [adBreaks, null];
    } catch (e: unknown) {
      if (this.destroyed) return [[], null];

      return [
        null,
        new AWPError({
          context: "http",
          message:
            e instanceof Error
              ? e.message
              : "Unknown error in freewheel ad server",
          category: ERROR_CATEGORY.ADS,
          code: PLAYER_ERROR.AD_BLOCKER,
          errorLevel: ErrorLevel.USER,
          raw: e,
        }),
      ];
    }
  }

  private getAdURL(): string {
    return this.constructUrl(
      this.getGlobalParams(false),
      this.getKeyValues(),
      this.getAdURLExtraParams()
    );
  }

  private async fetchAdsVMAP(u: string): Promise<Record<string, any>> {
    const response = await fetch(u, {
      // this is required to support tearsheets, freewheel sets a cookie that when is sent to the
      // vmap endpoint will return only a particular set of ads.
      credentials: "include",
    });
    const xml = await response.text();
    const xmlParser = new DOMParser();
    const xmlDoc = xmlParser.parseFromString(xml, "text/xml");
    const vmapObject = new VMAPParser(xmlDoc);
    return vmapObject;
  }

  private async resolveAds(
    vmapObject: Record<string, any>
  ): Promise<IAdBreak[]> {
    const options = this.getVastParserOptions();
    this.vastParser.initParsingStatus(options);

    const unsortedBreaks = await this.parseAdBreaks(
      vmapObject.adBreaks,
      options
    );

    if (this.destroyed) return [];

    return unsortedBreaks.sort((a, b) => a.timeOffset - b.timeOffset);
  }

  private async parseAdBreaks(
    adBreaks: any,
    options: VastParserOptions
  ): Promise<IAdBreak[]> {
    const adBreakPromises = adBreaks.map(async (adBreak) => {
      if (!this.hasValidBreaktype(adBreak.breakType)) return adBreak;
      const adsInBreak = adBreak.adSource?.vastAdData;
      if (!adsInBreak) return mapAdBreakObject(adBreak, []);

      const adBreakAds = await this.vastParser.parseVAST(
        { documentElement: adsInBreak },
        options
      );

      if (this.destroyed) return;

      return mapAdBreakObject(adBreak, adBreakAds.ads);
    });

    if (this.destroyed) return Promise.resolve([]);

    return Promise.all(adBreakPromises);
  }

  private getVastParserOptions(): VastParserOptions {
    return {
      isRootVAST: true,
      timeout: 10 * 1000,
      withCredentials: true,
      wrapperLimit: 10,
      followAdditionalWrappers: true,
    };
  }

  private hasValidBreaktype(adBreakType: string): boolean {
    const breakTypes = adBreakType.split(",");
    return breakTypes.includes("linear");
  }

  public destroy() {
    this.destroyed = true;
  }
}
