import {
  AdTrackingEvent,
  CoreEvents,
  CoreEventsMap,
  EventEmitter,
  getRemoteConfigValue,
  getTranslationsForLocale,
  IPauseAd,
  Locale,
  localeFromService,
  Service,
} from "@tv4/avod-web-player-common";
import { isSupportedOS } from "@tv4/avod-web-player-device-capabilities";
import { FreeWheelAdServer } from "@tv4/avod-web-player-http";

import { createElement } from "../utils/html";
import { createArrowIcon } from "./util/iconBuilder";

export interface IPauseAdEngineInitOptions {
  root: HTMLElement;
  adServerInstance: FreeWheelAdServer;
}

export class PauseAdEngine extends EventEmitter<CoreEventsMap> {
  private container: HTMLDivElement;
  private pauseImage: HTMLImageElement;
  private visitAdvertiserLink?: HTMLButtonElement;

  private adServerInstance: FreeWheelAdServer;

  private currentAd?: IPauseAd;
  private clickListener?: (e: Event) => void;

  private contentPlaying: "ad" | "content" = "content";
  private autoplayBlocked = false;
  private active = false;
  private destroyed = false;

  constructor({ root, adServerInstance }: IPauseAdEngineInitOptions) {
    super();
    const service = getRemoteConfigValue("SERVICE") || Service.DEFAULT;
    const locale: Locale = localeFromService(service);
    const translations = getTranslationsForLocale(locale);
    this.container = createElement({
      type: "div",
      parent: root,
      classes: ["avod-web-player-pause"],
    });
    this.container.style.display = "none";

    this.adServerInstance = adServerInstance;

    this.pauseImage = createElement({
      type: "img",
      parent: this.container,
      classes: ["pause-image"],
    });

    const visitParent = document.getElementById("skin-container");

    if (visitParent) {
      this.visitAdvertiserLink = createElement({
        type: "button",
        // @TODO PauseAdEngine should not expect arbitrary skin dom to be rendered and manually attach elements.
        parent: visitParent,
        classes: [
          "visit-advertiser-link",
          ...(!isSupportedOS() ? ["mobile"] : []),
        ],
      });
    }

    if (this.visitAdvertiserLink) {
      this.visitAdvertiserLink.innerHTML = translations(
        "ad_break_overlay__visit_advertiser"
      ) as string;
    }

    this.setupResizeListener(root);

    const arrowIcon = createArrowIcon();
    this.visitAdvertiserLink?.appendChild(arrowIcon);

    this.setupCloseAdListener();
  }

  public async onEvent<T extends keyof CoreEventsMap>(
    event: T,
    data: CoreEventsMap[T]
  ): Promise<void> {
    switch (event) {
      case CoreEvents.PAUSED:
        {
          const payload = data as CoreEventsMap[CoreEvents.PAUSED];
          if (
            this.contentPlaying === "ad" ||
            this.autoplayBlocked ||
            payload.programmatic
          )
            return;
          // active while ad is loading, and will stay active until hide is called
          this.active = true;
          const ad = await this.adServerInstance.getPauseAd();

          if (this.destroyed) return;

          if (ad && this.active) {
            this.show(ad);
          } else {
            if (this.active) {
              console.log(
                "[Pause Ad]",
                new Date().toLocaleTimeString(),
                "Paused, but no pause ad available"
              );
            }
            this.hide();
          }
        }
        break;
      case CoreEvents.RESUME:
      case CoreEvents.START:
        this.hide();
        break;
      case CoreEvents.BREAK_START:
        this.contentPlaying = "ad";
        break;
      case CoreEvents.BREAK_END:
        this.contentPlaying = "content";
        break;
      case CoreEvents.AUTOPLAY_BLOCKED:
        this.autoplayBlocked = true;
        break;
      case CoreEvents.SUCCESSFUL_PLAY:
        this.autoplayBlocked = false;
    }
  }

  private show(ad: IPauseAd): void {
    if (!ad) return;
    this.currentAd = ad;
    this.pauseImage.src = ad.imageSource;
    this.setAdvertiserInterfaceVisibility(true);
    this.emit(CoreEvents.PAUSE_AD_SHOWN, undefined);
    this.trackEvent(AdTrackingEvent.IMPRESSION, ad);

    if (ad.clickthroughUrl) {
      this.setupClickThroughListener(ad);
    } else {
      this.hideVisitAdvertiserLink();
    }
  }

  private hideVisitAdvertiserLink() {
    if (!this.visitAdvertiserLink) return;

    this.visitAdvertiserLink.style.visibility = "hidden";
  }

  private hide(): void {
    this.setAdvertiserInterfaceVisibility(false);
    this.removeVisitAdvertiserListener();

    if (this.currentAd) {
      this.emit(CoreEvents.PAUSE_AD_HIDDEN, undefined);
      this.trackEvent(AdTrackingEvent.CLOSE, this.currentAd);
    }
    this.active = false;
    this.currentAd = undefined;
  }

  private setContainerVisibility(visible: boolean) {
    this.container.style.display = visible ? "block" : "none";
  }

  private setButtonVisibility(visible: boolean) {
    if (!this.visitAdvertiserLink) return;

    this.visitAdvertiserLink.style.visibility = visible ? "visible" : "hidden";
  }

  private setAdvertiserInterfaceVisibility(visible: boolean) {
    this.setContainerVisibility(visible);
    this.setButtonVisibility(visible);
  }

  private removeVisitAdvertiserListener() {
    if (!this.visitAdvertiserLink) return;

    if (this.clickListener) {
      this.visitAdvertiserLink.removeEventListener("click", this.clickListener);
    }
    this.clickListener = undefined;
  }

  private setupResizeListener(root: HTMLElement) {
    window.addEventListener("resize", () =>
      this.toggleMobileClassOnVisitAdvertiserLink(root)
    );
    this.toggleMobileClassOnVisitAdvertiserLink(root);
  }

  private toggleClassAtSize(
    className: string,
    size: number,
    currentSize: number
  ): void {
    if (!this.visitAdvertiserLink) return;

    if (currentSize < size) {
      this.visitAdvertiserLink.classList.add(className);
    } else {
      this.visitAdvertiserLink.classList.remove(className);
    }
  }

  private toggleMobileClassOnVisitAdvertiserLink(root: HTMLElement) {
    const rootWidth = root.getBoundingClientRect().width;

    this.toggleClassAtSize("smallest", 320, rootWidth);

    if (isSupportedOS()) {
      this.toggleClassAtSize("desktop-small", 768, rootWidth);
    }
  }

  private setupCloseAdListener() {
    const closeButton = document.getElementById(
      "close-pause-ad-button"
    ) as HTMLButtonElement;
    closeButton?.addEventListener("pointerdown", () => this.hide());
  }

  private setupClickThroughListener(ad: IPauseAd): void {
    if (!this.visitAdvertiserLink) return;

    this.visitAdvertiserLink.addEventListener(
      "click",
      (this.clickListener = (e) => {
        e.stopPropagation();
        if (!ad.clickthroughUrl) return;
        window.open(ad.clickthroughUrl, "_blank");
        this.trackEvent(AdTrackingEvent.CLICK_THROUGH, ad);
      })
    );
  }

  private trackEvent(event: AdTrackingEvent, ad: IPauseAd): void {
    if (!ad || !event) return;
    let trackingUrls: string[] = [];
    switch (event) {
      case AdTrackingEvent.IMPRESSION:
        trackingUrls = ad.impressionUrls;
        break;
      case AdTrackingEvent.CLICK_THROUGH:
        if (
          ad.clickthroughUrl?.includes("e=20") ||
          ad.clickthroughUrl?.includes("cn=defaultClick")
        ) {
          trackingUrls.push(ad.clickthroughUrl);
        }
        ad.clickthroughTrackingUrls.forEach((url) => {
          trackingUrls.push(url);
        });
        break;
      case AdTrackingEvent.ERROR:
        trackingUrls = ad.errorTrackingUrls;
        break;
      case AdTrackingEvent.CLOSE:
        trackingUrls = ad.trackCollapseUrls;
        break;
      default:
        trackingUrls = [];
        break;
    }
    trackingUrls?.forEach(async (url: string) => {
      new Image().src = url;
    });
  }

  public override destroy() {
    this.hide();
    window.removeEventListener("resize", this.removeVisitAdvertiserListener);
    super.destroy();
    this.destroyed = true;
  }
}
