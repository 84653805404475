import {
  AdBreakPayload,
  AdClickPayload,
  AdPayload,
  CdnChangedPayload,
  DroppedFramesPayload,
  EngineSelectedPayload,
  ErrorPayload,
  FullscreenPayload,
  MediaLoadingPayload,
  OptionalTrackingPayload,
  PlaybackErrorPayload,
  PlaybackRateChangePayload,
  TimePayload,
  TrackPayload,
  VolumePayload,
} from "@tv4/avod-web-player-common";

import { ITracker, ITrackerSetupOptions } from "./trackers/tracker";

export type Tracker = ITracker;

export class TrackingManager {
  constructor(private trackers: Tracker[] = []) {
    console.debug("TrackingManager initialized");
  }

  public addTracker(tracker: Tracker) {
    this.trackers.push(tracker);
  }

  public setup(options: ITrackerSetupOptions) {
    this.trackers.forEach((tracker) => {
      tracker.setup?.(options);
    });
  }

  public onEngine(data: EngineSelectedPayload) {
    this.trackers.forEach((tracker) => {
      tracker.onEngine?.(data);
    });
  }

  /**
   * Player load section
   */
  public onLoading() {
    this.trackers.forEach((tracker) => {
      tracker.onLoading?.();
    });
  }

  public onContentMediaLoading(data: MediaLoadingPayload) {
    this.trackers.forEach((tracker) => {
      tracker.onContentMediaLoading?.(data);
    });
  }

  public onLoaded(data: TimePayload) {
    this.trackers.forEach((tracker) => {
      tracker.onLoaded?.(data);
    });
  }

  public onLoadError(data: ErrorPayload) {
    this.trackers.forEach((tracker) => {
      tracker.onLoadError?.(data);
    });
  }

  /**
   * Content section
   */

  public onStart(data: TimePayload) {
    this.trackers.forEach((tracker) => {
      tracker.onStart?.(data);
    });
  }

  public onPlay(data: TimePayload) {
    this.trackers.forEach((tracker) => {
      tracker.onPlay?.(data);
    });
  }

  public onPause(data: TimePayload) {
    this.trackers.forEach((tracker) => {
      tracker.onPause?.(data);
    });
  }

  public onResume(data: TimePayload) {
    this.trackers.forEach((tracker) => {
      tracker.onResume?.(data);
    });
  }

  public onReset() {
    this.trackers.forEach((tracker) => {
      tracker.onReset?.();
    });
  }

  public onEnded() {
    this.trackers.forEach((tracker) => {
      tracker.onEnded?.();
    });
  }

  public onError(data: PlaybackErrorPayload) {
    this.trackers.forEach((tracker) => {
      tracker.onError?.(data);
    });
  }

  public onSeek(data: TimePayload) {
    this.trackers.forEach((tracker) => {
      tracker.onSeek?.(data);
    });
  }

  public onSeeked(data: TimePayload) {
    this.trackers.forEach((tracker) => {
      tracker.onSeeked?.(data);
    });
  }

  public onBuffering(data: TimePayload) {
    this.trackers.forEach((tracker) => {
      tracker.onBuffering?.(data);
    });
  }

  public onBuffered(data: TimePayload) {
    this.trackers.forEach((tracker) => {
      tracker.onBuffered?.(data);
    });
  }

  public onTimeUpdate(data: TimePayload) {
    if (data.isInAdBreak) return;
    this.trackers.forEach((tracker) => {
      tracker.onTimeUpdate?.(data);
    });
  }

  public onTrackChanged(data: TrackPayload) {
    this.trackers.forEach((tracker) => {
      tracker.onTrackChanged?.(data);
    });
  }

  public onDroppedFrames(data: DroppedFramesPayload) {
    this.trackers.forEach((tracker) => {
      tracker.onDroppedFrames?.(data);
    });
  }

  public onCdnChanged(data: CdnChangedPayload) {
    this.trackers.forEach((tracker) => {
      tracker.onCdnChanged?.(data);
    });
  }

  /**
   * Ad Section
   */

  public onAdBreakStart({ adBreak, tracking }: AdBreakPayload): void {
    if (tracking !== false)
      this.trackers.forEach((tracker) => {
        tracker.onAdBreakStart?.({ adBreak });
      });
  }

  public onAdBreakEnd({ tracking }: OptionalTrackingPayload): void {
    if (tracking !== false)
      this.trackers.forEach((tracker) => {
        tracker.onAdBreakEnd?.();
      });
  }

  public onAdLoading(data: AdPayload): void {
    this.trackers.forEach((tracker) => {
      tracker.onAdLoading?.(data);
    });
  }

  public onAdMediaLoading(data: MediaLoadingPayload) {
    this.trackers.forEach((tracker) => {
      tracker.onAdMediaLoading?.(data);
    });
  }

  public onAdLoaded(data: AdPayload): void {
    this.trackers.forEach((tracker) => {
      tracker.onAdLoaded?.(data);
    });
  }

  public onAdPlaying(data: AdPayload): void {
    this.trackers.forEach((tracker) => {
      tracker.onAdPlaying?.(data);
    });
  }

  public onAdPaused(data: TimePayload): void {
    this.trackers.forEach((tracker) => {
      tracker.onAdPaused?.(data);
    });
  }

  public onAdResume(data: TimePayload): void {
    this.trackers.forEach((tracker) => {
      tracker.onAdResume?.(data);
    });
  }

  public onAdTimeUpdate(data: TimePayload): void {
    this.trackers.forEach((tracker) => {
      tracker.onAdTimeUpdate?.(data);
    });
  }

  public onAdBuffering(data: TimePayload): void {
    this.trackers.forEach((tracker) => {
      tracker.onAdBuffering?.(data);
    });
  }

  public onAdBuffered(data: TimePayload): void {
    this.trackers.forEach((tracker) => {
      tracker.onAdBuffered?.(data);
    });
  }

  public onAdError(data: ErrorPayload): void {
    this.trackers.forEach((tracker) => {
      tracker.onAdError?.(data);
    });
  }

  public onAdEnded(): void {
    this.trackers.forEach((tracker) => {
      tracker.onAdEnded?.();
    });
  }

  public onAdClick(data: AdClickPayload): void {
    this.trackers.forEach((tracker) => tracker.onAdClick?.(data));
  }

  public onFullscreenChanged(data: FullscreenPayload): void {
    this.trackers.forEach((tracker) => tracker.onFullscreenChanged?.(data));
  }

  public onVolumeChanged(data: VolumePayload): void {
    this.trackers.forEach((tracker) => tracker.onVolumeChanged?.(data));
  }

  public onPlaybackRateChanged(data: PlaybackRateChangePayload): void {
    this.trackers.forEach((tracker) => tracker.onPlaybackRateChanged?.(data));
  }

  /**
   * generic
   */

  public destroy() {
    this.trackers.forEach((tracker) => {
      tracker.destroy?.();
    });

    this.trackers = [];
  }
}
