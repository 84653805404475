import Core from './src/core/Core';
import CdnBalancer from './src/balancer/CdnBalancer';
import npaw from './src/analytics/npawlib';
import DiagnosticTool from './src/diagnostic/DiagnosticTool';
import Session from './src/sessions/Session';
import Log, { LogLevel } from './src/common/log';
import { AnalyticsTag } from './src/common/Constants';
import NpawPluginOptions from './src/core/NpawPluginOptions';
import AppAnalytics from './src/appAnalytics/AppAnalytics';
import ProductAnalytics from './src/productAnalytics/ProductAnalytics';
global.LogLevel = LogLevel;
global.NpawPluginOptions = NpawPluginOptions;
export { LogLevel } from './src/common/log';
export * from './src/core/NpawPluginOptions';
export default class NpawPlugin {
    constructor(accountCode, options) {
        this.core = Core.getInstance(accountCode, options, true);
        this.cdnBalancer = new CdnBalancer(accountCode, options);
        this.session = new Session(accountCode);
        this.appAnalytics = new AppAnalytics(this.session);
        this.videoAnalytics = new npaw.Plugin(this.appAnalytics, this.session);
        this.productAnalytics = new ProductAnalytics(this.session, this.videoAnalytics.getReferral.bind(this.videoAnalytics), this.setAnalyticsOptions.bind(this), this.videoAnalytics.addOnWillSendRequestListener.bind(this.videoAnalytics), this.videoAnalytics.removeOnWillSendRequestListener.bind(this.videoAnalytics));
        this.diagnostic = DiagnosticTool.getInstance(true);
        this.diagnostic.start(this);
    }
    destroy() {
        this.cdnBalancer.destroy();
        this.videoAnalytics.videos.getVideoKeys().forEach((videoKey) => {
            var _a, _b;
            if (typeof this.videoAnalytics !== 'undefined') {
                (_a = this.videoAnalytics.getAdapter(videoKey)) === null || _a === void 0 ? void 0 : _a.fireStop();
                (_b = this.videoAnalytics.getAdsAdapter(videoKey)) === null || _b === void 0 ? void 0 : _b.fireStop();
            }
        });
        this.videoAnalytics.destroy();
        this.session.destroy();
        this.diagnostic.destroy();
        this.core.destroy();
    }
    /**
     * Changes the log level of the NPAW Plugin. See {@link LogLevel} for the different levels available
     *
     * @param logLevel Log level to be used
     */
    setLogLevel(logLevel) {
        Log.setLogLevel(logLevel);
    }
    /**
     * Sets Balancer options. See {@link BalancerOptions}.
     * @param options Balancer options
     */
    setBalancerOptions(options) {
        this.cdnBalancer.setOptions(options);
    }
    /**
     * Sets the manifest URL of the played content in the Balancer options object.
     * For url changes the loader is restarted.
     * @param url Manifest url of the content being played.
     * @public
     */
    setManifestResource(url) {
        this.cdnBalancer.setManifestResource(url);
    }
    /**
     * Adds an interceptor to the Balancer
     * @param name Name of the interceptor
     * @param player Video player object
     */
    addInterceptor(name, player) {
        this.cdnBalancer.addInterceptor(name, player);
    }
    /**
     * Returns Bitmovin configuration for request interception
     */
    getBitmovinSendHttpRequest() {
        return this.cdnBalancer.getBitmovinSendHttpRequest();
    }
    /**
     * Returns the manifest/video URL to be used following the Selector API.
     * If an error occurs this will return undefined
     */
    getRecommendedManifest() {
        return this.cdnBalancer.getRecommendedManifest();
    }
    /**
     * @internal
     * @returns True if balancer is enabled, false otherwise
     */
    isBalancerEnabled() {
        return this.cdnBalancer.getIsEnabled();
    }
    /**
     * Register a video player within the Video Analytics module
     * If there already is a registered ads adapter, it is removed first.
     *
     * @param player Video player instance
     * @param adapterUrl URL of the JSON adapter
     * @param options Analytics options
     * @param videoKey Custom video identifier
     * @param callback Callback to be called after the adapter is registered
     */
    registerAdapter(player, adapterUrl, options, videoKey, callback) {
        if (this.core.isUsingLegacy())
            return;
        this.registerAdapterInModule(false, player, adapterUrl, options, videoKey, callback);
    }
    /**
     * Register a video player within the Video Analytics module
     * If there already is a registered ads adapter, it is removed first.
     *
     * @param player Video player instance
     * @param adapterClass Class of the adapter to be registered
     * @param options Analytics options
     * @param videoKey Custom video identifier
     * @param callback Callback to be called after the adapter is registered
     */
    registerAdapterFromClass(player, adapterClass, options, videoKey, callback) {
        if (typeof adapterClass !== 'undefined' && typeof adapterClass === 'function') {
            this.registerAdapterFromClassInModule(false, player, new adapterClass(), options, videoKey, callback);
        }
        else {
            Log.error(AnalyticsTag, 'Adapter is not valid');
        }
    }
    /**
     * Register a video player within the Video Analytics module
     * If there already is a registered ads adapter, it is removed first.
     *
     * @param player Video player instance
     * @param adapterString JSON code with the adapter configuration
     * @param options Analytics options
     * @param videoKey Custom video identifier
     * @param callback Callback to be called after the adapter is registered
     */
    registerAdapterFromString(player, adapterString, options, videoKey, callback) {
        if (typeof adapterString !== 'undefined' && typeof adapterString === 'string') {
            this.videoAnalytics.registerAdapter(player, adapterString, options, videoKey);
            this.registerAdapterProductAnalytics(videoKey);
            callback === null || callback === void 0 ? void 0 : callback();
        }
        else {
            Log.error(AnalyticsTag, 'The provided adapter is not from a valid type');
        }
    }
    /**
     * Registers a barebones player adapter without any intrinsic behavior within the Video Analytics module.
     * If there already is a registered ads adapter, it is removed first.
     *
     * @param videoKey Custom video identifier
     * @param options Analytics options
     */
    registerDefaultAdapter(videoKey, options) {
        if (this.core.isUsingLegacy())
            return;
        this.videoAnalytics.registerAdapter(undefined, '{}', options, videoKey);
        this.registerAdapterProductAnalytics(videoKey);
    }
    /**
     * Removes a registered video adapter after calling a fireStop. Optionally, a video identifier can be passed.
     *
     * @param videoKey Custom video identifier
     */
    removeAdapter(videoKey) {
        if (this.core.isUsingLegacy())
            return;
        if (videoKey &&
            this.videoAnalytics.videos.existsVideo(videoKey) &&
            this.getAdapter(videoKey) &&
            this.getAdapter(videoKey).fireStop) {
            this.getAdapter(videoKey).fireStop();
        }
        this.productAnalytics.removeAdapter(videoKey);
        this.videoAnalytics.removeAdapter(videoKey);
    }
    /**
     * Returns the registered player adapter. Optionally, a video identifier can be passed.
     *
     * @param videoKey Custom video identifier
     */
    getAdapter(videoKey) {
        return this.videoAnalytics.getAdapter(videoKey);
    }
    /**
     * Returns the registered ads adapter. Optionally, a video identifier can be passed.
     *
     * @param videoKey Custom video identifier
     */
    getAdsAdapter(videoKey) {
        return typeof this.videoAnalytics !== 'undefined' ? this.videoAnalytics.getAdsAdapter(videoKey) : undefined;
    }
    /**
     * Register an ads player within the Ads Analytics module.
     * If there already is a registered ads adapter, it is removed first.
     * This method is asynchronous.
     *
     * @param player Ads player/manager
     * @param adapterUrl URL of the JSON adapter
     * @param options Analytics options
     * @param videoKey Custom video identifier
     * @param callback Callback to be called after the adapter is registered
     */
    registerAdsAdapter(player, adapterUrl, options, videoKey, callback) {
        if (this.core.isUsingLegacy())
            return;
        this.registerAdapterInModule(true, player, adapterUrl, options, videoKey, callback);
    }
    /**
     * Register an ads player within the Ads Analytics module.
     * If there already is a registered ads adapter, it is removed first.
     *
     * @param player Video player instance
     * @param adapterClass Class of the ads adapter to be registered
     * @param options Analytics options
     * @param videoKey Custom video identifier
     * @param callback Callback to be called after the adapter is registered
     */
    registerAdsAdapterFromClass(player, adapterClass, options, videoKey, callback) {
        if (typeof adapterClass !== 'undefined' && typeof adapterClass === 'function') {
            this.registerAdapterFromClassInModule(true, player, new adapterClass(), options, videoKey, callback);
        }
        else {
            Log.error(AnalyticsTag, 'Adapter is not valid');
        }
    }
    /**
     * Register an ads player within the Ads Analytics module.
     * If there already is a registered ads adapter, it is removed first.
     *
     * @param player Ads player/manager
     * @param adapterString JSON code with the adapter configuration
     * @param options Analytics options
     * @param videoKey Custom video identifier
     * @param callback Callback to be called after the adapter is registered
     */
    registerAdsAdapterFromString(player, adapterString, options, videoKey, callback) {
        if (typeof adapterString !== 'undefined' && typeof adapterString === 'string') {
            this.videoAnalytics.registerAdsAdapter(player, adapterString, options, videoKey);
            callback === null || callback === void 0 ? void 0 : callback();
        }
        else {
            Log.error(AnalyticsTag, 'The provided adapter is not from a valid type');
        }
    }
    /**
     * Update the AdsAdapter by name, that is previously loaded internally with the registerAdapter method
     *
     * @param adsAdapterName
     * @param videoKey Custom video identifier
     */
    updateAdsAdapter(adsAdapterName, videoKey) {
        videoKey = videoKey !== null && videoKey !== void 0 ? videoKey : 'default';
        if (typeof adsAdapterName !== 'undefined' && adsAdapterName != '') {
            this.videoAnalytics.updateAdsAdapter(adsAdapterName, videoKey);
        }
        else {
            Log.debug(AnalyticsTag, 'Invalid ads adapter name');
        }
    }
    /**
     * Registers a barebones ads adapter without any intrinsic behavior within the Video Analytics module.
     * If there already is a registered ads adapter, it is removed first.
     *
     * @param videoKey Custom video identifier
     * @param options Analytics options
     */
    registerDefaultAdsAdapter(videoKey, options) {
        if (this.core.isUsingLegacy())
            return;
        this.videoAnalytics.registerAdsAdapter(undefined, '{}', options, videoKey);
    }
    /**
     * Removes a registered ads adapter. Optionally, a video identifier can be passed.
     *
     * @param videoKey Custom video identifier
     */
    removeAdsAdapter(videoKey) {
        if (this.core.isUsingLegacy())
            return;
        this.videoAnalytics.removeAdsAdapter(videoKey);
    }
    /**
     * Sets Analytics options. See {@link npaw.Options.setOptions}.
     *
     * @param options Analytics options object
     */
    setAnalyticsOptions(options) {
        if (this.core.isUsingLegacy())
            return;
        this.videoAnalytics.setOptions(options);
        this.session.setOptions(this.videoAnalytics.options);
        this.core.setOptions(this.videoAnalytics.options);
        this.diagnostic.setOptions(this.videoAnalytics.options);
    }
    /**
     * Sets analytics options for a specific video.
     * If no videoKey is provided, 'default' will be used
     *
     * @param options Analytics options object to set
     * @param videoKey Video Identifier
     */
    setVideoOptions(options, videoKey) {
        if (!options) {
            Log.debug(AnalyticsTag, 'Provided options object is not valid');
            return;
        }
        this.videoAnalytics.setVideoOptions(options, videoKey !== null && videoKey !== void 0 ? videoKey : 'default');
    }
    /**
     * Updates the user defined custom metrics
     *
     * @param metrics Metrics to be updated
     * @param videoKey Video Identifier
     */
    updateMetrics(metrics, videoKey) {
        if (!metrics) {
            Log.debug(AnalyticsTag, 'Provided metrics object is not valid');
            return;
        }
        this.videoAnalytics.updateCustomMetrics(metrics, videoKey !== null && videoKey !== void 0 ? videoKey : 'default');
    }
    /**
     * Sends the events that were previously stored during an offline session
     */
    fireOfflineEvents() {
        if (this.core.isUsingLegacy())
            return;
        if (this.videoAnalytics.videos.getVideoKeys().length > 0) {
            Log.notice(AnalyticsTag, "Unable to send offline events because there's at least one adapter registered");
            return;
        }
        this.core.sendOfflineEvents();
    }
    registerAdapterFromClassInModule(isAds, player, adapterClass, options, videoKey, callback) {
        if (!isAds) {
            this.videoAnalytics.registerAdapter(player, adapterClass, options, videoKey);
            this.registerAdapterProductAnalytics(videoKey);
        }
        else {
            this.videoAnalytics.registerAdsAdapter(player, adapterClass, options, videoKey);
        }
        callback === null || callback === void 0 ? void 0 : callback();
    }
    registerAdapterInModule(isAds, player, adapterUrl, options, videoKey, callback) {
        if (adapterUrl.length === 0) {
            Log.error("Adapter URL can't be empty!");
        }
        // If fetch doesn't exist use XHR instead
        if (typeof fetch != 'undefined') {
            fetch(adapterUrl)
                .then((res) => res.json())
                .then((adapterJson) => {
                const adapterJsonCode = JSON.stringify(adapterJson);
                if (!isAds) {
                    this.videoAnalytics.registerAdapter(player, adapterJsonCode, options, videoKey);
                    this.registerAdapterProductAnalytics(videoKey);
                }
                else {
                    this.videoAnalytics.registerAdsAdapter(player, adapterJsonCode, options, videoKey);
                }
                callback === null || callback === void 0 ? void 0 : callback();
            });
        }
        else {
            const xhr = new XMLHttpRequest();
            xhr.addEventListener('readystatechange', () => {
                if (xhr.readyState === 4) {
                    if (xhr.status >= 200 && xhr.status < 400) {
                        const adapterJsonCode = xhr.response;
                        if (!isAds) {
                            this.videoAnalytics.registerAdapter(player, adapterJsonCode, options, videoKey);
                            this.registerAdapterProductAnalytics(videoKey);
                        }
                        else {
                            this.videoAnalytics.registerAdsAdapter(player, adapterJsonCode, options, videoKey);
                        }
                        callback === null || callback === void 0 ? void 0 : callback();
                    }
                }
            });
            xhr.open('GET', adapterUrl);
            xhr.send();
        }
    }
    /**
     * Register adapter in Product Analytics
     * @param videoKey
     */
    registerAdapterProductAnalytics(videoKey) {
        /*
         * this.getAdapters returns an object of Adapter type. The Adapter type does not define the on
         * and off event listeners. So, to avoid typing issues, we access this.videoAnalytics.getAdapter
         * instead of this.getAdapter since the former returns a generic object instead of an Adapter.
         */
        const adapter = this.videoAnalytics.getAdapter(videoKey);
        this.productAnalytics.registerAdapter(adapter, videoKey);
    }
}
