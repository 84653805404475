import { AdInsertionType, IAd } from "@tv4/avod-web-player-common";

import { Adapter } from "./Adapter";
import {
  AdapterStateManager,
  ADS_ADAPTER_INITIAL_STATE,
  AdsAdapterState,
} from "./AdapterStateManager";
import { slugify } from "./helpers";

// The extended adapter has type any, create our own
// interface for the documented methods.
export interface IAdsAdapter extends Adapter {
  // Implement these
  getGivenBreaks(): [];
  getExpectedBreaks(): [];
  getExpectedPattern?();
  getGivenAds(): number;
  getExpectedAds?(): [];
  getAudioEnabled(): boolean;
  getIsSkippable(): boolean;
  getIsFullscreen(): boolean;
  getCreativeId();
  getAdInsertionType(): "csai" | "ssai";

  getCampaign?();
  getProvider?();
  // Return if the ad is being shown in the screen or not.
  // The standard definition is more than 50% of the pixels of
  // the ad are on the screen.
  getIsVisible?(): boolean;
  getBreaksTime?();

  // Build in methods
  fireQuartile(quartile: number): void;
  fireAdBreakStart(): void;
  fireAdBreakStop(): void;
  fireManifest(): void;
  fireClick(url: string): void;
  fireSkip(): void;
}

export enum NpawAdReports {
  START = 0,
  FIRST_QUARTILE = 0.25,
  SECOND_QUARTILE = 0.5,
  THIRD_QUARTILE = 0.75,
  END = 0.98,
}

export class AdsAdapter {
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // NOTE: Once NPAW plugin has processed the class, all
  // custom properties will the stripped from it.
  // Including anything set from the constructor.
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  // Once NPAW processes the class, player will be
  // a reference to the first parameter passed to
  // registerAdsAdapterFromClass(--> player <--, adapter)
  //
  // Assign it here for TypeScript purposes. This property
  // will be stripped by NPAW plugin before being merged with
  // their internal base adapter functionality.
  private player = new AdapterStateManager<AdsAdapterState>(
    ADS_ADAPTER_INITIAL_STATE
  );

  public getPlayerName() {
    return this.player.getState().playerName;
  }

  public getPlayerVersion() {
    return this.player.getState().playerVersion;
  }

  public getResource() {
    return this.player.getState().source;
  }

  public getDuration() {
    return (
      this.player.getState().ongoingAdDetails?.creative.duration ||
      this.player.getState().duration
    );
  }

  public getPlayhead() {
    return this.player.getState().playhead;
  }

  public getTitle() {
    return this.player.getState().ongoingAdDetails?.title || "";
  }

  public getTitle2() {
    return this.player.getState().ongoingAdDetails?.id || "";
  }

  // Bits per second
  public getBitrate() {
    return this.player.getState().bitrate;
  }

  public registerListeners() {
    // noop, listeners are handled in NpawTracker.ts
  }

  public unregisterListeners() {
    // noop, listeners are handled in NpawTracker.ts
  }

  // Return current ad position (usually pre, mid, or post break).
  public getPosition() {
    return this.player.getState().adBreakPosition;
  }

  // Return the number of ads given for the break.
  public getGivenAds() {
    return this.player.getState().numberOfAds || 0;
  }

  // Return a boolean showing if the audio is enabled when the ad begins.
  public getAudioEnabled() {
    return !this.player.getState().muted;
  }

  // Return if the ad is skippable.
  public getIsSkippable() {
    return false;
  }

  // Return a boolean showing if the player is in full-screen mode when the ad begins.
  public getIsFullscreen() {
    return this.player.getState().fullscreen;
  }

  public getCreativeId() {
    const ad = this.player.getState().ongoingAdDetails;
    if (!ad) return;

    const details: IAd = ad;

    let creativeId = ad.universalAdId;

    if (!details.universalAdId && details.id) {
      creativeId = details.id;
    } else if (!details.universalAdId && !details.id) {
      creativeId = slugify(details.title);
    }

    return creativeId;
  }

  public getAdInsertionType() {
    switch (this.player.getState().adInsertionType) {
      case AdInsertionType.ClientSide:
        // @ts-expect-error this method exists after NPAW does it's
        //  magical smushing
        return this.getNpawReference().Constants.AdInsertionType.ClientSide;
      case AdInsertionType.ServerSide:
        // @ts-expect-error this method exists after NPAW does it's
        //  magical smushing
        return this.getNpawReference().Constants.AdInsertionType.ServerSide;
      default:
        return null;
    }
  }
}
