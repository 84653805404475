import { MediaLicense } from "@tv4/avod-web-player-common";
import { HlsConfig } from "hls.js";

export const getHlsJsDrmConfig = ({ licenseUrl, getToken }: MediaLicense) => {
  const config: Partial<HlsConfig> = {
    emeEnabled: true,
    drmSystems: {
      "com.widevine.alpha": {
        licenseUrl,
      },
      "com.microsoft.playready": {
        licenseUrl,
      },
    },
    licenseXhrSetup: async (xhr, url, keyContext, licenseChallenge) => {
      const { keySystem } = keyContext;

      const token = await getToken();
      if (!token) return licenseChallenge;

      if (
        keySystem === "com.widevine.alpha" ||
        keySystem === "com.microsoft.playready"
      ) {
        xhr.open("POST", url, true);
        xhr.setRequestHeader("x-dt-auth-token", token);
      }

      return licenseChallenge;
    },
    licenseResponseCallback: (xhr, _url) => {
      const { response } = xhr;
      return response;
    },
  };

  return config;
};
