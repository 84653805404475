const { default: Log } = require('../../common/log');
var Constants = require('../constants');
const { default: DiagnosticTool } = require('../../diagnostic/DiagnosticTool');
const { default: Core } = require('../../core/Core');
const { default: VideoAnalyticsRequest } = require('../comm/VideoAnalyticsRequest');
const { AnalyticsTag, Method } = require('../../common/Constants');
const { default: Util } = require('../../core/utils/Util');
const { default: CoreConstants } = require('../../core/utils/CoreConstants');

var NpawVideoPingMixin = {
  /**
   * Starts sending pings.
   *
   * @private
   * @memberof npaw.Plugin.prototype
   */
  _startPings: function () {
    if (!this.sendingPings) {
      var reqMethod = this.plugin.isMethodPostEnabled() ? Method.POST : Method.GET;
      const pingTime = Core.getInstance().getFastDataService().getPingTime() * 1000;
      Core.getInstance().pushPeriodicDataFromCallback(
        this.plugin.options.offline ? CoreConstants.Consumer.PERSISTENT : CoreConstants.Consumer.NQS,
        this.getVideoKey(),
        Constants.Service.PING,
        pingTime,
        reqMethod,
        this._sendPing.bind(this),
        this._onPingSuccess.bind(this),
        undefined,
        this.expirationManager
      );
      this.sendingPings = true;
    }
  },

  /**
   * Code to execute when a PING request is sent successfully
   *
   * @private
   * @memberof npaw.Plugin.prototype
   */
  _onPingSuccess: function () {
    DiagnosticTool.getInstance().addNQSRequest(Constants.Service.PING);
    this.lastEventTime = new Date().getTime();
  },

  /**
   * Stops sending pings.
   *
   * @private
   * @memberof npaw.Plugin.prototype
   */
  _stopPings: function () {
    Core.getInstance().unregisterPeriodicPush(this.getVideoKey());
    this.sendingPings = false;
  },

  /**
   * Sends ping request
   *
   * @param {number} diffTime Time since the last ping
   *
   * @private
   * @memberof npaw.Plugin.prototype
   */
  _sendPing: function () {
    if (!this.plugin.analyticsRequestHandler.shouldSendStats()) {
      return;
    }

    if (this._expiredSession()) {
      return
    }

    const now = new Date().getTime();
    const diffTime = this.timeSinceLastPing ? now - this.timeSinceLastPing : 5000;
    this.timeSinceLastPing = now;

    var params = {
      diffTime: diffTime,
      entities: this.requestBuilder.getChangedEntities([this, this.plugin], this.options)
    };
    if (this._adapter) {
      if (this._adapter.flags.isPaused) {
        params = this.requestBuilder.fetchParams(params, ['pauseDuration'], false, [this, this.plugin], this.options);
      } else {
        params = this.requestBuilder.fetchParams(params, ['bitrate', 'throughput', 'fps'], false, [this, this.plugin], this.options);
      }
      if (this._adapter.flags.isJoined) {
        params = this.requestBuilder.fetchParams(params, ['playhead'], false, [this, this.plugin], this.options);
      }
      if (this._adapter.flags.isBuffering) {
        params = this.requestBuilder.fetchParams(params, ['bufferDuration'], false, [this, this.plugin], this.options);
      }
      if (this._adapter.flags.isSeeking) {
        params = this.requestBuilder.fetchParams(params, ['seekDuration'], false, [this, this.plugin], this.options);
      }
    }

    if (this._adsAdapter && !this.options['ad.ignore']) {
      if (this._adsAdapter.flags.isStarted) {
        params = this.requestBuilder.fetchParams(params, ['adPlayhead', 'adViewedDuration', 'adViewability'], false, [
          this,
          this.plugin
        ], this.options);
        if (this._adsAdapter.flags.isPaused) {
          params = this.requestBuilder.fetchParams(params, ['adPauseDuration'], false, [this, this.plugin], this.options);
        } else {
          params = this.requestBuilder.fetchParams(params, ['adBitrate'], false, [this, this.plugin], this.options);
        }
      }
      if (this._adsAdapter.flags.isBuffering) {
        params = this.requestBuilder.fetchParams(params, ['adBufferDuration'], false, [this, this.plugin], this.options);
      }
    }

    params = this.requestBuilder.buildParams(params, Constants.Service.PING, [this, this.plugin], this.options);

    if (this.controlPlayerExists()) {
      if (this._adapter && !this._adapter.checkExistsPlayer()) {
        Log.debug(AnalyticsTag, 'Detected player no exists, and fire stop event');
        this._adapter.fireStop();
        return;
      }
    }

    DiagnosticTool.getInstance().setTriedSendingNQSStats(true);
    var reqMethod = this.plugin.isMethodPostEnabled() ? Method.POST : Method.GET;
    const request = new VideoAnalyticsRequest(Constants.Service.PING, params, this.getVideoKey(), reqMethod, undefined, undefined, undefined, this.expirationManager);

    let videoKey = request.getVideoKey();
    let serviceName = request.getService();

    const listeners = this.plugin.analyticsRequestHandler.onWillSendVideoRequestListeners;
    for (const listener of listeners) {
      listener(serviceName, videoKey, params);
    }

    this.plugin.analyticsRequestHandler.prepareParams(request);

    Log.verbose(AnalyticsTag, Constants.Service.PING);
    if (this.startDelayed) {
      this._retryStart();
    }
    return request.getParams();
  }
};

module.exports = NpawVideoPingMixin;
