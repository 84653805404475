import {
  getRemainingAdImmunity,
  hasAdImmunity,
  version,
} from "@tv4/avod-web-player-core";
import { useEffect, useRef, useState } from "react";

import { useDebugInfo, usePlayerState } from "../../providers/CoreProvider";
import { useMetadata } from "../../providers/MetadataProvider";
import {
  OverlayContainer,
  Table,
  TableHeading,
  TableValue,
  Title,
} from "./DebugOverlay.styles";

const useInterval = (callback: () => void, delay: number) => {
  const callbackRef: { current: () => void } = useRef(callback);
  const intervalIdRef: { current: number } = useRef(-1);

  useEffect(() => {
    // always keep reference to callback from last render
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay !== -1) {
      intervalIdRef.current = window.setInterval(
        () => callbackRef.current(),
        delay * 1000
      );
    }
    // stop interval when unmounted or when dependencies change
    return () => {
      if (intervalIdRef.current !== -1) {
        window.clearInterval(intervalIdRef.current);
        intervalIdRef.current = -1;
      }
    };
  }, [delay]);
};

const DebugOverlay = () => {
  const state = usePlayerState();
  const { asset } = useMetadata();
  const debugInfo = useDebugInfo();
  const [adImmunityRemaining, setAdImmunityRemaining] = useState(0);

  const engineInfo = debugInfo?.mediaEngine?.getDebugInfo();
  const { engineName, engineVersion } = engineInfo || {};

  const adImmunityDuration = debugInfo?.adImmunityDuration || 0;

  const immune = asset && hasAdImmunity(asset.id, adImmunityDuration);

  const allBitrates = [...(engineInfo?.bitrates ?? [])]
    .sort((a, b) => b.bitrate - a.bitrate)
    .map((track) => {
      const prefix = state.currentBitrate === track.bitrate ? "🟢 " : "   ";
      const resolution = `${prefix}${track.width}x${track.height}`.padStart(
        12,
        " "
      );
      return `${resolution} ${track.bitrate / 1000000}Mb/s 🎞️${track.videoCodec} 🎵${track.audioCodec}`;
    });

  // Remove duplicates caused by multiple audio languages
  const bitrates = [...new Set(allBitrates)];

  const textTracks = state.textTracks.flatMap((track) => track.language || []);

  useInterval(
    () => {
      setAdImmunityRemaining(
        asset ? getRemainingAdImmunity(asset.id, adImmunityDuration) : 0
      );
    },
    immune ? 1 : -1
  );

  const adImmunity = immune
    ? `🔓 ${adImmunityRemaining} / ${adImmunityDuration}s remaining`
    : "🔒";

  return (
    <OverlayContainer>
      <Title>Debug Overlay</Title>
      <Table>
        <tbody>
          <tr>
            <TableHeading>Version</TableHeading>
            <TableValue>{version}</TableValue>
          </tr>
          <tr>
            <TableHeading>Engine</TableHeading>
            <TableValue>
              {engineVersion
                ? `${engineName} (${engineVersion})`
                : (engineName ?? "")}
            </TableValue>
          </tr>
          <tr>
            <TableHeading>Playback</TableHeading>
            <TableValue>{debugInfo?.playbackController?.name}</TableValue>
          </tr>
          <tr>
            <TableHeading>Vimond id</TableHeading>
            <TableValue>{asset?.vimondId}</TableValue>
          </tr>
          <tr>
            <TableHeading>Ad immunity</TableHeading>
            <TableValue>{adImmunity}</TableValue>
          </tr>
          <tr>
            <TableHeading>Subtitles</TableHeading>
            <TableValue>{textTracks.join(", ")}</TableValue>
          </tr>
          <tr>
            <TableHeading>Bitrates</TableHeading>
            <TableValue>{bitrates.join("\n")}</TableValue>
          </tr>
        </tbody>
      </Table>
    </OverlayContainer>
  );
};

export { DebugOverlay };
