import { useCallback, useEffect, useRef, useState } from "react";

import useAutoHideUI from "../hooks/useAutoHideUI";
import { useUIVisibility } from "../providers/LayoutProvider";

type TUseDraggingProps = {
  onDragChange?: (event: PointerEvent) => void;
  onDragStop?: (event?: PointerEvent) => void;
};

export default function useDragging({
  onDragChange,
  onDragStop,
}: TUseDraggingProps) {
  const { handleInteractionStart, handleInteractionEnd } = useAutoHideUI();

  // state is used to update and propagate dragging state
  const [dragging, setDragging] = useState(false);
  // local ref is used to track actual dragging state to avoid updating interaction incorrectly when events trigger multiple updates before the state value has updated
  const draggingState = useRef(false);

  const startDragging = useCallback(
    (event: React.PointerEvent) => {
      if (!draggingState.current) {
        // prevent default to stop dragging from starting selecting content. event should still propagate so the interaction is detected and auto hide is reset.
        event.preventDefault();
        handleInteractionStart();
        setDragging(true);
        draggingState.current = true;
        onDragChange?.(event.nativeEvent);
      }
    },
    [handleInteractionStart, setDragging, onDragChange]
  );

  const stopDragging = useCallback(
    (event?: PointerEvent) => {
      if (draggingState.current) {
        setDragging(false);
        draggingState.current = false;
        handleInteractionEnd();
        onDragStop?.(event);
      }
    },
    [setDragging, handleInteractionEnd, onDragStop]
  );

  useEffect(() => {
    if (dragging) {
      const handleStopDrag = (event: PointerEvent) => {
        onDragChange?.(event);
        stopDragging(event);
      };
      const handleRelease = (event: PointerEvent) => {
        event.stopPropagation();
        handleStopDrag(event);
      };

      // TODO instead of using global, native, pointer event listeners,
      //  rewrite to use React synthetic events with pointer capture
      //  to prevent clicks outside of the desired target.
      if (onDragChange) {
        window.addEventListener("pointermove", onDragChange);
      }
      window.addEventListener("pointerup", handleRelease);
      /**
       * committing drag if dragged outside window. may depend on what browser is used,
       * but main reason for this is to avoid getting stuck in dragging state if releasing pointer outside window.
       * adding to body because adding to window seems to not work.
       * pointerleave is also triggered on touch devices when ending touch, but at that point dragging would also stop regardless.
       */
      window.document.body.addEventListener("pointerleave", handleStopDrag);
      return () => {
        if (onDragChange) {
          window.removeEventListener("pointermove", onDragChange);
        }
        window.removeEventListener("pointerup", handleRelease);
        window.document.body.removeEventListener(
          "pointerleave",
          handleStopDrag
        );
      };
    }
  }, [stopDragging, dragging, onDragChange]);

  const { uiVisible } = useUIVisibility();

  // stop dragging if ui is no longer visible
  useEffect(() => {
    if (dragging && !uiVisible) {
      stopDragging();
    }
  }, [uiVisible, dragging, stopDragging]);

  return {
    dragging,
    startDragging,
    stopDragging,
  };
}
