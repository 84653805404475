/**
 * https://engineeringportal.nielsen.com/docs/DCR_Sweden_Video_Browser_SDK
 */
import {
  AdBreakPayload,
  AdPayload,
  AdVideoVariant,
  IAd,
  NielsenTrackingMetadata,
  TimePayload,
} from "@tv4/avod-web-player-common";
import { SHA512 } from "jshashes";

import { getAdType, isStopOrEnd, nielsenBool } from "./nielsen-utils/methods";
import {
  NielsenAdBreakType,
  NielsenAdMetadata,
  NielsenContentMetadata,
  NielsenEvent,
  NielsenInstance,
  nlns,
} from "./nielsen-utils/Nielsen";
import { ITrackerSetupOptions, Tracker } from "./tracker";

export interface INielsenTrackerInstanceOptions {
  debug?: boolean;
  trackingMetadata: NielsenTrackingMetadata;
  playerName: string;
  appId: string;
  playerVersion: string;
  userInitiatedPlayback: boolean;
}

export class NielsenTracking extends Tracker {
  private debug: boolean = false;

  private appId: string;

  private adIndex = 0;
  private adTotal = 0;

  private nlsn: NielsenInstance | undefined;

  private lastTimePayload: TimePayload = {
    currentTime: -1,
    duration: 0,
    isInAdBreak: false,
  };
  private lastEvent?: NielsenEvent;

  private adBreakType?: NielsenAdBreakType;
  private currentMetadata?: NielsenContentMetadata | NielsenAdMetadata | null;

  private isLive = false;
  // if the live stream doesn't have PDT (utcCurrentTimeMs) we use a calculated start time to track playhead position
  // this is only used if utcCurrentTimeMs is not available, eg. virtual channels
  private liveStartTimeSeconds = 0;
  private contentMetadata: NielsenContentMetadata;

  private onWindowUnload: () => void;

  constructor(options: INielsenTrackerInstanceOptions) {
    super();
    if (options?.debug) {
      this.debug = options.debug;
      console.debug("NielsenTracking initialized");
    }

    this.appId = options.appId;

    this.contentMetadata = {
      ...options.trackingMetadata.contentMetadata,
      playerv: options.playerName,
      plugv: options.playerVersion,
      isautoplay: nielsenBool(!options.userInitiatedPlayback),
    };

    this.onWindowUnload = () => this.onEnd(this.lastTimePayload);
    window.addEventListener("beforeunload", this.onWindowUnload);
  }

  public override setup(options: ITrackerSetupOptions): void {
    super.setup(options);
    this.nlsn = nlns.nlsQ(this.appId, options.sessionId, {
      ...(this.debug
        ? {
            // debug status is not expected to change dynamically
            nol_sdkDebug: "debug",
          }
        : {}),
    });

    const userId = options.userId;
    this.contentMetadata.userid =
      userId && userId !== "-1" ? new SHA512().hex(userId) : "";
    this.isLive = !!options.metadata?.asset.isLive;

    this.loadMetadata(this.contentMetadata);
  }

  public onLoaded(payload: TimePayload): void {
    if (this.isLive) {
      this.liveStartTimeSeconds = Date.now() / 1000 - payload.duration;
    }
  }

  public onStart(): void {
    this.loadMetadata(this.contentMetadata);
  }

  // TODO onPlay no longer means what it used to
  public onPlay(payload: TimePayload): void {
    this.setPlayheadPosition(payload);
  }

  public onPause(payload: TimePayload): void {
    this.onStop(payload);
  }

  public onBuffering(payload: TimePayload): void {
    this.onStop(payload);
  }

  public onTimeUpdate(payload: TimePayload): void {
    this.setPlayheadPosition(payload);
  }

  public onEnded(): void {
    this.onEnd(this.lastTimePayload);
  }

  public onAdBreakStart({ adBreak: { breakType, ads } }: AdBreakPayload): void {
    const adBreakType = getAdType(breakType) || undefined;
    if (adBreakType === NielsenAdBreakType.MIDROLL) {
      this.onStop(this.lastTimePayload, true);
      this.currentMetadata = null;
    }

    const adsCount = ads.filter((ad: IAd) => {
      return (
        ad.variant !== AdVideoVariant.VIGNETTE &&
        ad.variant !== AdVideoVariant.TRAILER
      );
    }).length;
    this.adIndex = 0;
    this.adTotal = adsCount;
    this.adBreakType = adBreakType;
  }

  public onAdBreakEnd(): void {
    this.loadMetadata(this.contentMetadata);
  }

  public onAdLoading({
    ad: {
      universalAdId,
      variant,
      creative: { duration, adId },
    },
  }: AdPayload): void {
    if (!this.adBreakType) {
      console.warn(
        "[NielsenTracking] Ad detected before ad break, something is wrong"
      );
      return;
    }
    if (
      variant === AdVideoVariant.VIGNETTE ||
      variant === AdVideoVariant.TRAILER
    ) {
      // jingles & trailers are ignored in tracking
      this.currentMetadata = null;
      return;
    }

    const adMetadata: NielsenAdMetadata = {
      type: this.adBreakType,
      adidx: `${++this.adIndex}/${this.adTotal}`,
      assetid: universalAdId || "empty",
      ad_campaign: adId,
      length: duration ? Math.round(duration).toString() : "",
    };
    this.loadMetadata(adMetadata);
  }

  public onAdTimeUpdate(data: TimePayload): void {
    this.onTimeUpdate(data);
  }

  public onAdBuffering(payload: TimePayload): void {
    this.onStop(payload);
  }

  public onAdPaused(payload: TimePayload): void {
    this.onStop(payload);
  }

  public onAdError(): void {
    this.onStop(this.lastTimePayload);
  }

  public onAdEnded(): void {
    this.onStop(this.lastTimePayload);
  }

  private loadMetadata(
    metadata: NielsenContentMetadata | NielsenAdMetadata | undefined
  ): void {
    if (!metadata || metadata === this.currentMetadata) {
      // ignore sending metadata if it's the same, will only be true for content metadata
      return;
    }
    this.currentMetadata = metadata;
    this.track(NielsenEvent.LOAD_METADATA, metadata);
  }

  private setPlayheadPosition(payload: TimePayload): void {
    const position = this.getPlayheadPosition(payload);
    const lastPosition = this.getPlayheadPosition(this.lastTimePayload);
    if (position !== lastPosition) {
      this.lastTimePayload = payload;
      this.track(NielsenEvent.SET_PLAYHEAD_POSITION, position);
    }
  }

  private getPlayheadPosition({
    currentTime,
    utcCurrentTimeMs,
  }: TimePayload): number {
    if (this.isLive) {
      if (utcCurrentTimeMs) {
        return Math.round(utcCurrentTimeMs / 1000);
      }
      return Math.round(this.liveStartTimeSeconds + currentTime);
    }
    return Math.round(currentTime);
  }

  private onStop(payload: TimePayload, force = false): void {
    this.track(NielsenEvent.STOP, this.getPlayheadPosition(payload), force);
  }

  private onEnd(payload: TimePayload, force = false): void {
    this.track(NielsenEvent.END, this.getPlayheadPosition(payload), force);
  }

  private track(
    event: NielsenEvent,
    payload: NielsenContentMetadata | NielsenAdMetadata | number,
    force = false
  ): void {
    if (
      !force &&
      (!this.currentMetadata ||
        (event === this.lastEvent && isStopOrEnd(event)))
    ) {
      return;
    }
    this.lastEvent = event;
    if (this.nlsn) {
      this.nlsn.ggPM(event, payload);
    }
  }

  public destroy(): void {
    this.onEnd(this.lastTimePayload, true);
    this.currentMetadata = null;
    window.removeEventListener("beforeunload", this.onWindowUnload);
  }
}
