type ID3Tag = {
  key: string;
  data: string;
};

export function byteToStr(byteArray: Uint8Array): string {
  let string = "";

  for (let i = 0; i < byteArray.length; i += 1) {
    string += String.fromCharCode(byteArray[i]);
  }

  return string;
}

export function parseID3ByteArray(byteArray: Uint8Array): ID3Tag[] {
  /* eslint-disable no-bitwise -- intentionally using bitwise operator */
  const tags: ID3Tag[] = [];
  let offset = 0;

  if (
    !(byteArray[0] === 0x49 && byteArray[1] === 0x44 && byteArray[2] === 0x33)
  ) {
    // Should be "ID3", but if receiving un-proxied segments, other data may be present.
    // Just return empty tags array in this case.
    return tags;
  }

  // %abc00000
  // a - Unsynchronisation
  // b - Extended header
  // c - Experimental indicator
  const flags = byteArray[5];
  const usesSynch = (flags & 0x80) !== 0;
  const hasExtendedHdr = (flags & 0x40) !== 0;
  // 4 bytes (4 * %0xxxxxxx) MSB is set to zero in every byte
  const size =
    (byteArray[9] & 0xff) |
    ((byteArray[8] & 0xff) << 7) |
    ((byteArray[7] & 0xff) << 14);

  offset += 10; // Headersize is 10 bytes

  if (usesSynch) {
    throw "Uses Synch not supported yet";
  }

  if (hasExtendedHdr) {
    throw "Has extended hdr not supported yet";
  }

  // TODO: add support for extended hdr
  // TODO: add support for usesSynch

  const frameHeaderSize = byteArray[3] < 3 ? 6 : 10;

  while (offset < byteArray.length) {
    if (offset > size + 10) {
      break;
    }

    const frameSize =
      (byteArray[offset + 7] & 0xff) |
      ((byteArray[offset + 6] & 0xff) << 8) |
      ((byteArray[offset + 5] & 0xff) << 16) |
      (byteArray[offset + 4] << 24);

    if (frameSize > 0) {
      const key = byteToStr(byteArray.slice(offset, offset + 4));
      const data = byteToStr(
        byteArray.slice(offset + 11, offset + frameSize + frameHeaderSize)
      );

      tags.push({
        key,
        data,
      });
    }

    offset += frameSize + frameHeaderSize;
  }

  return tags;
  /* eslint-enable no-bitwise -- restore */
}
